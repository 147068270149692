import apiHandler from './apiHandler';

export enum DeviceMode {
	Checkin = 'Checkin',
	Checkout = 'Checkout',
	None = 'None',
}

export enum DeviceCaps {
	TempOnly = 'TempOnly',
	FaceRecOnly = 'FaceRecOnly',
	FaceRecAndTemp = 'FaceRecAndTemp',
	None = 'None',
}

export type CreateDeviceProps = {
	deviceId: string;
	deviceCaps: DeviceCaps;
	name: string;
	mode: DeviceMode;
};

export type DeviceInfo = {
	id: number;
	deviceId: string;
	deviceCaps: DeviceCaps;
	name: string;
	mode: DeviceMode;
};

export async function getDevicesList() {
	return await apiHandler.get(`/settings/devices`);
}
export async function createDevice(postData: CreateDeviceProps) {
	return await apiHandler.post('/settings/devices', postData);
}
export async function deleteDevice(deviceId: string) {
	return await apiHandler.delete('/settings/devices?id=' + deviceId);
}

export function DeviceCapabilitiesList() {
	const out: any[] = [];
	Object.keys(DeviceCaps).forEach((k, index) => {
		out.push({ label: k, value: Object.values(DeviceCaps)[index] });
	});
	return out;
}

export function DeviceModeList() {
	const out: any[] = [];
	Object.keys(DeviceMode).forEach((k, index) => {
		out.push({ label: k, value: Object.values(DeviceMode)[index] });
	});
	return out;
}

const devicesAPI = {
	getDevicesList,
	createDevice,
	deleteDevice,
};

export default devicesAPI;
