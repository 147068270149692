import apiHandler from './apiHandler';

export async function transportList() {
	return apiHandler.get(`/student/transport`);
}

const transportAPI = {
	transportList,
};

export default transportAPI;
