import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { COLUMNS, getLeaveList } from './component/tableData';
import { Link, useNavigate } from 'react-router-dom';
import BasicModal from '../Dashboard/BasicModal';
import leaveAPI from '../../../api/leaveApi';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import swal from 'sweetalert';
import EmptyState from '../components/EmptyState';
import Pagination from '../components/Pagination';
import * as dateFn from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import RejectionPopUp from './component/RejectionPopUp';

const LeaveManagement = ({ historical = false }) => {
	const childRef = useRef();
	const [currentPage, setCurrentPage] = useState(1);
	const [unchecked, setUnChecked] = useState(true);
	
	const [modalShow, setModalShow] = useState(false);
	const [modalRow, setModalRow] = useState();

	const [leaveList, setLeaveList] = useState([]);
	const [checked, setChecked] = useState([]);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => leaveList, [leaveList]);

	useEffect(() => {
		getList();
	}, [historical]);

	const getList = async () => {
		const list = await getLeaveList(historical);
		setLeaveList(list);
	};

	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;

	const toggleModalOpen = () =>{
		setModalShow(true);
	}
	const toggleModalClose = () => {
		setModalShow(false);
	}

	const handleChecked = (id) => {
		let temp = checked.map((data) => {
			if (id === data.id) {
				return { ...data, inputchecked: !data.inputchecked };
			}
			return data;
		});
		setChecked(temp);
	};
	const handleCheckedAll = (value) => {
		let temp = checked.map((data) => {
			return { ...data, inputchecked: value };
		});
		setChecked(temp);
		setUnChecked(!unchecked);
	};

	const deleteLeave = async (personId, dateFrom) => {
		swal({
			title: 'Are you sure?',
			text: 'Once deleted, you will not be able to recover!',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: 'swal-border-danger',
		}).then(async (yes) => {
			if (yes) {
				try {
					const res = await leaveAPI.deleteLeave(personId, dateFrom);
					if (res.success) {
						swal('Leave has been deleted!', {
							icon: 'success',
						});
						getList();
					}
				} catch (err) {
					console.log(err);
					swal('Oops', 'Something went wrong!', 'error');
				}
			} else {
				swal('Leave deletion is cancelled');
			}
		});
	};

	const setStatus = async (personId, dateFrom, status, reason=undefined) => {
		try {
			let res;
			switch (status) {
				case 'Approved':
					res = await leaveAPI.approve(personId, dateFrom);
					break;
				case 'Rejected':
					res = await leaveAPI.reject(personId, dateFrom, reason);
					break;
				case 'Cancelled':
					res = await leaveAPI.cancel(personId, dateFrom);
					break;
				default:
					break;
			}
			if (res.success) {
				swal(`Leave has been ${status.toLowerCase()}!`, {
					icon: 'success',
				});
				getList();
			}
		} catch (err) {
			console.log(err);
			swal('Oops', 'Something went wrong!', 'error');
		}
	};

	let navigate = useNavigate();
	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title  shadow-sm flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
							</div>
						</div>
						<div className='col-xl-12 wow fadeInUp ' data-wow-delay='1.5s'>
							{leaveList.length ? (
								<div className='table-responsive full-data'>
									<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer '
										>
											<thead>
												{headerGroups.map((headerGroup) => {
													return (
														<tr {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column) => (
																<th
																	style={{
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		whiteSpace: 'nowrap',
																		minWidth: '2rem',
																		maxWidth: '7rem',
																	}}
																	{...column.getHeaderProps()}
																>
																	{column.render('Header')}
																</th>
															))}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row) => {
													prepareRow(row);
													return (
														<tr {...row.getRowProps()}>
															{row.cells.map((cell, index) => {
																if (cell.column.id.toLowerCase() === 'action' && !historical) {
																	return (
																		<td key={index}>
																			<div className='d-flex'>
																				{/* <div
																					className='icon-box icon-box-sm bg-light me-2'
																					onClick={() => {
																						deleteLeave(
																							row.original.personId,
																							row.original.dateFrom
																						);
																					}}
																				>
																					<i
																						className='material-symbols-outlined'
																						style={{ fontSize: 8, color: '#4D44B5' }}
																					>
																						delete
																					</i>
																				</div> */}
																				{row.original.status === 'Pending' && (
																					<div
																						className='icon-box icon-box-sm bg-light me-2'
																						onClick={() => {
																							setStatus(
																								row.original.personId,
																								row.original.dateFrom,
																								'Approved'
																							);
																						}}
																					>
																						<i
																							className='material-symbols-outlined'
																							style={{ fontSize: 8, color: '#4D44B5' }}
																						>
																							check_circle
																						</i>
																					</div>
																				)}
																				{row.original.status === 'Approved' && (
																					<div
																						className='icon-box icon-box-sm bg-light me-2'
																						onClick={() => {
																							setStatus(
																								row.original.personId,
																								row.original.dateFrom,
																								'Cancelled'
																							);
																						}}
																					>
																						<i
																							className='material-symbols-outlined'
																							style={{ fontSize: 8, color: '#4D44B5' }}
																						>
																							person_cancel
																						</i>
																					</div>
																				)}
																				{row.original.status === 'Pending' && (
																					<div
																						className='icon-box icon-box-sm bg-light me-2'
																						// onClick={() => {
																						// 	// setStatus(
																						// 	// 	row.original.personId,
																						// 	// 	row.original.dateFrom,
																						// 	// 	'Rejected'
																						// 	// );
																						// }}
																						onClick={()=>{
																							setModalRow(row);
																							toggleModalOpen();
																						}}
																					>
																						<i
																							className='material-symbols-outlined'
																							style={{ fontSize: 8, color: '#4D44B5' }}
																						>
																							cancel
																						</i>
																				
																					</div>
																				)}
																			</div>
																		</td>
																	);
																} else {
																	return (
																		<td key={index} {...cell.getCellProps()} className="py-3">
																			{cell.value ? cell.render('Cell') : '-'}
																		</td>
																	);
																}
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
									</div>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<BasicModal ref={childRef} />
			<RejectionPopUp 
				show= {modalShow} 
				toggleClose= {toggleModalClose}
				setStatus= {setStatus}
				row = {modalRow}
			/>
		</>
	);
};

export default LeaveManagement;
