import { getClaims } from './component/tableData';
import React, { useState, useEffect, useMemo } from 'react';
import { COLUMNS } from './component/tableData';
import { Link, useNavigate } from 'react-router-dom';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import EmptyState from '../../components/EmptyState';
import Pagination from '../../components/Pagination';
import { formatCurrency } from '../../../../services/general';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import claimsAPI from '../../../../api/claimsApi';
import swal from 'sweetalert';

const Claims = () => {
	const [claimList, setClaimList] = useState([]);
	const [openModel, setOpenModel] = useState(false);
	const [claimDetails, setClaimDetails] = useState({});
	const [imgUrl, setImgUrl] = useState('');
	const [openImageModal, setOpenImageModal] = useState(false);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => claimList, [claimList]);

	useEffect(() => {
		getList();
	}, []);

	const getList = async () => {
		const list = await getClaims();
		setClaimList(list);
	};
	const tableInstance = useTable({ columns, data }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;
	const getColor = (status) => {
		switch (status?.toLowerCase()) {
			case 'paid':
				return 'success';
			case 'approved':
				return 'success';
			case 'rejected':
				return 'danger';
			case 'pending':
				return 'primary';
			default:
				return 'primary';
		}
	};
	const updateClaimStatus = async (id, status) => {
		try {
			const data = { status: status };
			const res = await claimsAPI.updateClaim(id, data);
			if (res.success) {
				swal('Claim has been ' + status, {
					icon: 'success',
				});
				setOpenModel(false);
				getList();
			} else {
				swal('Oops', 'Something went wrong.', 'error');
			}
		} catch (error) {
			console.log(error);
			swal('Oops', 'Something went wrong.', 'error');
		}
	};
	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title  shadow-sm flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
							</div>
						</div>

						<div className='col-xl-12 wow fadeInUp ' data-wow-delay='1.5s'>
							{claimList.length ? (
								<div className='table-responsive full-data'>
									<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer '>
											<thead>
												{headerGroups.map((headerGroup) => {
													return (
														<tr {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column) => (
																<th
																	style={{
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		whiteSpace: 'nowrap',
																		minWidth: '2rem',
																		maxWidth: '7rem',
																	}}
																	{...column.getHeaderProps()}>
																	{column.render('Header')}
																</th>
															))}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row) => {
													prepareRow(row);
													return (
														<tr {...row.getRowProps()}>
															{row.cells.map((cell) => {
																return (
																	<td
																		{...cell.getCellProps()}
																		onClick={() => {
																			if (cell.column.id === 'Action') {
																				setOpenModel(true);
																				setClaimDetails(cell.row.original);
																			}
																		}}
																		className='py-3'>
																		{cell.value ? cell.render('Cell') : '-'}
																	</td>
																);
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
									</div>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal className='modal fade' size='lg' show={openModel} onHide={setOpenModel} centered>
				<div className='modal-header'>
					<div className='d-flex align-items-center gap-3'>
						<h4 className='modal-title fs-20 center'>Claim Approval</h4>
						<span className={`badge badge-sm light badge-${getColor(claimDetails?.status)}`}>{claimDetails?.status}</span>
					</div>
					<button title='close' type='button' className='btn-close' onClick={() => setOpenModel(false)}></button>
				</div>
				<div className='modal-body'>
					<div className='row '>
						<div className='flex-column d-flex col'>
							<label className='text-grey font-w500 '>Name</label>
							<label className='text-black font-w500 fs-6' style={{ marginTop: -5 }}>
								{claimDetails.name}
							</label>
						</div>
						<div className='flex-column d-flex col'>
							<label className='text-grey font-w500 '>Date</label>
							<label className='text-black font-w500 fs-6' style={{ marginTop: -5 }}>
								{moment(new Date(claimDetails.date)).format('DD MMM yyyy')}
							</label>
						</div>
						<div className='flex-column d-flex col'>
							<label className='text-grey font-w500 '>Amount</label>
							<label className='text-black font-w500 fs-6' style={{ marginTop: -5 }}>
								{formatCurrency(claimDetails.amount)}
							</label>
						</div>
					</div>
					<div className='flex-column d-flex mt-3'>
						<label className='text-grey font-w500 '>Description</label>
						<label className='text-black font-w500 fs-6' style={{ marginTop: -5 }}>
							{claimDetails.description}
						</label>
					</div>
					{claimDetails.media && claimDetails.media.length > 0 && (
						<div className='flex-column d-flex mt-3'>
							<label className='text-grey font-w500 '>Attachment</label>
							{claimDetails.media.map((item, i) => (
								<img
									onClick={() => {
										setOpenImageModal(true);
										setImgUrl(item.url);
									}}
									width={50}
									height={50}
									style={{ objectFit: 'contain', marginRight: 10 }}
									src={item.url}
									alt=''
									key={i}
								/>
							))}
							{/* <div onClick={() => setOpenImageModal(true)}>
							<label>idk</label>
						</div> */}
						</div>
					)}
				</div>
				{claimDetails.status?.toLowerCase() === 'pending' && (
					<div className='p-3 align-self-end mb-2 me-2'>
						<button className='btn btn-secondary me-2' onClick={() => updateClaimStatus(claimDetails.id, 'Rejected')}>
							Reject
						</button>
						<button className='btn btn-success' onClick={() => updateClaimStatus(claimDetails.id, 'Approved')}>
							Approve
						</button>
					</div>
				)}
			</Modal>
			<Modal className='modal fade' size='xl' show={openImageModal} onHide={setOpenImageModal} centered>
				<div className='d-flex justify-content-between align-items-center p-3'>
					<h4 className='modal-title fs-4 center'>Attachments</h4>

					<button title='close' type='button' className='btn-close' onClick={() => setOpenImageModal(false)}></button>
				</div>
				<div className='modal-body'>
					<img width={'100%'} height={500} style={{ objectFit: 'contain', marginRight: 10 }} src={imgUrl} />
				</div>
			</Modal>
		</>
	);
};
export default Claims;
