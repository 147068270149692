import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { COLUMNS, getLedger } from './component/tableData';
import { Link, useNavigate } from 'react-router-dom';
import BasicModal from '../../Dashboard/BasicModal';
import DatePicker from 'react-datepicker';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import swal from 'sweetalert';
import EmptyState from '../../components/EmptyState';
import Pagination from '../../components/Pagination';
import * as dateFn from 'date-fns';
import { formatCurrency } from '../../../../services/general';
import MonthPicker from './component/MonthPicker';
import ExpenditureModal from './component/ExpenditureModal';
import financeAPI from '../../../../api/financeApi';

const Ledger = () => {
	const childRef = useRef();
	const [currentPage, setCurrentPage] = useState(1);
	const [unchecked, setUnChecked] = useState(true);
	const [openModal, setOpenModal] = useState(false);
	const toggleClose = () => setOpenModal(false);
	// state for edit modal
	const [transactionId, setTransactionId] = useState(undefined);
	const clearTransactionId = () => {
		setTransactionId(undefined);
	};
	const [refresh, setRefresh] = useState(false);
	const refreshPage = () => setRefresh(!refresh);

	const [ledgerList, setLedgerList] = useState([]);
	const [summary, setSummary] = useState([
		// {
		// 	title: 'Balance brought forward',
		// 	svg: <i className='material-symbols-outlined text-white fs-5'>savings</i>,
		// 	number: '-',
		// 	change: 'std-data',
		// },
		{
			title: 'Income this month',
			svg: <i className='material-symbols-outlined text-dark fs-5'>mintmark</i>,
			number: '-',
			change: 'event-data',
		},
		{
			title: 'Expenditure this month',
			svg: <i className='material-symbols-outlined text-white fs-5'>send_money</i>,
			number: '-',
			change: 'teach-data',
		},
	]);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => ledgerList, [ledgerList]);
	const [startDate, setStartDate] = useState(new Date());

	useEffect(() => {
		getList(startDate);
	}, [startDate, refresh]);

	const getList = async (date) => {
		const { transactions, aggregated } = await getLedger(date);
		setLedgerList(transactions);
		assignSummary(aggregated);
	};

	const assignSummary = (aggregated) => {
		// const itms = ['balanceBroughtForward', 'income', 'expenditure'];
		const itms = ['income', 'expenditure'];
		for (let i = 0; i < itms.length; i++) {
			const val = aggregated?.[itms[i]] && Number(aggregated?.[itms[i]]);
			summary[i].number = val ? formatCurrency(val) : '-';
		}
		setSummary(summary);
	};

	const tableInstance = useTable({ columns, data }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;

	let navigate = useNavigate();

	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title  shadow-sm flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
								<MonthPicker startDate={startDate} setStartDate={setStartDate} />
							</div>
						</div>

						{/* Balance Sheet */}
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-body pb-xl-4 pb-sm-3 pb-0'>
									<div className='row'>
										{summary.map((item, ind) => (
											<div className={`col-xl-${12 / (summary.length + 1)}  col-6`} key={ind}>
												<div className='content-box'>
													<div className={`icon-box icon-box-xl ${item.change}`}>{item.svg}</div>
													<div className='chart-num'>
														<p>{item.title}</p>
														<h2 className='font-w700 mb-0'>{item.number}</h2>
													</div>
												</div>
											</div>
										))}
										<div className={`col-xl-${12 / (summary.length + 1)} col-6`}>
											<div className='content-box'>
												<div className='icon-box icon-box-xl std-data'>{summary[0].svg}</div>
												<div className='chart-num'>
													<button
														type='button'
														className='btn btn-primary'
														onClick={() => {
															setOpenModal(true);
														}}>
														Create New Expenditure
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='col-xl-12 wow fadeInUp ' data-wow-delay='1.5s'>
							{ledgerList.length ? (
								<div className='table-responsive full-data'>
									<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer '>
											<thead>
												{headerGroups.map((headerGroup) => {
													return (
														<tr {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column) => (
																<th
																	style={{
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		whiteSpace: 'nowrap',
																		minWidth: '2rem',
																		maxWidth: '7rem',
																	}}
																	{...column.getHeaderProps()}>
																	{column.render('Header')}
																</th>
															))}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row, index) => {
													prepareRow(row);
													return (
														<tr key={index} {...row.getRowProps()}>
															{row.cells.map((cell, index) => {
																if (
																	cell.column.id.toLowerCase() === 'action' &&
																	cell.row.cells[4].value < 0
																) {
																	return (
																		<td key={index}>
																			<div
																				className='icon-box icon-box-sm bg-light me-2'
																				onClick={() => {
																					setTransactionId(cell.row.cells[0].value);
																					setOpenModal(true);
																				}}>
																				<i
																					className='bi bi-pencil-fill'
																					style={{ fontSize: 8, color: 'var(--primary)' }}></i>
																			</div>
																			<div
																				className='icon-box icon-box-sm bg-light me-2'
																				onClick={async () => {
																					await financeAPI.deleteExpenditure(
																						cell.row.cells[0].value
																					);
																					swal(
																						'Deleted',
																						'Expenditure has been deleted!',
																						'success'
																					);
																					setRefresh(!refresh);
																				}}>
																				<i
																					className='bi bi-trash'
																					style={{ fontSize: 8, color: 'var(--primary)' }}></i>
																			</div>
																		</td>
																	);
																} else {
																	return (
																		<td {...cell.getCellProps()}>
																			{cell.value ? cell.render('Cell') : '-'}
																		</td>
																	);
																}
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
									</div>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<BasicModal ref={childRef} />
			<ExpenditureModal
				show={openModal}
				toggleClose={toggleClose}
				setRefresh={refreshPage}
				transactionId={transactionId}
				clearTransactionId={clearTransactionId}
			/>
		</>
	);
};

export default Ledger;
