import apiHandler from './apiHandler';

export async function chatRoomList() {
	return apiHandler.get(`/chat`);
}

export async function chat(chatId: number) {
	return apiHandler.get(`/app/chat/${chatId}`);
}

const chatAPI = {
	chatRoomList,
	chat,
};

export default chatAPI;
