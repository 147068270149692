import React, { useState, useEffect, useMemo } from 'react';
import adminApi from '../../../api/adminApi';
import authApi from '../../../api/authApi';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import swal from 'sweetalert';
import { Form } from 'react-bootstrap';
import { MappedACLs } from '../../../services/ACL';
import { customSelectStyle } from '../../../services/general';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import EmptyState from '../components/EmptyState';
import Pagination from '../components/Pagination';

const COLUMNS = [
	{
		Header: 'Checkbox',
		id: 'checkbox',
	},
	{
		Header: 'Name',
		Footer: 'Name',
		accessor: 'name',
	},
	{
		Header: 'ID Number',
		Footer: 'Name',
		accessor: 'idNumber',
	},

	{
		Header: 'Action',
		Footer: 'Action',
	},
];

const Admin = () => {
	const [staffList, setStaffList] = useState([]);
	const [checkedData, setCheckedData] = useState([]);
	const [acl, setAcl] = useState([]);
	const [templateList, setTemplateList] = useState([]);
	const [unchecked, setUnChecked] = useState(true);
	const [showButton, setShowButton] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [name, setName] = useState('');
	const [personId, setPersonId] = useState();
	const [aclValue, setAclValue] = useState(0);
	const [aclTemplateValue, setAclTemplateValue] = useState(0);
	const [aclInitialValue, setAclInitialValue] = useState(0);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [isCreateTemplate, setIsCreateTemplate] = useState(false);

	const [isCpOpen, setIsCpOpen] = useState(undefined);
	const [isView, setIsView] = useState({ old: false, new: false });

	useEffect(() => {
		getList();
		getACL();
		getTemplates();
	}, []);

	const getList = async () => {
		try {
			const res = await adminApi.getStaffList();
			setStaffList(res.data);
		} catch (err) {
			console.log(err);
		}
	};
	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => staffList, [staffList]);
	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setPageSize,
	} = tableInstance;
	const { pageIndex, pageSize } = state;

	const handleChecked = (id, checked) => {
		let temp = staffList.map((data) => {
			if (id === data.personId) {
				const hadID = checkedData.find((object) => data.personId === object);
				if (!hadID) {
					setCheckedData((oldArray) => [...oldArray, id]);
				}
				if (!checked) {
					const arr = checkedData.filter((item) => item !== id);
					setCheckedData(arr);
				}
				return { ...data, inputchecked: !data.inputchecked };
			}
			return data;
		});
		setStaffList(temp);
	};
	const handleCheckedAll = (value, checked) => {
		let temp = staffList.map((data) => {
			return { ...data, inputchecked: value };
		});

		if (checked) {
			setCheckedData(temp);
		} else {
			setCheckedData([]);
		}

		setShowButton(!showButton);
		setStaffList(temp);
		setUnChecked(!unchecked);
	};

	const openSideBar = async (e) => {
		e.preventDefault();
		setAclValue(0);

		// getStaffByID();
		if (checkedData.length === 1) {
			await getStaffByID(checkedData[0]);
		}
		let walletopen = document.querySelector('.wallet-open');

		if (!walletopen.classList.contains('active')) {
			walletopen.classList.add('active');
			// walletopen.classList.remove('active');
		}
		getTemplates();
	};
	const closeSideBar = () => {
		// setCheckedData([]);
		let walletopen = document.querySelector('.wallet-open');
		if (walletopen.classList.contains('active')) {
			walletopen.classList.remove('active');
		}
	};

	const getACL = () => {
		const arr = [];
		let title = '';
		for (let key in MappedACLs) {
			// console.log(`${key}: ${MappedACLs[key]}`);
			if (key === 'leavemanagement') {
				title = 'leave management';
			} else if (key === 'medicationrejection') {
				title = 'medication rejection';
			} else if (key === 'progressreport') {
				title = 'progress report';
			} else if (key === 'resourcemanagement') {
				title = 'resource management';
			} else if (key === 'health') {
				title = 'Injury Report';
			} else {
				title = key;
			}
			arr.push({ key: key, title: title, value: MappedACLs[key] });
		}
		setAcl(arr);
	};

	const getTemplates = async () => {
		const arr = [];
		try {
			const res = await adminApi.getTemplates();
			if (res.success) {
				res.data.map((item, i) => {
					const obj = { label: item.name, value: item.acl };
					arr.push(obj);
				});
				setTemplateList(arr);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleToggle = (item, checked, isTemplate) => {
		// if (checked) {
		// const sameKey = templateList.find((object) => object.key === item.key);
		// if (!sameKey) {
		if (!isTemplate) {
			setAclValue((oldACL) => (checked ? oldACL | item.value : oldACL & ~item.value));
		} else {
			setAclTemplateValue((oldACL) => (checked ? oldACL | item.value : oldACL & ~item.value));
		}

		// }
		// } else {
		// arr = arr.filter((object) => object.key === item.key);
		// setAclValue(array);
		// }
	};

	const updateStaffACL = async () => {
		const data = { ids: checkedData, acl: aclValue };

		try {
			const res = await adminApi.updateStaff(data);
			if (res.success) {
				swal('Success', 'You have updated successfully', 'success').then(() => {
					closeSideBar();
				});
			}
		} catch (err) {
			console.log(err);
		}
	};
	const createTemplate = async () => {
		try {
			const data = { name: name, acl: aclTemplateValue };
			const res = await adminApi.createTemplates(data);
			if (res.success) {
				swal('Success', 'You have updated successfully', 'success').then(() => {
					setIsCreateTemplate(false);
					getTemplates();
				});
			}
		} catch (err) {
			console.log(err);
		}
	};
	const getStaffByID = async (id) => {
		try {
			const res = await adminApi.getStaffByID(id);
			if (res.success) {
				setAclValue(res.acl);
				setAclInitialValue(res.acl);
			} else {
				setAclValue(0);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const closeModal = () => {
		setSelectedTemplate(null);
		setIsOpenModal(false);
		setAclTemplateValue(0);
		setAclValue(0);
		// setAclInitialValue(0);
		setIsCreateTemplate(false);
	};
	const deleteTemplate = async () => {
		const name = encodeURI(selectedTemplate.label);

		try {
			const res = await adminApi.deleteTemplates(name);
			if (res.success) {
				swal(`${selectedTemplate.label} has been deleted!`, {
					icon: 'success',
				});
				setSelectedTemplate(null);
				getTemplates();
				setAclValue(0);
			}
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<>
			<div className={'wallet-open'}>
				<div className='col-12 admin-body'>
					<div className='col-xl-12 wow fadeInUp' data-wow-delay='1.5s'>
						{staffList.length ? (
							<>
								<div className='d-flex justify-content-end mb-3'>
									<div
										className={` d-flex align-items-center bg-primary p-2 rounded pe-3 gap-2  mb-2 me-2 ${
											checkedData.length <= 0 ? 'opacity-50' : 'opacity-100 c-pointer'
										}`}
										onClick={(e) => {
											if (checkedData.length <= 0) {
												return;
											}
											openSideBar(e);
										}}>
										<i className='material-symbols-outlined text-white' style={{ fontSize: 18 }}>
											tune
										</i>
										<span className='text-white '>Permission</span>
									</div>
								</div>

								<div className='table-responsive full-data'>
									<div id='example-admin_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='table-responsive-lg table display dataTablesCard student-tab dataTable no-footer'>
											<thead>
												{headerGroups.map((headerGroup, i) => {
													return (
														<tr key={i} {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column, index) => {
																if (column.id === 'checkbox') {
																	return (
																		<th key={index}>
																			<input
																				type='checkbox'
																				className='form-check-input'
																				id='checkAll'
																				onClick={(e) =>
																					handleCheckedAll(unchecked, e.target.checked)
																				}
																			/>
																		</th>
																	);
																} else {
																	return (
																		<th key={index} {...column.getHeaderProps()}>
																			{column.render('Header')}
																		</th>
																	);
																}
															})}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row, idx) => {
													prepareRow(row);
													return (
														<tr key={idx} {...row.getRowProps()}>
															{row.cells.map((cell, index) => {
																if (cell.column.id.toLowerCase() === 'checkbox') {
																	return (
																		<td key={index}>
																			<div className='checkbox me-0 align-self-center'>
																				<div className='custom-control custom-checkbox '>
																					<input
																						type='checkbox'
																						className='form-check-input'
																						id={`stud-${row.original.personId}`}
																						checked={row.original.inputchecked}
																						onChange={(e) => {
																							handleChecked(
																								row.original.personId,
																								e.target.checked
																							);
																						}}
																					/>
																					<label
																						className='custom-control-label'
																						htmlFor={`stud-${row.original.personId}`}></label>
																				</div>
																			</div>
																		</td>
																	);
																} else {
																	return (
																		<td key={index} {...cell.getCellProps()}>
																			{cell.column.Header === 'Action' ? (
																				<button
																					key={index}
																					className='px-2 py-1 btn-primary rounded-2'
																					style={{ background: 'var(--primary)' }}
																					onClick={() => setIsCpOpen(cell.row.original)}>
																					Reset Password
																				</button>
																			) : cell.value ? (
																				cell.render('Cell')
																			) : (
																				'-'
																			)}
																		</td>
																	);
																}
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
									</div>
								</div>
							</>
						) : (
							<EmptyState />
						)}
					</div>
				</div>

				<div className={`wallet-bar dlab-scroll`} id='wallet-bar'>
					<div className='card bg-transparent mb-1'>
						<div className='card-header  border-0 px-3'>
							<div>
								<h2 className='heading mb-0'>Permissions</h2>
							</div>
						</div>
						<div
							className='card-body height450 dlab-scroll loadmore-content recent-activity-wrapper p-3 pt-2 '
							id='RecentActivityContent'>
							<div>
								{acl.map((item, index) => {
									return (
										<Form.Check
											key={index}
											type='switch'
											// id='toggle-switch'
											label={item.title}
											style={{ marginTop: 2 }}
											checked={(aclValue & item.value) === item.value}
											onChange={(e) => handleToggle(item, e.target.checked, false)}
										/>
									);
								})}
							</div>
						</div>
						<div className='d-flex align-items-center mt-5 justify-content-end '>
							<button type='button' className='btn w-100 btn-secondary' onClick={() => setIsOpenModal(true)}>
								Templates
							</button>
						</div>
						<div className='d-flex justify-content-end mt-3'>
							<button className='btn btn-primary w-100' type='button' onClick={() => updateStaffACL()}>
								Save
							</button>
						</div>
					</div>
				</div>
				<div className='wallet-bar-close' onClick={() => closeSideBar()}></div>
			</div>
			<Modal onHide={setIsOpenModal} show={isOpenModal} centered size='lg' backdrop='static'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='exampleModalLabel'>
							Permission Template
						</h5>
						<button title='close' type='button' className='btn-close' onClick={() => closeModal()}></button>
					</div>
					<div className='modal-body'>
						<div className='d-flex justify-content-end mb-3' style={{ marginTop: -10 }}>
							{isCreateTemplate ? (
								<div
									className=' d-flex align-items-center bg-light p-2 rounded pe-3 gap-2 c-pointer'
									onClick={() => setIsCreateTemplate(false)}>
									<i className='material-symbols-outlined'>search</i>
									<span className='text-primary'>Search Template</span>
								</div>
							) : (
								<div
									className=' d-flex align-items-center bg-light p-2 rounded pe-3 gap-2 c-pointer'
									onClick={() => {
										setIsCreateTemplate(true);
										setSelectedTemplate(null);
									}}>
									<i className='material-symbols-outlined text-primary'>add_circle</i>
									<span className='text-primary'>Create New Template</span>
								</div>
							)}
						</div>
						{isCreateTemplate ? (
							<>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Name<span className='required'>*</span>
									</label>
									<input
										onChange={(e) => setName(e.target.value)}
										type='text'
										className='form-control'
										id='exampleFormControlInput1'
										name='name'
									/>
								</div>
								<div className='mb-3 row'>
									{acl.map((item, index) => {
										return (
											<div className='col-4' key={index}>
												<Form.Check
													type='switch'
													// id='toggle-switch'
													label={item.title}
													style={{ marginTop: 2 }}
													//   checked={isChecked}
													onChange={(e) => handleToggle(item, e.target.checked, true)}
												/>
											</div>
										);
									})}
								</div>
							</>
						) : (
							<>
								<div>
									<h6 className=' mb-0'>Permissions</h6>
								</div>
								<div className='d-flex align-items-center gap-3'>
									<Select
										styles={customSelectStyle()}
										options={templateList}
										onChange={(e) => {
											setAclValue(e.value);
											setSelectedTemplate(e);
										}}
										value={selectedTemplate}
										inputId='template'
										placeholder={'Select Template'}
										className='mt-1 w-100 text-capitalize'
									/>
									{selectedTemplate && (
										<div style={{ cursor: 'pointer' }} className=' me-2 mt-2' onClick={() => deleteTemplate()}>
											<i className='material-symbols-outlined text-danger'>delete</i>
										</div>
									)}
								</div>
							</>
						)}
					</div>
					<div className='modal-footer'>
						<button
							type='button'
							className='btn btn-danger light'
							onClick={() => {
								closeModal();
								setAclValue(aclInitialValue);
							}}>
							Close
						</button>

						<button
							type='button'
							className='btn btn-primary'
							onClick={() => {
								isCreateTemplate ? createTemplate() : setIsOpenModal(false);
								setAclInitialValue(aclValue);
								setSelectedTemplate(null);
							}}>
							{isCreateTemplate ? 'Create Template' : 'Ok'}
						</button>
					</div>
				</div>
			</Modal>
			<Modal onHide={setIsCpOpen} show={isCpOpen} centered size='lg' backdrop='static'>
				<div className='modal-content text-black'>
					<div className='modal-header'>
						<h5 className='modal-title' id='exampleModalLabel'>
							Reset New Password
						</h5>
						<button title='close' type='button' className='btn-close' onClick={() => setIsCpOpen(false)}></button>
					</div>
					<form
						onSubmit={(e) => {
							const CryptoJS = require('crypto-js');
							e.preventDefault();
							authApi.changePassword({
								newPassword: CryptoJS.MD5(e.target.newP.value).toString(),
								// oldPassword: CryptoJS.MD5(e.target.oldP.value).toString(),
								personId: isCpOpen?.personId,
							});
							swal('Success', 'Password Reset Successfully', 'success').then(() => {
								setIsCpOpen(undefined);
							});
						}}>
						<div className='modal-body'>
							<div className='d-flex justify-content-center mb-3 spacing-2' style={{ marginTop: -10 }}>
								<table>
									<tbody>
										<tr>
											<td className='fs-4 p-2 text-20'>Name</td>
											<td className='fs-4 p-2 text-20'>{isCpOpen?.name}</td>
										</tr>
										{/* <tr>
											<td className='fs-4 p-2 text-20'>Old Password</td>
											<td>
												<input
													name='oldP'
													type={isView.old ? 'text' : 'password'}
													className='m-2 form-control'
													autoComplete='off'
												/>
											</td>
											<td>
												<i
													className={`m-3 bi ${isView.old ? 'bi-eye-fill' : 'bi-eye-slash'} fs-2`}
													onClick={() => {
														setIsView({ ...isView, old: !isView.old });
													}}
												/>
											</td>
										</tr> */}
										<tr>
											<td className='fs-4 p-2 text-20'>New Password</td>
											<td>
												<input
													name='newP'
													type={isView.new ? 'text' : 'password'}
													className='m-2 form-control '
													autoComplete='off'
												/>
											</td>
											<td>
												<i
													className={`m-3 bi ${isView.new ? 'bi-eye-fill' : 'bi-eye-slash'} fs-2`}
													onClick={() => {
														setIsView({ ...isView, new: !isView.new });
													}}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn btn-danger light'
								onClick={() => {
									setIsCpOpen(undefined);
								}}>
								Close
							</button>

							<button type='submit' className='btn btn-primary'>
								Password Reset
							</button>
						</div>
					</form>
				</div>
			</Modal>
		</>
	);
};

export default Admin;
