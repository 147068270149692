import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import swal from 'sweetalert';
import { IMAGES } from '../Dashboard/Content';
import StaffApi from '../../../api/staffApi';
import dashboardApi from '../../../api/dashboardApi';
import { useSubscription } from '../../../context/SubscriptionContextProvider';

const CardListBlog = [
	{
		id: 1,
		image: IMAGES.contact1,
		Post: 'Teacher',
		Cust_Name: 'Munaroh Steffani',
		Subject: 'Mathematics',
	},
	{
		id: 2,
		image: IMAGES.contact2,
		Post: 'Teacher',
		Cust_Name: 'Geovanny Anderson',
		Subject: 'Science ',
	},
	{
		id: 3,
		image: IMAGES.contact3,
		Post: 'Teacher',
		Cust_Name: 'Louis Ali',
		Subject: 'English',
	},
	{
		id: 4,
		image: IMAGES.contact4,
		Post: 'Teacher',
		Cust_Name: 'Marquezz',
		Subject: 'History',
	},
	{
		id: 5,
		image: IMAGES.contact5,
		Post: 'Teacher',
		Cust_Name: 'Richard ',
		Subject: 'Biology',
	},
	{
		id: 6,
		image: IMAGES.contact6,
		Post: 'Teacher',
		Cust_Name: 'Andrew Stevano',
		Subject: 'Music',
	},
	{
		id: 7,
		image: IMAGES.contact7,
		Post: 'Teacher',
		Cust_Name: 'Cathenna ',
		Subject: 'Literature',
	},
	{
		id: 8,
		image: IMAGES.contact8,
		Post: 'Teacher',
		Cust_Name: 'Hrisovalantis ',
		Subject: 'Psychology',
	},
	{
		id: 9,
		image: IMAGES.contact9,
		Post: 'Teacher',
		Cust_Name: 'Louis Ali',
		Subject: 'English',
	},
	{
		id: 10,
		image: IMAGES.contact10,
		Post: 'Teacher',
		Cust_Name: 'Marquezz',
		Subject: 'History',
	},
	{
		id: 11,
		image: IMAGES.contact11,
		Post: 'Teacher',
		Cust_Name: 'Richard ',
		Subject: 'Biology',
	},
	{
		id: 12,
		image: IMAGES.contact12,
		Post: 'Teacher',
		Cust_Name: 'Andrew Stevano',
		Subject: 'Music',
	},
];

const Teachers = () => {
	const navigate = useNavigate();
	const [search, setSearch] = useState('');
	const [postModal, setPostModal] = useState(false);
	const [contacts, setContacts] = useState(CardListBlog);
	const [staffList, setStaffList] = useState([]);
	const [totalPeople, setTotalPeople] = useState(0);

	const { subscription } = useSubscription();

	async function checkSubscriptionOk() {
		const dash = await dashboardApi.dashboardList();
		const total = dash.data.people.registeredStudents + dash.data.people.registeredTeachers;
		if (subscription) {
			if (subscription.headCount <= total && subscription.headCount > 0) {
				alert('Maximum number of students/teachers has been reached. Please contact your administrator.');
				return false;
			}
		}
		return true;
	}

	async function getStaffList() {
		const res = await StaffApi.getStaffList();
		if (res && res.success) {
			setStaffList([...res.data]);
		}
	}

	useEffect(() => {
		getStaffList();
	}, []);

	//For Image upload in ListBlog
	const [file, setFile] = React.useState(null);
	const fileHandler = (e) => {
		setFile(e.target.files[0]);
		setTimeout(function () {
			var src = document.getElementById('saveImageFile').getAttribute('src');
		}, 200);
	};

	const deleteStaff = (id) => {
		swal({
			title: 'Are you sure?',
			text: 'Once deleted, you will not be able to recover!',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: 'swal-border-danger',
		}).then(async (yes) => {
			if (yes) {
				try {
					const res = await StaffApi.deleteStaff(id);
					if (res && res.success) {
						swal('Staff has been deleted!', {
							icon: 'success',
						});
						getStaffList();
					}
				} catch (err) {
					console.log(err);
					swal('Oops', 'Something went wrong!', 'error');
				}
			} else {
				swal('Staff deletion is cancelled.');
			}
		});
	};
	return (
		<div className='row'>
			<div className='col-xl-12'>
				<div className='page-title flex-wrap'>
					<div className='input-group search-area mb-md-0 mb-3'>
						<input
							type='text'
							className='form-control'
							placeholder='Search here...'
							onChange={(e) => {
								setSearch(e.target.value);
							}}
						/>
						<span className='input-group-text'>
							<Link to={'#'}>
								<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
										fill='#01A3FF'
									/>
								</svg>
							</Link>
						</span>
					</div>
					<div className='d-flex'>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => {
								if (!subscription || subscription.error) return navigate('/add-staff');
								checkSubscriptionOk().then((res) => {
									if (res) return navigate('/add-staff');
								});
							}}>
							+ New Staff
						</button>
						{/* </Link> */}
					</div>
				</div>
			</div>
			<div className='col-xl-12'>
				<div className='row'>
					{staffList
						?.filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
						.map((staff, index) => (
							<div className='col-xl-3 col-lg-4 col-sm-6' key={index}>
								<div className='card contact_list text-center'>
									<div className='card-body'>
										<Dropdown>
											<Dropdown.Toggle as='div' className='btn sharp btn-light i-false'>
												<svg
													width='24'
													height='6'
													viewBox='0 0 24 6'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'>
													<path
														d='M12.0012 0.359985C11.6543 0.359985 11.3109 0.428302 10.9904 0.561035C10.67 0.693767 10.3788 0.888317 10.1335 1.13358C9.88829 1.37883 9.69374 1.67 9.56101 1.99044C9.42828 2.31089 9.35996 2.65434 9.35996 3.00119C9.35996 3.34803 9.42828 3.69148 9.56101 4.01193C9.69374 4.33237 9.88829 4.62354 10.1335 4.8688C10.3788 5.11405 10.67 5.3086 10.9904 5.44134C11.3109 5.57407 11.6543 5.64239 12.0012 5.64239C12.7017 5.64223 13.3734 5.36381 13.8686 4.86837C14.3638 4.37294 14.6419 3.70108 14.6418 3.00059C14.6416 2.3001 14.3632 1.62836 13.8677 1.13315C13.3723 0.637942 12.7004 0.359826 12 0.359985H12.0012ZM3.60116 0.359985C3.25431 0.359985 2.91086 0.428302 2.59042 0.561035C2.26997 0.693767 1.97881 0.888317 1.73355 1.13358C1.48829 1.37883 1.29374 1.67 1.16101 1.99044C1.02828 2.31089 0.959961 2.65434 0.959961 3.00119C0.959961 3.34803 1.02828 3.69148 1.16101 4.01193C1.29374 4.33237 1.48829 4.62354 1.73355 4.8688C1.97881 5.11405 2.26997 5.3086 2.59042 5.44134C2.91086 5.57407 3.25431 5.64239 3.60116 5.64239C4.30165 5.64223 4.97339 5.36381 5.4686 4.86837C5.9638 4.37294 6.24192 3.70108 6.24176 3.00059C6.2416 2.3001 5.96318 1.62836 5.46775 1.13315C4.97231 0.637942 4.30045 0.359826 3.59996 0.359985H3.60116ZM20.4012 0.359985C20.0543 0.359985 19.7109 0.428302 19.3904 0.561035C19.07 0.693767 18.7788 0.888317 18.5336 1.13358C18.2883 1.37883 18.0937 1.67 17.961 1.99044C17.8283 2.31089 17.76 2.65434 17.76 3.00119C17.76 3.34803 17.8283 3.69148 17.961 4.01193C18.0937 4.33237 18.2883 4.62354 18.5336 4.8688C18.7788 5.11405 19.07 5.3086 19.3904 5.44134C19.7109 5.57407 20.0543 5.64239 20.4012 5.64239C21.1017 5.64223 21.7734 5.36381 22.2686 4.86837C22.7638 4.37294 23.0419 3.70108 23.0418 3.00059C23.0416 2.3001 22.7632 1.62836 22.2677 1.13315C21.7723 0.637942 21.1005 0.359826 20.4 0.359985H20.4012Z'
														fill='#A098AE'
													/>
												</svg>
											</Dropdown.Toggle>
											<Dropdown.Menu className='dropdown-menu dropdown-menu-end' align={'end'}>
												{/* <Dropdown.Item onClick={() => {}}>Edit</Dropdown.Item> */}
												<Dropdown.Item
													className='text-danger'
													onClick={() => {
														deleteStaff(staff.id);
													}}>
													Delete
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
										{/* <div className="d-flex justify-content-end">
                                            <div
                                                style={{ cursor: "pointer" }}
                                                className="icon-box icon-box-sm bg-danger me-2"
                                                onClick={() => {
                                                    deleteStaff(staff.id);
                                                }}
                                            >
                                                <i
                                                    className="material-symbols-outlined"
                                                    style={{
                                                        fontSize: 10,
                                                        color: "white",
                                                    }}
                                                >
                                                    delete
                                                </i>
                                            </div>
                                        </div> */}
										<div className='user-content'>
											<div className='user-info'>
												<div className='user-img'>
													<img
														src={staff?.staff?.avatarUrl ? staff?.staff?.avatarUrl : IMAGES.noimage}
														alt=''
														className='avatar avatar-xl'
													/>
												</div>
												<div className='user-details'>
													<h4 className='user-name mb-0'>{staff.name}</h4>
													<p>{staff.staff.isTeacher ? 'Teacher' : 'Staff'}</p>
												</div>
											</div>
										</div>
										<div className='contact-icon'>
											{staff.classes.length > 0 ? (
												<>
													{staff.classes.map((c, i) => {
														return (
															<span key={i} className='badge badge-success light mx-1'>
																{c.classInfo.name}
															</span>
														);
													})}
												</>
											) : (
												<span className='badge badge-secondary light mx-2'>Unassigned</span>
											)}
										</div>
										<div className='d-flex align-items-center'>
											<div
												onClick={() => {
													navigate(`/staff/details/${staff.id}`);
												}}
												className='btn btn-primary btn-sm w-50 me-2'>
												<i className='fa fa-eye me-2'></i>
												View
											</div>
											{/* <Link
                                                to={"/staff/details/" + staff.id}
                                                className="btn  btn-primary btn-sm w-50 me-2"
                                            >
                                                <i className="fa fa-eye me-2"></i>
                                                View
                                            </Link> */}
											{/* staff/details/ */}
											<div
												onClick={() => {
													navigate(`/staff/edit-staff/${staff.id}`);
												}}
												className='btn btn-secondary btn-sm w-50 me-2'>
												<i className='fa fa-eye me-2'></i>
												Edit
											</div>
											{/* <Link
                                                to={"/add-staff"}
                                                state={{ edit: true }}
                                                className="btn  btn-secondary btn-sm w-50"
                                            >
                                                <i className="fa fa-pencil me-2"></i>
                                                Edit
                                            </Link> */}
										</div>
									</div>
								</div>
							</div>
						))}
				</div>
				<div className='table-pagenation teach'>
					<small>
						Showing <span>1-12</span> from <span> 24</span> data
					</small>
					<nav>
						<ul className='pagination pagination-gutter pagination-primary no-bg'>
							<li className='page-item page-indicator'>
								<Link to={'#'} className='page-link'>
									<i className='fa-solid fa-chevron-left'></i>
								</Link>
							</li>
							<li className='page-item active'>
								<Link to={'#'} className='page-link'>
									1
								</Link>
							</li>
							<li className='page-item '>
								<Link to={'#'} className='page-link'>
									2
								</Link>
							</li>
							<li className='page-item page-indicator'>
								<Link to={'#'} className='page-link'>
									<i className='fa-solid fa-chevron-right'></i>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Teachers;
