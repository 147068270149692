import { formatParams } from '../services/general';
import apiHandler from './apiHandler';

export async function progressReports(classId: number, term: string) {
	return apiHandler.get(`/progressReport${formatParams({ classId, term })}`);
}

export async function uploadprogressReport(studentId: number, reportUrl: string, term: string) {
	return apiHandler.post(`/progressReport`, { studentId, reportUrl, term });
}

const progressReportAPI = {
	progressReports,
	uploadprogressReport,
};

export default progressReportAPI;
