import React, { useState, useRef, useEffect, Suspense } from 'react';
import stripeSubscriptionApi, { StripeSubscription } from '../../../api/stripeSubscriptionApi';
import swal from 'sweetalert';

const Subscription = () => {
	const [subscription, setSubscription] = useState<StripeSubscription>();

	const getSubscription = async () => {
		stripeSubscriptionApi.get().then((response) => {
			console.log(response);
			setSubscription(response);
		});
	};

	useEffect(() => {
		getSubscription();
	}, []);

	const Loading = () => {
		return <div className='text-xl'>Loading...</div>;
	};

	const statucColor = subscription?.status === 'active' ? 'green' : 'black';

	return (
		<Suspense fallback={<Loading />}>
			<div className='col-xl-12'>
				<div className='card'>
					<div className='card-body pb-xl-4 pb-sm-3 pb-0'>
						<div className='card col-8'>
							<div className='card-body d-flex flex-column gap-lg-5' style={{ border: '1px solid lightgray' }}>
								<div
									style={{
										padding: '1rem',
										borderBottom: '1px solid lightgray',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<h3>Subscription Details</h3>
									<h3 style={{ color: `${statucColor}` }}>{subscription?.status?.toUpperCase()}</h3>
								</div>
								<p
									style={{
										margin: '3rem 1 0 3rem 0',
										paddingInline: '1rem',
										color: 'black',
										fontSize: '14pt',
										display: 'flex',
										gap: '50pt',
									}}
								>
									<span>Headcount:</span>
									<input
										id='headcount'
										type='number'
										defaultValue={subscription?.headCount}
										min={1}
										max={1000}
										style={{ color: 'darkgreen', fontSize: '12pt' }}
									/>
									<a
										className='shadow-hover'
										href='#'
										style={{
											alignContent: 'center',
											textAlign: 'center',
											fontSize: '12pt',
											fontWeight: 'bold',
											color: 'white',
											backgroundColor: '#40a0ffc0',
											borderRadius: '2rem',
											padding: '0 2rem 0 2rem',
											boxShadow: '4px 4px 5px lightgray',
										}}
										onClick={async () => {
											const hce = document.getElementById('headcount') as HTMLInputElement;
											if (!hce) return;
											const newHeadCount = parseInt(hce.value);
											try {
												const subscription = await stripeSubscriptionApi.get();
												await stripeSubscriptionApi.updateHeadCount({ ...subscription, headCount: newHeadCount });
												getSubscription();
												swal('Success', 'Headcount updated successfully.', 'success');
											} catch (err) {
												swal('Error', (err as Error).message, 'error');
											}
										}}
									>
										Change
									</a>
								</p>
								{subscription?.cancelAt === undefined && (
									<a
										href='#'
										style={{
											marginTop: '2rem',
											padding: '0.3rem 2rem 0.3rem 2rem',
											backgroundColor: '#b00000',
											alignContent: 'center',
											textAlign: 'center',
											fontSize: '12pt',
											color: 'white',
											borderRadius: '2rem',
											boxShadow: '4px 4px 5px lightgray',
										}}
										onClick={async () => {
											try {
												await stripeSubscriptionApi.cancel();
												getSubscription();
												swal('Success', 'Subscription cancelled successfully.', 'success');
											} catch (err) {
												swal('Error', (err as Error).message, 'error');
											}
										}}
									>
										Cancel Subscription
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Suspense>
	);
};

export default Subscription;
