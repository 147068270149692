import apiHandler from './apiHandler';

export async function dashboardList() {
	return apiHandler.get(`/dashboard`);
}
export async function calendarEventList() {
	return apiHandler.get(`/holidays/events`);
}

const dashboardAPI = {
	dashboardList,
	calendarEventList
};

export default dashboardAPI;