import apiHandler from './apiHandler';

const getTimeTable = async (classId: number, date: String) => {
	return await apiHandler.get(`/timetable?date=${date}&class=${classId}`);
};

const postTimeTable = async (data: {
	title: string;
	description: string;
	class_id: number;
	color: string;
	date: string;
	start_time: string;
	end_time: string;
	date_created: string;
}) => {
	return await apiHandler.post(`timetable`, data);
};

const editTimeTable = async (data: {
	id: number;
	title: string;
	description: string;
	class_id: number;
	date: string;
	color: string;
	start_time: string;
	end_time: string;
	date_created: string;
}) => {
	return await apiHandler.put(`timetable`, data);
};

export async function deleteTimeTable(id: number) {
	return apiHandler.delete(`/timetable`, { data: { id } });
}

//     ######   #######           ######  ##     ## ########  ########  ####  ######  ##     ## ##          ###    ##     ##
//    ##    ## ##     ##         ##    ## ##     ## ##     ## ##     ##  ##  ##    ## ##     ## ##         ## ##   ###   ###
//    ##       ##     ##         ##       ##     ## ##     ## ##     ##  ##  ##       ##     ## ##        ##   ##  #### ####
//    ##       ##     ## ####### ##       ##     ## ########  ########   ##  ##       ##     ## ##       ##     ## ## ### ##
//    ##       ##     ##         ##       ##     ## ##   ##   ##   ##    ##  ##       ##     ## ##       ######### ##     ##
//    ##    ## ##     ##         ##    ## ##     ## ##    ##  ##    ##   ##  ##    ## ##     ## ##       ##     ## ##     ##
//     ######   #######           ######   #######  ##     ## ##     ## ####  ######   #######  ######## ##     ## ##     ##

type AddCoco = {
	id?: number;
	color: string;
	title: string;
	description: string;
	teachers: string[];
	student_people_ids: number[];
	date: string;
	start_time: string;
	end_time: string;
};

export async function addCocoRecord(data: AddCoco) {
	return apiHandler.post(`/timetable/coCurriculum`, data);
}

export async function getCocoList(date: string) {
	return apiHandler.get(`/timetable/coCurriculum?date=` + date);
}

export async function editCocoItem(data: AddCoco) {
	return apiHandler.put(`/timetable/coCurriculum`, data);
}

export async function deleteCocoItem(id: number) {
	return apiHandler.delete(`/timetable/coCurriculum`, { data: { id: Number(id) } });
}

export async function getCocoItemById(id: number) {
	return apiHandler.get(`/timetable/coCurriculum?id=` + id);
}

const timeTableApi = {
	getTimeTable,
	postTimeTable,
	editTimeTable,
	deleteTimeTable,
	addCocoRecord,
	getCocoList,
	editCocoItem,
	deleteCocoItem,
	getCocoItemById,
};

export default timeTableApi;
