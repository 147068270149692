import apiHandler from './apiHandler';

// enum EnrollmentType {
// 	Morning = 'Morning',
// 	Evening = 'Evening',
// 	Extended = 'Extended',
// }

type CreateProps = {
	type: string;
	startTime: string;
	endTime: string;
};
type EditProps = {
	id: string;
	type: string;
	startTime: string;
	endTime: string;
};

type EnrollmentTypeInfo = {
	id: number;
	type: string;
	startTime: string;
	endTime: string;
};

async function getEnrollmentTypes(): Promise<EnrollmentTypeInfo[]> {
	return await apiHandler.get(`/settings/enrollmentTypes`);
}
async function createEnrollmentType(postData: CreateProps): Promise<EnrollmentTypeInfo> {
	return await apiHandler.post('/settings/enrollmentTypes', postData);
}
async function editEnrollmentType(postData: EditProps): Promise<EnrollmentTypeInfo> {
	return await apiHandler.put('/settings/enrollmentTypes', postData);
}
async function deleteEnrollmentType(id: number): Promise<EnrollmentTypeInfo> {
	return await apiHandler.delete('/settings/enrollmentTypes?id=' + id);
}

export function EnrollmentTypeOptions(types: EnrollmentTypeInfo[]) {
	const out: { label: string; value: number }[] = [];
	for (const val of types) out.push({ label: val.type, value: val.id });
	// Object.keys(EnrollmentType).forEach((k, index) => {
	// 	out.push({ label: k, value: Object.values(EnrollmentType)[index] });
	// });
	return out;
}

const devicesAPI = {
	getEnrollmentTypes,
	createEnrollmentType,
	deleteEnrollmentType,
	editEnrollmentType,
};

export default devicesAPI;
