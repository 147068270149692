import apiHandler from './apiHandler';

export function getAttendanceList(postData: { date: string; klass?: number; category: string }) {
	return apiHandler.get(
		`/attendance?category=${postData.category}${postData.date ? `&date=${postData.date}` : ''}${
			postData.klass ? `&klass=${postData.klass}` : ''
		}`
	);
}
export function createAttendance(postData: any) {
	return apiHandler.post('/attendance/manual', postData);
}
export function getPickUpPerson(id: number) {
	return apiHandler.get('/attendance/pickup/' + id);
}

const attendanceAPI = {
	getAttendanceList,
	createAttendance,
	getPickUpPerson,
};

export default attendanceAPI;
