import apiHandler from './apiHandler';

export enum FeeType {
	fees = 'Monthly',
	deposit = 'Deposit',
	adhoc = 'Adhoc',
	other = 'Other',
}

export type CreateFeeProps = {
	description: string;
	type: FeeType;
	amount: number;
};

export type FeeInfo = {
	id: number;
	schoolId: number;
	description: string;
	type: FeeType;
	amount: number;
};

export async function getFeesList(): Promise<FeeInfo[]> {
	return await apiHandler.get(`/settings/fees`);
}

export async function createFee(postData: CreateFeeProps) {
	return await apiHandler.post('/settings/fees', postData);
}

export async function deleteFee(feeId: string) {
	return await apiHandler.delete('/settings/fees?feeId=' + feeId);
}

export function FeeTypeList() {
	const out: any[] = [];
	Object.keys(FeeType).forEach((k, index) => {
		out.push({ label: k, value: Object.values(FeeType)[index] });
	});
	return out;
}

const feesApi = {
	getFeesList,
	createFee,
	deleteFee,
};

export default feesApi;
