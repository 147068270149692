import financeApi from '../../../../../api/financeApi';
import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Transaction No',
		Footer: 'Transaction No',
		accessor: 'transactionId',
	},
	{
		Header: 'Action',
		Footer: 'Action',
	},
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'timestamp',
		Cell: ({ value }) => {
			return moment(Number(value) * 1000).format('DD/MM/yyyy HH:mm a');
		},
	},
	{
		Header: 'Description',
		Footer: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Amount',
		Footer: 'Amount',
		accessor: 'amount',
		Cell: ({ value }) => {
			if (Number(value) < 0) return <span className='text-red'>{`(RM ${Math.abs(Number(value)).toFixed(2).toLocaleString()})`}</span>;
			else return `RM ${Number(value).toFixed(2).toLocaleString()}`;
		},
	},
];

export async function getLedger(date) {
	try {
		let res = await financeApi.ledgerList(new Date(date).toISOString());
		if (res.success) {
			return res.data;
		} else {
			return { transactions: [], aggregated: undefined };
		}
	} catch (err) {
		console.log(err.message);
		return { transactions: [], aggregated: undefined };
	}
}
