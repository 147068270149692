import newsAPI from '../../../../api/newsApi';
import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Title',
		Footer: 'Name',
		accessor: 'title',
	},
	{
		Header: 'Type',
		Footer: 'Type',
		accessor: 'type',
	},
	{
		Header: 'Post Date',
		Footer: 'post Date',
		accessor: 'postDate',
		Cell: ({ value }) => {
			return moment(value).format('DD-MM-yyyy');
		},
	},
	{
		Header: 'Start Date',
		Footer: 'Start Date',
		accessor: 'startDate',
		Cell: ({ value }) => {
			return moment(value).format('DD-MM-yyyy');
		},
	},
	{
		Header: 'End Date',
		Footer: 'End Date',
		accessor: 'endDate',
		Cell: ({ value }) => {
			return moment(value).format('DD-MM-yyyy');
		},
	},

	{
		Header: 'Description',
		Footer: 'Description',
		accessor: 'description',
		Cell: ({ value }) => {
			return (
				<p
					style={{
						maxWidth: '340px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}>
					{value}
				</p>
			);
		},
	},
	{
		Header: 'Action',
		Footer: 'Action',
	},
];

export async function getNewsList() {
	try {
		const res = await newsAPI.newsList();
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
	}
}
