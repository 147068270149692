import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import profile06 from '../../../../images/profile/6.jpg';
import moment from 'moment';
import { IMAGES } from '../../Dashboard/Content';

export const TeacherDetails = (props) => {
	const [currentPage, setCurrentPage] = useState(1);
	const recordsPage = 10;
	const lastIndex = currentPage * recordsPage;
	const firstIndex = lastIndex - recordsPage;
	const records = props?.data.slice(firstIndex, lastIndex);
	const npage = Math.ceil(props?.data.length / recordsPage);
	const number = [...Array(npage + 1).keys()].slice(1);
	function prePage() {
		if (currentPage !== 1) {
			setCurrentPage(currentPage - 1);
		}
	}
	function changeCPage(id) {
		setCurrentPage(id);
	}
	function nextPage() {
		if (currentPage !== npage) {
			setCurrentPage(currentPage + 1);
		}
	}
	return (
		<div className='table-responsive basic-tbl'>
			<div id='teacher-table_wrapper' className='dataTables_wrapper no-footer'>
				<table id='teacher-table' className='tech-data dataTable no-footer' style={{ width: '100%' }}>
					<thead>
						<tr>
							<th>Name</th>
							<th className='text-center'>Temperature</th>
							<th className='text-end'>Status</th>
						</tr>
					</thead>
					<tbody className='text-black'>
						{records.map((item, ind) => (
							<tr key={ind}>
								<td className='text-black' style={{ fontSize: '11pt' }}>
									<img
										src={item?.avatarUrl !== undefined ? item?.avatarUrl : IMAGES.noimage}
										
										alt=''
										className='avatar avatar-sm me-3'
									/>
									{item.name}
								</td>
								<td className='text-black text-center'>{item.temperature ? `${item.temperature} °C` : '-'}</td>
								<td className='text-end'>
									<span className={`badge badge-sm light badge-${item.status === 'Present' ? 'success' : 'danger'}`}>
										{item.status}
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className='d-sm-flex text-center justify-content-between align-items-center'>
					<div className='dataTables_info'>
						Showing {lastIndex - recordsPage + 1} to {props?.data.length < lastIndex ? props?.data.length : lastIndex} of{' '}
						{props?.data.length} entries
					</div>
					<div className='dataTables_paginate paging_simple_numbers justify-content-center' id='example2_paginate'>
						<Link className='paginate_button previous disabled' to='#' onClick={prePage}>
							<i className='fa-solid fa-angle-left' />
						</Link>
						<span>
							{number.map((n, i) => (
								<Link
									className={`paginate_button ${currentPage === n ? 'current' : ''} `}
									key={i}
									onClick={() => changeCPage(n)}>
									{n}
								</Link>
							))}
						</span>
						<Link className='paginate_button next' to='#' onClick={nextPage}>
							<i className='fa-solid fa-angle-right' />
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
