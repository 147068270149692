import absentAPI from '../../../../api/absentApi';
import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Student Name',
		Footer: 'Student Name',
		accessor: 'student.name',
	},
	{
		Header: 'Start Date',
		Footer: 'Start Date',
		accessor: 'startDate',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'End Date',
		Footer: 'End Date',
		accessor: 'endDate',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Class Name',
		Footer: 'Class Name',
		accessor: 'className',
	},
	{
		Header: 'Acknowledged By',
		Footer: 'Acknowledged By',
		accessor: 'acknowledgedByStaff',
	},
	{
		Header: 'Reason',
		Footer: 'Reason',
		accessor: 'reason',
	},
];

export async function getAbsent(date) {
	try {
		let res = await absentAPI.absentList(date);
		// console.log(res);
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
	}
}
