export const MenuList = [
	//Dashboard
	{
		title: 'Dashboard',
		acl: 'dashboard',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>home</i>,
		to: 'dashboard',
	},
	{
		title: 'Student',
		acl: 'student',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>school</i>,
		to: 'student',
	},
	{
		title: 'Staff',
		acl: 'staff',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>person</i>,
		to: 'staff',
	},
	{
		title: 'Class',
		acl: 'class',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>class</i>,
		to: 'class',
	},
	{
		title: 'Time Table',
		acl: 'timetable',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>view_timeline</i>,
		content: [
			{
				title: 'Class',
				to: '/timetable/class',
			},
			{
				title: 'Co-Curriculum',
				to: '/timetable/co-curriculum',
			},
		],
	},
	{
		title: 'Attendance',
		acl: 'attendance',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>how_to_reg</i>,
		content: [
			{
				title: 'Student',
				to: '/attendance/student-attendance',
			},
			{
				title: 'Staff',
				to: '/attendance/staff-attendance',
			},
		],
	},
	{
		title: 'Finance',
		acl: 'finance',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>price_change</i>,
		content: [
			{
				title: 'Billing',
				to: '/finance/billing',
			},
			{
				title: 'Ledger',
				to: '/finance/ledger',
			},
			{
				title: 'Payroll',
				to: '/finance/payroll',
			},
			{
				title: 'Claims',
				to: '/finance/claims',
			},
			{
				title: 'Payout Pending',
				to: '/finance/payout-pending',
			},
		],
	},
	{
		title: 'Leave',
		acl: 'leave',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>kayaking</i>,
		content: [
			{
				title: 'Upcoming',
				to: 'leave-management',
			},
			{
				title: 'Historical',
				to: 'leave-history',
			},
		],
	},
	{
		title: 'Chat History',
		acl: 'chat',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>chat</i>,
		to: 'chat-history',
	},
	{
		title: 'Absentees',
		acl: 'attendance',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>outbound</i>,
		to: 'absent',
	},
	{
		title: 'Transport',
		acl: 'student',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>directions_bus</i>,
		to: 'transport',
	},
	{
		title: 'Medication',
		acl: 'medication',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>medication</i>,
		to: 'medication',
	},
	{
		title: 'Injury Report',
		acl: 'health',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>medication</i>,
		to: 'injury-report',
	},
	{
		title: 'Report Card',
		acl: 'progressreport',
		classsChange: 'mm-collapse',
		iconStyle: <i className='mdi mdi-star-half'></i>,
		to: 'report-card',
	},
	{
		title: 'News & Event',
		acl: 'news',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>library_books</i>,
		to: 'news',
	},
	{
		title: 'Birthday',
		acl: 'student',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>cake</i>,
		to: 'birthdays',
	},
	{
		title: 'Holidays',
		acl: 'holidays',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>calendar_month</i>,
		to: 'holidays',
	},
	{
		title: 'Weekly Task',
		acl: 'tasks',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>add_task</i>,
		to: 'weekly-task',
	},
	{
		title: 'Evaluation',
		acl: 'evaluation',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>summarize</i>,
		to: 'evaluation',
	},
	{
		title: 'Food',
		acl: 'holidays', // change to food acl in the future
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>local_dining</i>,
		to: 'food',
	},
	{
		title: 'Settings',
		acl: 'settings',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>settings</i>,
		content: [
			{
				title: 'Devices',
				to: 'settings/devices',
			},
			{
				title: 'Enrollment Type',
				to: 'settings/enrollment-type',
			},
			{
				title: 'Fees',
				to: 'settings/fees-setup',
			},
			{
				title: 'Payment Gateway',
				to: 'settings/payment-gateway-setup',
			},
			// {
			// 	title: 'End of Year',
			// 	to: 'settings/end-of-year',
			// },
			{
				title: 'Rejection Reasons',
				to: 'settings/rejection-reasons',
			},
			{
				title: 'School Details',
				to: 'settings/school-setup',
			},
		],
	},
	{
		title: 'Admin',
		acl: 'admin',
		classsChange: 'mm-collapse',
		iconStyle: <i className='material-symbols-outlined'>manage_accounts</i>,
		content: [
			{
				title: 'Access Control',
				to: '/admin',
			},
			{
				title: 'Notification',
				to: '/admin/notification',
			},
			{
				title: 'Manage Subscription',
				to: '/admin/subscription',
			},
		],
	},
	//         {
	//             title: 'Shop',
	//             //to: './',
	//             hasMenu : true,
	//             content: [
	//                 {
	//                     title: 'Product Grid',
	//                     to: 'ecom-product-grid',
	//                 },
	//                 {
	//                     title: 'Product List',
	//                     to: 'ecom-product-list',
	//                 },
	//                 {
	//                     title: 'Product Details',
	//                     to: 'ecom-product-detail',
	//                 },
	//                 {
	//                     title: 'Order',
	//                     to: 'ecom-product-order',
	//                 },
	//                 {
	//                     title: 'Checkout',
	//                     to: 'ecom-checkout',
	//                 },
	//                 {
	//                     title: 'Invoice',
	//                     to: 'ecom-invoice',
	//                 },
	//                 {
	//                     title: 'Customers',
	//                     to: 'ecom-customers',
	//                 },
	//             ],
	//         },
	//     ],
	// },
];
