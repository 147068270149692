import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { IMAGES, SVGICON } from '../Dashboard/Content';
import { Dropdown } from 'react-bootstrap';
import PaymentHistoryTable from './PaymentHistoryTable';

import profile from './../../../images/profile.svg';
import location from './../../../images/svg/location.svg';
import phone from './../../../images/svg/phone.svg';
import email from './../../../images/svg/email.svg';
import studentAPI from '../../../api/studentApi';
import { formatDate, formatCurrency } from '../../../services/general';

const scheduleBlog = [
	{ title: 'Basic Algorithm', subtitle: 'Algorithm', image: IMAGES.avat1, color: 'schedule-card' },
	{ title: 'Basic Art', subtitle: 'Art', image: IMAGES.avat2, color: 'schedule-card-1' },
	{ title: 'React & Scss', subtitle: 'Programming', image: IMAGES.avat3, color: 'schedule-card-2' },
	{ title: 'Simple Past Tense', subtitle: 'English', image: IMAGES.avat4, color: 'schedule-card-3' },
];

const basicDetail = [
	{ title: 'Parents', subtitle: 'Justin Hope', image: profile },
	{ title: 'Address', subtitle: 'Jakarta, Indonesia', image: location },
	{ title: 'Phone', subtitle: '+12 345 6789 0', image: phone },
	{ title: 'Email', subtitle: 'Historia@mail.com', image: email },
];

const StudentDetails = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [studentId, setStudentId] = useState(params.studentId);
	const [studentData, setStudentData] = useState([]);
	const [avatar, setAvatar] = useState(null);

	useEffect(() => {
		if (studentId) {
			getStudentDetails();
		}
	}, [params.studentId]);

	const getStudentDetails = async () => {
		try {
			const res = await studentAPI.getStudentDetails(studentId);

			if (res.success) {
				const studentData = res.data;
				setStudentData(studentData);
				if (studentData.student?.avatarUrl) {
					setAvatar(studentData.student?.avatarUrl);
				}
			}
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<div className='row'>
			<div className='col-xl-12'>
				<div className='card h-auto'>
					<div className='card-header p-0'>
						<div className='user-bg w-100'>
							<div className='user-svg'>
								<svg width='264' height='109' viewBox='0 0 264 109' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<rect x='0.0107422' y='0.6521' width='263.592' height='275.13' rx='20' fill='#FCC43E' />
								</svg>
							</div>
							<div className='user-svg-1'>
								<svg width='264' height='59' viewBox='0 0 264 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<rect y='0.564056' width='263.592' height='275.13' rx='20' fill='#FB7D5B' />
								</svg>
							</div>
						</div>
					</div>
					<div className='card-body'>
						<div className='d-flex justify-content-between'>
							<div className='user'>
								<div className='user-media'>
									<img src={avatar ? avatar : IMAGES.noimage} alt='' className='avatar avatar-xxl bg-white' />
								</div>
								<div>
									<h2 className='mb-0'>{studentData.name}</h2>
									<p className='text-primary font-w600'>{studentData.role}</p>
								</div>
							</div>
							{/* <Dropdown className='custom-dropdown'>
								<Dropdown.Toggle as='div' className='i-false btn sharp tp-btn '>
									{SVGICON.dots}
								</Dropdown.Toggle>
								<Dropdown.Menu className='dropdown-menu-end' align='end'>
									<Dropdown.Item>Option 1</Dropdown.Item>
									<Dropdown.Item>Option 2</Dropdown.Item>
									<Dropdown.Item>Option 3</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown> */}
						</div>
						<div className='row mt-2'>
							{/* {basicDetail.map((item, ind) => (
								<div className=' col-xxl-6 col-sm-6' key={ind}>
									<ul className='student-details'>
										<li className='me-2'>
											<Link to={'#'} className='icon-box bg-secondary'>
											<img src={item.image} alt='' />
											</Link>
										</li>
										<li>
											<span>{item.title}:</span>
											<h5 className='mb-0'>{item.subtitle}</h5>
										</li>
									</ul>
								</div>
							))} */}
							<div className='col-xl-6 col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li className='me-2 icon-box bg-secondary '>
										<i className='material-symbols-outlined align-middle' style={{ fontSize: 14, color: 'white' }}>
											person
										</i>
									</li>
									<li>
										<span>Parents:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.name}</h5>
									</li>
								</ul>
							</div>
							<div className='col-xl-6 col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li className='me-2 icon-box bg-secondary '>
										<i className='material-symbols-outlined align-middle' style={{ fontSize: 14, color: 'white' }}>
											pin_drop
										</i>
									</li>
									<li>
										<span>Address:</span>
										<h5 className='mb-0'>{studentData?.student?.address}</h5>
									</li>
								</ul>
							</div>
							<div className='col-xl-6 col-xxl-6 col-sm-6 mt-4 '>
								<ul className='student-details'>
									<li className='me-2 icon-box bg-secondary '>
										<i className='material-symbols-outlined align-middle' style={{ fontSize: 14, color: 'white' }}>
											{studentData.gender === 'Female' ? 'female' : 'male'}
										</i>
									</li>
									<li>
										<span>Gender:</span>
										<h5 className='mb-0'>{studentData.gender}</h5>
									</li>
								</ul>
							</div>
							<div className='col-xl-6 col-xxl-6 col-sm-6  mt-4 '>
								<ul className='student-details'>
									<li className='me-2 icon-box bg-secondary '>
										<i className='material-symbols-outlined align-middle' style={{ fontSize: 14, color: 'white' }}>
											cake
										</i>
									</li>
									<li>
										<span>DOB:</span>
										<h5 className='mb-0'>{formatDate(studentData.student?.dob)}</h5>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='card h-auto'>
					<div className='card-header  p-3'>
						<h4 className='heading mb-0'>Details</h4>
					</div>
					<div className='card-body'>
						<div className='row'>
							<div className=' col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>IC / Passport:</span>
										<h5 className='mb-0'>{studentData.idNumber ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Nationality:</span>
										<h5 className='mb-0'>{studentData.student?.nationality ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Race:</span>
										<h5 className='mb-0'>{studentData.race ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Religion:</span>
										<h5 className='mb-0'>{studentData.religion ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>No of Siblings:</span>
										<h5 className='mb-0'>{studentData.student?.siblings ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Home Language:</span>
										<h5 className='mb-0'>{studentData.student?.homeLanguage ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Info:</span>
										<h5 className='mb-0'>{studentData.student?.info ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Previous Pre School:</span>
										<h5 className='mb-0'>{studentData.student?.previousPreschool ?? '-'}</h5>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='card h-auto'>
					<div className='card-header  p-3'>
						<h4 className='heading mb-0'>Enrolment Details</h4>
					</div>
					<div className='card-body'>
						<div className='row'>
							<div className='col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Class:</span>
										<h5 className='mb-0'>
											{studentData.classes && studentData.classes.length > 0
												? studentData.classes[0].classInfo?.name
												: '-'}
										</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Enrolment Type:</span>
										<h5 className='mb-0'>{studentData.student?.enrollmentType.type}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Fee:</span>
										<h5 className='mb-0'>
											{studentData.student?.monthlyFee?.amount
												? formatCurrency(studentData.student?.monthlyFee?.amount)
												: '-'}
										</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Enrolment Deposit Fee:</span>
										<h5 className='mb-0'>
											{studentData.student?.enrollmentDepositFee?.amount
												? formatCurrency(studentData.student?.enrollmentDepositFee?.amount)
												: '-'}
										</h5>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='card h-auto'>
					<div className='card-header p-3'>
						<h4 className='heading mb-0'>Parents Details</h4>
					</div>
					<div className='card-body'>
						<div className='row'>
							<div className=' col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Name:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.name}</h5>
									</li>
								</ul>
							</div>

							<div className=' col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Phone Number:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.mobileNumber}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Email:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.email}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Relationship:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.relationship}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Occupation:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.occupation ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Employer:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.employer ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Work Address:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.workAddress ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Work Contact Number:</span>
										<h5 className='mb-0'>{studentData.student?.parent?.workPhone ?? '-'}</h5>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{studentData.student?.pickupPersons.length > 0 && (
					<div className='card h-auto'>
						<div className='card-header p-3'>
							<h4 className='heading mb-0'>Authorised Pick Up Details</h4>
						</div>
						<div className='card-body'>
							{studentData.student?.pickupPersons.map((item, index) => {
								return (
									<div className='row' key={index}>
										<div className=' col-xxl-6 col-sm-6'>
											<ul className='student-details'>
												<li>
													<span className='fs-5 fw-semi-bold'>Name:</span>
													<h5 className='mb-0'>{item?.name ?? '-'}</h5>
												</li>
											</ul>
										</div>
										<div className=' col-xxl-6 col-sm-6'>
											<ul className='student-details'>
												<li>
													<span className='fs-5 fw-semi-bold'>IC / Passport:</span>
													<h5 className='mb-0'>{item?.idNumber ?? '-'}</h5>
												</li>
											</ul>
										</div>
										<div className=' col-xxl-6 col-sm-6  mt-4 mb-4'>
											<ul className='student-details'>
												<li>
													<span className='fs-5 fw-semi-bold'>Contact Number:</span>
													<h5 className='mb-0'>{item?.contactNumber ?? '-'}</h5>
												</li>
											</ul>
										</div>
										<hr />
									</div>
								);
							})}
						</div>
					</div>
				)}
				<div className='card h-auto'>
					<div className='card-header p-3'>
						<h4 className='heading mb-0'>Medical Details</h4>
					</div>
					<div className='card-body'>
						<div className='row'>
							<div className=' col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Allergies:</span>
										<h5 className='mb-0'>{studentData.student?.allergies ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Medical History:</span>
										<h5 className='mb-0'>{studentData.student?.medicalHistory ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Family Doctor Name:</span>
										<h5 className='mb-0'>{studentData.student?.familyDoctorName ?? '-'}</h5>
									</li>
								</ul>
							</div>
							<div className=' col-xxl-6 col-sm-6 mt-4'>
								<ul className='student-details'>
									<li>
										<span className='fs-5 fw-semi-bold'>Family Doctor Contact Number:</span>
										<h5 className='mb-0'>{studentData.student?.familyDoctorContact ?? '-'}</h5>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				{/* <div className='card h-auto'>
					<div className='card-header border-0 p-3'>
						<h4 className='heading mb-0'>Payment History</h4>
					</div>
					<div className='card-body p-0'>
						<PaymentHistoryTable />
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default StudentDetails;
