import React from 'react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';

export function MonthDropDown(props) {
	const [startDate, setStartDate] = useState(new Date());
	// const [isFilter,setIsFilter] = useState(false);

	return (
		<div className='d-flex'>
			<button
				type='button'
				className='btn btn-linklight'
				onClick={() => {
					props.cb(startDate);
					// setIsFilter((prevState) => {
					// 	return !prevState;
					// });
				}}>
				Filter By Date
			</button>
			<div className='d-flex align-items-center'>
				<DatePicker
					dateFormat='MM/yyyy'
					className='form-control'
					selected={startDate}
					showMonthYearPicker
					fixedHeight
					onChange={(date) => {
						setStartDate(date);
						props.cb(date);
					}}
				/>
			</div>
		</div>
	);
}

const DropDownFilter = {
	MonthDropDown,
};

export default DropDownFilter;
