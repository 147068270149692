import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { startOfDay, format } from 'date-fns';
import swal from 'sweetalert';
import timeTableApi from '../../../../api/timeTableApi';
import { useNavigate } from 'react-router-dom';
import '../../../../css/timetable.css';
import { Form, Formik } from 'formik';
import { SketchPicker } from 'react-color';
import { isDarkColor } from '../../../../services/general';

const CoCurriculumTimeTable = () => {
	const [date, setDate] = useState(new Date());
	const [timeTable, setTimeTable] = useState([]);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editdata, setEditData] = useState({});
	const [editColor, setEditColor] = useState(false);

	useEffect(() => {
		getTimeTable();
	}, [date, showEditModal]);

	let navigate = useNavigate();

	const getTimeTable = async () => {
		try {
			const l = new Map();
			let tableList = []; // console.log("query for class", classes[activeTab].id, " day: ", format(day, "yyyy-MM-dd"));
			const res = await timeTableApi.getCocoList(format(date, 'yyyy-MM-dd'));

			if (res && res.success && res.data.length > 0) {
				for (const d of res.data) {
					if (l.has(d.date)) {
						const val = l.get(d.date);
						l.set(d.date, [...val, d]);
					} else {
						l.set(d.date, [d]);
					}
				}
				for (const v of l.values()) {
					tableList.push({
						date: v[0].date,
						data: v.sort((a, b) => Number(a.start_time.split(':')[0]) - Number(b.start_time.split(':')[0])),
					});
				}
			}
			setTimeTable(tableList);
		} catch (err) {
			console.log(err);
		}
	};

	const formatToTime = (date) => {
		let ans =
			(date.getHours() === 0 ? '00' : date.getHours().toString()) +
			':' +
			(date.getMinutes() === 0 ? '00' : date.getMinutes().toString());
		return ans;
	};

	return (
		<>
			<div className='row'>
				<div className='col-xl-4 col-md-6 col-12'>
					<div className='card card-body'>
						<div>
							<h2 className='heading fw-bold fs-3'>Date Range</h2>
							<div className='d-flex align-items-center'>
								<button
									type='button'
									className='btn btn-linklight'
									style={{
										cursor: 'default',
										color: 'black',
										width: '100px',
										paddingLeft: '0px',
										paddingRight: '0px',
										textAlign: 'left',
									}}>
									Select Week
								</button>
								<DatePicker
									dateFormat='dd/MM/yyyy'
									className='form-control'
									selected={date}
									onChange={(d) => {
										const _startTime = startOfDay(d);
										setDate(_startTime);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='col-xl-4 col-md-6 col-12'>
					<div className='card card-body'>
						<div>
							<h2 className='heading fw-bold fs-3'>Add Co-Curriculum </h2>
							<p className='text-black fs-5'>Click to add Co-Curriculum timetable</p>
							<button
								type='button'
								className='btn btn-primary'
								onClick={() => {
									navigate('/timetable/co-curriculum/add');
								}}>
								<div className='d-flex'>
									Add
									<i className='material-symbols-outlined' style={{ marginRight: '2px' }}>
										arrow_forward
									</i>
								</div>
							</button>
						</div>
					</div>
				</div>
				<div className='col-xl-4 col-md-6 col-12'>
					<div className='card card-body'>
						<div>
							<h2 className='heading fw-bold fs-3'>Edit Co-Curriculum</h2>
							<p className='text-black fs-5'>Click the Co-Curriculum timetable to edit existing timetable entries</p>
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='d-flex gap-4' style={{ overflow: 'auto' }}>
					{timeTable.map((table, index) => {
						return (
							<div key={index} className='d-flex flex-column align-items-center p-3'>
								<p className='fs-4'>{format(new Date(table.date), 'yyyy/MM/dd')}</p>
								{table.data.map((data, index2) => {
									return (
										<div
											key={index2}
											className='shadow cursor-pointer timetable'
											style={{
												width: '300px',
												textAlign: 'center',
												backgroundColor: data.color ? data.color : 'white',
												color: isDarkColor(data.color ?? '#ffffff') ? '#ffffff' : '#4F42AD',
											}}
											onClick={() => {
												navigate('/timetable/co-curriculum/edit/' + data.id);
											}}>
											<div className='row' style={{ width: '100%', margin: '16px 0px' }}>
												<div className='col-5 d-flex align-items-center justify-content-center'>
													{data.start_time} - {data.end_time}
												</div>
												<div className='col-7' style={{ textAlign: 'left' }}>
													<div>{data.title}</div>
													<div>{data.description}</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
			<Modal
				backdrop='static'
				show={showEditModal}
				onHide={() => {
					setShowEditModal(false);
				}}>
				<Modal.Header closeButton>
					<h4>Edit Timetable</h4>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={editdata}
						enableReinitialize
						onSubmit={async (values) => {
							// console.log(values);
							try {
								await timeTableApi.editTimeTable({
									id: values.id,
									title: values.title,
									description: values.description,
									class_id: values.class_id,
									date: format(values.date, 'yyyy-MM-dd'),
									color: values.color,
									start_time: formatToTime(values.start_time),
									end_time: formatToTime(values.end_time),
									date_created: values.date_created,
								});
								swal({ text: 'Saved!', icon: 'success' }).then(() => {
									setShowEditModal(false);
								});
							} catch (err) {
								console.log(err);
								swal({ text: 'Cant Save!', icon: 'error' });
							}
						}}>
						{({ values, handleChange, handleBlur, setFieldValue }) => (
							<Form>
								<div className='col-12 mb-4'>
									<h6 className='form-label'>Title</h6>
									<input
										className='form-control'
										name='title'
										value={values.title}
										type='text'
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder='Class title'
									/>
								</div>
								<div className='row mb-4'>
									<div className='col-6 '>
										<h6 className='form-label'>Date</h6>
										<DatePicker
											name='date'
											dateFormat='dd/MM/yyyy'
											className='form-control'
											selected={values.date}
											onChange={(d) => setFieldValue('day', d)}
											onBlur={handleBlur}
										/>
									</div>
									<div className='col-6'>
										<h6 className='form-label'>Color</h6>
										<button
											type='button'
											className='btn btn-primary form-control'
											style={{ backgroundColor: values.color, color: 'black', textAlign: 'end' }}
											onClick={() => {
												setEditColor(true);
											}}>
											<i className='material-symbols-outlined' style={{ marginRight: '2px' }}>
												left_click
											</i>
										</button>
									</div>
								</div>
								{editColor && (
									<div className='col-12 mb-4 d-flex'>
										<SketchPicker
											color={values.color}
											onChange={(color) => setFieldValue('color', color.hex)}
											width={300}
										/>
										<button
											type='button'
											className='btn btn-primary'
											onClick={() => {
												setEditColor(false);
											}}>
											Done
										</button>
									</div>
								)}
								<div className='row'>
									<div className='col-6 mb-4'>
										<h6 className='form-label'>Start Time</h6>
										<DatePicker
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={15}
											name='start_time'
											dateFormat='h:mm aa'
											className='form-control'
											selected={values.start_time}
											onChange={(d) => setFieldValue('start_time', d)}
											onBlur={handleBlur}
										/>
									</div>
									<div className='col-6 mb-4'>
										<h6 className='form-label'>End time</h6>
										<DatePicker
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={15}
											name='end_time'
											dateFormat='h:mm aa'
											className='form-control'
											selected={values.end_time}
											onChange={(d) => setFieldValue('end_time', d)}
											onBlur={handleBlur}
										/>
									</div>
								</div>
								<div className='col-12 mb-4'>
									<h6 className='form-label'>Description</h6>
									<textarea
										className='form-control'
										name='description'
										value={values.description}
										type='text'
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder='Class title'
										rows={5}
									/>
								</div>
								<div className='col-12 d-flex justify-content-center gap-4'>
									<button type='submit' className='btn btn-primary' style={{ width: '120px' }}>
										Save
									</button>
									<button
										type='button'
										className='btn btn-danger'
										style={{ width: '120px' }}
										onClick={() => {
											setShowEditModal(false);
										}}>
										Exit
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default CoCurriculumTimeTable;
