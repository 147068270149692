import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import attendanceAPI from '../../../api/attendanceApi';
import StaffApi from '../../../api/staffApi';
import EmptyState from '../components/EmptyState';
import Pagination from '../components/Pagination';
import NewAttendance from './component/newAttendance';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

const COLUMNS = [
	{ Header: 'Id', accessor: 'personId' },
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'ID Number',
		accessor: 'idNumber',
	},
	// {
	// 	Header: 'Class',
	// 	accessor: 'class',
	// },
	{
		Header: 'Temperature °C',
		accessor: 'temperature',
	},
	{
		Header: 'Time In',
		accessor: 'timeIn',
		Cell: ({ value }) => {
			return moment(value).format('h:mm a');
		},
	},
	{
		Header: 'Time Out',
		accessor: 'timeOut',
		Cell: ({ value }) => {
			return moment(value).format('h:mm a');
		},
	},

	// {
	// 	Header: 'Action',
	// 	Footer: 'Action',
	// },
];

const TeacherAttendance = () => {
	const [attendanceList, setAttendanceList] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
	const [timeOutDate, setTimeOutDate] = useState(moment().format('yyyy-MM-DD'));
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isOpenTimeOutModal, setIsOpenTimeOutModal] = useState(false);
	const [timeOutData, setTimeOutData] = useState({});
	const [timeOut, setTimeOut] = useState('');

	useEffect(() => {
		getList();
	}, [startDate]);

	const getList = async () => {
		const postData = {
			date: moment(startDate).format('yyyy-MM-DD'),
			category: 'staff',
		};
		try {
			const res = await attendanceAPI.getAttendanceList(postData);
			if (res.success) {
				setAttendanceList(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const checkOutStaff = async () => {
		if (timeOut) {
			timeOutData.time = timeOut;
			delete timeOutData.timeOut;
			delete timeOutData.timeIn;
		}
		timeOutData.date = timeOutDate;
		try {
			const datetime = new Date(`${timeOutData.date} ${timeOutData.time}`).toISOString();
			const postData = { ...timeOutData, datetime };
			const res = await attendanceAPI.createAttendance(postData);
			if (res.success) {
				setIsOpenTimeOutModal(false);
				getList();
			}
		} catch (err) {
			console.log(err);
		}
	};

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => attendanceList, [attendanceList]);
	const tableInstance = useTable(
		{ columns, data, initialState: { pageIndex: 0, hiddenColumns: ['personId'] } },
		useGlobalFilter,
		usePagination
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setPageSize,
	} = tableInstance;

	const { pageIndex, pageSize } = state;

	return (
		<>
			<div>
				<div className='page-title shadow-sm '>
					<div>
						<h6 htmlFor='gender' className='form-label '>
							Date
						</h6>
						<DatePicker
							dateFormat='dd/MM/yyyy'
							selected={startDate}
							onChange={(date) => {
								setStartDate(date);
							}}
							className='form-control'
							fixedHeight
						/>
					</div>
					<button type='button' className='btn btn-md btn-primary' onClick={() => setIsOpenModal(true)}>
						New Attendance
					</button>
				</div>
				<div className='row'>
					<div className='col-xl-12 wow fadeInUp' data-wow-delay='1.5s'>
						<div className='table-responsive   full-data'>
							{attendanceList.length ? (
								<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
									<table
										{...getTableProps()}
										className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer'>
										<thead>
											{headerGroups.map((headerGroup) => {
												return (
													<tr {...headerGroup.getHeaderGroupProps()}>
														{headerGroup.headers.map((column) => (
															<th {...column.getHeaderProps()}>{column.render('Header')}</th>
														))}
													</tr>
												);
											})}
										</thead>
										<tbody {...getTableBodyProps()} className=''>
											{page.map((row) => {
												prepareRow(row);
												return (
													<tr {...row.getRowProps()}>
														{row.cells.map((cell, index) => {
															if (cell.column.id.toLowerCase() === 'timeout') {
																return (
																	<td key={index} {...cell.getCellProps()}>
																		{cell.value ? (
																			cell.render('Cell')
																		) : (
																			<button
																				type='button'
																				className='btn btn-sm btn-primary '
																				onClick={() => {
																					setIsOpenTimeOutModal(true);
																					setTimeOutData(row.values);
																				}}>
																				Time Out
																			</button>
																		)}
																	</td>
																);
															} else {
																return (
																	<td key={index} {...cell.getCellProps()}>
																		{cell.value ? cell.render('Cell') : '-'}
																	</td>
																);
															}
														})}
													</tr>
												);
											})}
										</tbody>
									</table>
									<Pagination
										nextPage={nextPage}
										previousPage={previousPage}
										canNextPage={canNextPage}
										canPreviousPage={canPreviousPage}
										pageIndex={pageIndex}
										pageOptions={pageOptions}
										pageSize={pageSize}
										setPageSize={setPageSize}
										gotoPage={gotoPage}
										pageCount={pageCount}
									/>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<NewAttendance
				setIsOpenModal={setIsOpenModal}
				isOpenModal={isOpenModal}
				isStaff={true}
				reload={getList}
				attendanceList={attendanceList}
				date={startDate}
			/>
			<Modal onHide={setIsOpenTimeOutModal} show={isOpenTimeOutModal} centered backdrop='static'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title'>Time Out</h5>

						<button type='button' title='close' className='btn-close' onClick={() => setIsOpenTimeOutModal(false)}></button>
					</div>
					<div className='modal-body'>
						<div className='mb-4'>
							<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
								Date
							</label>

							<input
								type='date'
								className='form-control'
								name='date'
								defaultValue={timeOutDate}
								onChange={(e) => setTimeOutDate(e.target.value)}
							/>
						</div>
						<div className='mb-4'>
							<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
								Time
							</label>

							<input type='time' className='form-control' name='time' onChange={(e) => setTimeOut(e.target.value)} />
						</div>
						<button type='button' className='btn btn-primary w-100' onClick={() => checkOutStaff()}>
							Save
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default TeacherAttendance;
