import apiHandler from './apiHandler';

export async function absentList() {
	return apiHandler.get(`/attendance/absent`);
}

const absentAPI = {
	absentList,
};

export default absentAPI;