const Pagination = ({
	pageCount,
	pageIndex,
	pageOptions,
	gotoPage,
	pageSize,
	setPageSize,
	canNextPage,
	nextPage,
	canPreviousPage,
	previousPage,
}) => {
	return (
		<div className='teach d-flex justify-content-between'>
			<small>
				<span>
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>
					{'  '}
				</span>
				<span>
					| Go to page :
					<input
						type='number'
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(page);
						}}
						style={{
							width: '80px',
							marginLeft: 10,
							border: '1px solid ',
							borderColor: '#dee2e6',
							padding: 5,
							borderRadius: '0.625rem',
						}}
					/>
				</span>{' '}
				<select
					style={{ borderRadius: 0, padding: 5 }}
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}>
					{[10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</small>

			<nav>
				<ul className='pagination pagination-gutter pagination-primary no-bg '>
					<li className='page-item  page-indicator'>
						{/* <Link to={'#'} className='page-link' >
														<i className='fa-solid fa-chevron-left'></i>
													</Link> */}
						<button title='back' className='page-link shadow-sm  h-50' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
							<i className='fa-solid fa-chevron-left font-8'></i>
							<i className='fa-solid fa-chevron-left font-8'></i>
						</button>
					</li>
					<li className='page-item page-indicator'>
						{/* <Link to={'#'} className='page-link' >
														<i className='fa-solid fa-chevron-left'></i>
													</Link> */}
						<button
							title='forward'
							className='page-link shadow-sm h-50'
							onClick={() => previousPage()}
							disabled={!canPreviousPage}>
							<i className='fa-solid fa-chevron-left font-8'></i>
						</button>
					</li>

					<li className='page-item page-indicator'>
						{/* <Link to={'#'} className='page-link'>
														<i className='fa-solid fa-chevron-right'></i>
													</Link> */}
						<button title='next' className='page-link shadow-sm  h-50' onClick={() => nextPage()} disabled={!canNextPage}>
							<i className='fa-solid fa-chevron-right font-8'></i>
						</button>
					</li>
					<li className='page-item page-indicator'>
						{/* <Link to={'#'} className='page-link'>
														<i className='fa-solid fa-chevron-right'></i>
													</Link> */}
						<button
							title='last'
							className='page-link shadow-sm  h-50'
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}>
							<i className='fa-solid fa-chevron-right font-8'></i>
							<i className='fa-solid fa-chevron-right font-8'></i>
						</button>
					</li>
				</ul>
			</nav>
		</div>
	);
};
export default Pagination;
