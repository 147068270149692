import apiHandler from './apiHandler';

export async function birthdayList() {
	return await apiHandler.get('/birthday');
}

export async function birthdayListByClass(id: number) {
	return await apiHandler.get('/birthday?class=' + id);
}

const birthdayAPI = {
	birthdayList,
	birthdayListByClass,
};

export default birthdayAPI;
