import apiHandler from './apiHandler';

export function getStaffList() {
	return apiHandler.get('/useradmin');
}
export function getStaffByID(id: number) {
	return apiHandler.get('/useradmin/' + id);
}
export function updateStaff(postData: any) {
	return apiHandler.put('/useradmin', postData);
}
export function getTemplates() {
	return apiHandler.get('/useradmin/role_templates');
}
export function createTemplates(postData: any) {
	return apiHandler.post('/useradmin/role_templates', postData);
}
export function updateTemplates(role: string, postData: any) {
	return apiHandler.put(`/useradmin/role_templates/${role}/`, postData);
}
export function getTemplatesByRole(role: string) {
	return apiHandler.get('/useradmin/role_templates/' + role);
}
export function deleteTemplates(role: string) {
	return apiHandler.delete('/useradmin/role_templates/' + role);
}

export function broadcast(data: any) {
	return apiHandler.post('/useradmin/broadcast', data);
}

const adminApi = {
	getStaffList,
	getStaffByID,
	updateStaff,
	getTemplates,
	createTemplates,
	updateTemplates,
	getTemplatesByRole,
	deleteTemplates,
    broadcast
};

export default adminApi;
