import FoodApi from '../../../../api/foodApi';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { customSelectStyle, FoodTypeOption } from '../../../../services/general';
import swal from 'sweetalert';
import Select from 'react-select';

export default function CreateMenu() {
	const navigate = useNavigate();
	const [date, setDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [showModal, setShowModal] = useState(false);
	const [initialValues, setInitialValues] = useState({
		data: [
			{
				date: new Date(),
				meals: [
					{
						type: undefined,
						name: undefined,
						description: undefined,
					},
				],
			},
		],
	});
	const [foodList, setFoodList] = useState([]);

	useEffect(() => {
		getList();
		async function getList() {
			try {
				const res = await FoodApi.getMeal();
				if (!res.success) throw new Error('Cant get food');
				// console.log(res.food);
				setFoodList(res.food);
			} catch (e) {
				console.log(e);
			}
		}
	}, [showModal]);

	useEffect(() => {
		try {
			const daysInBetween = differenceInCalendarDays(endDate, date);
			let nextInitialValue = { data: [] };
			for (let i = 0; i <= daysInBetween; i++) {
				nextInitialValue.data.push({
					date: addDays(date, i),
					meals: [
						{
							type: undefined,
							name: undefined,
							description: undefined,
						},
					],
				});
			}
			// const datemap = new Map();
			// for (let a of nextInitialValue.data) {
			// 	const here = initialValues.data?.filter((s) => {
			// 		return s.date.toDateString() === a.date.toDateString();
			// 	});
			// 	if (here.length > 0) {
			// 		datemap.set(here[0].date, here[0]);
			// 	} else {
			// 		datemap.set(a.date, a);
			// 	}
			// }
			// setInitialValues({ data: Array.from(datemap.values()) });
			setInitialValues(nextInitialValue);
		} catch (err) {
			console.log(err);
		}
	}, [date, endDate]);

	const validationSchema = {
		date: Yup.date(),
		meals: Yup.array().of(
			Yup.object().shape({
				type: Yup.string().required('Please choose a type'),
				name: Yup.string().required('Please choose a meal'),
				description: Yup.string().required('Please choose a meal'),
			})
		),
	};

	const baseSchema = {
		data: Yup.array().of(Yup.object().shape(validationSchema)).required('error').min(1, 'minumum one required'),
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				validationSchema={Yup.object().shape(baseSchema)}
				onSubmit={async (values, { resetForm }) => {
					try {
						for (let i = 0; i < values.data.length; i++) {
							const date = values.data[i].date.toISOString();
							for (let j = 0; j < values.data[i].meals.length; j++) {
								const { name, type } = values.data[i].meals[j];
								const res = await FoodApi.createMenu({ date, type, name });
								if (!res.success) throw new Error('Cant create menu!');
							}
						}
						swal({ title: 'Created Menu', icon: 'success' });
						resetForm();
						navigate('/food');
					} catch (e) {
						console.log(e);
						swal({ title: 'Failed to create menu', icon: 'error' });
					}
				}}>
				{({ values, errors, handleChange, handleBlur, setFieldValue, handleSubmit }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div className='row'>
								<div className='col-xl-12'>
									<div className='page-title  shadow-sm flex-wrap'>
										<div className='d-flex'>
											<button
												type='button'
												className='btn btn-linklight'
												style={{ cursor: 'default', color: 'black' }}>
												Start Date
											</button>
											<div className='d-flex align-items-center'>
												<DatePicker
													dateFormat='dd/MM/yyyy'
													className='form-control'
													selected={date}
													fixedHeight
													onChange={(d) => {
														if (differenceInCalendarDays(endDate, d) >= 0) setDate(d);
														else alert("Start date can't be later than end data!");
													}}
												/>
											</div>
											<button
												type='button'
												className='btn btn-linklight'
												style={{
													cursor: 'default',
													color: 'black',
													marginLeft: '25px',
												}}>
												End Date
											</button>
											<div className='d-flex align-items-center'>
												<DatePicker
													dateFormat='dd/MM/yyyy'
													className='form-control'
													selected={endDate}
													fixedHeight
													onChange={(d) => {
														if (differenceInCalendarDays(d, date) >= 0) setEndDate(d);
														else alert("End date can't be earlier than start data!");
													}}
												/>
											</div>
										</div>
										<div className='d-flex gap-3'>
											<button type='submit' className='btn btn-primary'>
												Save Menu
											</button>
										</div>
									</div>
								</div>
							</div>
							{values.data.map((data, index) => {
								return (
									<div key={index}>
										<div className='col-xl-12'>
											<div className='card p-4'>
												<Field
													name={`values.data[${index}].date`}
													value={data.date.toDateString()}
													className='border-0 w-100 font-weight-bold fs-4 mb-2'
													style={{ cursor: 'default' }}
												/>
												<FieldArray name={`data.${index}.meals`}>
													{({ push, remove }) => (
														<div>
															{data.meals.map((meal, index2, arr) => {
																return (
																	<div key={index2} className='card shadow'>
																		<Container fluid>
																			<Row>
																				<Col className='text-dark fs-4'>
																					Food Type <span className='text-red'>*</span>
																				</Col>
																			</Row>
																			<Row className='align-items-center'>
																				<Col xs={4}>
																					<Select
																						className='mb-1'
																						styles={customSelectStyle()}
																						options={FoodTypeOption}
																						onChange={(e) =>
																							setFieldValue(
																								`data.${index}.meals.${index2}.type`,
																								e.value
																							)
																						}
																						onBlur={handleBlur}
																						value={{
																							value: meal.type,
																							label: meal.type,
																						}}
																					/>
																					<span className='text-red small'>
																						{errors.data?.[index]?.meals[index2]?.type}
																					</span>
																				</Col>
																				{arr.length > 1 && (
																					<Col xs={6}>
																						<div className='d-flex justify-content-end'>
																							<button
																								className='btn btn-danger btn-sm'
																								type='button'
																								onClick={() => {
																									remove(index2);
																								}}>
																								<i className='material-symbols-outlined'>
																									delete
																								</i>
																							</button>
																						</div>
																					</Col>
																				)}
																			</Row>
																			<Row className='mt-3'>
																				<Col className='text-dark fs-4'>
																					Meal Name <span className='text-red'>*</span>
																				</Col>
																				<Col className='text-dark fs-4'>Meal Description</Col>
																			</Row>
																			<Row className='mb-4'>
																				<Col xs={4}>
																					<Select
																						className='mb-1'
																						styles={customSelectStyle()}
																						options={foodList.map((food) => {
																							return {
																								label: food.name,
																								value: food.name,
																							};
																						})}
																						onChange={(e) => {
																							const food = foodList.filter(
																								(food) => food.name === e.value
																							);
																							if (food && food[0].description) {
																								setFieldValue(
																									`data.${index}.meals.${index2}.name`,
																									e.value
																								);

																								setFieldValue(
																									`data.${index}.meals.${index2}.description`,
																									food[0].description
																								);
																							}
																						}}
																						value={{
																							value: meal.name,
																							label: meal.name,
																						}}
																					/>
																					<span className='text-red small'>
																						{errors.data?.[index]?.meals[index2]?.name}
																					</span>
																				</Col>
																				<Col xs={2}>
																					<button
																						type='button'
																						className='btn btn-primary'
																						onClick={() => {
																							setShowModal(true);
																						}}>
																						+ Meal
																					</button>
																				</Col>
																				<Col xs={6}>
																					<input
																						className='form-control'
																						type='text'
																						value={meal.description}
																						name={`data.${index}.meals.${index2}.description`}
																						onChange={handleChange}
																						onBlur={handleBlur}
																						disabled
																					/>
																				</Col>
																			</Row>
																		</Container>
																	</div>
																);
															})}
															<div className='w-100 d-flex justify-content-end'>
																<button
																	type='button'
																	className='btn btn-primary btn-sm'
																	onClick={() => {
																		push({
																			type: undefined,
																			name: undefined,
																			description: undefined,
																		});
																	}}>
																	+ Menu
																</button>
															</div>
														</div>
													)}
												</FieldArray>
											</div>
										</div>
									</div>
								);
							})}
						</Form>
					);
				}}
			</Formik>
			<Modal onHide={setShowModal} show={showModal} centered size='lg' backdrop='static'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title'>Add a new meal</h5>
						<button title='close' type='button' className='btn-close' onClick={() => setShowModal(false)}></button>
					</div>
					<div className='modal-body'>
						<div className='my-1 text-black'>
							<Formik
								onSubmit={async (values, { resetForm }) => {
									try {
										const res = await FoodApi.postFood(values);
										if (!res) throw new Error('Cant create Food');
										swal({ text: `Add meal successfully`, icon: 'success' });
										setShowModal(false);
										resetForm();
									} catch (e) {
										console.log(e);
									}
								}}
								validationSchema={Yup.object().shape({
									name: Yup.string().required('Please fill the name').min(2, 'minimum 2 character'),
									description: Yup.string().required('Please fill the description').min(2, 'minimum 2 character'),
								})}
								initialValues={{ name: undefined, description: undefined }}>
								{({ values, errors, handleChange, handleBlur }) => (
									<Form>
										<Container fluid>
											<Row>
												<Col>
													<span>
														Meal Name <span className='text-red'>*</span>
													</span>
												</Col>
												<Col>
													<span>
														Meal Description <span className='text-red'>*</span>
													</span>
												</Col>
											</Row>
											<Row>
												<Col>
													<input
														className='form-control mb-2'
														type='text'
														value={values.name}
														name={`name`}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													{errors?.name && <span className='text-red'>{errors.name}</span>}
												</Col>
												<Col>
													<input
														className='form-control'
														type='text'
														value={values.description}
														name={`description`}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													{errors?.description && <span className='text-red'>{errors.description}</span>}
												</Col>
											</Row>
											<Row className='mt-3'>
												<Col className='d-flex justify-content-center'>
													<button type='submit' className='btn btn-primary'>
														Submit
													</button>
												</Col>
											</Row>
										</Container>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}
