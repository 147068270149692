import axios from 'axios';

export default function useMediaServer() {
	const uploadMedia = async (media: FileList, folders: string) => {
		const fd = new FormData();
		for (let i = 0; i < media.length; i++) {
			fd.append('file', media[i]);
		}

		const { data } = await axios.post(String(process.env.REACT_APP_MEDIA_SERVER_URL), fd, {
			headers: {
				Authorization: '1b5aec89-c5bf-463f-ac46-b17702b700c1',
				'Content-Type': 'multipart/form-data',
				Folder: folders,
			},
		});

		return data;
	};

	const deleteMedia = async (url: string) => {
		console.log(url);
		const response = await axios.delete(String(process.env.REACT_APP_MEDIA_SERVER_URL), {
			headers: {
				Authorization: '1b5aec89-c5bf-463f-ac46-b17702b700c1',
			},
			data: { url: url },
		});

		return response;
	};
	return { uploadMedia, deleteMedia };
}
