import apiHandler from './apiHandler';

export function classList() {
	return apiHandler.get('/class');
}

export function createClass(postData: any) {
	return apiHandler.post('/class', postData);
}

export function assignClass(classId: number, postData: any) {
	return apiHandler.put(`/class/${classId}`, postData);
}
export function unassignClass(classId: number, postData: any) {
	return apiHandler.delete(`/class/${classId}/unassign`, {
		data: postData,
	});
}
export function deleteClass(classId: number) {
	return apiHandler.delete(`/class/${classId}`);
}

const classAPI = {
	classList,
	createClass,
	assignClass,
	unassignClass,
	deleteClass,
};

export default classAPI;
