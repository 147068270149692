import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { customSelectStyle } from '../../../../../services/general';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import devicesApi, { DeviceCaps, DeviceMode, DeviceCapabilitiesList, DeviceModeList } from '../../../../../api/devicesApi';

const NewDevice = ({ setIsOpenModal, isOpenModal, isStaff, reload, attendanceList, date }) => {
	const [validation, setValidation] = useState({});

	const [capabilities, setCapabilities] = useState();
	const [modes, setModes] = useState(DeviceCapabilitiesList());

	useEffect(() => {
		console.log(DeviceCapabilitiesList());
		setCapabilities(DeviceCapabilitiesList());
		setModes(DeviceModeList());
	}, []);

	const initialValues = {
		deviceId: undefined,
		name: undefined,
		deviceCaps: DeviceCaps.FaceRecAndTemp,
		mode: DeviceMode.None,
	};

	const validationSchema = {
		deviceId: Yup.string().required('Please input a device id'),
		name: Yup.string().required('Please input a name'),
	};

	useEffect(() => {
		setValidationScheme();
	}, []);

	const setValidationScheme = () => {
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const handleSubmit = async (e) => {
		console.log(e);
		try {
			const res = await devicesApi.createDevice({ name: e.name, deviceId: e.deviceId, deviceCaps: e.deviceCaps, mode: e.deviceMode });
			if (res.success) {
				setIsOpenModal(false);
				reload();
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Modal onHide={setIsOpenModal} show={isOpenModal} centered backdrop='static'>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title'>Add Device</h5>

					<button type='button' className='btn-close' onClick={() => setIsOpenModal(false)}></button>
				</div>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validationSchema={validation}
					onSubmit={(e) => handleSubmit(e)}
				>
					{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
						return (
							<div className='modal-body'>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Name<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='name'
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Device Name'
									/>
									{errors?.name && touched.name && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.name}
										</div>
									)}
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Device ID
									</label>

									<input
										type='text'
										className='form-control'
										name='deviceId'
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Device ID'
									/>
									{errors?.deviceId && touched.deviceId && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.deviceId}
										</div>
									)}
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Capabilities
									</label>
									<Select
										styles={customSelectStyle()}
										options={capabilities}
										onChange={(e) => {
											setFieldValue('deviceCaps', e.value);
										}}
										onBlur={handleBlur}
										// defaultValue={values.gender}
										inputId='deviceCaps'
									/>
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Mode
									</label>
									<Select
										styles={customSelectStyle()}
										options={modes}
										onChange={(e) => {
											setFieldValue('deviceMode', e.value);
										}}
										onBlur={handleBlur}
										// defaultValue={values.gender}
										inputId='deviceMode'
									/>
								</div>

								<button type='button' className='btn btn-primary w-100 mt-3' onClick={handleSubmit}>
									Save
								</button>
							</div>
						);
					}}
				</Formik>
			</div>
		</Modal>
	);
};
export default NewDevice;
