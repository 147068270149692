import React from "react";
import DatePicker from 'react-datepicker';

export default function MonthPicker(props) {
    const { startDate, setStartDate } = props;

    return (
        <div className="d-flex">
            <button
                type='button'
                className='btn btn-linklight'
                style={{cursor:'default', color:'black'}}
            >
                Select Ledger by Month
            </button>
            <div className="d-flex align-items-center">
                <DatePicker
                    dateFormat="MM/yyyy"
                    className='form-control'
                    selected={startDate}
                    showMonthYearPicker
                    fixedHeight
                    onChange={(date) => {
                        setStartDate(date);
                    }}
                />
            </div>
        </div>
    )
}