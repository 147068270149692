import apiHandler from "./apiHandler";

export function taskList(date: string) {
    return apiHandler.get(`/tasks?date=${date}`);
}

export function createTask(postData: any) {
    return apiHandler.post("/tasks", postData);
}
export function updateTask(postData: {
    id: number;
    complete: boolean;
    title: string;
    description?: string;
    start_time: string;
    end_time: string;
    assigned_to: number;
    date: string;
}) {
    return apiHandler.put("/tasks", postData);
}

export function getTaskDetails(teacherId: number, week: number) {
    return apiHandler.get(`/tasks?teacherId=${teacherId}&week=${week}`);
}

export function getTeacherTaskByDate(teacherId: number, date: string) {
    return apiHandler.get(`/tasks?teacherId=${teacherId}&date=${date}`);
}

const weeklyTaskAPI = {
    taskList,
    createTask,
    getTaskDetails,
    updateTask,
    getTeacherTaskByDate
};

export default weeklyTaskAPI;
