import holidayAPI from '../../../../api/holidayApi';
import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Name',
		Footer: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Start Date',
		Footer: 'Start Date',
		accessor: 'startDate',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'End Date',
		Footer: 'End Date',
		accessor: 'endDate',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Type',
		Footer: 'Type',
		accessor: 'type',
	},
	{
		Header: 'Action',
		Footer: 'Action',
	},
];

export async function getHolidayList() {
	try {
		const res = await holidayAPI.holidayList();
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
	}
}
