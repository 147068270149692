import swal from 'sweetalert';
import '../../jsx/swall.css';
import {
	formatError,
	login,
	runLogoutTimer,
	saveTokensInLocalStorage,
	signUp,
	savePayloadInLocalStorage,
} from '../../services/AuthService';
import { getJWTDecode } from '../../api/apiHandler';
import stripeSubscriptionApi from '../../api/stripeSubscriptionApi';
// import authAPI from '../../api/authApi';
// import axios from 'axios';
export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOGIN_BLOCKED_ACTION = '[login action] login blocked';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';

export function signupAction(email, password, navigate) {
	return (dispatch) => {
		signUp(email, password)
			.then((response) => {
				saveTokensInLocalStorage(response.data);
				// runLogoutTimer(
				// 	dispatch,
				// 	response.data.expiresIn * 1000
				// 	//history,
				// );
				dispatch(confirmedSignupAction(response.data));
				navigate('/dashboard');
				//history.push('/dashboard');
			})
			.catch((error) => {
				const errorMessage = formatError(error.response.data);
				dispatch(signupFailedAction(errorMessage));
			});
	};
}

export function Logout(navigate) {
	sessionStorage.clear();
	navigate('/login');
	//history.push('/login');

	return {
		type: LOGOUT_ACTION,
	};
}

export function loginAction(email, password, navigate, setSubscription) {
	return (dispatch) => {
		login(email, password)
			.then(({ status, data: ddata }) => {
				if (status !== 200) throw ddata;
				const res = ddata;
				if (res.success) {
					const { data } = res;
					const token = data.tokens;
					console.log(data);
					saveTokensInLocalStorage(token);
					savePayloadInLocalStorage(data.payload);
					const TokenData = getJWTDecode(token.accessToken);
					if (TokenData.exp) runLogoutTimer(dispatch, TokenData.exp * 1000 - new Date().getTime(), navigate);
					stripeSubscriptionApi.get().then((sub) => setSubscription(sub));
					navigate('/dashboard');
					dispatch(loginConfirmedAction(data.payload));
				}
			})
			.catch((error) => {
				let res = error?.response;
				if (res?.status === 408) {
					dispatch({ type: LOGIN_BLOCKED_ACTION, payload: { blocked: true, error: res.data.error } });
					setTimeout(() => {
						dispatch({ type: LOGIN_BLOCKED_ACTION, payload: { blocked: false, error: '' } });
					}, res.data.blockDuration);
				}
				swal('Error', res.data.error, 'error');

				// console.log(error);
				const errorMessage = formatError(error.response);
				dispatch(loginFailedAction(errorMessage));
			});
	};
}

export function loginFailedAction(data) {
	return {
		type: LOGIN_FAILED_ACTION,
		payload: data,
	};
}

export function loginConfirmedAction(data) {
	return {
		type: LOGIN_CONFIRMED_ACTION,
		payload: data,
	};
}

export function confirmedSignupAction(payload) {
	return {
		type: SIGNUP_CONFIRMED_ACTION,
		payload,
	};
}

export function signupFailedAction(message) {
	return {
		type: SIGNUP_FAILED_ACTION,
		payload: message,
	};
}

export function loadingToggleAction(status) {
	return {
		type: LOADING_TOGGLE_ACTION,
		payload: status,
	};
}
export const navtoggle = () => {
	return {
		type: 'NAVTOGGLE',
	};
};
