import { useEffect, useState } from 'react';
import { customSelectStyle } from '../../../../services/general';
import timeTableApi from '../../../../api/timeTableApi';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { FieldArray, Form, Formik, ErrorMessage, FormikActions } from 'formik';
import swal from 'sweetalert';
import { SketchPicker, CompactPicker } from 'react-color';
import studentAPI from '../../../../api/studentApi';
import makeAnimated from 'react-select/animated';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import * as dateFn from 'date-fns';

const animatedComponents = makeAnimated();
const CoCurriculumAddTimeTable = () => {
	const params = useParams();
	const curriculumId = params.curriculumId;
	const [studentList, setStudentList] = useState([]);
	const [curriculumDetails, setCurriculumDetails] = useState([]);

	useEffect(() => {
		getStudentList();
	}, []);

	useEffect(() => {
		if (curriculumId) {
			getCurriculumDetails();
		}
	}, [params.curriculumId]);

	const getStudentList = async () => {
		try {
			const { data } = await studentAPI.studentList();
			const arr = data.map((item, i) => ({ label: item.name, value: item.id }));
			setStudentList(arr);
		} catch (err) {
			console.log(err);
		}
	};
	const stringToTime = (time) => {
		const date = new Date();
		const timeArray = time.split(':');
		date.setHours(Number(timeArray[0]));
		date.setMinutes(Number(timeArray[1]));
		return date;
	};
	const getCurriculumDetails = async () => {
		try {
			const { data } = await timeTableApi.getCocoItemById(curriculumId);

			data.start_time = stringToTime(data.start_time);
			data.end_time = stringToTime(data.end_time);
			data.teachers = data.teachers.map((item, i) => ({ name: item }));
			setCurriculumDetails(data);
		} catch (err) {
			console.log(err);
		}
	};

	const initialValue = {
		date: new Date(curriculumDetails?.date ?? new Date()),
		data: [
			{
				start_time: curriculumDetails.start_time ?? new Date(),
				end_time: curriculumDetails.end_time ?? new Date(),
				color: curriculumDetails.color ?? '#FFFFFF',
				title: curriculumDetails.title ?? '',
				description: curriculumDetails.description ?? '',
				showColor: false,
				teachers: curriculumDetails.teachers ?? [{ name: '' }],
				student_people_ids: curriculumDetails.student_people_ids ?? undefined,
			},
		],
	};
	const validationSchema = Yup.object().shape({
		data: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required('Please fill in the field'),
				description: Yup.string().required('Please fill in the field'),
				teachers: Yup.array().of(
					Yup.object().shape({
						name: Yup.string().required('Please enter the establishment.'),
					})
				),
				student_people_ids: Yup.array().required('Please fill in the field'),
			})
		),
	});

	const formatToTime = (date) => {
		let ans =
			(date.getHours() === 0 ? '00' : date.getHours().toString()) +
			':' +
			(date.getMinutes() === 0 ? '00' : date.getMinutes().toString());
		return ans;
	};

	let navigate = useNavigate();
	const handleSubmit = async (e) => {
		try {
			let res;

			if (curriculumId) {
				const data = e.data[0];
				const obj = {
					id: Number(curriculumId),
					start_time: formatToTime(data.start_time),
					end_time: formatToTime(data.end_time),
					color: data.color,
					title: data.title,
					description: data.description,
					teachers: data.teachers.map((t) => t.name),
					student_people_ids: data.student_people_ids,
					date: e.date,
				};
				res = await timeTableApi.editCocoItem(obj);
			} else {
				for (const d of e.data) {
					const obj = {
						start_time: formatToTime(d.start_time),
						end_time: formatToTime(d.end_time),
						color: d.color,
						title: d.title,
						description: d.description,
						teachers: d.teachers.map((t) => t.name),
						student_people_ids: d.student_people_ids,
						date: e.date,
					};
					res = await timeTableApi.addCocoRecord(obj);
				}
			}
			if (res.success) {
				swal({ text: curriculumId ? 'Successfully edited the time table' : 'Successfully added to time table', icon: 'success' });
				navigate('/timetable/co-curriculum');
			} else {
				swal({ text: curriculumId ? 'Failed to edit time table' : 'Failed to add time table', icon: 'error' });
			}
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<>
			{/* validationSchema={validationSchema} */}
			<Formik validationSchema={validationSchema} initialValues={initialValue} enableReinitialize onSubmit={handleSubmit}>
				{({ values, handleChange, handleBlur, setFieldValue }) => (
					<Form>
						<div className='row'>
							<div className='page-title w-100 shadow-sm justify-content-between'>
								<div className='d-flex w-75 gap-5'>
									<div className='w-25'>
										<h6 className='form-label'>Date</h6>
										<DatePicker
											name='day'
											dateFormat='dd/MM/yyyy'
											className='form-control'
											selected={values.date}
											onChange={(d) => {
												setFieldValue('date', d);
											}}
											onBlur={handleBlur}
										/>
									</div>
								</div>
								<div className='d-flex gap-4'>
									<button
										type='button'
										className='d-flex justify-content-center btn btn-danger '
										style={{ width: '60px' }}
										onClick={async () => {
											try {
												console.log(curriculumId);
												await timeTableApi.deleteCocoItem(curriculumId);
												swal({ text: 'Succesfully Deleted', icon: 'success' }).then(() => {
													navigate(-1);
												});
											} catch (err) {
												console.log(err);
												// swal({ text: 'Error', icon: 'error' });
											}
										}}>
										<i className='material-symbols-outlined'>delete</i>
									</button>
									<button type='submit' className='btn btn-primary'>
										Save Timetable
									</button>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='card' style={{ padding: '30px' }}>
								<FieldArray name='data'>
									{({ push, remove }) => (
										<>
											{values.data.map((data, index) => {
												return (
													<div className='row ' key={index}>
														{values.data.length > 1 && (
															<div>
																{index > 0 && <hr />}
																<div className='w-100 d-flex justify-content-end mt-4'>
																	<button
																		type='button'
																		className='btn btn-danger d-flex flex-row align-items-center justify-content-end gap-2'
																		onClick={() => {
																			remove(index);
																		}}>
																		<i
																			className='material-symbols-outlined'
																			style={{
																				fontSize: 15,
																				color: 'white',
																			}}>
																			delete
																		</i>
																		Delete
																	</button>
																</div>
															</div>
														)}
														<div
															className='card shadow-sm w-100'
															style={{
																borderWidth: 0.3,
																borderColor: 'lightgrey',
																marginTop: values.data.length > 1 ? 20 : 0,
															}}>
															<div className='card-body'>
																<div className='row' style={{ marginBottom: '15px' }}>
																	<div className='col-3'>
																		<h6 className='form-label'>Time</h6>
																		<div className='d-flex'>
																			<DatePicker
																				showTimeSelect
																				showTimeSelectOnly
																				className='form-control'
																				timeIntervals={15}
																				dateFormat='h:mm aa'
																				selected={values.data[index].start_time}
																				onChange={(d) => {
																					setFieldValue(`data.${index}.start_time`, d);
																				}}
																			/>
																			<div
																				className='h-full d-flex align-items-center'
																				style={{ margin: '0px 10px' }}>
																				-
																			</div>
																			<DatePicker
																				showTimeSelect
																				showTimeSelectOnly
																				className='form-control'
																				timeIntervals={15}
																				dateFormat='h:mm aa'
																				selected={values.data[index].end_time}
																				onChange={(d) => {
																					setFieldValue(`data.${index}.end_time`, d);
																				}}
																			/>
																		</div>
																	</div>
																	<div className='col-8'>
																		<h6 className='form-label'>Title</h6>
																		<input
																			className='form-control'
																			name={`data.${index}.title`}
																			value={values.data[index].title}
																			type='text'
																			onBlur={handleBlur}
																			onChange={handleChange}
																			placeholder='Title'
																		/>
																		<ErrorMessage name={`data.${index}.title`}>
																			{(msg) => (
																				<div
																					className='invalid-feedback animated fadeInUp'
																					style={{ display: 'block' }}>
																					{msg}
																				</div>
																			)}
																		</ErrorMessage>
																	</div>
																</div>
																<div className='row'>
																	<div className='col-3'>
																		<h6 className='form-label'>Color</h6>
																		{/* <CompactPicker
                                                                            name={`data.${index}.color`}
                                                                            color={values.data[index].color}
                                                                            onChange={(color) =>
                                                                                setFieldValue(
                                                                                    `data.${index}.color`,
                                                                                    color.hex
                                                                                )
                                                                            }
                                                                            onBlur={handleBlur}
                                                                        /> */}
																		<button
																			type='button'
																			className='btn btn-primary form-control'
																			style={{
																				backgroundColor: values.data[index].color,
																				color: 'black',
																				textAlign: 'end',
																			}}
																			onClick={() => {
																				setFieldValue(`data.${index}.showColor`, true);
																			}}>
																			<i
																				className='material-symbols-outlined'
																				style={{ marginRight: '2px' }}>
																				left_click
																			</i>
																		</button>
																	</div>
																	<div className='col-8'>
																		<h6 className='form-label'>Description</h6>
																		<input
																			className='form-control'
																			name={`data.${index}.description`}
																			value={values.data[index].description}
																			type='text'
																			onBlur={handleBlur}
																			onChange={handleChange}
																			placeholder='Description'
																		/>
																		<ErrorMessage name={`data.${index}.description`}>
																			{(msg) => (
																				<div
																					className='invalid-feedback animated fadeInUp'
																					style={{ display: 'block' }}>
																					{msg}
																				</div>
																			)}
																		</ErrorMessage>
																	</div>
																</div>
																{values.data[index].showColor && (
																	<div className='col-12 mt-4 mb-4 d-flex'>
																		<SketchPicker
																			name={`data.${index}.color`}
																			width={320}
																			color={values.data[index].color}
																			onChange={(color) =>
																				setFieldValue(`data.${index}.color`, color.hex)
																			}
																			onBlur={handleBlur}
																		/>
																		<button
																			type='button'
																			className='btn btn-primary'
																			onClick={() => {
																				setFieldValue(`data.${index}.showColor`, false);
																			}}>
																			Done
																		</button>
																	</div>
																)}
															</div>
														</div>
														<div
															className='card shadow-sm w-100'
															style={{ borderWidth: 0.3, borderColor: 'lightgrey' }}>
															<div className='card-body'>
																<h4>Teacher</h4>

																<FieldArray name={`data.${index}.teachers`}>
																	{({ push, remove }) => (
																		<div>
																			{values.data[index].teachers.map((t, idx) => (
																				<div
																					key={idx}
																					className={`${
																						idx > 0 ? 'mt-3' : 'mt-0'
																					} d-flex flex-row gap-3 align-items-center justify-content-center`}>
																					<div style={{ flex: 1 }}>
																						<input
																							className='form-control '
																							name={`data.${index}.teachers.${idx}.name`}
																							defaultValue={
																								values.data[index].teachers[idx].name
																							}
																							type='text'
																							onBlur={handleBlur}
																							onChange={handleChange}
																							placeholder='Name'
																						/>
																						<ErrorMessage
																							name={`data.${index}.teachers.${idx}.name`}>
																							{(msg) => (
																								<div
																									className='invalid-feedback animated fadeInUp'
																									style={{ display: 'block' }}>
																									{msg}
																								</div>
																							)}
																						</ErrorMessage>
																					</div>
																					{values.data[index].teachers.length > 1 && (
																						<i
																							className='material-symbols-outlined'
																							style={{
																								fontSize: 20,
																								color: 'red',
																								cursor: 'pointer',
																							}}
																							onClick={() => {
																								remove(idx);
																							}}>
																							close
																						</i>
																					)}
																				</div>
																			))}
																			<div className='float-end mt-3'>
																				<div
																					className='icon-box icon-box-sm bg-light cursor-pointer'
																					onClick={() => {
																						push({
																							name: '',
																						});
																					}}>
																					<i
																						className='material-symbols-outlined'
																						style={{ fontSize: 8, color: '#4D44B5' }}>
																						add
																					</i>
																				</div>
																			</div>
																		</div>
																	)}
																</FieldArray>
															</div>
														</div>
														<div
															className='card shadow-sm w-100'
															style={{
																borderWidth: 0.3,
																borderColor: 'lightgrey',
															}}>
															<div className='card-body'>
																<h4>Student</h4>
																<div>
																	<Select
																		options={studentList}
																		onChange={(e) => {
																			setFieldValue(
																				`data.${index}.student_people_ids`,
																				e.map((item) => item.value)
																			);
																		}}
																		onBlur={handleBlur}
																		value={
																			curriculumId &&
																			studentList.filter(({ value }) => {
																				return values.data[0].student_people_ids?.includes(value);
																			})
																		}
																		isMulti
																		components={animatedComponents}
																		inputId='student_people_ids'
																		placeholder={'Select Student'}
																	/>
																	<ErrorMessage name={`data.${index}.student_people_ids`}>
																		{(msg) => (
																			<div
																				className='invalid-feedback animated fadeInUp'
																				style={{ display: 'block' }}>
																				{msg}
																			</div>
																		)}
																	</ErrorMessage>
																</div>
															</div>
														</div>
													</div>
												);
											})}
											{!curriculumId && (
												<div className='row mb-4'>
													<div className='w-100 d-flex justify-content-end'>
														<button
															type='button'
															className='btn btn-primary'
															onClick={() => {
																push({
																	start_time: new Date(),
																	end_time: new Date(),
																	color: '#FFFFFF',
																	title: '',
																	description: '',
																	showColor: false,
																	teachers: [{ name: '' }],
																	student_people_ids: undefined,
																});
															}}>
															+ Add
														</button>
													</div>
												</div>
											)}
										</>
									)}
								</FieldArray>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CoCurriculumAddTimeTable;
