import apiHandler from './apiHandler';

export function newsList() {
	return apiHandler.get('/news');
}
export function updateNews(postData: any) {
	return apiHandler.put(`/news`, postData);
}

export function createNews(postData: any) {
	return apiHandler.post('/news', postData);
}

export function getNewsDetails(id: number) {
	return apiHandler.get('/news/' + id);
}

export function deleteNews(id: number) {
	return apiHandler.delete(`/news`, { data: { id } });
}

const newsAPI = {
	newsList,
	createNews,
	updateNews,
	getNewsDetails,
	deleteNews,
};

export default newsAPI;
