import { format } from "date-fns";
import weeklyTaskAPI from "../../../api/weeklyTaskApi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";

const ViewTask = (props) => {
    const { teacher_id, date, show, setShow } = props;
    const [task, setTask] = useState();

    useEffect(() => {
        teacher_id && date && getTask();
    }, [teacher_id, date]);

    const getTask = async () => {
        try {
            const res = await weeklyTaskAPI.getTeacherTaskByDate(Number(teacher_id), String(date));
            console.log(res);
            if (res.success) {
                setTask(res.data);
            } else {
                throw new Error(`Cant get task`);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                setShow({ show: false, teacher_id: undefined, date: undefined });
            }}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <h4>Weekly task for {task && task[0].teacher.name}</h4>
            </Modal.Header>
            <Modal.Body>
                {task &&
                    task.map((ele, index) => {
                        return (
                            <div className="col-12" key={index}>
                                <div className="card shadow p-3 d-flex">
                                    <div className="row">
                                        <div className="col-10">
                                            <h5 style={{ textDecoration: "underline", fontWeight: "bold" }}>
                                                {ele.title}
                                            </h5>
                                            <h6>{ele.description}</h6>
                                            {ele.start_time} - {ele.end_time}
                                        </div>
                                        <div className="col-2 d-flex align-items-center justify-content-center">
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{
                                                    border: "1px solid grey",
                                                    width: "50%",
                                                    height: "auto",
                                                    aspectRatio: "1",
                                                }}
                                            >
                                                {ele.complete && (
                                                    <i
                                                        className="material-symbols-outlined unselectable"
                                                        style={{ color: "#2AB514" }}
                                                    >
                                                        check
                                                    </i>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </Modal.Body>
        </Modal>
    );
};

export default ViewTask;
