import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Button, Dropdown } from "react-bootstrap";
import rejectionReasonsApi from '../../../../api/rejectionReasonsApi';

export default function RejectionPopUp(props) {
    const { show, toggleClose, setStatus, row } = props;
    const [ reasons, setReasons ] = useState([]);
    const [ activeSelect, setActiveSelect ] = useState('Select A Reason');

    const getList = useCallback(async () => {
		const res = await rejectionReasonsApi.getRejectionList();
		if (res.success) setReasons(res.data);
	}, []);

	useEffect(() => {
		getList();
	}, [getList]);

    return(
        <Modal 
            show={show} 
            onHide={()=>toggleClose()} 
        >
            <Modal.Header closeButton>
                <Modal.Title>Rejection Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{color:"black"}}>Please select a reason!</p>
                <Dropdown>
                    <Dropdown.Toggle>
                        {activeSelect}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {reasons.map((r,index)=>{
                            return(
                                <Dropdown.Item 
                                    key={index}
                                    onClick={(e)=>{
                                        setActiveSelect(e.target.innerHTML)
                                    }}
                                >
                                    {r.reason}
                                </Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="secondary" 
                    onClick={()=>{
                        toggleClose();
                        setActiveSelect('Select A Reason');
                    }}
                >
                    Discard
                </Button>
                <Button 
                    variant="primary" 
                    onClick={()=>{
                        let rejectReason = activeSelect;
                        if(rejectReason!=='Select A Reason') {
                            setStatus(
                                row.original.personId,
                                row.original.dateFrom,
                                'Rejected',
                                rejectReason
                            );
                            setActiveSelect('Select A Reason'); // change back to default value
                            toggleClose();
                        }
                    }}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}