import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMAGES } from '../Dashboard/Content';
import StaffApi from '../../../api/staffApi';
import { format } from 'date-fns';

import profile from './../../../images/profile.svg';
import location from './../../../images/svg/location.svg';
import phone from './../../../images/svg/phone.svg';
import { formatCurrency } from '../../../services/general';
import { Button } from 'react-bootstrap';
import UploadModal from './components/Upload';

const StaffDetail = () => {
	const params = useParams();
	const [staff, setStaff] = useState();
	const [avatar, setAvatar] = useState(null);
	const [show, setShow] = useState(false);

	useEffect(() => {
		async function getData() {
			const res = await StaffApi.getSingleStaff(Number(params.staffId));
			if (res && res.success) {
				setStaff(res.data);
				if (res.data.staff?.avatarUrl) {
					setAvatar(res.data.staff?.avatarUrl);
				}
			}
		}
		getData();
	}, []);

	const handleClose = () => setShow(false);

	return (
		<div className='row'>
			<div className=''>
				<div className='card h-auto'>
					<div className='card-header p-0'>
						<div className='user-bg w-100'>
							<div className='user-svg'>
								<svg width='264' height='109' viewBox='0 0 264 109' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<rect
										x='8.01074'
										y='8.6521'
										width='247.592'
										height='259.13'
										rx='123.796'
										stroke='#FCC43E'
										strokeWidth='16'
									/>
								</svg>
							</div>
							<div className='user-svg-1'>
								<svg width='264' height='59' viewBox='0 0 264 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<rect
										x='8'
										y='8.56406'
										width='247.592'
										height='259.13'
										rx='123.796'
										stroke='#FB7D5B'
										strokeWidth='16'
									/>
								</svg>
							</div>
						</div>
					</div>
					<div className='card-body'>
						<div className='d-flex justify-content-between'>
							<div className='user'>
								<div className='user-media'>
									<img src={avatar ? avatar : IMAGES.noimage} alt='' className='avatar avatar-xxl bg-white' />
								</div>
								<div>
									<h2 className='mb-0'>{staff?.name}</h2>
									{staff?.staff.isTeacher && staff.classes.length > 0 ? (
										staff.classes.map((e, i) => {
											return (
												<span className='badge badge-success light my-2' style={{ fontSize: '12px' }} key={i}>
													{e?.classInfo.name}
												</span>
											);
										})
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-xxl-6 col-sm-6 mt-2'>
							<ul className='student-details gap-xl-5'>
								<div className='col-xl-10 col-xxl-6 col-sm-6 student-details'>
									<li className='me-2'>
										<img src={profile} alt='' className='icon-box bg-secondary py-2' />
									</li>
									<li>
										<span>Gender:</span>
										<h5 className='mb-0'>{staff?.gender}</h5>
									</li>
								</div>
								<div className='col-xl-12 col-xxl-6 col-sm-8 student-details'>
									<li className='me-2'>
										<img src={location} alt='' className='icon-box bg-secondary p-2' />
									</li>
									<li>
										<span>Address:</span>
										<h5 className='mb-0'>{staff?.staff.address}</h5>
									</li>
								</div>
								<div className='col-xl-12 col-xxl-6 col-sm-8 student-details'>
									<li className='me-2'>
										<img src={phone} alt='' className='icon-box bg-secondary px-2' />
									</li>
									<li>
										<span>Phone:</span>
										<h5 className='mb-0'>{staff?.staff.mobileNumber}</h5>
									</li>
								</div>
							</ul>
						</div>
					</div>
				</div>
				<div className='card h-auto'>
					<div className='card-body'>
						<div className='teacher-deatails' style={{ fontSize: '15px' }}>
							<h3 className='heading-lg py-3'>Profile:</h3>
							<div className='d-flex pb-4'>
								<div className='row col-xl-6 col-md-7 col-sm-5' style={{ fontSize: '15px' }}>
									<span className='mb-2'>
										<span className='h4'>Date Of Birth</span>
										<br />
										{staff?.staff.dob && format(new Date(staff?.staff?.dob), 'dd-LL-yyyy')}
									</span>
									<span className='mb-2'>
										<span className='h4'>Race</span>
										<br />
										{staff?.race}
									</span>
									<span className='mb-2'>
										<span className='h4'>Religion</span>
										<br />
										{staff?.religion}
									</span>
									<span className='mb-2'>
										<span className='h4'>Marital Status</span>
										<br />
										{staff?.staff.maritalStatus}
									</span>
									{staff?.staff.spouseCompany && (
										<>
											<span>
												<span className='h4'>Spouse Company</span>
												<br />
												{staff?.staff.spouseCompany}
											</span>
										</>
									)}
									<span>
										<span className='h4'>No. Of Children</span>
										<br />
										{staff?.staff.numberOfChildren}
									</span>
								</div>
								<div className='row col-xl-6 col-md-6 col-sm-6' style={{ fontSize: '15px' }}>
									<span className='mb-2'>
										<span className='h4'>Start Date</span>
										<br />
										{staff?.staff.startDate && format(new Date(staff?.staff?.startDate), 'dd-LL-yyyy')}
									</span>
									<span className='mb-2'>
										<span className='h4'>Employment Basis</span>
										<br />
										{staff?.staff.employmentBasis}
									</span>
									<span className='mb-2'>
										<span className='h4'>Salary Basis</span>
										<br />
										{staff?.staff.salaryBasis}
									</span>
									<span>
										<span className='h4'>Salary</span>
										<br />
										<span>{formatCurrency(staff?.staff.salary)}</span>
									</span>
								</div>
							</div>
							<h3 className='heading-lg'>Summary:</h3>
							<p style={{ fontSize: '15px' }}>{staff?.staff.summary}</p>
							<h3 className='heading-lg py-3'>Education:</h3>
							<ul>
								{staff?.staff.education &&
									staff?.staff.education?.map((e, i) => {
										return (
											<li className='dots' style={{ fontSize: '15px' }} key={i}>
												<h6 className='mb-1 h4'>{e.establishment && e.establishment}</h6>
												{e.qualification && (
													<span>
														Qualification:&nbsp;
														{e.qualification}
													</span>
												)}
												<br />
												{e.grade && (
													<span>
														Grade:&nbsp;
														{e.grade}
													</span>
												)}
												<br />
												{e.yearGraduated && (
													<span>
														Graduation Year:&nbsp;
														{e.yearGraduated}
													</span>
												)}
											</li>
										);
									})}
							</ul>
							{staff?.staff.expertise && (
								<>
									<h3 className='heading-lg mt-4'>Expertise:</h3>
									{staff?.staff.expertise}
								</>
							)}
							<h3 className='heading-lg py-3'>Emergency Contact:</h3>
							<div className='d-flex'>
								<div className='col-xl-6'>
									<span className='h4'>Name</span>
									<br />
									{staff?.staff.emergencyContactName}
								</div>
								<div className='col-xl-6'>
									<span className='h4'>Mobile Number</span>
									<br />
									{staff?.staff.emergencyContactNumber}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='card h-auto'>
					<div className='card-body'>
						<div className='teacher-deatails'>
							<div className='d-flex justify-content-between'>
								<h3 className='heading-lg'>Filing:</h3>
								<Button
									variant='primary'
									className='mb-3'
									onClick={() => {
										setShow(true);
									}}>
									Upload
								</Button>
							</div>
							<div className='d-flex'>
								<table className='table'>
									<thead>
										<tr>
											<th>Title</th>
											<th>Descriptions</th>
											<th>Uploaded Date</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{staff?.filing &&
											staff?.filing.map((e, i) => {
												return (
													<tr key={i}>
														<td>{e.title}</td>
														<td>{e.description}</td>
														<td>{e.date_created}</td>
														<td>
															<td>
																<div
																	className='icon-box icon-box-sm bg-light me-2'
																	onClick={() => {
																		window.location.href = e.url;
																	}}>
																	<i
																		className='bi bi-download'
																		style={{ fontSize: 8, color: 'var(--primary)' }}></i>
																</div>
																<div className='icon-box icon-box-sm bg-light me-2' onClick={() => {}}>
																	<i
																		className='bi bi-trash'
																		style={{ fontSize: 8, color: 'var(--primary)' }}></i>
																</div>
															</td>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
								<UploadModal show={show} handleClose={handleClose} staffId={staff?.id} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StaffDetail;
