// import axios from 'axios';
import apiHandler from './apiHandler';

type ChangePasswordInput = {
	personId?: number;
	oldPassword: string;
	newPassword: string;
};

export function login(postData: any) {
	// return apiHandler.post('/auth/login', postData);
	const headers = {
		'Content-Type': 'application/json',
	};
	return apiHandler.post('auth/login', postData);
}

export function verifyUUID(uuid: number) {
	return apiHandler.get('auth/enrolment?u=' + uuid);
}

export function changePassword(data: ChangePasswordInput) {
	return apiHandler.post('auth/changePassword', data);
}

type enrollUser = {
	uuid: string;
	password: string;
	username: string;
};

export function enrollUser(postData: enrollUser) {
	return apiHandler.post('auth/enrolment', postData);
}

const authAPI = {
	login,
	verifyUUID,
	enrollUser,
	changePassword,
};

export default authAPI;
