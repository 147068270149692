import transportAPI from '../../../../api/transportApi';

import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Student Name',
		Footer: 'Student Name',
		accessor: 'student.name',
	},
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'date',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Acknowledged By',
		Footer: 'Acknowledged By',
		accessor: 'acknowledgedByStaffName',
	},
	{
		Header: 'Address',
		Footer: 'Address',
		accessor: 'address',
	},
];

export async function getTransport(date) {
	try {
		let res = await transportAPI.transportList();
		// console.log(res);
		if (res.success) {
			return res?.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
}
