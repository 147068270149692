import apiHandler from './apiHandler';

export async function medicationList() {
	return apiHandler.get(`/medication`);
}

const medicationAPI = {
	medicationList,
};

export default medicationAPI;
