import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { addDays, eachWeekOfInterval, endOfMonth, startOfMonth } from 'date-fns';
import FoodApi from '../../../api/foodApi';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';
import Select from 'react-select';
import { customSelectStyle, FoodTypeOption } from '../../../services/general';

export default function FoodMenu() {
	const [date, setDate] = useState(new Date());
	const [menuList, setMenuList] = useState([]);
	const [showModal, setShowModal] = useState(undefined);
	const [foodList, setFoodList] = useState([]);
	const weeks = useMemo(() => {
		const start = startOfMonth(date);
		const end = endOfMonth(date);
		return eachWeekOfInterval({ start, end });
	}, [date]);

	let navigate = useNavigate();

	useEffect(() => {
		getMenuList();
		async function getMenuList() {
			try {
				const list = [];
				for (let day of weeks) {
					const listOfTheWeek = [];
					for (let i = 0; i < 7; i++) {
						const res = await FoodApi.getFood(addDays(day, i).toISOString());
						if (!res.success) throw new Error('Cant get food');
						listOfTheWeek.push(res.meals);
					}
					list.push(listOfTheWeek);
				}
				setMenuList(list);
			} catch (e) {
				console.log(e);
				setMenuList([]);
			}
		}
	}, [weeks, showModal]);

	useEffect(() => {
		getList();
		async function getList() {
			try {
				const res = await FoodApi.getMeal();
				if (!res.success) throw new Error('Cant get food');
				// console.log(res.food);
				setFoodList(res.food);
			} catch (e) {
				console.log(e);
			}
		}
	}, [showModal]);

	function formatDate(date) {
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		return `${day}/${month}/${year}`;
	}
	return (
		<div>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='page-title  shadow-sm flex-wrap'>
						<div className='d-flex'>
							<button type='button' className='btn btn-linklight' style={{ cursor: 'default', color: 'black' }}>
								Select by Month
							</button>
							<div className='d-flex align-items-center'>
								<DatePicker
									dateFormat='MM/yyyy'
									className='form-control'
									selected={date}
									showMonthYearPicker
									fixedHeight
									onChange={(date) => {
										setDate(date);
									}}
								/>
							</div>
						</div>
						<div className='d-flex gap-3'>
							<button
								type='button'
								className='btn btn-primary'
								onClick={() => {
									navigate('/food/create-menu');
								}}>
								+ Create New Menu
							</button>
						</div>
					</div>
				</div>
			</div>
			{weeks.map((day, index) => (
				<div key={index} className='row'>
					<div className='col-xl-12'>
						<div
							className='card'
							style={{
								padding: '0.8rem 2rem',
								fontWeight: 'bold',
								backgroundColor: '#ECEBF6',
								color: '#4537A8',
								borderBottom: '1px solid #C5C5C5',
							}}>
							{formatDate(day)} - {formatDate(addDays(day, 6))}
						</div>
					</div>
					<div className='col-xl-12 d-flex gap-4'>
						{menuList[index]?.map((menu, index2) => {
							if (!menu || menu === '' || menu.length === 0) return <React.Fragment key={index2} />;
							if (menu && menu.length > 0) {
								return (
									<div
										key={index2}
										onClick={() => setShowModal(menu)}
										className='card d-flex flex-column align-items-center'
										style={{ padding: '1.5rem 4rem' }}>
										<div style={{ marginBottom: '1rem', color: '#6D60C9' }}>
											{new Date(menu[0].date).toDateString()}
										</div>
										{menu.map((meal, index3) => {
											return (
												<div key={`meal-${index3}`} className='d-flex flex-column align-items-center'>
													<div style={{ color: '#968DA4', fontSize: '11px' }}>{meal.type}</div>
													<div style={{ color: '#4537A8', fontSize: '17px' }}>{meal.name}</div>
													<div style={{ color: '#887EF', fontSize: '12px' }}>{meal.description}</div>
													<br />
												</div>
											);
										})}
									</div>
								);
							}
						})}
					</div>
				</div>
			))}
			<Modal onHide={setShowModal} show={showModal} centered size='lg' backdrop='static'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title'>Edit Meal for - {new Date(showModal?.[0].date).toDateString()}</h5>
						<button title='close' type='button' className='btn-close' onClick={() => setShowModal(undefined)}></button>
					</div>
					<div className='modal-body '>
						<div className='my-1 text-black'>
							<Formik
								onSubmit={async (values, { resetForm }) => {
									try {
										const date = new Date(showModal[0].date).toDateString();
										const oldV = [...showModal];
										for (let ed of values.data) {
											if (ed.id) {
												// edit
												// console.log('Edit', ed.id);
												const res = await FoodApi.updateFood({ ...ed, mealName: ed.name });
											} else {
												//create
												// console.log('Create', ed.name);
												const res = await FoodApi.createMenu({ ...ed, date });
												// if (!res) throw new Error('Cant create Food');
											}
										}
										const idList = values.data.map((i) => i.id).filter((d) => d !== undefined);
										const deleted_items = oldV.filter((d) => !idList.includes(d.id));
										for (let d of deleted_items) {
											const res = await FoodApi.deleteFood(d.id);
										}
										swal({ text: `Edit meal successfully`, icon: 'success' });
										setShowModal(undefined);
										resetForm();
									} catch (e) {
										console.log(e);
									}
								}}
								validationSchema={Yup.object().shape({
									data: Yup.array().of(
										Yup.object().shape({
											name: Yup.string().required('Please fill the name').min(2, 'minimum 2 character'),
											// description: Yup.string().required('Please fill the description').min(2, 'minimum 2 character'),
											type: Yup.string().required('Please choose a type'),
										})
									),
								})}
								enableReinitialize
								initialValues={{ data: showModal ? [...showModal] : [] }}>
								{({ values, errors, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
									<Form onSubmit={handleSubmit}>
										<Container fluid>
											<FieldArray name={'data'}>
												{({ push, remove }) => (
													<div key={values}>
														<div className=' w-100 text-end'>
															<button
																type='button'
																className='btn btn-success btn-xs ms-3'
																onClick={() => {
																	push({
																		type: undefined,
																		name: undefined,
																		description: undefined,
																	});
																}}>
																+ Add New
															</button>
														</div>
														{values.data && values.data.length > 0 ? (
															values.data.map((meal, idx, arr) => {
																return (
																	<Row key={idx} className='m-2'>
																		<Col className='col-3'>
																			{idx === 0 && <label>Name</label>}
																			<Select
																				styles={customSelectStyle()}
																				options={foodList?.map((f) => ({
																					label: f.name,
																					value: f.name,
																				}))}
																				onChange={(e) => setFieldValue(`data.${idx}.name`, e.value)}
																				onBlur={handleBlur}
																				value={{
																					value: meal.name,
																					label: meal.name,
																				}}
																			/>
																			<span className='text-red small'>
																				{errors.data?.[idx]?.name}
																			</span>
																		</Col>
																		<Col className='col-3'>
																			{idx === 0 && <label>Type</label>}
																			<Select
																				styles={customSelectStyle()}
																				options={FoodTypeOption}
																				onChange={(e) => setFieldValue(`data.${idx}.type`, e.value)}
																				onBlur={handleBlur}
																				value={{
																					value: meal.type,
																					label: meal.type,
																				}}
																			/>
																			<span className='text-red small'>
																				{errors.data?.[idx]?.type}
																			</span>
																		</Col>
																		<Col className='col-6'>
																			{idx === 0 && <label>Description</label>}
																			<div className='d-flex'>
																				<input
																					className='form-control text-dark fs-4 w-100'
																					type='text'
																					disabled
																					value={
																						foodList?.find(
																							(f) => f.name === values.data?.[idx]?.name
																						)?.description ?? ''
																					}
																					name={`data[${idx}].description`}
																					onChange={handleChange}
																					onBlur={handleBlur}
																				/>
																				<button
																					type='button'
																					className='btn btn-danger btn-xs ms-3'
																					onClick={() => {
																						// console.log(values.data[index2]);
																						remove(idx);
																						// push({
																						// 	type: undefined,
																						// 	name: undefined,
																						// 	description: undefined,
																						// });
																					}}>
																					<i className='material-symbols-outlined'>delete</i>
																				</button>
																			</div>
																			<span className='text-red small'>
																				{errors.data?.[idx]?.description}
																			</span>
																		</Col>
																	</Row>
																);
															})
														) : (
															<p>No Food is scheduled</p>
														)}
													</div>
												)}
											</FieldArray>
											<Row className='mt-3'>
												<Col className='d-flex justify-content-center mt-3'>
													<button type='submit' className='btn btn-primary'>
														Submit
													</button>
												</Col>
											</Row>
										</Container>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
