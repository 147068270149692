import chatAPI from '../../../../api/chatApi';

export const COLUMNS = [
	{
		Header: 'Chat ID',
		Footer: 'Chat ID',
		accessor: 'id',
	},
	{
		Header: 'Teacher',
		Footer: 'Teacher',
		accessor: 'teachers',
		// Cell: ({ value }) => {
		// 	if (!value || value.length === 0) return 'No teacher';
		// 	return value.map((teacher) => teacher.name || '').join(', ');
		// },
	},
	{
		Header: 'Parents',
		Footer: 'Parents',
		accessor: 'parents',
		// Cell: ({ value }) => {
		// 	if (!value || value.length === 0) return 'No parent';
		// 	return value.map((parent) => parent.name || '').join(', ');
		// },
	},
	{
		Header: 'Messages',
		Footer: 'Messages',
		accessor: 'numMesages',
		// Cell: ({ value }) => {
		// 	return moment(value).format('DD/MM/yyyy');
		// },
	},
	{
		Header: 'Action',
		Footer: 'Action',
		accessor: 'action',
	},
];

export async function getChats() {
	try {
		let res = await chatAPI.chatRoomList();
		// console.log(res);
		if (res.success) {
			const edittedData = res.rooms.map((room) => {
				return {
					id: room.id,
					name: room.name,
					members: room.members,
					parents: room.members
						.map((member) => {
							if (member.personId === null) {
								return member.name;
							}
						})
						.filter((name) => name !== undefined)
						.join(', '),
					teachers: room.members
						.map((member) => {
							if (member.parentId === null) {
								return member.name;
							}
						})
						.filter((name) => name !== undefined)
						.join(', '),
					numMesages: room.numMesages,
					action: room.id,
				};
			});
			// console.log(edittedData);
			return edittedData;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
}

// {
// 	"success": true,
// 	"rooms": [
// 	  {
// 		"id": 9,
// 		"name": "Vignes parent",
// 		"members": [
// 		  {
// 			"personId": null,
// 			"parentId": 2,
// 			"name": "Jaya"
// 		  },
// 		  {
// 			"personId": null,
// 			"parentId": 4,
// 			"name": "Vignes parent"
// 		  }
// 		],
// 		"numMesages": 1
// 	  },
// 	  {
// 		"id": 10,
// 		"name": "Cher",
// 		"members": [
// 		  {
// 			"personId": null,
// 			"parentId": 2,
// 			"name": "Jaya"
// 		  },
// 		  {
// 			"personId": null,
// 			"parentId": 3,
// 			"name": "Cher"
// 		  }
// 		],
// 		"numMesages": 60
// 	  },
// 	  {
// 		"id": 11,
// 		"name": "Vignes parent",
// 		"members": [
// 		  {
// 			"personId": null,
// 			"parentId": 3,
// 			"name": "Cher"
// 		  },
// 		  {
// 			"personId": null,
// 			"parentId": 4,
// 			"name": "Vignes parent"
// 		  }
// 		],
// 		"numMesages": 5
// 	  },
// 	  {
// 		"id": 12,
// 		"name": "Shamil",
// 		"members": [
// 		  {
// 			"personId": 128,
// 			"parentId": null,
// 			"name": "Shamil"
// 		  },
// 		  {
// 			"personId": 4,
// 			"parentId": null,
// 			"name": "Paul"
// 		  }
// 		],
// 		"numMesages": 2
// 	  },
// 	  {
// 		"id": 13,
// 		"name": "Paul",
// 		"members": [
// 		  {
// 			"personId": null,
// 			"parentId": 3,
// 			"name": "Cher"
// 		  },
// 		  {
// 			"personId": 4,
// 			"parentId": null,
// 			"name": "Paul"
// 		  }
// 		],
// 		"numMesages": 16
// 	  },
// 	  {
// 		"id": 14,
// 		"name": "Admin",
// 		"members": [
// 		  {
// 			"personId": 2,
// 			"parentId": null,
// 			"name": "Admin"
// 		  },
// 		  {
// 			"personId": null,
// 			"parentId": 3,
// 			"name": "Cher"
// 		  }
// 		],
// 		"numMesages": 10
// 	  },
// 	  {
// 		"id": 15,
// 		"name": "Cher",
// 		"members": [
// 		  {
// 			"personId": 103,
// 			"parentId": null,
// 			"name": "Tea Cher"
// 		  },
// 		  {
// 			"personId": null,
// 			"parentId": 3,
// 			"name": "Cher"
// 		  }
// 		],
// 		"numMesages": 85
// 	  },
// 	  {
// 		"id": 16,
// 		"name": "Vignes parent",
// 		"members": [
// 		  {
// 			"personId": 122,
// 			"parentId": null,
// 			"name": "Kevin Raj"
// 		  },
// 		  {
// 			"personId": null,
// 			"parentId": 4,
// 			"name": "Vignes parent"
// 		  }
// 		],
// 		"numMesages": 1
// 	  },
// 	  {
// 		"id": 17,
// 		"name": "Kevin Raj",
// 		"members": [
// 		  {
// 			"personId": null,
// 			"parentId": 2,
// 			"name": "Jaya"
// 		  },
// 		  {
// 			"personId": 122,
// 			"parentId": null,
// 			"name": "Kevin Raj"
// 		  }
// 		],
// 		"numMesages": 2
// 	  }
// 	]
//   }
