import apiHandler from './apiHandler';

export async function claimList() {
	return apiHandler.get(`/staff/claim`);
}

export async function updateClaim(claimId: number, data: any) {
	return apiHandler.put(`/staff/claim/` + claimId, data);
}

const claimsAPI = {
	claimList,
	updateClaim,
};

export default claimsAPI;
