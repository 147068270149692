import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IMAGES } from '../../Dashboard/Content';
import schoolSetupApi from '../../../../api/schoolSetupApi';
import useMediaServer from '../../../../services/useMediaServer';
import swal from 'sweetalert';
import feesApi from '../../../../api/feesApi';

const payload = JSON.parse(sessionStorage.getItem('payload'));

const SchoolSetup = ({ setIsOpenModal, isOpenModal, isStaff, reload, attendanceList, date }) => {
	const [validation, setValidation] = useState({});
	const [data, setData] = useState();
	const [file, setFile] = useState(null);
	const [avatar, setAvatar] = useState(null);
	const [fees, setFees] = useState([]);

	const { uploadMedia } = useMediaServer();

	const getSchoolSetup = async () => {
		const res = await schoolSetupApi.get();
		if (res.success) {
			setData(res.data);
			console.log(res.data);
			if (res.data.logoUrl) setAvatar(res.data.logoUrl);
		}
	};

	const getFees = async () => {
		const fees = await feesApi.getFeesList();
		if (fees.success) {
			setFees(fees.data);
		}
	};

	useEffect(() => {
		getSchoolSetup();
		getFees();
	}, []);

	const initialValues = {
		name: data?.name,
		address_1: data?.address_1,
		address_2: data?.address_2,
		city: data?.city,
		state: data?.state,
		postcode: data?.postcode,
		logoUrl: data?.logo_url,
		email_server_host: data?.email_server_host,
		email_server_port: data?.email_server_port,
		email_user: data?.email_user,
		email_password: data?.email_password,
		email_ssl: data?.email_ssl,
		email_address: data?.email_address,
		prorataLeave: data?.prorataLeave,
		hasQualifiedNurse: data?.hasQualifiedNurse,
		exam_terms_count_peryear: data?.exam_terms_count_peryear,
		auto_billing_day_of_month: data?.auto_billing_day_of_month,
		auto_billing_grace_days: data?.auto_billing_grace_days,
		late_payment_fee_id: Number(data?.late_payment_fee_id ?? 0),
		late_pickup_fee_id: Number(data?.late_pickup_fee_id ?? 0),
		late_pickup_billing_frequency_minutes: data?.late_pickup_billing_frequency_minutes,
		late_pickup_max_minutes: data?.late_pickup_max_minutes,
		late_pickup_start_time: data?.late_pickup_start_time,
		schoolId: data?.id,
	};

	const validationSchema = {
		name: Yup.string().required('Please input a value'),
		address_1: Yup.string().required('Please input a value'),
		address_2: Yup.string(),
		city: Yup.string().required('Please input a value'),
		state: Yup.string().required('Please input a value'),
		postcode: Yup.string().required('Please input a value'),
		logoUrl: Yup.string(),
		email_server_host: Yup.string().required('Please input a value'),
		email_server_port: Yup.number().required('Please input a value'),
		email_user: Yup.string().required('Please input a value'),
		email_password: Yup.string().required('Please input a value'),
		email_ssl: Yup.boolean(),
		email_address: Yup.string().required('Please input a value'),
		prorataLeave: Yup.boolean(),
		hasQualifiedNurse: Yup.boolean(),
		exam_terms_count_peryear: Yup.number().min(1),
		auto_billing_day_of_month: Yup.number().min(0).max(29).required('Enter a value between 0 and 29 (0 for month end)'),
		auto_billing_grace_days: Yup.number().min(0).max(31).required('Enter a value between 0 and 31'),
		late_payment_fee_id: Yup.number(),
		late_pickup_fee_id: Yup.number(),
		late_pickup_billing_frequency_minutes: Yup.number().min(5),
		late_pickup_max_minutes: Yup.number().min(5),
		late_pickup_start_time: Yup.string()
			.matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/, 'Invalid time format, please use the format hh:mm:ss')
			.required('Please input a time formatted as hh:mm:ss'),
	};

	useEffect(() => {
		setValidationScheme();
	}, []);

	const setValidationScheme = () => {
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const fileHandler = (e) => {
		setFile(e.target.files);
		setAvatar(URL.createObjectURL(e.target.files[0]));
	};

	const uploadImg = async () => {
		const folder = `school_${payload.schoolId}/logo/`;
		const res = await uploadMedia(file, folder);
		return res[0].url;
	};

	const handleSubmit = async (e) => {
		try {
			let {
				name,
				address_1,
				address_2,
				city,
				state,
				postcode,
				logoUrl,
				email_server_host,
				email_server_port,
				email_user,
				email_password,
				email_ssl,
				email_address,
				prorataLeave,
				hasQualifiedNurse,
				exam_terms_count_peryear,
				auto_billing_day_of_month,
				auto_billing_grace_days,
				late_payment_fee_id,
				late_pickup_fee_id,
				late_pickup_billing_frequency_minutes,
				late_pickup_max_minutes,
				late_pickup_start_time,
			} = e;
			console.log('Late Payment ID', late_payment_fee_id);

			if (file !== null) {
				const iuUrl = await uploadImg();
				if (iuUrl) logoUrl = iuUrl;
			}

			const res = await schoolSetupApi.update({
				name,
				address_1,
				address_2,
				city,
				state,
				postcode,
				logoUrl,
				email_server_host,
				email_server_port,
				email_user,
				email_password,
				email_ssl,
				email_address,
				prorataLeave,
				hasQualifiedNurse,
				exam_terms_count_peryear,
				auto_billing_day_of_month,
				auto_billing_grace_days,
				late_payment_fee_id: late_payment_fee_id > 0 ? late_payment_fee_id : undefined,
				late_pickup_fee_id: late_pickup_fee_id > 0 ? late_pickup_fee_id : undefined,
				late_pickup_billing_frequency_minutes,
				late_pickup_max_minutes,
				late_pickup_start_time,
			});
			if (res.success) {
				swal('Success', 'School has been updated!', 'success');
				console.log('Updated');
			}
		} catch (err) {
			swal('Oops', 'Something went wrong! Please try again!', 'error');
			console.log(err);
		}
	};

	return (
		<div className='modal-content'>
			<div className='modal-header'>
				<h5 className='modal-title'>School Setup</h5>
			</div>
			<Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validation} onSubmit={(e) => handleSubmit(e)}>
				{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
					return (
						<div className='modal-body'>
							<div className='my-2 mb-4 col-xl-3 col-lg-4'>
								<label className='form-label text-primary'>Logo</label>
								<div className='avatar-upload'>
									<div className='avatar-preview'>
										<div
											id='imagePreview'
											style={{
												backgroundImage: avatar ? 'url(' + avatar + ')' : 'url(' + IMAGES.noimage + ')',
											}}></div>
									</div>
									<div className='change-btn mt-2 mb-lg-0 mb-3'>
										<input
											type='file'
											className='form-control d-none'
											onChange={fileHandler}
											id='imageUpload'
											accept='.png, .jpg, .jpeg'
										/>
										<label htmlFor='imageUpload' className='dlab-upload mb-0 btn btn-primary btn-sm'>
											Choose File
										</label>
									</div>
								</div>
							</div>

							<div className='mb-3'>
								<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
									School Name<span className='required'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='name'
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder='School Name'
								/>
								{errors?.name && touched.name && (
									<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
										{errors.name}
									</div>
								)}
							</div>
							<div className='mt-3 p-4 border border-1 border-grey-500'>
								<h4 className='mb-3'>Location</h4>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Address 1<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='address_1'
										value={values.address_1}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Address 1'
									/>
									{errors?.address_1 && touched.address_1 && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.address_1}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Address 2
									</label>
									<input
										type='text'
										className='form-control'
										name='address_2'
										value={values.address_2}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Address 2'
									/>
									{errors?.address_2 && touched.address_2 && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.address_2}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										City<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='city'
										value={values.city}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='City'
									/>
									{errors?.city && touched.city && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.city}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										State<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='state'
										value={values.state}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='State'
									/>
									{errors?.state && touched.state && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.state}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Postcode<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='postcode'
										value={values.postcode}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Postcode'
									/>
									{errors?.postcode && touched.postcode && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.postcode}
										</div>
									)}
								</div>
							</div>

							<div className='mt-3 p-4 border border-1 border-grey-500'>
								<h4 className='mb-3'>Email</h4>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Email Server Host<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='email_server_host'
										value={values.email_server_host}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Email Server Host'
									/>
									{errors?.email_server_host && touched.email_server_host && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.email_server_host}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Email Server Port<span className='required'>*</span>
									</label>
									<input
										type='number'
										className='form-control'
										name='email_server_port'
										value={values.email_server_port}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Email Server Port'
									/>
									{errors?.email_server_port && touched.email_server_port && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.email_server_port}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Email Server User<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='email_user'
										value={values.email_user}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Email Server User'
									/>
									{errors?.email_user && touched.email_user && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.email_user}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Email Server Password<span className='required'>*</span>
									</label>
									<input
										type='password'
										className='form-control'
										name='email_password'
										value={values.email_password}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Email Server Password'
									/>
									{errors?.email_password && touched.email_password && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.email_password}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Email Server SSL<span className='required'>*</span>
									</label>
									<Form.Check
										type='switch'
										name='email_ssl'
										checked={values.email_ssl}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Email Server SSL'
									/>
									{errors?.email_ssl && touched.email_ssl && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.email_ssl}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Email Address<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='email_address'
										value={values.email_address}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Email Address'
									/>
									{errors?.email_address && touched.email_address && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.email_address}
										</div>
									)}
								</div>
							</div>
							<div className='mt-3 p-4 border border-1 border-grey-500'>
								<h4 className='mb-3'>Billing</h4>
								<div className='mb-6'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Auto-billing day of month<span className='required'>*</span>
									</label>
									<input
										type='number'
										className='form-control'
										name='auto_billing_day_of_month'
										value={values.auto_billing_day_of_month}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='26'
									/>
									{errors?.auto_billing_day_of_month && touched.auto_billing_day_of_month && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.auto_billing_day_of_month}
										</div>
									)}
								</div>
								<div className='mb-6'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Grace period (days)<span className='required'>*</span>
									</label>
									<input
										type='number'
										className='form-control'
										name='auto_billing_grace_days'
										value={values.auto_billing_grace_days}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='14'
									/>
									{errors?.auto_billing_grace_days && touched.auto_billing_grace_days && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.auto_billing_grace_days}
										</div>
									)}
								</div>
								<div className='mb-3 mt-2'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Late payment fee
									</label>

									<select
										name='late_payment_fee_id'
										className='form-control'
										onChange={(e) => {
											setFieldValue('late_payment_fee_id', Number(e.target.value));
										}}
										onBlur={handleBlur}>
										<option value={0} key={0} selected={0 === values.late_pickup_fee_id}>
											None
										</option>
										{fees.map((f) => (
											<option value={f.id} key={f.id} selected={f.id === values.late_payment_fee_id}>
												{f.description}
											</option>
										))}
									</select>

									{fees.length === 0 && (
										<div className='light-gray animated fadeInUp' style={{ display: 'block' }}>
											Please Add Fee to configure the late fee
										</div>
									)}
									{errors?.late_payment_fee_id && touched.late_payment_fee_id && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.late_payment_fee_id}
										</div>
									)}
								</div>
							</div>
							<div className='mt-3 p-4 border border-1 border-grey-500'>
								<h4 className='mb-3'>Late Pickup</h4>
								<div className='mb-6'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Time to start charging<span className='required'>*</span>
									</label>
									<input
										type='time'
										step={1}
										className='form-control'
										name='late_pickup_start_time'
										value={values.late_pickup_start_time}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='15'
									/>
									{errors?.late_pickup_start_time && touched.late_pickup_start_time && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.late_pickup_start_time}
										</div>
									)}
								</div>

								<div className='mb-6'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Minutes between charges<span className='required'>*</span>
									</label>
									<input
										type='number'
										className='form-control'
										name='late_pickup_billing_frequency_minutes'
										value={values.late_pickup_billing_frequency_minutes}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='15'
									/>
									{errors?.late_pickup_billing_frequency_minutes && touched.late_pickup_billing_frequency_minutes && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.late_pickup_billing_frequency_minutes}
										</div>
									)}
								</div>
								<div className='mb-3 mt-2'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Fee
									</label>
									<select
										name='late_pickup_fee_id'
										className='form-control'
										onChange={(e) => {
											setFieldValue('late_pickup_fee_id', Number(e.target.value));
										}}
										onBlur={handleBlur}>
										<option value={0} key={0} selected={0 === values.late_pickup_fee_id}>
											None
										</option>
										{fees.map((f) => (
											<option value={f.id} key={f.id} selected={f.id === values.late_pickup_fee_id}>
												{f.description}
											</option>
										))}
									</select>

									{fees.length === 0 && (
										<div className='light-gray animated fadeInUp' style={{ display: 'block' }}>
											Please Add Fee to configure the late pick up fee
										</div>
									)}

									{errors?.late_payment_fee_id && touched.late_payment_fee_id && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.late_payment_fee_id}
										</div>
									)}
								</div>
								<div className='mb-6'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Maximum minutes to charge<span className='required'>*</span>
									</label>
									<input
										type='number'
										className='form-control'
										name='late_pickup_max_minutes'
										value={values.late_pickup_max_minutes}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='90'
									/>
									{errors?.late_pickup_max_minutes && touched.late_pickup_max_minutes && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.late_pickup_max_minutes}
										</div>
									)}
								</div>
							</div>
							<div className='mt-3 p-4 border border-1 border-grey-500'>
								<h4 className='mb-3'>Other</h4>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Prorata Leave<span className='required'>*</span>
									</label>
									<Form.Check
										type='switch'
										name='prorataLeave'
										checked={values.prorataLeave}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Prorata Leave'
									/>
									{errors?.prorataLeave && touched.prorataLeave && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.prorataLeave}
										</div>
									)}
								</div>

								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Nurse Available<span className='required'>*</span>
									</label>
									<Form.Check
										type='switch'
										name='hasQualifiedNurse'
										checked={values.hasQualifiedNurse}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Nurse Available'
									/>
									{errors?.hasQualifiedNurse && touched.hasQualifiedNurse && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.hasQualifiedNurse}
										</div>
									)}
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Number of Progress Report per year<span className='required'>*</span>
									</label>
									<input
										type='number'
										className='form-control'
										name='exam_terms_count_peryear'
										value={values.exam_terms_count_peryear}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Email Address'
									/>
									{errors?.exam_terms_count_peryear && touched.exam_terms_count_peryear && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.exam_terms_count_peryear}
										</div>
									)}
								</div>
							</div>

							<button type='button' className='btn btn-primary w-100 mt-3' onClick={handleSubmit}>
								Save
							</button>
						</div>
					);
				}}
			</Formik>
		</div>
	);
};

export default SchoolSetup;
