import apiHandler from './apiHandler';

export async function injuryReportList() {
	return apiHandler.get(`/app/health/injury`);
}

const injuryAPI = {
	injuryReportList,
};

export default injuryAPI;
