import phone from './../../../images/svg/phone.svg';

export const IMAGES = {
	trans1: require('./../../../images/trans/1.jpg'),
	trans2: require('./../../../images/trans/2.jpg'),
	trans3: require('./../../../images/trans/3.jpg'),
	trans4: require('./../../../images/trans/4.jpg'),
	trans5: require('./../../../images/trans/5.jpg'),
	trans6: require('./../../../images/trans/6.jpg'),
	trans7: require('./../../../images/trans/7.jpg'),
	trans8: require('./../../../images/trans/8.jpg'),
	trans9: require('./../../../images/trans/9.jpg'),
	trans10: require('./../../../images/trans/10.jpg'),
	avat1: require('./../../../images/avatar/1.jpg'),
	avat2: require('./../../../images/avatar/2.jpg'),
	avat3: require('./../../../images/avatar/3.jpg'),
	avat4: require('./../../../images/avatar/4.jpg'),
	avat8: require('./../../../images/avatar/8.jpg'),
	avat9: require('./../../../images/avatar/9.jpg'),
	avat10: require('./../../../images/avatar/10.jpg'),
	noimage: require('./../../../images/no-img-avatar.png'),
	contact1: require('./../../../images/contacts/1.jpg'),
	contact2: require('./../../../images/contacts/2.jpg'),
	contact3: require('./../../../images/contacts/3.jpg'),
	contact4: require('./../../../images/contacts/4.jpg'),
	contact5: require('./../../../images/contacts/5.jpg'),
	contact6: require('./../../../images/contacts/6.jpg'),
	contact7: require('./../../../images/contacts/7.jpg'),
	contact8: require('./../../../images/contacts/8.jpg'),
	contact9: require('./../../../images/contacts/9.jpg'),
	contact10: require('./../../../images/contacts/10.jpg'),
	contact11: require('./../../../images/contacts/11.jpg'),
	contact12: require('./../../../images/contacts/12.jpg'),
	food1: require('./../../../images/food/pic1.jpg'),
	food2: require('./../../../images/food/pic2.jpg'),
	food3: require('./../../../images/food/pic3.jpg'),
	food4: require('./../../../images/food/pic4.jpg'),
	food5: require('./../../../images/food/pic5.jpg'),
	food6: require('./../../../images/food/pic6.jpg'),
	avatarpng1: require('./../../../images/avatar/1.png'),
	avatarpng2: require('./../../../images/avatar/2.png'),
	avatarpng3: require('./../../../images/avatar/3.png'),
	profile14: require('./../../.././images/profile/14.jpg'),
	profile18: require('./../../.././images/profile/18.jpg'),
	profile19: require('./../../.././images/profile/19.jpg'),
	profilepic1: require('./../../.././images/profile/small/pic1.jpg'),
	profilepic2: require('./../../.././images/profile/small/pic2.jpg'),
	profilepic3: require('./../../.././images/profile/small/pic3.jpg'),
	profilepic4: require('./../../.././images/profile/small/pic4.jpg'),
	profilepic5: require('./../../.././images/profile/small/pic5.jpg'),
	activity1: require('./../../.././images/activity-img/pic1.jpg'),
	activity2: require('./../../.././images/activity-img/pic2.jpg'),
	activity3: require('./../../.././images/activity-img/pic3.jpg'),
	activity4: require('./../../.././images/activity-img/pic4.jpg'),
};

export const SVGICON = {
	user: (
		<span style={{ fontSize: '10px', color: 'white' }}>
			<i className='fa-solid fa-graduation-cap'></i>
		</span>
	),
	user2: (
		<span style={{ fontSize: '10px', color: 'white' }}>
			<i className='fa-solid fa-user-tie'></i>
		</span>
	),
	event: (
		<span style={{ fontSize: '10px', color: 'white' }}>
			<i className='fa-solid fa-calendar'></i>
		</span>
	),
	food: (
		<span style={{ fontSize: '10px', color: 'white' }}>
			<i className='fa-solid fa-calendar-days'></i>
		</span>
	),
	calndar: (
		<span style={{ fontSize: '10px', color: 'white' }}>
			<i className='fa-solid fa-graduation-cap'></i>
		</span>
	),
	watch: (
		<svg className='me-2 ms-1' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0V0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z'
				fill='#FCC43E'
			/>
			<path
				d='M13 9H11V5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4C9.73478 4 9.48043 4.10536 9.29289 4.29289C9.10536 4.48043 9 4.73478 9 5V10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9Z'
				fill='#FCC43E'
			/>
		</svg>
	),
	dots: (
		<svg width='24' height='6' viewBox='0 0 24 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.0012 0.359985C11.6543 0.359985 11.3109 0.428302 10.9904 0.561035C10.67 0.693767 10.3788 0.888317 10.1335 1.13358C9.88829 1.37883 9.69374 1.67 9.56101 1.99044C9.42828 2.31089 9.35996 2.65434 9.35996 3.00119C9.35996 3.34803 9.42828 3.69148 9.56101 4.01193C9.69374 4.33237 9.88829 4.62354 10.1335 4.8688C10.3788 5.11405 10.67 5.3086 10.9904 5.44134C11.3109 5.57407 11.6543 5.64239 12.0012 5.64239C12.7017 5.64223 13.3734 5.36381 13.8686 4.86837C14.3638 4.37294 14.6419 3.70108 14.6418 3.00059C14.6416 2.3001 14.3632 1.62836 13.8677 1.13315C13.3723 0.637942 12.7004 0.359826 12 0.359985H12.0012ZM3.60116 0.359985C3.25431 0.359985 2.91086 0.428302 2.59042 0.561035C2.26997 0.693767 1.97881 0.888317 1.73355 1.13358C1.48829 1.37883 1.29374 1.67 1.16101 1.99044C1.02828 2.31089 0.959961 2.65434 0.959961 3.00119C0.959961 3.34803 1.02828 3.69148 1.16101 4.01193C1.29374 4.33237 1.48829 4.62354 1.73355 4.8688C1.97881 5.11405 2.26997 5.3086 2.59042 5.44134C2.91086 5.57407 3.25431 5.64239 3.60116 5.64239C4.30165 5.64223 4.97339 5.36381 5.4686 4.86837C5.9638 4.37294 6.24192 3.70108 6.24176 3.00059C6.2416 2.3001 5.96318 1.62836 5.46775 1.13315C4.97231 0.637942 4.30045 0.359826 3.59996 0.359985H3.60116ZM20.4012 0.359985C20.0543 0.359985 19.7109 0.428302 19.3904 0.561035C19.07 0.693767 18.7788 0.888317 18.5336 1.13358C18.2883 1.37883 18.0937 1.67 17.961 1.99044C17.8283 2.31089 17.76 2.65434 17.76 3.00119C17.76 3.34803 17.8283 3.69148 17.961 4.01193C18.0937 4.33237 18.2883 4.62354 18.5336 4.8688C18.7788 5.11405 19.07 5.3086 19.3904 5.44134C19.7109 5.57407 20.0543 5.64239 20.4012 5.64239C21.1017 5.64223 21.7734 5.36381 22.2686 4.86837C22.7638 4.37294 23.0419 3.70108 23.0418 3.00059C23.0416 2.3001 22.7632 1.62836 22.2677 1.13315C21.7723 0.637942 21.1005 0.359826 20.4 0.359985H20.4012Z'
				fill='#A098AE'
			/>
		</svg>
	),
	message: (
		<svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19 0.000114441H3C2.20435 0.000114441 1.44129 0.316185 0.878679 0.878794C0.31607 1.4414 0 2.20446 0 3.00011V13.0001C0 13.7958 0.31607 14.5588 0.878679 15.1214C1.44129 15.684 2.20435 16.0001 3 16.0001H19C19.7956 16.0001 20.5587 15.684 21.1213 15.1214C21.6839 14.5588 22 13.7958 22 13.0001V3.00011C22 2.20446 21.6839 1.4414 21.1213 0.878794C20.5587 0.316185 19.7956 0.000114441 19 0.000114441V0.000114441ZM20 12.7501L15.1 8.35011L20 4.92011V12.7501ZM2 4.92011L6.9 8.35011L2 12.7501V4.92011ZM8.58 9.53011L10.43 10.8201C10.5974 10.9362 10.7963 10.9985 11 10.9985C11.2037 10.9985 11.4026 10.9362 11.57 10.8201L13.42 9.53011L18.42 14.0001H3.6L8.58 9.53011ZM3 2.00011H19C19.1857 2.0016 19.3673 2.05478 19.5245 2.15369C19.6817 2.2526 19.8083 2.39333 19.89 2.56011L11 8.78011L2.11 2.56011C2.19171 2.39333 2.31826 2.2526 2.47545 2.15369C2.63265 2.05478 2.81428 2.0016 3 2.00011V2.00011Z'
				fill='#A098AE'
			></path>
		</svg>
	),
	search: (
		<svg width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M23.6 21.8001L18 16.2001C19.3 14.5001 20.1 12.4001 20.1 10.1001C20.1 4.6001 15.6 0.100098 10.1 0.100098C4.6 0.100098 0 4.6001 0 10.1001C0 15.6001 4.5 20.1001 10 20.1001C12.3 20.1001 14.5 19.3001 16.2 17.9001L21.8 23.5001C22 23.7001 22.4 23.9001 22.7 23.9001C23 23.9001 23.3 23.8001 23.6 23.5001C24.1 23.1001 24.1 22.3001 23.6 21.8001ZM2.5 10.1001C2.5 6.0001 5.9 2.6001 10 2.6001C14.1 2.6001 17.5 6.0001 17.5 10.1001C17.5 14.2001 14.1 17.6001 10 17.6001C5.9 17.6001 2.5 14.3001 2.5 10.1001Z'
				fill='var(--primary)'
			/>
		</svg>
	),
	plus: (
		<svg width='14' height='14' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M5.87826 10.0611H0V5.869H5.87826V0H10.0522V5.869H16V10.0611H10.0522V16H5.87826V10.0611Z' fill='white' />
		</svg>
	),
};
export const LOGOSVG = {
	edubricks: require('../../../images/logo/eduBricks.svg'),
};
