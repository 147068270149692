import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

//Import Components
import { TeacherDetails } from './Elements/TeacherDetails';
import { UnpaidStudentTable } from './Elements/UnpaidStudentTable';
import dashboardAPI from '../../../api/dashboardApi';
import EventCalendar from './Elements/EventCalendar';
import useACL from '../../../services/ACL';

const SchoolPerformance = loadable(() => pMinDelay(import('./Elements/SchoolPerformance'), 500));
const SchoolOverView = loadable(() => pMinDelay(import('./Elements/SchoolOverView'), 1000));

const Home = () => {
	const [dashboardCount, setDashboardCount] = useState([]);
	const [attendingTeacher, setAttendingTeacher] = useState(0);
	const [attendingStudent, setAttendingStudent] = useState(0);
	const [teachersDetails, setTeachersDetails] = useState([]);
	const [unpaidStudents, setUnpaidStudents] = useState([]);
	const [attendanceOverview, setAttendanceOverview] = useState([]);
	const [expectedFees, setExpectedFees] = useState([]);
	const [actualFees, setActualFees] = useState([]);
	const [calendarEvent, setCalendarEvent] = useState([]);
	const [allowHolidays, setAllowHolidays] = useState(false);

	const payload = JSON.parse(sessionStorage.getItem('payload'));
	// console.log(payload);
	const isAllowed = useACL(payload.acl);

	useEffect(() => {
		setAllowHolidays(isAllowed('Holidays'));
		getList();
		if (isAllowed('Holidays')) getCalendarEvent();
	}, []);

	const getList = async () => {
		try {
			let res = await dashboardAPI.dashboardList();
			if (res.success) {
				let attendTeacher = res?.data?.staffAttendance?.filter((v) => v.status === 'Present');
				let studentAttendance = res?.data?.studentAttendance?.filter((v) => v.status === 'Present');
				setDashboardCount(res?.data?.people);
				setAttendingTeacher(attendTeacher?.length);
				setAttendingStudent(studentAttendance?.length);
				setTeachersDetails(res?.data?.staffAttendance);
				setUnpaidStudents(res?.data?.unpaidStudentFees);
				setAttendanceOverview(res?.data?.attendanceOverview?.months);
				setExpectedFees(res?.data?.feesData?.expected);
				setActualFees(res?.data?.feesData?.actual);
			} else {
				return [];
			}
		} catch (err) {
			console.log(err);
			return [];
		}
	};
	const getCalendarEvent = async () => {
		try {
			let res = await dashboardAPI.calendarEventList();
			if (res.success) {
				let calendarData = res.data?.map((v, i) => {
					return {
						title: v.name,
						start: v.startDate,
						end: v.endDate,
						eventType: v.type,
						id: i,
						eventColor: '#378006',
						backgroundColor: v.type === 'Holiday' ? '#F04F3E' : '#001D6C',
					};
				});
				setCalendarEvent(calendarData);
			} else {
				return [];
			}
		} catch (err) {
			console.log(err);
			return [];
		}
	};
	const cardCount = [
		{
			title: 'Registered Students',
			svg: (
				<span style={{ fontSize: '10px', color: 'white' }}>
					<i className='fa-solid fa-graduation-cap'></i>
				</span>
			),
			number: dashboardCount?.registeredStudents,
			change: 'std-data',
		},
		{
			title: 'Registered Teachers',
			svg: (
				<span style={{ fontSize: '10px', color: 'white' }}>
					<i className='fa-solid fa-user-tie'></i>
				</span>
			),
			number: dashboardCount?.registeredTeachers,
			change: 'teach-data',
		},
		{
			title: 'Students Attending Today',
			svg: (
				<span style={{ fontSize: '10px', color: 'white' }}>
					<i className='fa-regular fa-calendar-check'></i>
				</span>
			),
			number: attendingStudent,
			change: 'event-data',
		},
		{
			title: 'Staff Attending Today',
			svg: (
				<span style={{ fontSize: '10px', color: 'white' }}>
					<i className='fa-solid fa-calendar-days'></i>
				</span>
			),
			number: attendingTeacher,
			change: 'food-data bg-dark',
		},
	];
	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='card'>
						<div className='card-body pb-xl-4 pb-sm-3 pb-0'>
							<div className='row'>
								{cardCount.map((item, ind) => (
									<div className='col-xl-3 col-md-6 col-12' key={ind}>
										<div className='content-box'>
											<div className={`icon-box icon-box-xl ${item.change}`}>{item.svg}</div>
											<div className='chart-num'>
												<p>{item.title}</p>
												<h2 className='font-w700 mb-0'>{item.number}</h2>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-xl-6 '>
					<div className='card crypto-chart '>
						<div className='card-header pb-0 border-0 flex-wrap'>
							<div className='mb-2 mb-sm-0'>
								<div className='chart-title mb-3'>
									<h2 className='heading'>School Fees</h2>
								</div>
							</div>
							<div className='p-static'>
								<div className='d-flex align-items-center mb-3 mb-sm-0'>
									<div className='round weekly' id='dzOldSeries'></div>
									<div className='round' id='dzNewSeries'>
										<div>
											<span className='fs-16'>Month</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='card-body pt-2 custome-tooltip pb-0 px-2'>
							{expectedFees && actualFees && <SchoolPerformance expectedFees={expectedFees} actualFees={actualFees} />}
						</div>
					</div>
				</div>
				<div className='col-xl-6'>
					<div className='card h-auto'>
						<SchoolOverView data={attendanceOverview} />
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-xl-5'>
					<div className='card'>
						<div className='card-header py-3 border-0 px-3'>
							<h4 className='heading m-0'>Teacher Attendance</h4>
						</div>
						<div className='card-body p-0'>
							<TeacherDetails data={teachersDetails} />
						</div>
					</div>
				</div>
				<div className='col-xl-7'>
					<div className='card'>
						<div className='card-header border-0 p-3'>
							<h4 className='heading mb-0'>Unpaid Student Tuition</h4>
						</div>
						<div className='card-body p-0'>
							<UnpaidStudentTable data={unpaidStudents} />
						</div>
					</div>
				</div>
				{allowHolidays && (
					<div className='col-xl-12 wow fadeInUp' data-wow-delay='1.5s'>
						<div className='card'>
							<div className='card-header pb-0 border-0 flex-wrap'>
								<div>
									<div className='mb-3'>
										<h2 className='heading mb-0'>School Calendar</h2>
									</div>
								</div>
							</div>
							<div className='card-body text-center event-calender dz-calender py-0 px-1'>
								<EventCalendar data={calendarEvent} />
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default Home;
