// import newsAPI from 'api/newsApi';
import birthdayApi from '../../../../api/birthdayApi';
import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Name',
		Footer: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Birth Date',
		Footer: 'Birth Date',
		accessor: 'date',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Age',
		Footer: 'Age',
		accessor: 'age',
	},
	{
		// Header: 'Next Birthday',
		Footer: 'Next Birthday',
		accessor: 'nextBday',
		Cell: ({ value }) => {
			const t = moment(new Date()).format('DD/MM/yyyy');
			const d = moment(value).format('DD/MM/yyyy');
			return (
				<div className={`text-center px-4 ${t === d ? 'text-orange  rounded fw-bold' : ''}`}>
					{t === d ? (
						<span>
							🎂<span className='mx-2'>{d}</span>🎂
						</span>
					) : (
						d
					)}
				</div>
			);
		},
		Header: ({ column }) => {
			return (
				<div className='text-center' {...column.getHeaderProps()}>
					Next Birthday
				</div>
			);
		},
	},
	{
		Header: 'Class',
		Footer: 'Class',
		accessor: 'class',
	},
	// {
	// 	Header: 'Type',
	// 	Footer: 'Type',
	// 	accessor: 'type',
	// },
	// {
	// 	Header: 'Action',
	// 	Footer: 'Action',
	// },
];

export async function getBirthdayList() {
	try {
		const res = await birthdayApi.birthdayList();
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
	}
}
