import apiHandler from './apiHandler';

export type Person = {
	id: number;
	name: string;
	avatarUrl?: string;
};

export type StudentInfo = Person & {
	dob: Date;
};

export type TeacherInfo = Person;

type ClassInfo = {
	id: number;
	name: string;
	students: StudentInfo[];
	teachers: TeacherInfo[];
};

export type ClassData = {
	classInfo: ClassInfo;
	unassignedStudents: StudentInfo[];
	unassignedTeachers: TeacherInfo[];
};

type Success = {
	success: true;
};

type Fail = {
	success: false;
	error: string;
};

export type AssignableClassResponse =
	| Fail
	| (Success & {
			data: ClassData;
	  });

export function getAllForClass(classId: number): Promise<AssignableClassResponse> {
	return apiHandler.get(`/class/${classId}/assignments`);
}

export function updateAssignments(classId: number, personIds: number[]): Promise<AssignableClassResponse> {
	return apiHandler.post(`/class/${classId}/assignments`, { personIds });
}

const classAssignmentAPI = {
	getAllForClass,
	updateAssignments,
};

export default classAssignmentAPI;
