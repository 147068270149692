import studentAPI from '../../../api/studentApi';
import classAPI from '../../../api/classApi';

export async function getParentsList() {
	const arr = [];
	try {
		const { data } = await studentAPI.parentList();
		data.map((item, i) => {
			const obj = { label: item.name, value: item.id };
			arr.push(obj);
		});
		return arr;
	} catch (err) {
		console.log(err);
	}
}

export async function getClassList() {
	const arr = [];
	try {
		const { data } = await classAPI.classList();

		data.map((item, i) => {
			const obj = { label: item.name, value: item.id };
			arr.push(obj);
		});
		return arr;
	} catch (err) {
		console.log(err);
	}
}
