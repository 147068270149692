import financeApi from '../../../../../api/financeApi';
import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Name',
		Footer: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
		Cell: ({ value }) => {
			if (String(value).includes('Paid')) return <span style={{ fontWeight: '500', color: '#092E20' }}>Complete</span>;
			else return String(value);
		},
	},
	{
		Header: 'Gross Salary',
		Footer: 'Gross Salary',
		accessor: (row) => row,
		Cell: ({ value }) => {
			const gross = Number(value.salary) + Number(value.allowance);
			return `RM ${gross.toFixed(2).toLocaleString()}`;
		},
	},
	{
		Header: 'Total Deduction',
		Footer: 'Total Deduction',
		accessor: (row) => row,
		Cell: ({ value }) => {
			// total of [ PCB | EIS | SOCSO | EPF ]
			const total = Number(value.epf) + Number(value.eis) + Number(value.socso) + Number(value.pcb);
			return `RM ${total.toFixed(2).toLocaleString()}`;
		},
	},
	{
		Header: 'Pay Slip',
		Footer: 'Total Deduction',
		accessor: 'payslipUrl',
		Cell: ({ value }) => {
			if (value)
				return (
					<a href={value} target='_blank' rel='noreferrer' download={`test.pdf`}>
						<i className='mdi mdi-24px mdi-download ' />
					</a>
				);
		},
	},
];

export async function getPayroll(date) {
	try {
		let res = await financeApi.payrollList(new Date(date).toISOString());
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		return [];
	}
}
export async function editPayroll(monthyear, staffId, data) {
	try {
		let res = await financeApi.editPayroll(monthyear, staffId, data);
		return res;
	} catch (err) {
		console.log(err);
		return { success: false };
	}
}

export async function preparePost() {
	try {
		let res = await financeApi.prepare();
		if (res.success) {
			return res.data;
		} else {
			return res.error;
		}
	} catch (err) {
		console.log(err);
		return { success: false };
	}
}

export async function runPayrollPatch(date) {
	try {
		let res = await financeApi.runPayroll(date);
		if (res.success) {
			return res.data;
		} else {
			return res.error;
		}
	} catch (err) {
		return { success: false };
	}
}
