import React from "react";

const Footer = (props) => {
    let d = new Date();
    return (
        <div className={`footer ${props.changeFooter}`}>
            <div className="copyright">
                <p>
                    Copyright © Designed &amp; Developed by&nbsp;
                    <a
                        href="https://www.ocunapse.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Ocunapse&nbsp;
                    </a>
                    {d.getFullYear()}
                </p>
            </div>
        </div>
    );
};

export default Footer;
