import React, { useState, useEffect, useMemo, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from 'react-datepicker';
import { IMAGES } from '../Dashboard/Content';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import data from '../../data.json';
import { customSelectStyle } from '../../../services/general';
import { getParentsList, getClassList } from './utils';
import studentAPI from '../../../api/studentApi';
import swal from 'sweetalert';
import financeAPI, { FeeType } from '../../../api/financeApi';
import enrollmentTypesAPI, { EnrollmentTypeOptions } from '../../../api/enrollmentTypesApi';
import dashboardApi from '../../../api/dashboardApi';
import useMediaServer from '../../../services/useMediaServer';
import { useSubscription } from '../../../context/SubscriptionContextProvider';

const AddNewStudent = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(null);
	const [prtAvfile, setPrtAvFile] = useState(undefined);
	const [avatar, setAvatar] = useState(undefined);
	const [parentAvatar, setParentAvatar] = useState(undefined);
	const [oldAvatar, setOldAvatar] = useState(undefined);
	const [isCreateParent, setIsCreateParent] = useState(true);
	const [validation, setValidation] = useState({});
	const [studentId, setStudentId] = useState(params.studentId);
	const [parentList, setParentList] = useState([]);
	const [classList, setClassList] = useState([]);
	const [monthlyFeeList, setMonthlyFeeList] = useState([]);
	const [depositFeeList, setDepositFeeList] = useState([]);
	const [studentEnrollmentTypes, setStudentEnrollmentTypes] = useState([]);
	const [studentData, setStudentData] = useState(undefined);
	const { subscription } = useSubscription();
	const payload = JSON.parse(sessionStorage.getItem('payload'));

	// const [initialValues, setInitialValues] = useState(studentInitialValues);

	const { uploadMedia, deleteMedia } = useMediaServer();

	async function getPeople() {
		const dash = await dashboardApi.dashboardList();
		const total = dash.data.people.registeredStudents + dash.data.people.registeredTeachers;
		if (subscription) {
			if (subscription.headCount <= total && subscription.headCount > 0) {
				alert('Maximum number of students/teachers has been reached. Please contact your administrator.');
				navigate('/student');
			}
		}
	}

	useEffect(() => {
		if (subscription && !params?.studentId) getPeople();
	}, [subscription, params?.studentId]);

	useEffect(() => {
		getParentsDropdownList();
		getClassDropdownList();
		getMonthlyFee();
		getDepositFee();
	}, []);

	const getParentsDropdownList = async () => {
		const list = await getParentsList();
		setParentList(list);
	};
	const getClassDropdownList = async () => {
		const list = await getClassList();
		setClassList(list);
	};

	useEffect(() => {
		if (studentId) {
			getStudentDetails();
		}
	}, [params.studentId]);

	useEffect(() => {
		if (studentData) {
			console.log(studentData.student.siblings);
		}
	}, [studentData]);

	const getStudentDetails = async () => {
		try {
			const res = await studentAPI.getStudentDetails(studentId);

			if (res.success) {
				const studentD = res.data;
				setStudentData(studentD);
				if (studentD.student?.avatarUrl) {
					setAvatar(studentD.student?.avatarUrl);
					setOldAvatar(studentD.student?.avatarUrl);
					setParentAvatar(studentD.student?.parent.avatarUrl);
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const getMonthlyFee = async () => {
		try {
			const res = await financeAPI.feeList(FeeType.Monthly);

			if (res.success) {
				const feelist = res.data.map((fee) => {
					return {
						label: `${fee.description} - RM${fee.amount}`,
						value: fee.id,
					};
				});
				setMonthlyFeeList(feelist);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const getDepositFee = async () => {
		try {
			const res = await financeAPI.feeList(FeeType.Deposit);

			if (res.success) {
				const feelist = res.data.map((fee) => {
					return {
						label: `${fee.description} - RM${fee.amount}`,
						value: fee.id,
					};
				});
				setDepositFeeList(feelist);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const fileHandler = (e) => {
		const files = e.target.files;
		if (files.length > 0) {
			setFile(files);
			setAvatar(URL.createObjectURL(files[0]));
		} else {
			setFile(null);
			setAvatar(null);
		}
	};

	const parentImage = (e) => {
		const files = e.target.files;
		if (files.length > 0) {
			setPrtAvFile(e.target.files);
			setParentAvatar(URL.createObjectURL(files[0]));
		} else {
			setPrtAvFile(null);
			setParentAvatar(null);
		}
	};
	// const RemoveFile = async () => {
	// 	setFile(null);
	// 	setAvatar(null);
	// 	setIsDeleteAvatar(true);
	// 	// deleteMedia(avatar);
	// };

	const initialValues = useMemo(
		() => ({
			name: studentData?.name ?? undefined,
			avatarUrl: studentData?.student?.avatarUrl ?? undefined,
			dob: studentData?.student?.dob ? new Date(studentData?.student?.dob) : new Date(),
			nationality: studentData?.student?.nationality ?? undefined,
			address: studentData?.student?.address ?? undefined,
			siblings: studentData?.student?.siblings ?? 0,
			allergies: studentData?.student?.allergies ?? undefined,
			medicalHistory: studentData?.student?.medicalHistory ?? undefined,
			familyDoctorContact: studentData?.student?.familyDoctorContact ?? undefined,
			familyDoctorName: studentData?.student?.familyDoctorName ?? undefined,
			previousPreschool: studentData?.student?.previousPreschool ?? undefined,
			homeLanguage: studentData?.student?.homeLanguage ?? undefined,
			enrollmentTypeId: studentData?.student?.enrollmentType?.id ?? undefined,
			info: studentData?.student?.info ?? undefined,
			parent: {
				avatarUrl: studentData?.student?.parent.avatarUrl ?? undefined,
				name: studentData?.student?.parent.name ?? undefined,
				email: studentData?.student?.parent.email ?? undefined,
				mobileNumber: studentData?.student?.parent.mobileNumber ?? undefined,
				relationship: studentData?.student?.parent.relationship ?? undefined,
				occupation: studentData?.student?.parent.occupation ?? undefined,
				employer: studentData?.student?.parent.employer ?? undefined,
				idNumber: studentData?.student?.parent.idNumber ?? undefined,
				workAddress: studentData?.student?.parent.workAddress ?? undefined,
				workPhone: studentData?.student?.parent.workPhone ?? undefined,
			},
			feesId: studentData?.student?.feesId ?? undefined,
			enrollmentDepositFeeId: studentData?.student?.enrollmentDepositFeeId ?? undefined,
			klassId: studentData?.student?.klassId ?? undefined,
			idNumber: studentData?.idNumber ?? undefined,
			gender: studentData?.gender ?? undefined,
			race: studentData?.race ?? undefined,
			religion: studentData?.religion ?? undefined,
			parentId: studentData?.student?.parentId ?? undefined,
			frUserid: studentData?.frUserid ?? undefined,
		}),
		[studentData]
	);
	const validationSchema = {
		name: Yup.string().required('Please fill in the field'),
		dob: Yup.string().required('Please fill in the field'),
		nationality: Yup.string().required('Please fill in the field'),
		address: Yup.string().required('Please fill in the field'),
		gender: Yup.string().required('Please fill in the field'),
		race: Yup.string().required('Please fill in the field'),
		siblings: Yup.number().required('Please fill in the field'),
		religion: Yup.string().required('Please fill in the field'),
		enrollmentTypeId: Yup.number().required('Please choose a type'),
		feesId: Yup.string().required('Please fill in the field'),
		enrollmentDepositFeeId: Yup.string().required('Please fill in the field'),
		homeLanguage: Yup.string().required('Please fill in the field'),
		// klassId: Yup.string().required('Please fill in the field'),
		idNumber: Yup.string().required('Please fill in the field'),
	};
	useEffect(() => {
		getStudentEnrollmentTypes();
		setValidationScheme();
	}, [isCreateParent]);

	const setValidationScheme = () => {
		if (!isCreateParent && !studentId) {
			validationSchema.parent = Yup.object().shape({
				name: Yup.string().required('Please fill in the field'),
				mobileNumber: Yup.string().required('Please fill in the field'),
				email: Yup.string().required('Please fill in the field'),
				relationship: Yup.string().required('Please fill in the field'),
				idNumber: Yup.string().required('Please fill in the field'),
			});
		} else {
			validationSchema.parentId = Yup.string().required('Please fill in the field');
		}
		// validationSchema.parentId = Yup.string().required('Please fill in the field');
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const getStudentEnrollmentTypes = async () => {
		const { data } = await enrollmentTypesAPI.getEnrollmentTypes();
		// console.log(data);
		setStudentEnrollmentTypes(EnrollmentTypeOptions(data));
	};

	const handleSubmit = async (e) => {
		if (!studentId) {
			let parentId;
			if (e.dob) {
				e.dob = new Date(e.dob).toISOString();
			}
			if (e.parentId) {
				delete e.parent;
			}
			// if (e.frUserid) {
			// 	e.frUserid = Number(e.frUserid);
			// }
			if (Number(e.frUserid) === 0) e.frUserid = undefined;
			else e.frUserid = Number(e.frUserid);

			if (e.parent) {
				parentId = await handleParentSubmit(e.parent, e.schoolId);
				if (parentId) {
					e.parentId = parentId;
					delete e.parent;
				}
			}
			if (file && avatar && oldAvatar) {
				setLoading(true);
				try {
					const res = await deleteMedia(oldAvatar);
					if (res) {
						setLoading(false);
						e.avatarUrl = undefined;
					}
				} catch (e) {
					setLoading(false);
					console.log(e);
				}
			}
			if (e.parentId && prtAvfile) {
				e.parent = { avatarUrl: await uploadImg() };
			}
			if (file) {
				e.avatarUrl = await uploadImg(e.parentId);
			}

			if (e.frUserid) {
				e.frUserid = Number(e.frUserid);
			}
			try {
				setLoading(true);
				const res = await studentAPI.createStudent(e);
				if (res.success) {
					setLoading(false);
					swal('Success', 'You have added a student', 'success').then(() => {
						navigate('..', { relative: 'path', replace: true });
					});
				} else {
					setLoading(false);
					swal('Oops', 'Something went wrong!', 'error');
				}
			} catch (err) {
				console.log(err);
				setLoading(false);
				swal('Oops', 'Something went wrong!', 'error');
			}
		} else {
			try {
				// const url = new URL(avatar);
				// if (url.host) {
				// 	console.log(avatar);
				// 	await deleteMedia(avatar);
				// 	setAvatar(IMAGES.noimage);
				// }
				if (file && avatar && oldAvatar) {
					setLoading(true);
					try {
						const res = await deleteMedia(oldAvatar);
						if (res) {
							setLoading(false);
							e.avatarUrl = undefined;
						}
					} catch (e) {
						setLoading(false);
					}
				}
				if (e.parentId && prtAvfile) {
					e.parent.avatarUrl = await uploadImg();
				}
				if (file) {
					e.avatarUrl = await uploadImg(e.parentId);
				}

				if (e.frUserid && e.frUserid.length > 0 && !Number.isNaN(e.frUserid)) {
					e.frUserid = Number(e.frUserid);
				}
				const res = await studentAPI.updateStudent(e, studentId);
				if (res.success) {
					setLoading(false);
					swal('Success', 'You have updated successfully', 'success').then(() => {
						navigate('/student');
					});
				} else {
					swal('Oops', 'Something went wrong!', 'error');
				}
			} catch (err) {
				console.log(err);
				swal('Oops', 'Something went wrong!', 'error');
			}
		}
	};
	const handleParentSubmit = async (e, schoolId) => {
		e.schoolId = schoolId;
		try {
			e.avatarUrl = await uploadImg();
			const { data } = await studentAPI.createParent(e);
			return data.id;
		} catch (err) {
			console.log(err);
			swal('Oops', 'Something went wrong!', 'error');
		}
	};
	const uploadImg = async (id) => {
		let config;
		if (id) config = `school_${payload.schoolId}/parent_${id}/`;
		else config = `school_${payload.schoolId}/parent_avatar`;
		const res = await uploadMedia(id ? file : prtAvfile, config);
		console.log(res[0].url);
		return res[0].url;
	};

	return (
		<>
			<Formik
				initialValues={studentData ? initialValues : initialValues}
				enableReinitialize={studentId ? true : false}
				validationSchema={validation}
				onSubmit={handleSubmit}>
				{({
					values,
					errors,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					touched,
					setFieldValue,
					validateForm,
					setTouched,
				}) => {
					return (
						<>
							<div className='row'>
								<div className='col-xl-12'>
									<div className='card'>
										<div className='card-header'>
											<h5 className='mb-0'>Student Details</h5>
										</div>
										<div className='card-body'>
											<div className='row'>
												<div className='col-xl-3 col-lg-4'>
													<label className='form-label text-primary'>
														Photo<span className='required'>*</span>
														{!(file || avatar) && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																Photo is required
															</div>
														)}
													</label>
													<div className='avatar-upload'>
														<div className='avatar-preview'>
															<div
																id='imagePreview'
																style={{
																	backgroundImage: avatar
																		? 'url(' + avatar + ')'
																		: 'url(' + IMAGES.noimage + ')',
																}}></div>
														</div>
														<div className='change-btn mt-2 mb-lg-0 mb-3'>
															<input
																type='file'
																className='form-control d-none'
																onChange={fileHandler}
																id='imageUpload'
																accept='.png, .jpg, .jpeg'
															/>
															<label
																htmlFor='imageUpload'
																className='dlab-upload mb-0 btn btn-primary btn-sm'>
																Choose File
															</label>
														</div>
													</div>
												</div>
												<div className='col-xl-9 col-lg-8'>
													<div className='row'>
														<div className='col-xl-6 col-sm-6'>
															<div className='mb-3'>
																<label
																	htmlFor='exampleFormControlInput1'
																	className='form-label text-primary'>
																	Name<span className='required'>*</span>
																</label>
																<input
																	type='text'
																	className='form-control'
																	id='exampleFormControlInput1'
																	name='name'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.name}
																/>
																{errors?.name && touched.name && typeof errors.name === 'string' && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{ display: 'block' }}>
																		{errors.name}
																	</div>
																)}
															</div>
															<div className='mb-3'>
																<label className='form-label text-primary'>
																	Date of Birth
																	<span className='required'>*</span>
																</label>
																<div style={{ display: 'grid' }}>
																	<DatePicker
																		className='form-control'
																		selected={new Date(values.dob)}
																		// onChange={(e) => setFieldValue('dob', e)}
																		onSelect={(e) => setFieldValue('dob', e)}
																		onBlur={handleBlur}
																		name='dob'
																	/>
																	{errors?.dob && touched.dob && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.dob}
																		</div>
																	)}
																	{/* <input type="text" className="form-control w-50 ms-3" id="exampleFormControlInput7" placeholder="USA" /> */}
																</div>
															</div>
															{/* <div className='mb-3'>
															<label htmlFor='exampleFormControlInput3' className='form-label text-primary'>
																Email<span className='required'>*</span>
															</label>
															<input
																type='email'
																className='form-control'
																id='exampleFormControlInput3'
																placeholder='hello@example.com'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.email}
															/>
														</div> */}
															<div className='mb-3'>
																<label
																	htmlFor='exampleFormControlInput3'
																	className='form-label text-primary'>
																	Nationality<span className='required'>*</span>
																</label>
																<input
																	type='text'
																	className='form-control'
																	id='exampleFormControlInput3'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.nationality}
																	name='nationality'
																/>
																{errors?.nationality &&
																	touched.nationality &&
																	typeof errors.nationality === 'string' && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.nationality}
																		</div>
																	)}
															</div>
															<div className='mb-3'>
																<label
																	htmlFor='exampleFormControlTextarea1'
																	className='form-label text-primary'>
																	Address<span className='required'>*</span>
																</label>
																<textarea
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.address}
																	className='form-control'
																	id='exampleFormControlTextarea1'
																	rows='2'
																	name='address'
																/>
																{errors?.address &&
																	touched.address &&
																	typeof errors.address === 'string' && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.address}
																		</div>
																	)}
															</div>
														</div>
														<div className='col-xl-6 col-sm-6'>
															<div className='mb-3'>
																<label className='form-label text-primary'>
																	IC / Passport <span className='required'>*</span>
																</label>
																<input
																	type='text'
																	className='form-control'
																	name='idNumber'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.idNumber}
																/>
																{errors?.idNumber &&
																	touched.idNumber &&
																	typeof errors.idNumber === 'string' && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.idNumber}
																		</div>
																	)}
															</div>
															<div className='mb-3'>
																<label htmlFor='gender' className='form-label text-primary'>
																	Gender<span className='required'>*</span>
																</label>

																<Select
																	styles={customSelectStyle()}
																	options={data.gender}
																	onChange={(e) => setFieldValue('gender', e.value)}
																	onBlur={handleBlur}
																	value={data.gender.filter(({ value }) => value === values.gender)}
																	// defaultValue={values.gender}
																	// inputId='gender'
																	placeholder={'Select Gender'}
																/>
																{errors?.gender && touched.gender && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{ display: 'block' }}>
																		{errors.gender}
																	</div>
																)}
															</div>
															<div className='mb-3'>
																<label
																	htmlFor='exampleFormControlInput5'
																	className='form-label text-primary'>
																	Race<span className='required'>*</span>
																</label>

																<Select
																	inputId='race'
																	styles={customSelectStyle()}
																	options={data.race}
																	onChange={(e) => setFieldValue('race', e.value)}
																	onBlur={handleBlur}
																	value={data.race.filter(({ value }) => value === values.race)}
																	placeholder={'Select Race'}
																/>
																{errors?.race && touched.race && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{ display: 'block' }}>
																		{errors.race}
																	</div>
																)}
															</div>
															<div className='mb-3'>
																<label
																	htmlFor='exampleFormControlInput5'
																	className='form-label text-primary'>
																	Religion<span className='required'>*</span>
																</label>

																<Select
																	inputId='religion'
																	styles={customSelectStyle()}
																	options={data.religion}
																	onBlur={handleBlur}
																	value={data.religion.filter(({ value }) => value === values?.religion)}
																	onChange={(e) => setFieldValue('religion', e.value)}
																	placeholder={'Select Religion'}
																/>
																{errors?.religion && touched.religion && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{ display: 'block' }}>
																		{errors.religion}
																	</div>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-xl-12'>
									<div className='card'>
										<div className='card-header'>
											<h5 className='mb-0'>Enrolment Details</h5>
										</div>
										<div className='card-body'>
											<div className='row'>
												<div className='col-xl-6 col-sm-6'>
													{/* <div className='mb-3'>
														<label htmlFor='exampleFormControlInput8' className='form-label text-primary'>
															Enrollment Deposit Fee<span className='required'>*</span>
														</label>
														<input type='text' className='form-control' name='enrollmentFee' />
													</div> */}
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput8' className='form-label text-primary'>
															Class
														</label>
														<Select
															styles={customSelectStyle()}
															options={classList}
															onChange={(e) => setFieldValue('klassId', e.value)}
															onBlur={handleBlur}
															value={classList.filter(({ value }) => value === values.klassId)}
															inputId='klassId'
														/>
														{/* {errors?.klassId && touched.klassId && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																{errors.klassId}
															</div>
														)} */}
													</div>
													<div className='mb-3'>
														<label htmlFor='gender' className='form-label text-primary'>
															Enrolment Type<span className='required'>*</span>
														</label>

														<Select
															styles={customSelectStyle()}
															options={studentEnrollmentTypes}
															onChange={(e) => setFieldValue('enrollmentTypeId', Number(e.value))}
															onBlur={handleBlur}
															value={studentEnrollmentTypes.filter(
																({ value }) => value === Number(values.enrollmentTypeId)
															)}
															inputId='enrollmentTypeId'
														/>
														{errors?.enrollmentTypeId && touched.enrollmentTypeId && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																{errors.enrollmentTypeId}
															</div>
														)}
													</div>
												</div>
												<div className='col-xl-6 col-sm-6'>
													<div className='mb-3'>
														<label className='form-label text-primary'>Face Recognition ID</label>
														<input
															type='text'
															className='form-control'
															name='frUserid'
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.frUserid}
														/>
													</div>
												</div>
											</div>

											<hr />
											<div className='row'>
												<div className='col-xl-6 col-sm-6'>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput8' className='form-label text-primary'>
															Fee<span className='required'>*</span>
														</label>
														<Select
															styles={customSelectStyle()}
															options={monthlyFeeList}
															onChange={(e) => setFieldValue('feesId', e.value)}
															onBlur={handleBlur}
															value={monthlyFeeList.filter(({ value }) => value === values.feesId)}
															inputId='feesId'
														/>
														{errors?.feesId && touched.feesId && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																{errors.feesId}
															</div>
														)}
													</div>
												</div>
												<div className='col-xl-6 col-sm-6'>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput8' className='form-label text-primary'>
															Enrolment Deposit Fee
															<span className='required'>*</span>
														</label>
														<Select
															styles={customSelectStyle()}
															options={depositFeeList}
															onChange={(e) => setFieldValue('enrollmentDepositFeeId', e.value)}
															onBlur={handleBlur}
															value={depositFeeList.filter(
																({ value }) => value === values.enrollmentDepositFeeId
															)}
															inputId='enrollmentDepositFeeId'
														/>
														{errors?.enrollmentDepositFeeId && touched.enrollmentDepositFeeId && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																{errors.enrollmentDepositFeeId}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-xl-12'>
									<div className='card'>
										<div className='card-header'>
											<h5 className='mb-0'>Parents Details</h5>
											{!studentId && (
												<div>
													{isCreateParent ? (
														<div
															className=' d-flex align-items-center bg-light p-2 rounded pe-3 gap-2 c-pointer'
															onClick={() => {
																setFieldValue('parentId', undefined);
																setIsCreateParent(false);
															}}>
															<i className='material-symbols-outlined text-primary'>add_circle</i>
															<span className='text-primary'>Create New</span>
														</div>
													) : (
														<div
															className=' d-flex align-items-center bg-light rounded p-2 pe-3 gap-2 c-pointer'
															onClick={() => {
																setIsCreateParent(true);
															}}>
															<i className='material-symbols-outlined text-primary'>search</i>
															<span className='text-primary'>Search Parent</span>
														</div>
													)}
												</div>
											)}
										</div>
										<div className='card-body'>
											{!isCreateParent || studentId ? (
												<div className='row'>
													<div className='col-xl-3 col-lg-4'>
														<label className='form-label text-primary'>
															Photo<span className='required'>*</span>
															{((!isCreateParent && !prtAvfile) ||
																(studentData && !studentData?.student.parent?.avatarUrl)) && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{ display: 'block' }}>
																	Photo is required
																</div>
															)}
														</label>
														<div className='avatar-upload'>
															<div className='avatar-preview'>
																<div
																	id='imagePreview'
																	style={{
																		backgroundImage: parentAvatar
																			? 'url(' + parentAvatar + ')'
																			: 'url(' + IMAGES.noimage + ')',
																	}}></div>
															</div>
															<div className='change-btn mt-2 mb-lg-0 mb-3'>
																<input
																	type='file'
																	className='form-control d-none'
																	onChange={parentImage}
																	id='pavUpload'
																	accept='.png, .jpg, .jpeg'
																/>
																<label
																	htmlFor='pavUpload'
																	className='dlab-upload mb-0 btn btn-primary btn-sm'>
																	Choose File
																</label>
																{/* <Link
																to={'#'}
																className='btn btn-danger light remove-img ms-2 btn-sm'
																onClick={RemoveFile}>
																Remove
															</Link> */}
															</div>
														</div>
													</div>
													<div className='col-xl-9 col-lg-8'>
														<div className='row'>
															<div className='col-xl-6 col-sm-6'>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlInput8'
																		className='form-label text-primary'>
																		Name<span className='required'>*</span>
																	</label>
																	<input
																		type='text'
																		className='form-control'
																		name='parent.name'
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.parent?.name}
																	/>

																	{errors?.parent?.name &&
																		touched.parent?.name &&
																		typeof errors.parent?.name === 'string' && (
																			<div
																				className='invalid-feedback animated fadeInUp'
																				style={{ display: 'block' }}>
																				{errors.parent?.name}
																			</div>
																		)}
																</div>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlInput9'
																		className='form-label text-primary'>
																		Email<span className='required'>*</span>
																	</label>
																	<input
																		type='email'
																		className='form-control'
																		placeholder='hello@example.com'
																		name='parent.email'
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.parent?.email}
																	/>
																	{errors?.parent?.email &&
																		touched.parent?.email &&
																		typeof errors.parent?.email === 'string' && (
																			<div
																				className='invalid-feedback animated fadeInUp'
																				style={{ display: 'block' }}>
																				{errors.parent?.email}
																			</div>
																		)}
																</div>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlTextarea2'
																		className='form-label text-primary'>
																		Occupation
																	</label>
																	<input
																		type='text'
																		className='form-control'
																		name='parent.occupation'
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.parent?.occupation}
																	/>
																</div>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlTextarea2'
																		className='form-label text-primary'>
																		Work Address
																	</label>
																	<textarea
																		className='form-control'
																		id='exampleFormControlTextarea2'
																		rows='3'
																		name='parent.workAddress'
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.parent?.workAddress}
																	/>
																</div>
															</div>
															<div className='col-xl-6 col-sm-6'>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlInput11'
																		className='form-label text-primary'>
																		Phone Number
																		<span className='required'>*</span>
																	</label>
																	<input
																		type='text'
																		className='form-control'
																		id='exampleFormControlInput11'
																		placeholder='+123456789'
																		name='parent.mobileNumber'
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.parent?.mobileNumber}
																	/>
																	{errors?.parent?.mobileNumber &&
																		touched.parent?.mobileNumber &&
																		typeof errors.parent?.mobileNumber === 'string' && (
																			<div
																				className='invalid-feedback animated fadeInUp'
																				style={{ display: 'block' }}>
																				{errors.parent?.mobileNumber}
																			</div>
																		)}
																</div>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlTextarea2'
																		className='form-label text-primary'>
																		Identification Number
																		<span className='required'>*</span>
																	</label>
																	<input
																		type='text'
																		className='form-control'
																		id='exampleFormControlInput8'
																		name='parent.idNumber'
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.parent?.idNumber}
																	/>
																	{errors?.parent?.idNumber &&
																		touched.parent?.idNumber &&
																		typeof errors.parent?.idNumber === 'string' && (
																			<div
																				className='invalid-feedback animated fadeInUp'
																				style={{ display: 'block' }}>
																				{errors.parent?.idNumber}
																			</div>
																		)}
																</div>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlTextarea2'
																		className='form-label text-primary'>
																		Relationship
																		<span className='required'>*</span>
																	</label>
																	<Select
																		inputId='parent.relationship'
																		styles={customSelectStyle()}
																		options={data.relationship}
																		onBlur={handleBlur}
																		value={data.relationship.filter(
																			({ value }) => value === values.parent?.relationship
																		)}
																		onChange={(e) => setFieldValue('parent.relationship', e.value)}
																	/>
																	{errors?.parent?.relationship && touched.parent?.relationship && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.parent?.relationship}
																		</div>
																	)}
																</div>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlTextarea2'
																		className='form-label text-primary'>
																		Employer
																	</label>
																	<input
																		type='text'
																		className='form-control'
																		id='exampleFormControlInput8'
																		name='parent.employer'
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.parent?.employer}
																	/>
																</div>
																<div className='mb-3'>
																	<label
																		htmlFor='exampleFormControlTextarea2'
																		className='form-label text-primary'>
																		Work Contact Number
																	</label>
																	<input
																		type='text'
																		className='form-control'
																		id='exampleFormControlInput8'
																		name='parent.workPhone'
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.parent?.workPhone}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											) : (
												<div className='row'>
													<div className='col-xl-6 col-sm-6'>
														<Select
															inputId='parentId'
															placeholder='Search Parent'
															styles={customSelectStyle()}
															options={parentList}
															onBlur={handleBlur}
															value={parentList.filter(({ value }) => value === values.parentId)}
															onChange={(e) => setFieldValue('parentId', e.value)}
														/>
														{errors?.parentId && touched.parentId && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																{errors.parentId}
															</div>
														)}
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-xl-12'>
									<div className='card'>
										<div className='card-header'>
											<h5 className='mb-0'>Medical Details</h5>
										</div>
										<div className='card-body'>
											<div className='row'>
												<div className='col-xl-6 col-sm-6'>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput8' className='form-label text-primary'>
															Allergies
														</label>
														<input
															type='text'
															className='form-control'
															name='allergies'
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.allergies}
														/>
													</div>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput8' className='form-label text-primary'>
															Family Doctor Name
														</label>
														<input
															type='text'
															className='form-control'
															name='familyDoctorName'
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.familyDoctorName}
														/>
													</div>
												</div>
												<div className='col-xl-6 col-sm-6'>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput8' className='form-label text-primary'>
															Medical History
														</label>
														<input
															type='text'
															className='form-control'
															name='medicalHistory'
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.medicalHistory}
														/>
													</div>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput8' className='form-label text-primary'>
															Family Doctor Contact Number
														</label>
														<input
															type='text'
															className='form-control'
															name='familyDoctorContact'
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.familyDoctorContact}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-xl-12'>
									<div className='card'>
										<div className='card-header'>
											<h5 className='mb-0'>Other Details</h5>
										</div>
										<div className='card-body'>
											<div className='row'>
												<div className='col-xl-6 col-sm-6'>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput3' className='form-label text-primary'>
															No of Siblings<span className='required'>*</span>
														</label>
														<input
															type='number'
															className='form-control'
															onChange={handleChange}
															onBlur={handleBlur}
															value={Number(values.siblings)}
															name='siblings'
														/>
														{errors?.siblings && touched.siblings && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																{errors.siblings}
															</div>
														)}
													</div>

													<div className='mb-3'>
														<label className='form-label text-primary'>Info</label>
														<textarea
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.info}
															className='form-control'
															rows='2'
															name='info'
														/>
													</div>
												</div>
												<div className='col-xl-6 col-sm-6'>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput3' className='form-label text-primary'>
															Home Language<span className='required'>*</span>
														</label>
														<Select
															inputId='homeLanguage'
															placeholder='Search Language'
															styles={customSelectStyle()}
															options={data.language}
															onBlur={handleBlur}
															value={data.language.filter(({ value }) => value === values.homeLanguage)}
															onChange={(e) => setFieldValue('homeLanguage', e.value)}
														/>
														{errors?.homeLanguage && touched.homeLanguage && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																{errors.homeLanguage}
															</div>
														)}
													</div>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInput3' className='form-label text-primary'>
															Previous PreSchool
														</label>
														<input
															type='text'
															className='form-control'
															id='exampleFormControlInput3'
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.previousPreschool}
															name='previousPreschool'
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='d-flex justify-content-end '>
								<button
									className='btn btn-primary w-100'
									type='button'
									onClick={async (e) => {
										if ((file || avatar) && (isCreateParent ? true : prtAvfile !== undefined && prtAvfile !== null)) {
											handleSubmit(e);
										} else {
											let err = await validateForm();
											setTouched(err, true);
										}
									}}>
									Save
								</button>
							</div>
							<Modal size='sm' className='modal fade' show={loading} centered>
								<div className='modal-body' style={{ textAlign: 'center', padding: '50px' }}>
									<Spinner animation='border' variant='primary' />
								</div>
							</Modal>
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default AddNewStudent;
