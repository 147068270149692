import studentAPI from '../../../api/studentApi';
import classAPI from '../../../api/classApi';
import StaffApi from '../../../api/staffApi';

export async function getStaffList() {
	const arr = [];
	try {
		const { data } = await StaffApi.getStaffList();
		data.map((item, i) => {
			const obj = { label: item.name, value: item.id };
			arr.push(obj);
		});
		return arr;
	} catch (err) {
		console.log(err);
	}
}

