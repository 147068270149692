import progressReportAPI from '../../../../api/progressReportApi';

import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Student Name',
		Footer: 'Student Name',
		accessor: 'student.name',
	},
	{
		Header: 'Term',
		Footer: 'Term',
		accessor: 'term',
	},
	{
		Header: 'Report',
		Footer: 'Report',
		accessor: (row) => ({ studentName: row.student.name, term: row.term, reportUrl: row.reportUrl }),
		Cell: ({ value }) => {
			let { studentName, term, reportUrl } = value;
			return reportUrl === undefined ? (
				''
			) : (
				<a href={reportUrl} target='_blank' rel='noreferrer' download={`test.pdf`}>
					<i className='mdi mdi-24px mdi-download ' />
				</a>
			);
		},
	},
	{
		Header: 'Action',
	},
];

export async function getReports(klassId, term) {
	try {
		let res = await progressReportAPI.progressReports(klassId, term);
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
	}
}
