import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Outlet, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

/// Css
import './index.css';
import './chart.css';
import './step.css';

/// Layout
import Nav from './layouts/nav';
import Nav2 from './layouts/nav/index2';
import Footer from './layouts/Footer';
import ScrollToTop from './layouts/ScrollToTop';
// import Main from './layouts/Main';
import WalletBar from './layouts/WalletBar';
/// Dashboard
import Home from './components/Dashboard/Home';
import Finance from './components/Dashboard/Finance';
import DashboardDark from './components/Dashboard/DashboardDark';

//student
import Students from './components/Student/Students';
import StudentDetails from './components/Student/StudentDetails';
import AddNewStudent from './components/Student/AddNewStudent';

//Teacher
import Staff from './components/Staff/Staff';
import StaffDetail from './components/Staff/StaffDetail';
import AddNewStaff from './components/Staff/AddNewStaff';

// Birthdays
import Birthdays from './components/Birthday/Birthdays';

// Holidays
import Holidays from './components/Holidays/Holidays';

// Absentees
import Absent from './components/Absent/Absent';

// News
import News from './components/News/News';
import AddNewNews from './components/News/AddNewNews';

// Transport
import Transport from './components/Transport/Transport';

// Medication
import Medication from './components/Medication/Medication';

//InjuryReport
import InjuryReport from './components/InjuryReport/InjuryReport';

// ProgressReport
import ProgressReport from './components/ProgressReport/ProgressReport';

// Leave Management
import LeaveManagement from './components/Leave Management/LeaveManagement';

// Finance
import Ledger from './components/Finance/ledger/LedgerList';
import Payroll from './components/Finance/payroll/Payroll';
import Billing from './components/Finance/billing/Billing';
import Claims from './components/Finance/claims/Claims';
import Payout from './components/Finance/payout/Payout';

//Food
import Food from './components/Food/Food';
import FoodDetails from './components/Food/FoodDetails';
import Class from './components/Class/Class';

/// File Manager
import FileManager from './components/FileManager/FileManager';
import User from './components/FileManager/User';
import HomeCalendar from './components/FileManager/HomeCalendar';
import Activity from './components/FileManager/Activity';
import FileChat from './components/FileManager/FileChat';

// Admin

import Admin from './components/Admin/Admin';
import Notification from './components/Admin/Notification';

//Weekly Task
import WeeklyTask from './components/WeeklyTask/WeeklyTask';
import AddNewTask from './components/WeeklyTask/AddNewTask';
/// App
import AppProfile from './components/AppsMenu/AppProfile/AppProfile';
import PostDetails from './components/AppsMenu/AppProfile/PostDetails';
import EditProfile from './components/AppsMenu/AppProfile/EditProfile';
import Compose from './components/AppsMenu/Email/Compose/Compose';
import Inbox from './components/AppsMenu/Email/Inbox/Inbox';
import Read from './components/AppsMenu/Email/Read/Read';
import Calendar from './components/AppsMenu/Calendar/Calendar';

/// Product List
import ProductGrid from './components/AppsMenu/Shop/ProductGrid/ProductGrid';
import ProductList from './components/AppsMenu/Shop/ProductList/ProductList';
import ProductDetail from './components/AppsMenu/Shop/ProductGrid/ProductDetail';
import ProductOrder from './components/AppsMenu/Shop/ProductOrder';
import Checkout from './components/AppsMenu/Shop/Checkout/Checkout';
import Invoice from './components/AppsMenu/Shop/Invoice/Invoice';
import Customers from './components/AppsMenu/Shop/Customers/Customers';

/// Charts
import SparklineChart from './components/charts/Sparkline';
import ChartJs from './components/charts/Chartjs';
import RechartJs from './components/charts/rechart';
import ApexChart from './components/charts/apexcharts';

/// Bootstrap
import UiAlert from './components/bootstrap/Alert';
import UiAccordion from './components/bootstrap/Accordion';
import UiBadge from './components/bootstrap/Badge';
import UiButton from './components/bootstrap/Button';
import UiModal from './components/bootstrap/Modal';
import UiButtonGroup from './components/bootstrap/ButtonGroup';
import UiListGroup from './components/bootstrap/ListGroup';
import UiCards from './components/bootstrap/Cards';
import UiCarousel from './components/bootstrap/Carousel';
import UiDropDown from './components/bootstrap/DropDown';
import UiPopOver from './components/bootstrap/PopOver';
import UiProgressBar from './components/bootstrap/ProgressBar';
import UiTab from './components/bootstrap/Tab';
import UiPagination from './components/bootstrap/Pagination';
import UiGrid from './components/bootstrap/Grid';
import UiTypography from './components/bootstrap/Typography';

/// Plugins
import Select2 from './components/PluginsMenu/Select2/Select2';
// import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from './components/PluginsMenu/SweetAlert/SweetAlert';
import Toastr from './components/PluginsMenu/Toastr/Toastr';
// import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from './components/PluginsMenu/Lightgallery/Lightgallery';

/// Widget
import Widget from './pages/Widget';

/// Table
import SortingTable from './components/table/SortingTable/SortingTable';
import FilteringTable from './components/table/FilteringTable/FilteringTable';
import BootstrapTable from './components/table/BootstrapTable';

/// Form
import Element from './components/Forms/Element/Element';
import Wizard from './components/Forms/Wizard/Wizard';
import CkEditor from './components/Forms/CkEditor/CkEditor';
import Pickers from './components/Forms/Pickers/Pickers';
import FormValidation from './components/Forms/FormValidation/FormValidation';

/// Pages

import LockScreen from './pages/LockScreen';
import Error400 from './pages/Error400';
import Error403 from './pages/Error403';
import Error404 from './pages/Error404';
import Error500 from './pages/Error500';
import Error503 from './pages/Error503';
import StudentAttendance from './components/Attendance/StudentAttendance';
import TeacherAttendance from './components/Attendance/TeacherAttendance';

import { ThemeContext } from '../context/ThemeContext';
import useACL from '../services/ACL';
import AssignClass from './components/Class/AssignClass';
import AddNewHoliday from './components/Holidays/AddNewHoliday';
import Devices from './components/Settings/Devices/Devices';
import EnrollmentType from './components/Settings/EnrollmentType/EnrollmentType';
import FeesSetup from './components/Settings/Fees/FeesSetup';
import PaymentGateway from './components/Settings/PaymentGateway/PaymentGateway';
import RejectionReasonsList from './components/Settings/RejectionReasons/RejectionReasonsList';
import SchoolSetup from './components/Settings/School/SchoolSetup';
import FoodMenu from './components/FoodMenu/FoodMenu';
import CreateMenu from './components/FoodMenu/CreateMenu/CreateMenu';
import ChatHistory from './components/Chats/ChatHistory';
import TimeTable from './components/TimeTable/class/TimeTable';
import AddTimeTable from './components/TimeTable/class/AddTimeTable';
import CoCurriculumTimeTable from './components/TimeTable/co-curriculum/CoCurriculumTimeTable';
import CoCurriculumAddTimeTable from './components/TimeTable/co-curriculum/CoCurriculumAddTimeTable';
import EditTaskPage from './components/WeeklyTask/EditTaskPage';
import ViewTask from './components/WeeklyTask/ViewTask';
import Evaluation from './components/Evaluation/Evaluation';
import Subscription from './components/Admin/Subscription';
// import TimeTable from './components/TimeTable/co-curriculum/TimeTable';

const Markup = () => {
	const [route, setRoute] = useState([]);
	const routhPath = [
		{ url: 'finance', component: <Finance />, title: 'finances' },
		{ url: 'student', component: <Students />, title: 'student' },
		{
			url: '/student/details/:studentId',
			component: <StudentDetails />,
			title: 'student',
		},
		{
			url: '/student/add-student',
			component: <AddNewStudent />,
			title: 'student',
		},
		{
			url: '/student/edit-student/:studentId',
			component: <AddNewStudent />,
			title: 'student',
		},
		{ url: 'staff', component: <Staff />, title: 'staff' },
		{ url: 'staff/details/:staffId', component: <StaffDetail />, title: 'staff' },
		{ url: 'add-staff', component: <AddNewStaff />, title: 'staff' },
		{ url: 'staff/edit-staff/:staffId', component: <AddNewStaff />, title: 'staff' },
		{ url: 'admin', component: <Admin />, title: 'admin' },
		{ url: 'admin/notification', component: <Notification />, title: 'admin' },
		{ url: 'admin/subscription', component: <Subscription />, title: 'admin' },

		{ url: 'attendance/student-attendance', component: <StudentAttendance />, title: 'attendance' },
		{ url: 'attendance/staff-attendance', component: <TeacherAttendance />, title: 'attendance' },
		{ url: 'class', component: <Class />, title: 'class' },
		{ url: 'weekly-task', component: <WeeklyTask />, title: 'tasks' },
		{ url: 'weekly-task/add-task', component: <EditTaskPage />, title: 'tasks' },
		{ url: 'weekly-task/edit-task/:teacher_id/:date', component: <EditTaskPage />, title: 'tasks' },
		{ url: 'weekly-task/view-task/:teacher_id/:date', component: <ViewTask />, title: 'tasks' },
		{ url: 'class/assign/:classId', component: <AssignClass />, title: 'class' },
		{ url: 'user', component: <User />, title: 'attendance' },
		// { url: 'attendance', component: <Attendance />, title: 'attendance' },
		{ url: 'activity', component: <Activity />, title: 'activity' },
		{ url: 'calendar', component: <HomeCalendar />, title: 'finance' },
		//Finance
		{ url: '/finance/ledger', component: <Ledger />, title: 'finance' },
		{ url: '/finance/payroll', component: <Payroll />, title: 'finance' },
		{ url: '/finance/billing', component: <Billing />, title: 'finance' },
		{ url: '/finance/claims', component: <Claims />, title: 'finance' },
		{ url: '/finance/payout-pending', component: <Payout />, title: 'finance' },
		//App Profile
		{ url: 'app-profile', component: <AppProfile />, title: 'profile' },
		{ url: 'post-details', component: <PostDetails />, title: 'details' },
		{ url: 'edit-profile', component: <EditProfile />, title: 'test' },
		{ url: 'app-calender', component: <Calendar />, title: 'test' },
		// Leave Management
		{ url: 'leave-management', component: <LeaveManagement />, title: 'leave' },
		{ url: 'leave-history', component: <LeaveManagement historical={true} />, title: 'leave' },

		// News
		{ url: 'news', component: <News />, title: 'news' },
		{ url: 'news/add-news', component: <AddNewNews />, title: 'news' },
		{ url: 'news/edit-news/:newsId', component: <AddNewNews />, title: 'news' },

		// Absentees
		{ url: 'absent', component: <Absent />, title: 'attendance' },

		// Transport
		{ url: 'transport', component: <Transport />, title: 'student' },

		// Medication
		{ url: 'medication', component: <Medication />, title: 'medication' },

		// Progress Report
		{ url: 'report-card', component: <ProgressReport />, title: 'progressreport' },

		// Chat History
		{ url: 'chat-history', component: <ChatHistory />, title: 'chat' },

		// Holidays
		{ url: 'birthdays', component: <Birthdays />, title: 'student' },
		{ url: 'holidays', component: <Holidays />, title: 'holidays' },
		{
			url: '/holidays/add-holiday',
			component: <AddNewHoliday />,
			title: 'holidays',
		},
		// Settings
		{ url: 'settings/devices', component: <Devices />, title: 'settings' },
		{ url: 'settings/enrollment-type', component: <EnrollmentType />, title: 'settings' },
		{ url: 'settings/fees-setup', component: <FeesSetup />, title: 'settings' },
		{ url: 'settings/payment-gateway-setup', component: <PaymentGateway />, title: 'settings' },
		{ url: 'settings/rejection-reasons', component: <RejectionReasonsList />, title: 'settings' },
		{ url: 'settings/school-setup', component: <SchoolSetup />, title: 'settings' },
		// Form Validation
		{
			url: 'form-validation',
			component: <FormValidation />,
			title: 'test',
		},
		{ url: 'food', component: <FoodMenu />, title: 'holidays' },
		{ url: 'food/create-menu', component: <CreateMenu />, title: 'holidays' },
		{ url: 'injury-report', component: <InjuryReport />, title: 'health' },
		{ url: 'timetable/class', component: <TimeTable />, title: 'timetable' },
		{ url: 'timetable/class/add', component: <AddTimeTable />, title: 'timetable' },
		{ url: 'timetable/co-curriculum', component: <CoCurriculumTimeTable />, title: 'timetable' },
		{ url: 'timetable/co-curriculum/add', component: <CoCurriculumAddTimeTable />, title: 'timetable' },
		{ url: 'timetable/co-curriculum/edit/:curriculumId', component: <CoCurriculumAddTimeTable />, title: 'timetable' },

		// Evaluation
		{ url: 'evaluation', component: <Evaluation />, title: 'evaluation' },
	];

	useEffect(() => {
		// checkAutoLogin(dispatch, navigate);
		CheckRoute();
	}, []);

	const CheckRoute = () => {
		const payload = JSON.parse(sessionStorage.getItem('payload'));
		const checkACL = useACL(payload.acl);
		const arr = [];
		routhPath.forEach((item) => {
			if (item.title && checkACL(item.title)) {
				arr.push(item);
			}
		});
		setRoute(arr);
	};

	return (
		<>
			<Routes>
				<Route path='/page-error-400' element={<Error400 />} />
				<Route path='/page-error-403' element={<Error403 />} />
				<Route path='/page-error-404' element={<Error403 />} />
				<Route path='/page-error-500' element={<Error500 />} />
				<Route path='/page-error-503' element={<Error503 />} />
				<Route path='/page-lock-screen' element={<LockScreen />} />
				<Route element={<Layout2 />}>
					<Route exact strict path='/' element={<Home />} />
					<Route exact path='/dashboard' element={<Home />} />
				</Route>
				<Route element={<Layout2 />}>
					{route.map((data, i) => {
						return <Route key={i} exact path={`/${data.url}`} element={data.component} />;
					})}
				</Route>
				{/* <Route element={<Layout5 />}>
					<Route path='/file-manager' exact element={<FileManager />} />
					<Route path='/chat' exact element={<FileChat/>} />
					<Route path='/email-compose' exact element={<Compose />} />
					<Route path='/email-inbox' exact element={<Inbox />} />
					<Route path='/email-read' exact element={<Read />} />
				</Route> */}
				{/* <Route element={<Layout6 />}>
					{routhPath2.map((data, i) => (
						<Route key={i} exact path={`/${data.url}`} element={data.component} />
					))}
				</Route> */}
			</Routes>
			<ScrollToTop />
		</>
	);
};

function Layout1() {
	const { sidebariconHover } = useContext(ThemeContext);
	const sideMenu = useSelector((state) => state.sideMenu);
	let windowsize = window.innerWidth;
	// console.log(windowsize, 'size')
	return (
		<div id='main-wrapper' className={` show  ${sidebariconHover ? 'iconhover-toggle' : ''} ${sideMenu ? 'menu-toggle' : ''}`}>
			<div className={`wallet-open`}>
				<Nav2 />
				<div className='content-body' style={{ minHeight: window.screen.height + 20 }}>
					<div className='container-fluid'>
						<Outlet />
					</div>
				</div>
				<Footer changeFooter='footer-outer' />
				<WalletBar />
			</div>
		</div>
	);
}

function Layout2() {
	const sideMenu = useSelector((state) => state.sideMenu);
	const { sidebariconHover } = useContext(ThemeContext);
	return (
		<div id='main-wrapper' className={`show ${sidebariconHover ? 'iconhover-toggle' : ''} ${sideMenu ? 'menu-toggle' : ''}`}>
			<Nav />
			<div className='content-body' style={{ minHeight: window.screen.height + 20 }}>
				<div className='container-fluid'>
					<Outlet />
				</div>
			</div>
			<Footer changeFooter='out-footer style-2' />
		</div>
	);
}

function Layout5() {
	const sideMenu = useSelector((state) => state.sideMenu);
	const { sidebariconHover } = useContext(ThemeContext);
	return (
		<div id='main-wrapper' className={`show ${sidebariconHover ? 'iconhover-toggle' : ''} ${sideMenu ? 'menu-toggle' : ''}`}>
			<Nav />
			<div className='content-body message-body mh-auto'>
				<div className='container-fluid mh-auto p-0'>
					<Outlet />
				</div>
			</div>
		</div>
	);
}
function Layout6() {
	const sideMenu = useSelector((state) => state.sideMenu);
	const { sidebariconHover } = useContext(ThemeContext);
	return (
		<div id='main-wrapper' className={`show ${sidebariconHover ? 'iconhover-toggle' : ''} ${sideMenu ? 'menu-toggle' : ''}`}>
			<Nav />
			<div className='content-body'>
				<div className='container-fluid'>
					<Outlet />
				</div>
			</div>
			<Footer changeFooter='out-footer style-1' />
		</div>
	);
}

export default Markup;
