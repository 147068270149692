import React, { useRef, useState, useEffect, useMemo } from "react";
import { COLUMNS, getNewsList } from "./component/tableData";
import { Link, useNavigate } from "react-router-dom";
import BasicModal from "../Dashboard/BasicModal";
import newsAPI from "../../../api/newsApi";
import {
  useTable,
  useGlobalFilter,
  usePagination,
} from "react-table";
import swal from "sweetalert";
import EmptyState from "../components/EmptyState";
import Pagination from "../components/Pagination";

const News = () => {
  const childRef = useRef();
  const [newsList, setNewsList] = useState([]);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => newsList, [newsList]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const list = await getNewsList();
    setNewsList(list);
  };

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    setPageSize,
  } = tableInstance;
  const { globalFilter, pageIndex, pageSize } = state;

  const deleteNews = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      className: "swal-border-danger",
    }).then(async (yes) => {
      if (yes) {
        try {
          const res = await newsAPI.deleteNews(id);
          if (res.success) {
            swal("News has been deleted!", {
              icon: "success",
            });
            getList();
          }
        } catch (err) {
          console.log(err);
          swal("Oops", "Something went wrong!", "error");
        }
      } else {
        swal("News deletion is cancelled");
      }
    });
  };

  let navigate = useNavigate();
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-title  shadow-sm flex-wrap">
                <div className="input-group search-area mb-md-0 mb-3">
                  <input
                    type="text"
                    className=" form-control"
                    value={globalFilter || ""}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search here..."
                  />
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                          fill="#01A3FF"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => navigate("/news/add-news")}
                  >
                    + New News
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-12 wow fadeInUp " data-wow-delay="1.5s">
              {newsList.length ? (
                <div className="table-responsive full-data">
                  <div
                    id="example-student_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      {...getTableProps()}
                      className="shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => {
                          return (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                  {column.render("Header")}
                                </th>
                              ))}
                            </tr>
                          );
                        })}
                      </thead>
                      <tbody {...getTableBodyProps()} className="">
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell, index) => {
                                if (cell.column.id.toLowerCase() === "action") {
                                  return (
                                    <td key={index}>
                                      <div className="d-flex">
                                        <div
                                          className="icon-box icon-box-sm bg-light me-2"
                                          onClick={() =>
                                            navigate(
                                              "/news/edit-news/" +
                                                row.original.id,
                                              {
                                                state: newsList.filter(
                                                  (v) =>
                                                    v.id === row.original.id
                                                ),
                                              }
                                            )
                                          }
                                        >
                                          <i
                                            className="material-symbols-outlined"
                                            style={{
                                              fontSize: 8,
                                              color: "#4D44B5",
                                            }}
                                          >
                                            edit
                                          </i>
                                        </div>
                                        <div
                                          className="icon-box icon-box-sm bg-light me-2"
                                          onClick={() =>
                                            deleteNews(row.original.id)
                                          }
                                        >
                                          <i
                                            className="material-symbols-outlined"
                                            style={{
                                              fontSize: 8,
                                              color: "#4D44B5",
                                            }}
                                          >
                                            delete
                                          </i>
                                        </div>
                                      </div>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td key={index} {...cell.getCellProps()}>
                                      {cell.value ? cell.render("Cell") : "-"}
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <Pagination
                      nextPage={nextPage}
                      previousPage={previousPage}
                      canNextPage={canNextPage}
                      canPreviousPage={canPreviousPage}
                      pageIndex={pageIndex}
                      pageOptions={pageOptions}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      gotoPage={gotoPage}
                      pageCount={pageCount}
                    />
                  </div>
                </div>
              ) : (
                <EmptyState />
              )}
            </div>
          </div>
        </div>
      </div>
      <BasicModal ref={childRef} />
    </>
  );
};

export default News;
