import React from 'react';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import blackBoard from '../../../images/class/blackboard.svg';
import defaultImg from '../../../images/avatar/blankProfile.png';

import './style/class.css';
import classAssignmentApi, { ClassData, StudentInfo, TeacherInfo } from '../../../api/classAssignmentApi';
import { Col, Container, Nav, Row, Tab, TabContainer } from 'react-bootstrap';
import swal from 'sweetalert';
import * as dateFn from 'date-fns';

type DragSource = {
	id: number;
	source: string;
	person: StudentInfo | TeacherInfo;
};

const onImageError = ({ currentTarget }: any) => {
	(currentTarget as HTMLImageElement).src = defaultImg;
};

const AssignClass = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [all, setAll] = useState<ClassData>();

	const getAge = useCallback((dob: Date) => {
		return dateFn.differenceInCalendarYears(new Date(), new Date(dob));
	}, []);

	const getClassData = useCallback(async (classId: number): Promise<ClassData | undefined> => {
		try {
			const res = await classAssignmentApi.getAllForClass(classId);
			if (res.success) {
				// console.log('Set All:', res.data);
				setAll(res.data);
				return res.data;
			} else throw new Error(res.error);
		} catch (error) {
			console.log((error as Error).message);
		}
	}, []);

	const dndSource = useRef<DragSource>();

	useEffect(() => {
		getClassData(Number(params.classId));
	}, []);

	const assignStudent = useCallback((id: number, all: ClassData | undefined) => {
		console.log(all);
		if (!all) return;
		const student = all.unassignedStudents?.find((a) => a.id === id);
		if (!student) {
			console.log('not found');
			return;
		}
		console.log('Assigning Student');
		all.classInfo.students.push(student);
		all.unassignedStudents.forEach((u, i) => {
			if (u.id === id) all.unassignedStudents.splice(i, 1);
		});
		setAll({ ...all });
	}, []);

	const assignTeacher = useCallback((id: number, all: ClassData | undefined) => {
		if (!all) return;
		const teacher = all.unassignedTeachers?.find((a) => a.id === id);
		if (!teacher) {
			console.log('not found');
			return;
		}
		console.log('Assigning Teacher');
		all.classInfo.teachers.push(teacher);
		all.unassignedTeachers.forEach((u, i) => {
			if (u.id === id) all.unassignedTeachers.splice(i, 1);
		});
		setAll({ ...all });
	}, []);

	const unassignStudent = useCallback((id: number, all: ClassData | undefined) => {
		console.log(`Abount to unassign student ${id}`);
		console.log('All:', JSON.stringify(all, null, 4));
		if (!all) return;
		const student = all.classInfo.students.find((a) => a.id === id);
		if (!student) {
			console.log('not found');
			return;
		}
		console.log(`Unassigning ${student?.name} from ${all?.classInfo.name}`);
		if (!student) return;
		all.unassignedStudents.push(student);
		all.classInfo.students.forEach((u, i) => {
			if (u.id === id) all.classInfo.students.splice(i, 1);
		});
		setAll({ ...all });
	}, []);

	const unassignTeacher = useCallback((id: number, all: ClassData | undefined) => {
		if (!all) return;
		const teacher = all.classInfo.teachers.find((a) => a.id === id);
		if (!teacher) {
			console.log('not found');
			return;
		}
		console.group(`Unassigning ${teacher?.name} from ${all?.classInfo.name}`);
		all.unassignedTeachers.push(teacher);
		all.classInfo.teachers.forEach((u, i) => {
			if (u.id === id) all.classInfo.teachers.splice(i, 1);
		});
		setAll({ ...all });
	}, []);

	const dragStart = useCallback((obj: DragSource | undefined = undefined) => {
		if (obj) dndSource.current = { ...obj };
	}, []);

	const allowDrop = useCallback((e: React.DragEvent<HTMLElement>) => {
		e.preventDefault();
	}, []);

	const drop = useCallback(
		(e: React.DragEvent<HTMLElement>) => {
			if (dndSource.current) {
				const person = dndSource.current.person;
				if (dndSource.current.source === 'unassigned') {
					if ('dob' in person) assignStudent(person.id, all);
					else assignTeacher(person.id, all);
				} else {
					if ('dob' in person) unassignStudent(person.id, all);
					else unassignTeacher(person.id, all);
				}
			}
		},
		[all]
	);

	const submit = useCallback(async () => {
		console.log('Submit', all);
		if (!all?.classInfo) return;
		const ids = [...all.classInfo.students, ...all.classInfo.teachers].map((p) => p.id);
		const res = await classAssignmentApi.updateAssignments(Number(params.classId), ids);
		if (res.success) {
			setAll(res.data);
			await swal({
				title: 'Success',
				text: 'Class updated successfully',
				icon: 'success',
			});
			navigate(`/class`);
		}
	}, [all]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className=' mt-2 mb-2 flex align-self-end ' onClick={submit}>
				<button className='btn btn-primary'>Submit</button>
			</div>
			<div className=' flex-1 p-2 ' style={{ background: '#EFEEFC' }} onDragOver={(e) => allowDrop(e)} onDrop={(e) => drop(e)}>
				<TabContainer defaultActiveKey={'Teachers'}>
					<div className='border-0 pb-0'>
						<Nav as='ul' className='classnav classnav-tabs class-tabs justify-content-center'>
							<Nav.Item as='li'>
								<Nav.Link eventKey={'Teachers'} style={{ fontSize: 15 }}>
									Teachers
								</Nav.Link>
							</Nav.Item>
							<Nav.Item as='li' className='nav-item'>
								<Nav.Link eventKey={'Students'} style={{ fontSize: 15 }}>
									Students
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>

					<Tab.Content>
						<Tab.Pane eventKey={'Teachers'}>
							<div style={{ display: 'flex', gap: 10, margin: 10, overflow: 'scroll' }}>
								{all?.unassignedTeachers.map((d, i) => (
									<div
										key={d.id}
										style={{
											background: 'white',
											padding: 10,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											borderRadius: 10,
											minWidth: 100,
											minHeight: 100,
										}}
										draggable
										onDragStart={() => dragStart({ id: d.id, source: 'unassigned', person: d })}>
										<img
											src={d.avatarUrl ? d.avatarUrl : defaultImg}
											alt='profile'
											style={{
												width: '50px',
												objectFit: 'cover',
											}}
											className='rounded-circle'
											onError={onImageError}
										/>
										<span
											style={{
												color: '#0C092A',
												fontSize: 12,
												fontWeight: '500',
												marginTop: 5,
											}}>
											{d.name.split(' ')[0]}
										</span>
										{<span style={{ fontSize: 11, color: 'transparent' }}>Age: </span>}
									</div>
								))}
							</div>
						</Tab.Pane>
						<Tab.Pane eventKey={'Students'}>
							<div style={{ display: 'flex', gap: 10, margin: 10, overflow: 'scroll' }}>
								{all?.unassignedStudents.map((d, i) => (
									<div
										key={d.id}
										style={{
											background: 'white',
											padding: 10,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											borderRadius: 10,
											minWidth: 100,
											minHeight: 100,
										}}
										draggable
										onDragStart={() => dragStart({ id: d.id, source: 'unassigned', person: d })}>
										<img
											src={d.avatarUrl ? d.avatarUrl : defaultImg}
											alt='profile'
											style={{
												width: '50px',
												objectFit: 'cover',
												marginBottom: 5,
											}}
											className='rounded-circle'
											onError={onImageError}
										/>
										<span
											style={{
												color: '#0C092A',
												fontSize: 12,
												fontWeight: '500',
											}}>
											{d.name.split(' ')[0]}
										</span>
										{'dob' in d && <span style={{ fontSize: 11 }}>Age: {getAge(d.dob)}</span>}
									</div>
								))}
							</div>
						</Tab.Pane>
					</Tab.Content>
					<div style={{ padding: 20, paddingBottom: 0 }}>
						<p style={{ textAlign: 'center' }}>+ Drag & Drop kids here to remove them from classroom</p>
					</div>
				</TabContainer>
			</div>
			<div style={{ backgroundColor: 'white', padding: 20 }} onDragOver={allowDrop} onDrop={(e) => drop(e)}>
				<Row>
					<Col sm='4' lg='3'>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<img src={blackBoard} alt='blackboard' />

							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{all?.classInfo?.teachers.map((item) => (
									<div style={{ position: 'relative', marginBottom: 20 }}>
										<div
											key={item.id}
											className='rounded-circle'
											style={{
												width: 82,
												height: 82,
												border: '1px solid #333333B2',
												marginLeft: 50,
												marginBottom: 10,
											}}
											draggable
											onDragStart={() => dragStart({ id: item.id, source: 'assigned', person: item })}>
											<img
												src={item.avatarUrl ? item.avatarUrl : defaultImg}
												alt='profile'
												style={{
													width: 80,
													height: 80,
													objectFit: 'cover',
												}}
												className='rounded-circle'
												onError={onImageError}
											/>
										</div>
										<span
											data-toggle='tooltip'
											data-placement='bottom'
											title={item.name}
											style={{
												position: 'absolute',
												zIndex: 99,
												right: 0,
												bottom: 0,
												maxWidth: 100,
												backgroundColor: '#333333B2',
												borderRadius: 20,
												color: '#D6D6D6',
												fontSize: 12,
												fontWeight: '500',
												padding: '2px 10px',
												border: '1px solid white',
											}}
											className='text-truncate'>
											{item.name}
										</span>
									</div>
								))}
							</div>
						</div>
					</Col>
					<Col sm='8' lg='9' className=' p-10'>
						<Container>
							<Row>
								{all?.classInfo.students.map((item) => (
									<Col
										key={item.id}
										style={{ position: 'relative', maxWidth: 115, marginBottom: 20 }}
										draggable
										onDragStart={() => dragStart({ id: item.id, source: 'assigned', person: item })}>
										<div
											className='rounded-circle'
											style={{
												width: 82,
												height: 82,
												border: '1px solid #333333B2',
											}}>
											<img
												src={item.avatarUrl ? item.avatarUrl : defaultImg}
												alt='profile'
												style={{
													width: 80,
													height: 80,
													objectFit: 'cover',
													pointerEvents: 'none',
												}}
												className='rounded-circle'
												onError={onImageError}
											/>
										</div>
										<span
											data-toggle='tooltip'
											data-placement='bottom'
											title={item.name}
											style={{
												position: 'absolute',
												zIndex: 99,
												right: 0,
												bottom: 0,
												maxWidth: 70,
												backgroundColor: '#333333B2',
												borderRadius: 20,
												color: '#D6D6D6',
												fontSize: 12,
												fontWeight: '500',
												padding: '2px 10px',
												border: '1px solid white',
											}}
											className='text-truncate'>
											{item.name}
										</span>
									</Col>
								))}
							</Row>
						</Container>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default AssignClass;
