import apiHandler from './apiHandler';

export type CreateReasonProps = {
	name: string;
	reasons: string;
};

export type Reason = {
	schoolId: number;
	name: string;
	reason: string;
};

export async function getRejectionList(): Promise<Reason[]> {
	return await apiHandler.get(`/settings/rejectionReasons`);
}

export async function createReason(postData: CreateReasonProps) {
	return await apiHandler.post('/settings/rejectionReasons', postData);
}

export async function deleteReason(name: string) {
	return await apiHandler.delete('/settings/rejectionReasons?name=' + name);
}

const rejectionReasonsApi = {
	getRejectionList,
	createReason,
	deleteReason,
};

export default rejectionReasonsApi;
