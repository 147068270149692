import axios from 'axios';
import swal from 'sweetalert';
import { loginConfirmedAction, Logout } from '../store/actions/AuthActions';
import apiHandler from '../api/apiHandler';

export function signUp(email, password) {
	//axios call
	const postData = {
		email,
		password,
		returnSecureToken: true,
	};
	return axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`, postData);
}

export function login(email, password) {
	const CryptoJS = require('crypto-js');
	const postData = {
		userId: email,
		password: CryptoJS.MD5(password).toString(),
		// returnSecureToken: true,
	};
	// console.log(postData);
	// return axios.post(
	// 	'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII',
	// 	postData
	// );
	// return axios.post('https://edubricks.ddns.net/api/auth/login', postData);
	return apiHandler.post('/auth/login', postData);
}

export function formatError(errorResponse) {
	switch (errorResponse.error) {
		case 'EMAIL_EXISTS':
			//return 'Email already exists';
			swal('Oops', 'Email already exists', 'error');
			break;
		case 'EMAIL_NOT_FOUND':
			//return 'Email not found';
			swal('Oops', 'Email not found', 'error', { button: 'Try Again!' });
			break;
		case 'INVALID_PASSWORD':
			//return 'Invalid Password';
			swal('Oops', 'Invalid Password', 'error', { button: 'Try Again!' });
			break;
		case 'USER_DISABLED':
			return 'User Disabled';
		default:
			return '';
	}
}

export function saveTokensInLocalStorage(tokensDetails) {
	// tokenDetails.expireDate = new Date(new Date().getTime() + tokenDetails.expiresIn * 1000);
	sessionStorage.setItem('token', tokensDetails.accessToken);
	sessionStorage.setItem('refresh', tokensDetails.refreshToken);
}
export function savePayloadInLocalStorage(payload) {
	sessionStorage.setItem('payload', JSON.stringify(payload));
}

export function getPayload() {
	const payload = JSON.parse(sessionStorage.getItem('payload'));
	return payload;
}

export function runLogoutTimer(dispatch, timer, navigate) {
	setTimeout(() => {
		//dispatch(Logout(history));
		// dispatch(Logout(navigate));
	}, timer);
}

export function checkAutoLogin(dispatch, navigate) {
	const pathname = window.location.pathname;
	if (pathname === '/enrolment') {
		return;
	}
	const tokenDetailsString = sessionStorage.getItem('token');
	let tokenDetails = '';
	if (!tokenDetailsString) {
		dispatch(Logout(navigate));
		return;
	}

	// tokenDetails = JSON.parse(tokenDetailsString);
	// let expireDate = new Date(tokenDetails.expireDate);
	// let todaysDate = new Date();

	// if (todaysDate > expireDate) {
	// 	dispatch(Logout(navigate));
	// 	return;
	// }

	// dispatch(loginConfirmedAction(tokenDetails));

	// const timer = expireDate.getTime() - todaysDate.getTime();
	// runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
	const tokenDetailsString = sessionStorage.getItem('token');
	if (tokenDetailsString) {
		return true;
	} else {
		return false;
	}
}
