import { id } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import financeAPI from '../../../../../api/financeApi';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';

const paymentType = ['FPX', 'DUITNOW', 'CARD', 'EWALLET', 'CASH', 'CHEQUE', 'UNKNOWN'];
const refType = ['Expenses', 'PettyCash', 'Miscelanious'];

export default function ExpenditureModal(props) {
	const { show, toggleClose } = props;
	const [editedData, setEditedData] = useState({
		paymentType: paymentType[0],
		ref: '-',
		refType: refType[0],
		amount: 0,
		description: '',
		time: new Date(),
	});
	// load transaction value if props.transactionId is defined
	const getTransactionData = async () => {
		if (props.transactionId) {
			try {
				const expenditure = await financeAPI.getExpenditureById(props.transactionId);
				console.log(expenditure);
				setEditedData({
					paymentType: expenditure.data.paymentType,
					ref: expenditure.data.ref,
					refType: expenditure.data.refType,
					amount: -expenditure.data.amount,
					description: expenditure.data.description,
					// time: new Date(),
					time: new Date(expenditure.data.date * 1000),
				});
			} catch (err) {
				console.log(err);
			}
		} else {
			setEditedData({
				paymentType: paymentType[0],
				ref: '-',
				refType: refType[0],
				amount: 0,
				description: '',
				time: new Date(),
			});
		}
	};
	useEffect(() => {
		console.log('Modal use effect');
		getTransactionData();
	}, [show]);

	const submitExpenditure = async () => {
		try {
			let description = editedData.description ? editedData.description : '-';
			let timestamp = Math.floor(editedData.time.getTime() / 1000);
			let res = await financeAPI.postExpenditure(
				description,
				editedData.amount,
				editedData.refType,
				editedData.ref,
				editedData.paymentType,
				timestamp
			);
			props.setRefresh();
			if (res.success) {
				swal('Created Expenditure', 'Expendtiure has been created', 'success');
				return res;
			} else {
				swal('Error', 'Cant Create Expenditure', 'error');
				return res.error;
			}
		} catch (err) {
			return { success: false };
		}
	};

	const updateExpenditure = async () => {
		try {
			let description = editedData.description ? editedData.description : '-';
			let timestamp = Math.floor(editedData.time.getTime() / 1000);
			let res = await financeAPI.updateExpenditureById(
				props.transactionId,
				description,
				editedData.amount,
				editedData.refType,
				editedData.ref,
				editedData.paymentType,
				timestamp
			);
			props.setRefresh();
			if (res.success) {
				swal('Updated Expenditure', 'Expendtiure has been updated', 'success');
				return res;
			} else {
				swal('Error', 'Cant Update Expenditure', 'error');
				return res.error;
			}
		} catch (err) {
			return { success: false };
		}
	};

	return (
		<Modal
			show={show}
			onHide={() => {
				props.clearTransactionId();
				toggleClose();
			}}
			backdrop='static'
			centered>
			<Modal.Header closeButton>
				<Modal.Title>{props.transactionId ? 'Edit Expenditure' : 'Create New Expenditure'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='add-contact-box'>
					<div className='add-contact-content'>
						<div className='row'>
							<div className='col-12'>
								<div className='form-group mb-3'>
									<label className='text-black font-w500'>
										Amount<i className='text-red'>*</i>
									</label>
									<div className='contact-occupation'>
										<input
											type='number'
											value={Number(editedData.amount).toString()}
											autoComplete='off'
											onChange={(e) => setEditedData({ ...editedData, amount: Number(e.target.value) })}
											name='classname'
											required='required'
											className='form-control'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-12'>
								<div className='form-group mb-3'>
									<label className='text-black font-w500'>
										Date<i className='text-red'>*</i>
									</label>
									<div className='contact-occupation'>
										<DatePicker
											dateFormat='dd/MM/yyyy'
											wrapperClassName='w-100'
											className='form-control'
											selected={editedData.time}
											onChange={(date) => {
												setEditedData({
													...editedData,
													time: date,
												});
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-12'>
								<div className='form-group mb-3'>
									<label className='text-black font-w500'>Reference No.</label>
									<div className='contact-occupation'>
										<input
											type='text'
											autoComplete='off'
											value={editedData.ref}
											onChange={(e) => setEditedData({ ...editedData, ref: String(e.target.value) })}
											name='classname'
											className='form-control'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='form-group mb-3'>
							<label className='text-black font-w500'>Payment Type</label>
							<div className='contact-occupation'>
								<select
									className='form-select'
									style={{
										backgroundColor: 'white',
										color: '#303a6f',
										borderColor: '#dddddd',
									}}
									value={editedData.paymentType}
									onChange={(e) => setEditedData({ ...editedData, paymentType: e.target.value })}>
									{paymentType.map((type, index) => {
										return <option key={index}>{type}</option>;
									})}
								</select>
							</div>
						</div>
						<div className='form-group mb-3'>
							<label className='text-black font-w500'>Ref Type</label>
							<div className='contact-occupation'>
								<select
									className='form-select'
									style={{
										backgroundColor: 'white',
										color: '#303a6f',
										borderColor: '#dddddd',
									}}
									value={editedData.refType}
									onChange={(e) => setEditedData({ ...editedData, refType: e.target.value })}>
									{refType.map((ref, index) => {
										return <option key={index}>{ref}</option>;
									})}
								</select>
							</div>
						</div>
						<div className='form-group mb-3'>
							<label className='text-black font-w500'>Description</label>
							<div className='contact-occupation'>
								<textarea
									className='form-control'
									value={editedData.description}
									onChange={(e) => setEditedData({ ...editedData, description: e.target.value })}></textarea>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={() => {
						props.clearTransactionId();
						toggleClose();
					}}>
					Discard
				</Button>
				<Button
					variant='primary'
					onClick={async () => {
						// check if value is > 0
						if (editedData.amount <= 0) {
							swal('Input Error ', 'Amount must be larger than zero', 'error');
						} else if (!props.transactionId) {
							// create mode
							await submitExpenditure();
							props.clearTransactionId();
							toggleClose();
						} else {
							// update mode
							await updateExpenditure();
							props.clearTransactionId();
							toggleClose();
						}
					}}>
					Submit
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
