import leaveAPI from '../../../../api/leaveApi';
import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Name',
		Footer: 'Name',
		accessor: 'person.name',
	},
	{
		Header: 'Start Date',
		Footer: 'Start Date',
		accessor: 'dateFrom',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'End Date',
		Footer: 'End Date',
		accessor: 'dateTo',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Days',
		Footer: 'Days',
		accessor: 'days',
	},
	{
		Header: 'Annual Remaining',
		Footer: 'Annual Remaining',
		accessor: 'annualRemaining',
	},
	{
		Header: 'MC Remaining',
		Footer: 'MC Remaining',
		accessor: 'mcRemaining',
	},
	{
		Header: 'Prorated',
		Footer: 'Prorated',
		accessor: 'prorated',
	},
	{
		Header: 'Type',
		Footer: 'Type',
		accessor: 'type',
	},
	{
		Header: 'Reason',
		Footer: 'Reason',
		accessor: 'reason',
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
	},
	{
		Header: 'Action',
		Footer: 'Action',
	},
];

export async function getLeaveList(historical) {
	try {
		let res;
		if (historical) {
			res = await leaveAPI.leaveList();
			if (res.success) {
				let data = Array.isArray(res.data) ? res.data : [res.data];
				data = data.filter((data) => new Date(data.dateFrom).getTime() < moment().startOf('day').toDate().getTime());
				data = data.map((data) => {
					const from = moment(new Date(data.dateFrom));
					const to = moment(new Date(data.dateTo));
					const days = to.diff(from, 'days') + 1;
					return { ...data, days };
				});
				return data;
			} else {
				return [];
			}
		} else {
			res = await leaveAPI.upcomingLeaveList();
			if (res.success) {
				let data = Array.isArray(res.data) ? res.data : [res.data];
				data = data.map((data) => {
					const from = moment(new Date(data.dateFrom));
					const to = moment(new Date(data.dateTo));
					const days = to.diff(from, 'days') + 1;
					return {
						...data,
						days,
						prorated: `${data.prorated ? data.prorated : '0'}`,
						annualRemaining: `${data.annualRemaining}/${data.annualAllocation}`,
						mcRemaining: `${data.mcRemaining}/${data.medicalAllocation}`,
					};
				});
				return data;
			} else return [];
		}
	} catch (err) {
		console.log(err);
	}
}
