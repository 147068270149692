import { number, string } from 'yup';
import { formatParams } from '../services/general';
import apiHandler from './apiHandler';
import * as dateFn from 'date-fns';

export enum FeeType {
	Deposit = 'Deposit',
	Monthly = 'Monthly',
	Adhoc = 'Adhoc',
	Other = 'Other',
}

export function feeList(type: FeeType) {
	return apiHandler.get(`/finance/fee/${type}`);
}

//  Ledger

export async function ledgerList(date?: Date) {
	return apiHandler.get(`/finance/ledger${formatParams({ date })}`);
}

export async function postExpenditure(
	description: string,
	amount: number,
	refType: string,
	ref: string,
	paymentType: string,
	timestamp: number
) {
	return apiHandler.post(`/finance/ledger`, {
		description,
		amount: -amount,
		refType,
		ref,
		paymentType,
		timestamp,
	});
}

export async function deleteExpenditure(transactionId: string) {
	return apiHandler.delete(`/finance/ledger/transactionId?transactionId=` + transactionId);
}

export async function getExpenditureById(transactionId: string) {
	return apiHandler.get(`/finance/ledger/transactionId?transactionId=${transactionId}`);
}

export async function updateExpenditureById(
	transactionId: string,
	description: string,
	amount: number,
	refType: string,
	ref: string,
	paymentType: string,
	timestamp: number
) {
	return apiHandler.patch(`/finance/ledger/transactionId`, {
		transactionId,
		description,
		amount: -amount,
		refType,
		ref,
		paymentType,
		timestamp,
	});
}

//  PAYROLL
export async function payrollList(date?: string) {
	return apiHandler.get(`/finance/payroll${formatParams({ monthEnding: date })}`);
}

export async function editPayroll(monthYear: string, staffId: number, data: object) {
	return apiHandler.put(`/finance/payroll/${monthYear}/${staffId}`, data);
}

export async function prepare() {
	return apiHandler.post(`/finance/payroll`, { monthEnding: new Date() });
}

export async function runPayroll(date?: Date) {
	return apiHandler.patch(`/finance/payroll`, { monthEnding: date ?? new Date() });
}

//  Billing
export async function billingList(date?: string) {
	return apiHandler.get(`/finance/billing${formatParams({ date })}`);
}

type PayInvoice = {
	invoiceId: string;
	description?: string;
	ref?: string;
	amount: number;
	paymentType: string;
	refType: string;
};

type Adhoc = {
	parent_id: number;
	list: {
		student_id: number;
		feeType: 'Adhoc';
		description: string;
		amount: number;
	}[];
};

export async function billingPay(data: PayInvoice) {
	return apiHandler.put(`/finance/billing`, { ...data });
}

export async function payoutList(date?: string) {
	return apiHandler.get(`/finance/payouts/staff/${date}`);
}
export async function adhocBilling(data: Adhoc) {
	return apiHandler.post(`/finance/billing/adhoc`, { ...data });
}

const financeAPI = {
	feeList,
	ledgerList,
	payrollList,
	prepare,
	runPayroll,
	editPayroll,
	billingList,
	billingPay,
	adhocBilling,
	postExpenditure,
	deleteExpenditure,
	getExpenditureById,
	updateExpenditureById,
	payoutList,
};

export default financeAPI;
