import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { Modal } from 'react-bootstrap';
import EmptyState from '../components/EmptyState';
import Pagination from '../components/Pagination';
import { getReport } from './component/tableData';
import { COLUMNS } from './component/tableData';

const InjuryReport = () => {
	const [injuryReporList, setInjuryReportList] = useState([]);
	const [showImgModal, setShowImgModal] = useState(undefined);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => injuryReporList, [injuryReporList]);

	useEffect(() => {
		getList();
	}, []);

	const getList = async () => {
		const list = await getReport();
		setInjuryReportList(list);
	};
	const tableInstance = useTable({ columns, data }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;

	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title  shadow-sm flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
							</div>
						</div>

						<div className='col-xl-12 wow fadeInUp ' data-wow-delay='1.5s'>
							{injuryReporList.length ? (
								<div className='table-responsive full-data'>
									<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer '>
											<thead>
												{headerGroups.map((headerGroup) => {
													return (
														<tr {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column) => (
																<th
																	style={{
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		whiteSpace: 'nowrap',
																		minWidth: '2rem',
																		maxWidth: '7rem',
																	}}
																	{...column.getHeaderProps()}>
																	{column.render('Header')}
																</th>
															))}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row) => {
													prepareRow(row);

													return (
														<tr {...row.getRowProps()}>
															{row.cells.map((cell) => {
																if (cell.column.id.toLowerCase() === 'image') {
																	if (row.original.media.length > 0) {
																		return (
																			<td className='py-3 flex-row'>
																				{row.original.media.map((item, i) => (
																					<img
																						width={50}
																						height={50}
																						style={{ objectFit: 'contain', marginRight: 10 }}
																						src={item.url}
																						alt=''
																						key={i}
																						onClick={() => setShowImgModal(item.url)}
																					/>
																				))}{' '}
																			</td>
																		);
																	} else {
																		return (
																			<td className='py-3'>
																				{' '}
																				-{/* <img src={cell.value} className="img-fluid" /> */}
																			</td>
																		);
																	}
																} else if ((row, cell.column.id.toLowerCase() === 'acknowledged')) {
																	return (
																		<td {...cell.getCellProps()} className='py-3'>
																			{cell.value ? cell.render('Cell') : ''}
																		</td>
																	);
																} else {
																	return (
																		<td {...cell.getCellProps()} className='py-3'>
																			{cell.value ? cell.render('Cell') : '-'}
																		</td>
																	);
																}
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
									</div>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal
				size='xl'
				backdrop='static'
				show={showImgModal !== undefined}
				onHide={() => {
					setShowImgModal(undefined);
				}}>
				<Modal.Header closeButton>
					<h4>Image Viewer</h4>
				</Modal.Header>
				<Modal.Body>
					<div className='container'>
						<img style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt='' src={showImgModal} />
					</div>
				</Modal.Body>
			</Modal>
			{/* setShowImgModal */}
		</>
	);
};
export default InjuryReport;
