import { add } from 'date-fns';
import apiHandler from './apiHandler';

export function createStaff(postData: any) {
	return apiHandler.post('/staff', postData);
}

export function getStaffList() {
	return apiHandler.get('/staff');
}

export function getSingleStaff(id: number) {
	return apiHandler.get(`/staff/${id}`);
}

export function deleteStaff(id: number) {
	return apiHandler.delete(`/staff/${id}`);
}

export function updateStaff(id: number, postData: any) {
	return apiHandler.put(`/staff/${id}`, postData);
}

export function addFiling(id: number, postData: any) {
	return apiHandler.post(`/staff/filing/${id}`, postData);
}

const StaffApi = {
	createStaff,
	getStaffList,
	getSingleStaff,
	deleteStaff,
	updateStaff,
	addFiling,
};

export default StaffApi;
