import apiHandler from './apiHandler';

enum LeaveStatus {
	Pending = 'Pending',
	Approved = 'Approved',
	Rejected = 'Rejected',
	Cancelled = 'Cancelled',
}

type AcknowledgeLeaveRequest = {
	acknowledgment: true;
	personId: number;
	dateFrom: string | Date;
	status: LeaveStatus;
	rejectReason?: string;
};

export async function leaveList() {
	return await apiHandler.get('/app/leave');
}

export async function upcomingLeaveList() {
	return await apiHandler.get('/app/leave?upcoming=1');
}

export async function approve(personId: number, dateFrom: Date) {
	// console.log({ acknowledgement: true, personId, dateFrom, status: LeaveStatus.Approved });
	return await apiHandler.put('/app/leave', { acknowledgement: true, personId, dateFrom, status: LeaveStatus.Approved });
}

export async function reject(personId: number, dateFrom: Date, rejectReason?: string) {
	return await apiHandler.put('/app/leave', { acknowledgement: true, personId, dateFrom, status: LeaveStatus.Rejected, rejectReason });
}

export async function cancel(personId: number, dateFrom: Date, reason?: string) {
	return await apiHandler.put('/app/leave', { acknowledgement: true, personId, dateFrom, status: LeaveStatus.Cancelled, reason });
}

export async function deleteLeave(personId: number, dateFrom: Date | string) {
	return await apiHandler.delete('/app/leave?personId=' + personId + '&dateFrom=' + dateFrom);
}

const leaveAPI = {
	leaveList,
	upcomingLeaveList,
	approve,
	reject,
	cancel,
	deleteLeave,
};

export default leaveAPI;
