import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { IMAGES } from '../Dashboard/Content';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import data from '../../data.json';
import { customSelectStyle } from '../../../services/general';
import holidayAPI from '../../../api/holidayApi';
import swal from 'sweetalert';
import useMediaServer from '../../../services/useMediaServer';
// import useMediaServer from 'services/useMediaServer';
import moment from 'moment';

const AddNewHoliday = () => {
	const navigate = useNavigate();
	const [file, setFile] = useState(null);
	const [validation, setValidation] = useState({});
	const payload = JSON.parse(sessionStorage.getItem('payload'));

	const { uploadMedia, deleteMedia } = useMediaServer();

	const fileHandler = (e) => {
		setFile(e.target.files);
	};

	const initialValues = {
		startDate: new Date(),
		name: '',
		endDate: new Date(),
		type: '',
	};

	useEffect(() => {
		const validationSchema = {
			name: Yup.string().required('Please fill in the field'),
			startDate: Yup.string().required('Please fill in the field'),
			endDate: Yup.string().required('Please fill in the field'),
			type: Yup.string().required('Please fill in the field'),
		};

		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	}, []);

	const handleSubmit = async (e) => {
		console.log(e);
		e.startDate = moment(new Date(e.startDate)).format('YYYY-MM-DD');
		e.endDate = moment(new Date(e.endDate)).format('YYYY-MM-DD');

		try {
			const res = await holidayAPI.createHoliday({ type: e.type, name: e.name, startDate: e.startDate, endDate: e.endDate });
			console.log(res);
			if (res.success) {
				swal('Success', 'You have added a holiday', 'success').then(() => {
					navigate('..', { relative: 'path', replace: true });
				});
			} else {
				swal('Oops', 'Something went wrong!', 'error');
			}
		} catch (err) {
			console.log(err);
			swal('Oops', 'Something went wrong!', 'error');
		}
	};

	const uploadImg = async (id) => {
		const config = `school_${payload.schoolId}/parent_${id}/`;

		const res = await uploadMedia(file, config);

		return res[0].url;
		// axios.post('https://media.ocu-NavbarCollapse.com', formData, config);
	};
	return (
		<>
			<Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validation} onSubmit={handleSubmit}>
				{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
					return (
						<>
							<div className='row'>
								<div className='col-xl-12'>
									<div className='card'>
										<div className='card-header'>
											<h5 className='mb-0'>New Holiday</h5>
										</div>
										<div className='card-body'>
											<div className='row'>
												<div className='col-xl-9 col-lg-8'>
													<div className='row'>
														<div className='col-xl-6 col-sm-6'>
															<div className='mb-3'>
																<label
																	htmlFor='exampleFormControlInput1'
																	className='form-label text-primary'
																>
																	Name<span className='required'>*</span>
																</label>
																<input
																	type='text'
																	className='form-control'
																	id='exampleFormControlInput1'
																	name='name'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	defaultValue={values.name}
																/>
																{errors?.name && typeof errors.name === 'string' && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{ display: 'block' }}
																	>
																		{errors.name}
																	</div>
																)}
															</div>
															<div className='mb-3'>
																<label className='form-label text-primary'>
																	Start Date<span className='required'>*</span>
																</label>
																<div style={{ display: 'grid' }}>
																	<DatePicker
																		className='form-control'
																		selected={new Date(values.startDate)}
																		onSelect={(e) => setFieldValue('startDate', new Date(e))}
																		onBlur={handleBlur}
																		name='startDate'
																	/>
																	{errors?.startDate && touched.startDate && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}
																		>
																			{errors.startDate}
																		</div>
																	)}
																</div>
															</div>
															<div className='mb-3'>
																<label className='form-label text-primary'>
																	End Date<span className='required'>*</span>
																</label>
																<div style={{ display: 'grid' }}>
																	<DatePicker
																		className='form-control'
																		selected={new Date(values.endDate)}
																		onSelect={(e) => setFieldValue('endDate', new Date(e))}
																		onBlur={handleBlur}
																		name='endDate'
																	/>
																	{errors?.endDate && touched.endDate && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}
																		>
																			{errors.endDate}
																		</div>
																	)}
																</div>
															</div>
															<div className='mb-3'>
																<label htmlFor='type' className='form-label text-primary'>
																	Holiday Type<span className='required'>*</span>
																</label>

																<Select
																	styles={customSelectStyle()}
																	options={data.holidayType}
																	onChange={(e) => setFieldValue('type', e.value)}
																	onBlur={handleBlur}
																	value={data.holidayType.filter(({ value }) => value === values.type)}
																	inputId='type'
																	placeholder={'Select holiday type'}
																/>
																{errors?.type && touched.type && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{ display: 'block' }}
																	>
																		{errors.type}
																	</div>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='d-flex justify-content-end '>
								<button className='btn btn-primary w-100' type='button' onClick={handleSubmit}>
									Save
								</button>
							</div>
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default AddNewHoliday;
