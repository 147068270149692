import {
	LOADING_TOGGLE_ACTION,
	LOGIN_CONFIRMED_ACTION,
	LOGIN_FAILED_ACTION,
	LOGOUT_ACTION,
	LOGIN_BLOCKED_ACTION,
	SIGNUP_CONFIRMED_ACTION,
	SIGNUP_FAILED_ACTION,
} from '../actions/AuthActions';

const initialState = {
	auth: {
		email: '',
		personId: '',
		role: '',
		name: '',
		accessToken: '',
	},
	errorMessage: '',
	successMessage: '',
	showLoading: false,
	blocked: false,
	blockedError: '',
};

export function AuthReducer(state = initialState, action) {
	if (action.type === SIGNUP_CONFIRMED_ACTION) {
		return {
			...state,
			auth: action.payload,
			errorMessage: '',
			successMessage: 'Signup Successfully Completed',
			showLoading: false,
		};
	}
	if (action.type === LOGIN_CONFIRMED_ACTION) {
		return {
			...state,
			auth: action.payload,
			errorMessage: '',
			successMessage: 'Login Successfully Completed',
			showLoading: false,
		};
	}

	if (action.type === LOGOUT_ACTION) {
		return {
			...state,
			errorMessage: '',
			successMessage: '',
			auth: {
				email: '',
				idToken: '',
				localId: '',
				expiresIn: '',
				refreshToken: '',
			},
		};
	}

	if (action.type === SIGNUP_FAILED_ACTION || action.type === LOGIN_FAILED_ACTION) {
		return {
			...state,
			errorMessage: action.payload,
			successMessage: '',
			showLoading: false,
		};
	}

	if (action.type === LOGIN_BLOCKED_ACTION) {
		return {
			...state,
			blocked: action.payload.blocked,
			blockedError: action.payload.error,
		};
	}

	if (action.type === LOADING_TOGGLE_ACTION) {
		return {
			...state,
			showLoading: action.payload,
		};
	}
	return state;
}
