import { useState } from "react";
import { Image, Modal } from "react-bootstrap";

const Advance = (props) => {
    const { image, subject, book_name, page } = props;
    const [show, setShow] = useState(false);
    return (
        <>
            <div className="row mt-2">
                <div className="col-4">
                    <div className="flex-column d-flex col">
                        <label className="text-grey font-w500">Subject</label>
                        <label className="text-black font-w500 fs-6" style={{ marginTop: -5 }}>
                            {subject}
                        </label>
                    </div>
                </div>
                <div className="col-4">
                    <div className="flex-column d-flex col">
                        <label className="text-grey font-w500">Book Name</label>
                        <label className="text-black font-w500 fs-6" style={{ marginTop: -5 }}>
                            {book_name}
                        </label>
                    </div>
                </div>
                <div className="col-4">
                    <div className="flex-column d-flex col">
                        <label className="text-grey font-w500">Page</label>
                        <label className="text-black font-w500 fs-6" style={{ marginTop: -5 }}>
                            {page}
                        </label>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 d-flex justify-content-center">
                    <Image
                        src={image}
                        rounded
                        fluid
                        onClick={() => {
                            setShow(true);
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>
            <Modal
                centered
                size="xl"
                show={show}
                onHide={() => {
                    setShow(false);
                }}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="col-12 d-flex justify-content-center">
                        <Image src={image} rounded fluid />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Advance;
