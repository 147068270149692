'use client';

import stripeSubscriptionApi, { StripeSubscription } from '../api/stripeSubscriptionApi';
import React, { ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { Logout } from '../store/actions/AuthActions';

type SubscriptionContext =
	| {
			setSubscription: React.Dispatch<SetStateAction<StripeSubscription | undefined>>;
			subscription?: StripeSubscription;
	  }
	| undefined
	| null;

type SubscriptionContextProviderProps = {
	children: ReactNode;
};

const SubscriptionContext = createContext<SubscriptionContext>(undefined);

export default function SubscriptionContextProvider({ children }: SubscriptionContextProviderProps) {
	const [subscription, setSubscription] = useState<StripeSubscription | undefined>(undefined);

	return (
		<SubscriptionContext.Provider
			value={{
				setSubscription,
				subscription,
			}}
		>
			{children}
		</SubscriptionContext.Provider>
	);
}

export function useSubscription() {
	const context = useContext(SubscriptionContext);
	if (!context || context === null) throw new Error('useSubscriptionmust be used inside a SubscriptiopnContextProvider');
	useEffect(() => {
		if (context.subscription && context.subscription.status) {
			// alert(JSON.stringify(context.subscription));
			if (
				!(
					context.subscription.status === 'active' ||
					context.subscription.status === 'trialing' ||
					context.subscription.headCount === 0
				)
			)
				Logout('/');
		}
	}, [context.subscription]);

	useEffect(() => {
		if (null === sessionStorage.getItem('token')) return;

		console.log('useEffect', context.subscription, context.subscription?.status);
		if (!context.subscription?.status) stripeSubscriptionApi.get().then((sub) => context.setSubscription(sub));
	}, []);

	return context;
}
