import apiHandler from './apiHandler';

export type StripeSubscription = {
	subscriptionId: string;
	headCount: number;
	status?: string;
	cancelAt?: number;
};

export async function get(): Promise<StripeSubscription> {
	return await apiHandler.get(`/useradmin/stripe/subscription`);
}

export async function cancel(): Promise<boolean> {
	return await apiHandler.delete(`/useradmin/stripe/subscription`);
}

export async function renew(subscription: StripeSubscription): Promise<boolean> {
	return await apiHandler.post(`/useradmin/stripe/subscription`, subscription);
}

export async function updateHeadCount(subscription: StripeSubscription): Promise<boolean> {
	return await apiHandler.put(`/useradmin/stripe/subscription`, subscription);
}

const stripeSubscriptionApi = {
	get,
	cancel,
	renew,
	updateHeadCount,
};

export default stripeSubscriptionApi;

declare global {
	interface Number {
		isBetween: (a: number, b: number) => boolean;
	}
}

Number.prototype.isBetween = function (a: Number, b: Number): boolean {
	return a <= this && this <= b;
};
