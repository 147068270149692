import apiHandler from './apiHandler';

export async function holidayList() {
	return await apiHandler.get('/holidays');
}

export async function holidayListByDate(date: string) {
	return await apiHandler.get('/holidays?date=' + date);
}

export async function holidayListByName(name: string) {
	return await apiHandler.get('/holidays?name=' + name);
}

export async function deleteHoliday(date: string, name: string) {
	return await apiHandler.delete('/holidays?date=' + date + '&name=' + name);
}

type CreateHolidayRequest = {
	name: string;
	startDate: string;
	endDate: string;
	type: string;
};

export function createHoliday(data: CreateHolidayRequest | CreateHolidayRequest[]) {
	return apiHandler.post('/holidays', data);
}

const holidayAPI = {
	holidayList,
	holidayListByDate,
	holidayListByName,
	deleteHoliday,
	createHoliday,
};

export default holidayAPI;
