import apiHandler from './apiHandler';

export async function getEvaluation(date: string, classId?: number) {
	return await apiHandler.get(`/evaluation?date=${date}&class=${classId}`);
}

const evaluationApi = {
	getEvaluation,
};

export default evaluationApi;
