import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';

const apiHandler = axios.create({
	baseURL: process.env.REACT_APP_HOST + '/api',
	timeout: 10 * 1000, // 10 seconds
});

export const getJWTDecode = (token: string) => {
	var decoded = jwtDecode(token);
	return decoded;
};

const requestHandler = async (config: InternalAxiosRequestConfig) => {
	const token = sessionStorage.getItem('token');
	const refreshToken = sessionStorage.getItem('refresh');
	const payload = sessionStorage.getItem('payload');

	if (token) {
		if (payload) {
			const schoolId = JSON.parse(payload).schoolId;
			config.headers.schoolId = schoolId;
		}

		config.headers.systemid = 'edubricks';

		const refresh = getJWTDecode(token);
		const expiry = refresh.exp;

		if (!expiry) {
			config.headers.Authorization = `Bearer ${token}`;
			return config;
		}

		if (Number(expiry) > Date.now() / 1000) {
			config.headers.Authorization = `Bearer ${token}`;
			return config;
		}

		try {
			const host = process.env.REACT_APP_HOST ?? 'https://edubricks.ocu-napse.com';
			const { data } = await axios.post(host + '/api/auth/refresh', {
				token: refreshToken,
			});
			if (data.accessToken) {
				sessionStorage.setItem('token', data.accessToken);
				config.headers.authorization = `Bearer ${data.accessToken}`;
			}
		} catch (error) {
			sessionStorage.clear();
			console.log('im in catch api handler');
			console.log(error);
		}
	} else {
		sessionStorage.clear();
		console.log('im in else api handler');
		// throw new Error('not logged in');
	}
	return config;
};

const responseHandler = (response: AxiosResponse) => {
	if (response?.request?.responseURL?.includes('auth/login')) {
		return response;
	}
	return response?.data;
};

const responseErrorHandler = async (error: AxiosError) => {
	if (error?.response?.status === 401 || error?.response?.status === 403) {
		window.location.href = '/';
	}
	if (error?.response?.status === 406 || error?.response?.status === 408) {
		throw error;
	}
	return error?.response?.data;
};

apiHandler.interceptors.request.use(requestHandler);
apiHandler.interceptors.response.use(responseHandler, responseErrorHandler);

export default apiHandler;
