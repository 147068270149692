// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timetable:hover {
    transform: scaleX(1.1);
    border-radius: 0.8rem;
    transition: transform  0.8s;
}

.unselectable {
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/css/timetable.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".timetable:hover {\n    transform: scaleX(1.1);\n    border-radius: 0.8rem;\n    transition: transform  0.8s;\n}\n\n.unselectable {\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
