import React, { useRef, useState, useEffect, useMemo } from 'react';
import { COLUMNS, getStudentList } from './component/tableData';
import { Link, useNavigate } from 'react-router-dom';
import { IMAGES } from '../Dashboard/Content';
import { Dropdown } from 'react-bootstrap';
import BasicModal from '../Dashboard/BasicModal';
import studentAPI from '../../../api/studentApi';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import swal from 'sweetalert';
import EmptyState from '../components/EmptyState';
import Pagination from '../components/Pagination';
import { useSubscription } from '../../../context/SubscriptionContextProvider';
import dashboardApi from '../../../api/dashboardApi';

const Students = () => {
	const childRef = useRef();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const [unchecked, setUnChecked] = useState(true);

	const [studentList, setStudentList] = useState([]);
	const [checked, setChecked] = useState([]);
	const [totalPeople, setTotalPeople] = useState(0);

	const columns = useMemo(() => COLUMNS(navigate, deleteStudent), []);
	const data = useMemo(() => studentList, [studentList]);

	const { subscription } = useSubscription();

	useEffect(() => {
		getList();
	}, []);

	async function checkSubscriptionOk() {
		const dash = await dashboardApi.dashboardList();
		const total = dash.data.people.registeredStudents + dash.data.people.registeredTeachers;
		if (subscription) {
			if (subscription.headCount <= total && subscription.headCount > 0) {
				alert('Maximum number of students/teachers has been reached. Please contact your administrator.');
				return false;
			}
		}
		return true;
	}

	async function deleteStudent(id) {
		swal({
			title: 'Are you sure?',
			text: 'Once deleted, you will not be able to recover!',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: 'swal-border-danger',
		}).then(async (yes) => {
			if (yes) {
				try {
					const res = await studentAPI.deleteStudent(id);
					if (res.success) {
						swal('Student has been deleted!', {
							icon: 'success',
						});
						getList();
					}
				} catch (err) {
					console.log(err);
					swal('Oops', 'Something went wrong!', 'error');
				}
			} else {
				swal('Student deletion is cancelled');
			}
		});
	}

	const getList = async () => {
		const list = await getStudentList();
		setStudentList(list);
	};

	// useEffect(() => {
	// 	getStudentList();
	// }, []);

	// const getStudentList = async () => {
	// 	try {
	// 		const res = await studentAPI.studentList();
	// 		setStudentList(res.data);
	// 		return res.data;
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };

	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;

	const handleChecked = (id) => {
		let temp = checked.map((data) => {
			if (id === data.id) {
				return { ...data, inputchecked: !data.inputchecked };
			}
			return data;
		});
		setChecked(temp);
	};

	const handleCheckedAll = (value) => {
		let temp = checked.map((data) => {
			return { ...data, inputchecked: value };
		});
		setChecked(temp);
		setUnChecked(!unchecked);
	};

	// const deleteStudent = async (id) => {
	// 	swal({
	// 		title: 'Are you sure?',
	// 		text: 'Once deleted, you will not be able to recover!',
	// 		icon: 'warning',
	// 		buttons: true,
	// 		dangerMode: true,
	// 		className: 'swal-border-danger',
	// 	}).then(async (yes) => {
	// 		if (yes) {
	// 			try {
	// 				const res = await studentAPI.deleteStudent(id);
	// 				if (res.success) {
	// 					swal('Student has been deleted!', {
	// 						icon: 'success',
	// 					});
	// 					getList();
	// 				}
	// 			} catch (err) {
	// 				console.log(err);
	// 				swal('Oops', 'Something went wrong!', 'error');
	// 			}
	// 		} else {
	// 			swal('Student deletion is cancelled');
	// 		}
	// 	});

	// 	// try {
	// 	// 	const res = await studentAPI.deleteStudent(id);
	// 	// 	if (res.success) {
	// 	// 		swal('Success', 'You have added a student', 'success').then(() => {
	// 	// 			navigate('..', { relative: 'path', replace: true });
	// 	// 		});
	// 	// 	}
	// 	// } catch (err) {
	// 	// 	console.log(err);
	// 	// 	swal('Oops', 'Something went wrong!', 'error').then(() => {
	// 	// 		navigate('..', { relative: 'path', replace: true });
	// 	// 	});
	// 	// }
	// };

	// const recordsPage = 15;
	// const lastIndex = currentPage * recordsPage;
	// const firstIndex = lastIndex - recordsPage;
	// const records = studentList.slice(firstIndex, lastIndex);
	// const npage = Math.ceil(studentList.length / recordsPage);
	// const number = [...Array(npage + 1).keys()].slice(1);

	// function prePage() {
	// 	if (currentPage !== 1) {
	// 		setCurrentPage(currentPage - 1);
	// 	}
	// }
	// function changeCPage(id) {
	// 	setCurrentPage(id);
	// }
	// function nextPage() {
	// 	if (currentPage !== npage) {
	// 		setCurrentPage(currentPage + 1);
	// 	}
	// }
	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title  shadow-sm flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
								<div>
									{/* <Dropdown className='drop-select me-3'>
										<Dropdown.Toggle as='div' className='drop-select-btn '>
											Newest
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item>Newest</Dropdown.Item>
											<Dropdown.Item>Oldest</Dropdown.Item>
											<Dropdown.Item>Recent</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown> */}
									<button
										type='button'
										className='btn btn-primary'
										onClick={() => {
											if (!subscription || subscription.error) return navigate('/student/add-student');
											checkSubscriptionOk().then((res) => {
												if (res) return navigate('/student/add-student');
											});
										}}>
										+ New Student
									</button>
								</div>
							</div>
						</div>
						<div className='col-xl-12 wow fadeInUp ' data-wow-delay='1.5s'>
							{studentList.length ? (
								<div className='table-responsive full-data'>
									<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer'>
											<thead>
												{/* <tr>
											
												<th>Name</th>
												<th>Enrollment Type</th>
												<th>Parent Name</th>
												<th>Class</th>
												<th className='text-center'>Action</th>
											</tr> */}
												{headerGroups.map((headerGroup) => {
													return (
														<tr {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column) => (
																<th {...column.getHeaderProps()}>{column.render('Header')}</th>
															))}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row) => {
													prepareRow(row);
													return (
														<tr {...row.getRowProps()}>
															{row.cells.map((cell, index) => {
																// if (cell.column.id.toLowerCase() === 'action') {
																// 	return (
																// 		<td key={index}>
																// 			<div className='d-flex'>
																// 				<div
																// 					className='icon-box icon-box-sm bg-light me-2'
																// 					onClick={() =>
																// 						navigate('/student/details/' + row.original.id)
																// 					}
																// 				>
																// 					<i
																// 						className='material-symbols-outlined'
																// 						style={{ fontSize: 8, color: '#4D44B5' }}
																// 					>
																// 						visibility
																// 					</i>
																// 				</div>
																// 				<div
																// 					className='icon-box icon-box-sm bg-light me-2'
																// 					onClick={() =>
																// 						navigate('/student/edit-student/' + row.original.id)
																// 					}
																// 				>
																// 					<i
																// 						className='material-symbols-outlined'
																// 						style={{ fontSize: 8, color: '#4D44B5' }}
																// 					>
																// 						edit
																// 					</i>
																// 				</div>
																// 				<div
																// 					className='icon-box icon-box-sm bg-light me-2'
																// 					onClick={() => deleteStudent(row.original.id)}
																// 				>
																// 					<i
																// 						className='material-symbols-outlined'
																// 						style={{ fontSize: 8, color: '#4D44B5' }}
																// 					>
																// 						delete
																// 					</i>
																// 				</div>
																// 			</div>
																// 		</td>
																// 	);
																// } else {
																return (
																	<td key={index} {...cell.getCellProps()}>
																		{cell.value ? cell.render('Cell') : '-'}
																	</td>
																);
																// }
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
										{/* <div className='teach d-flex justify-content-between'>
											<small>
												<span>
													Page{' '}
													<strong>
														{pageIndex + 1} of {pageOptions.length}
													</strong>
													{'  '}
												</span>
												<span>
													| Go to page :
													<input
														type='number'
														defaultValue={pageIndex + 1}
														onChange={(e) => {
															const page = e.target.value ? Number(e.target.value) - 1 : 0;
															gotoPage(page);
														}}
														style={{
															width: '80px',
															marginLeft: 10,
															border: '1px solid ',
															borderColor: '#dee2e6',
															padding: 5,
															borderRadius: '0.625rem',
														}}
													/>
												</span>{' '}
												<select
													style={{ borderRadius: 0, padding: 5 }}
													value={pageSize}
													onChange={(e) => {
														setPageSize(Number(e.target.value));
													}}>
													{[10, 20, 30, 40, 50].map((pageSize) => (
														<option key={pageSize} value={pageSize}>
															Show {pageSize}
														</option>
													))}
												</select>
											</small>

											<nav>
												<ul className='pagination pagination-gutter pagination-primary no-bg '>
													<li className='page-item page-indicator'>
														<button
															className='page-link h-50'
															onClick={() => gotoPage(0)}
															disabled={!canPreviousPage}>
															<i className='fa-solid fa-chevron-left font-8'></i>
															<i className='fa-solid fa-chevron-left font-8'></i>
														</button>
													</li>
													<li className='page-item page-indicator'>
														<button
															className='page-link h-50'
															onClick={() => previousPage()}
															disabled={!canPreviousPage}>
															<i className='fa-solid fa-chevron-left font-8'></i>
														</button>
													</li>

													<li className='page-item page-indicator'>
														<button
															className='page-link h-50'
															onClick={() => nextPage()}
															disabled={!canNextPage}>
															<i className='fa-solid fa-chevron-right font-8'></i>
														</button>
													</li>
													<li className='page-item page-indicator'>
														<button
															className='page-link h-50'
															onClick={() => gotoPage(pageCount - 1)}
															disabled={!canNextPage}>
															<i className='fa-solid fa-chevron-right font-8'></i>
															<i className='fa-solid fa-chevron-right font-8'></i>
														</button>
													</li>
												</ul>
											</nav>
										</div> */}
									</div>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<BasicModal ref={childRef} />
		</>
	);
};

export default Students;
