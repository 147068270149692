import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import attendanceAPI from '../../../api/attendanceApi';
import Select from 'react-select';
import { getClassList } from '../Student/utils';
import { customSelectStyle } from '../../../services/general';
import EmptyState from '../components/EmptyState';
import Pagination from '../components/Pagination';
import NewAttendance from './component/newAttendance';
import moment from 'moment';

const COLUMNS = [
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'ID Number',
		accessor: 'idNumber',
	},
	{
		Header: 'Class',
		accessor: 'class',
	},
	{
		Header: 'Temperature °C',
		accessor: 'temperature',
	},
	{
		Header: 'Time In',
		accessor: 'timeIn',
		Cell: ({ value }) => {
			return moment(value).format('h:mm a');
		},
	},
	{
		Header: 'Time Out',
		accessor: 'timeOut',
		Cell: ({ value }) => {
			return moment(value).format('h:mm a');
		},
	},
	{
		Header: 'Authorised Person',
		accessor: 'pickedUpBy.name',
	},
	// {
	// 	Header: 'Action',
	// 	Footer: 'Action',
	// },
];

const StudentAttendance = () => {
	const [attendanceList, setAttendanceList] = useState([]);
	const [klass, setKlass] = useState(undefined);
	const [startDate, setStartDate] = useState(new Date());
	const [classList, setClassList] = useState([]);
	const [isOpenModal, setIsOpenModal] = useState(false);

	useEffect(() => {
		getList();
	}, [startDate, klass]);

	const getList = async () => {
		const postData = {
			date: moment(startDate).format('yyyy-MM-DD'),
			category: 'student',
			klass,
		};
		try {
			const res = await attendanceAPI.getAttendanceList(postData);
			// console.log(res);
			if (res.success) {
				setAttendanceList(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getClassDropdownList();
	}, []);

	const getClassDropdownList = async () => {
		const classList = await getClassList();
		const arr = [{ label: 'All', value: null }, ...classList];
		setClassList(arr);
	};

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => attendanceList, [attendanceList]);
	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;

	const { pageIndex, pageSize } = state;

	return (
		<div className='row'>
			<div className='col-xl-12'>
				<div className='row'>
					<div className='col-xl-12'>
						<div className='page-title w-100 shadow-sm justify-content-between'>
							<div className='d-flex gap-5 w-50'>
								<div>
									<h6 className='form-label '>Date</h6>
									<DatePicker
										dateFormat='dd/MM/yyyy'
										selected={startDate}
										onChange={(date) => {
											setStartDate(date);
										}}
										className='form-control'
										fixedHeight
									/>
								</div>
								<div className='w-50'>
									<h6 className='form-label'>Class</h6>
									<Select
										styles={customSelectStyle()}
										options={classList}
										onChange={(e) => setKlass(e.value)}
										value={classList.filter(({ value }) => value === klass)}
										inputId='klassId'
									/>
								</div>
							</div>
							<button type='button' className='btn btn-md btn-primary' onClick={() => setIsOpenModal(true)}>
								New Attendance
							</button>
						</div>
					</div>
					<div className='col-xl-12 wow fadeInUp' data-wow-delay='1.5s'>
						<div className='table-responsive full-data'>
							{attendanceList.length ? (
								<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
									<table
										{...getTableProps()}
										className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer'>
										<thead>
											{/* <tr>
											
												<th>Name</th>
												<th>Enrollment Type</th>
												<th>Parent Name</th>
												<th>Class</th>
												<th className='text-center'>Action</th>
											</tr> */}
											{headerGroups.map((headerGroup) => {
												return (
													<tr {...headerGroup.getHeaderGroupProps()}>
														{headerGroup.headers.map((column) => (
															<th {...column.getHeaderProps()}>{column.render('Header')}</th>
														))}
													</tr>
												);
											})}
										</thead>
										<tbody {...getTableBodyProps()} className=''>
											{page.map((row) => {
												prepareRow(row);
												return (
													<tr {...row.getRowProps()}>
														{row.cells.map((cell, index) => {
															return (
																<td key={index} {...cell.getCellProps()}>
																	{cell.value ? cell.render('Cell') : '-'}
																</td>
															);
														})}
													</tr>
												);
											})}
										</tbody>
									</table>
									<Pagination
										nextPage={nextPage}
										previousPage={previousPage}
										canNextPage={canNextPage}
										canPreviousPage={canPreviousPage}
										pageIndex={pageIndex}
										pageOptions={pageOptions}
										pageSize={pageSize}
										setPageSize={setPageSize}
										gotoPage={gotoPage}
										pageCount={pageCount}
									/>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<NewAttendance
				setIsOpenModal={setIsOpenModal}
				isOpenModal={isOpenModal}
				isStaff={false}
				reload={getList}
				attendanceList={attendanceList}
				date={startDate}
			/>
		</div>
	);
};

export default StudentAttendance;
