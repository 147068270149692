import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import EmptyState from '../../components/EmptyState';
import Pagination from '../../components/Pagination';
import feesAPI from '../../../../api/feesApi';
import swal from 'sweetalert';
import NewFee from './component/newFee';

const COLUMNS = [
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Type',
		accessor: 'type',
	},
	{
		Header: 'Amount',
		accessor: 'amount',
	},
	{
		Header: 'Action',
		Footer: 'Action',
	},
];

const FeesSetup = () => {
	const [fees, setFees] = useState([]);
	const [isOpenModal, setIsOpenModal] = useState(false);

	const deleteFee = async (feeId) => {
		swal({
			title: 'Are you sure?',
			text: 'Once deleted, you will not be able to recover!',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: 'swal-border-danger',
		}).then(async (yes) => {
			if (yes) {
				try {
					const res = await feesAPI.deleteFee(feeId);
					if (res.success) {
						swal('Fee has been deleted!', {
							icon: 'success',
						});
						getList();
					}
				} catch (err) {
					console.log(err);
					swal('Oops', 'Something went wrong!', 'error');
				}
			} else {
				swal('Fee deletion is cancelled');
			}
		});
	};

	const getList = useCallback(async () => {
		const res = await feesAPI.getFeesList();
		if (res.success) setFees(res.data);
	}, []);

	useEffect(() => {
		getList();
	}, []);

	const columns = useMemo(() => COLUMNS, []);
	const tableInstance = useTable({ columns, data: fees, initialState: { pageIndex: 0 } }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;

	const { pageIndex, pageSize } = state;

	return (
		<div className='row'>
			<div className='col-xl-12'>
				<div className='row'>
					<div className='col-xl-12'>
						<div className='page-title w-100 shadow-sm justify-content-between'>
							<button type='button' className='btn btn-md btn-primary' onClick={() => setIsOpenModal(true)}>
								New Fee
							</button>
						</div>
					</div>
					<div className='col-xl-12 wow fadeInUp' data-wow-delay='1.5s'>
						<div className='table-responsive full-data'>
							{fees.length ? (
								<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
									<table
										{...getTableProps()}
										className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer'
									>
										<thead>
											{headerGroups.map((headerGroup) => {
												return (
													<tr {...headerGroup.getHeaderGroupProps()}>
														{headerGroup.headers.map((column) => (
															<th {...column.getHeaderProps()}>{column.render('Header')}</th>
														))}
													</tr>
												);
											})}
										</thead>

										<tbody {...getTableBodyProps()} className=''>
											{page.map((row) => {
												prepareRow(row);
												return (
													<tr {...row.getRowProps()}>
														{row.cells.map((cell, index) => {
															if (cell.column.id.toLowerCase() === 'action') {
																return (
																	<td key={index}>
																		<div className='d-flex'>
																			<div
																				className='icon-box icon-box-sm bg-light me-2'
																				onClick={() => {
																					deleteFee(row.original.id);
																				}}
																			>
																				<i
																					className='material-symbols-outlined'
																					style={{ fontSize: 8, color: '#4D44B5' }}
																				>
																					delete
																				</i>
																			</div>
																		</div>
																	</td>
																);
															} else {
																return (
																	<td key={index} {...cell.getCellProps()}>
																		{cell.value
																			? cell.render('Cell')
																			: isNaN(Number(cell.value))
																			? '-'
																			: cell.value}
																	</td>
																);
															}
														})}
													</tr>
												);
											})}
										</tbody>
									</table>
									<Pagination
										nextPage={nextPage}
										previousPage={previousPage}
										canNextPage={canNextPage}
										canPreviousPage={canPreviousPage}
										pageIndex={pageIndex}
										pageOptions={pageOptions}
										pageSize={pageSize}
										setPageSize={setPageSize}
										gotoPage={gotoPage}
										pageCount={pageCount}
									/>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<NewFee setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} reload={getList} />
		</div>
	);
};

export default FeesSetup;
