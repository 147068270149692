import claimsAPI from '../../../../../api/claimsApi';

import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Name',
		Footer: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'date',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Description',
		Footer: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
		Cell: ({ value }) => {
			const getColor = () => {
				switch (value.toLowerCase()) {
					case 'paid':
						return 'success';
					case 'approved':
						return 'success';
					case 'rejected':
						return 'danger';
					case 'pending':
						return 'primary';
					default:
						return 'primary';
				}
			};
			return <span className={`badge badge-sm light badge-${getColor()}`}>{value}</span>;
		},
	},
	{
		Header: 'Action',
		Footer: 'Action',
		accessor: (row) => row,
		Cell: ({ value }) => {
			return (
				<div
					className='icon-box icon-box-sm   me-2'
					style={{
						backgroundColor: '#ebebf9',
						cursor: 'pointer',
					}}>
					<i className='material-symbols-outlined' style={{ fontSize: 8, color: '#4D44B5' }}>
						visibility
					</i>
				</div>
			);
		},
	},
];

export async function getClaims() {
	try {
		let res = await claimsAPI.claimList();
		if (res.success) {
			return res?.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
}
