import apiHandler from './apiHandler';

export type PgInfo = {
	pk: string;
	sk: string;
	pmc: string;
};

export async function get() {
	return await apiHandler.get(`/settings/paymentGateway`);
}

export async function update(postData: PgInfo) {
	return await apiHandler.put('/settings/paymentGateway', postData);
}

const paymentGatewayApi = {
	get,
	update,
};

export default paymentGatewayApi;
