import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import DatePicker from 'react-datepicker';
import Pagination from '../components/Pagination';
import weeklyTaskAPI from '../../../api/weeklyTaskApi';
import moment from 'moment';
import { format } from 'date-fns';
import ViewTask from './ViewTask';

const WeeklyTask = () => {
	const navigate = useNavigate();
	const [weekDate, setWeekDate] = useState(new Date());
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [taskData, setTaskData] = useState([]);

	const [viewModal,setViewModal] = useState(
		{
			show:false,
			teacherId: undefined,
			date: undefined
		}
	);

	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
	};
	const COLUMNS = [
		{
			Header: 'Teacher’s Name',
			Footer: 'Teacher’s Name',
			accessor: 'teacher[0].teacher.name',
		},
		{
			Header: 'Tasks',
			Footer: 'Tasks',
			accessor: 'id',
		},
		{
			Header: 'Action',
			Footer: 'Action',
		},
	];
	const columns = useMemo(() => COLUMNS, []);
	const getTaskList = async () => {
		const list = await weeklyTaskAPI.taskList(moment(weekDate).format('YYYY-MM-DD'));

		//   const arrayHashmap = list?.data.reduce((obj, item) => {
		//     obj[item.assigned_to] ? obj[item.assigned_to].elements?.push(...item?.elements) : (obj[item.assigned_to] = { ...item });
		//     return obj;
		//   }, {});
		//   console.log("arrayHashmap", arrayHashmap)
		//   const mergedArray = Object.values(arrayHashmap);

		//   console.log("ffff", mergedArray);

		//   let results = list?.data?.reduce(function(results, org) {
		//     (results[org.assigned_to] = results[org.assigned_to] || []).push(org);
		//     return results;
		// }, {})
		// const mergedAAArray = Object.values(results);
		//   console.log("list", list)
		//   console.log("mergedAAArray", mergedAAArray)
		//   console.log("results", results)

		//   const resultt = list?.data.reduce((accumulator, current) => {
		//     let exists = accumulator.find(item => {
		//       return item.assigned_to === current.assigned_to;
		//     });
		//     if(!exists) {
		//       accumulator = accumulator.concat(current);
		//     }
		//     return accumulator;
		//   }, []);

		//   console.log("mapp", resultt);
		const reducedArr = Object.entries(
			list?.data?.reduce((acc, cur) => {
				if (!acc[cur.assigned_to]) {
					acc[cur.assigned_to] = [];
				}
				acc[cur.assigned_to].push(cur);
				return acc;
			}, {})
		).map(([id, teacher]) => ({ id, teacher }));
		setTaskData(reducedArr);
		console.log('reducedArr', reducedArr);

		// console.log("dd")
		//     const reducedArr2 = Object.entries(
		//       list?.data?.reduce((acc, cur) => {
		//         if (!acc[cur.assigned_to]) {
		//           acc[cur.assigned_to] = [];
		//         }
		//         acc[cur.assigned_to].push(cur);
		//         return acc;
		//       }, {})
		//     );
		//    const rrrr = Object.values(reducedArr2);
		//     // console.log(reducedArr);
		//     console.log("finalResult", reducedArr)
		//     console.log("finalResult 22", rrrr)
	};
	useEffect(() => {
		getTaskList();
	}, [weekDate]);

	const data = useMemo(() => taskData, [taskData]);
	// const data = demoData;
	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;
	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title flex-wrap'>
								<div className='form-group mb-3'>
									<div className='contact-occupation'>
										<DatePicker
											dateFormat='dd/MM/yyyy'
											wrapperClassName='w-100'
											className='form-control'
											selected={weekDate}
											onChange={(date) => setWeekDate(date)}
										/>
									</div>
								</div>
								<div className='d-flex'>
									<button
										type='button'
										className='btn btn-primary'
										onClick={() => {
											navigate('/weekly-task/add-task');
											// setAddTask(true);
										}}>
										+ New Task
									</button>
								</div>
							</div>
						</div>
						<div className='col-xl-12 wow fadeInUp' data-wow-delay='1.5s'>
							<div className='table-responsive full-data'>
								<div id='' className='dataTables_wrapper no-footer'>
									<table
										{...getTableProps()}
										className='table-responsive-lg table display dataTablesCard student-tab dataTable no-footer'>
										<thead>
											{headerGroups.map((headerGroup) => {
												return (
													<tr {...headerGroup.getHeaderGroupProps()}>
														{headerGroup.headers.map((column, idx) => (
															<th
																key={idx}
																className={
																	column.Header.toLowerCase() === 'teacherName' ? 'col-xl-6' : 'col-xl-4'
																}>
																{column.render('Header')}
															</th>
														))}
													</tr>
												);
											})}
										</thead>
										<tbody {...getTableBodyProps()} className=''>
											{page.map((row) => {
												prepareRow(row);
												return (
													<tr {...row.getRowProps()}>
														{row.cells.map((cell, index) => {
															if (cell.column.id.toLowerCase() === 'action') {
																return (
																	<td key={index}>
																		<div className='d-flex justify-content-start'>
																			<div
																			className='icon-box icon-box-sm bg-light me-2'
																			style={{userSelect:'none'}}
																			onClick={() => {
																				setViewModal(
																					{
																						show:true,
																						teacherId: Number(cell.row.original.id),
																						date: format(weekDate,'yyyy-MM-dd'),
																					}
																				);
																			}}>
																				<i
																					className='material-symbols-outlined'
																					style={{
																						fontSize: 10,
																						color: '#4D44B5',
																					}}>
																					visibility
																				</i>
																			</div>
																			<div
																				className='icon-box icon-box-sm bg-light me-2'
																				style={{userSelect:'none'}}
																				onClick={() => {
																					navigate(`/weekly-task/edit-task/${cell.row.original.id}/${format(weekDate,'yyyy-MM-dd')}`);
																				}}>
																				<i
																					className='material-symbols-outlined'
																					style={{
																						fontSize: 10,
																						color: '#4D44B5',
																					}}>
																					edit
																				</i>
																			</div>
																		</div>
																	</td>
																);
															}
															if (
																cell.column.id.toLowerCase() === 'id' &&
																cell.row.original.teacher?.length > 0
															) {
																const result = cell.row.original.teacher?.length;
																const completeResult = cell.row.original.teacher.filter(
																	(v) => v.complete === true
																);
																return (
																	<td key={index}>
																		{completeResult?.length + '/' + result + ' Done'}
																	</td>
																);
															} else {
																return <td key={index}>{cell.value ? cell.render('Cell') : '-'}</td>;
															}
														})}
													</tr>
												);
											})}
										</tbody>
									</table>
									<Pagination
										nextPage={nextPage}
										previousPage={previousPage}
										canNextPage={canNextPage}
										canPreviousPage={canPreviousPage}
										pageIndex={pageIndex}
										pageOptions={pageOptions}
										pageSize={pageSize}
										setPageSize={setPageSize}
										gotoPage={gotoPage}
										pageCount={pageCount}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ViewTask show={viewModal.show} setShow={setViewModal} teacher_id={viewModal.teacherId} date={viewModal.date}/>
		</>
	);
};

export default WeeklyTask;
