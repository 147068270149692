import { getStaffList } from "./utils";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Select from "react-select";
import { customSelectStyle } from "../../../services/general";
import DatePicker from "react-datepicker";
import "../../../css/timetable.css";
import swal from "sweetalert";
import weeklyTaskAPI from "../../../api/weeklyTaskApi";
import { format } from "date-fns";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

const EditTaskPage = () => {
    const { teacher_id, date } = useParams();
    const [teacherOption, setTeacherOption] = useState([]);
    const [initialValue, setInitialValue] = useState({
        id: undefined,
        day: new Date(),
        teacher: [
            {
                title: "",
                description: "",
                start_time: new Date(),
                end_time: new Date(),
            },
        ],
    });

    useEffect(() => {
        getTeacherOption();
    }, []);

    useEffect(() => {
        teacher_id && date && getInitialValue();
    }, [teacher_id, date]);

    const validationSchema = Yup.object().shape({
        id: Yup.number().required("Please select a teacher"),
        teacher: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().required("Please fill in the field"),
            })
        ),
    });

    const getInitialValue = async () => {
        try {
            const res = await weeklyTaskAPI.getTeacherTaskByDate(Number(teacher_id), String(date));
            if (res.success) {
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i].start_time = stringToTime(res.data[i].start_time);
                    res.data[i].end_time = stringToTime(res.data[i].end_time);
                }
                console.log(res.data);
                setInitialValue({ id: Number(teacher_id), day: new Date(date), teacher: res.data });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getTeacherOption = async () => {
        try {
            const res = await getStaffList();
            res && setTeacherOption(res);
        } catch (err) {
            console.log(err);
        }
    };

    const stringToTime = (time) => {
        const date = new Date();
        const timeArray = time.split(":");
        date.setHours(Number(timeArray[0]));
        date.setMinutes(Number(timeArray[1]));
        date.setSeconds(0);
        return date;
    };

    const formatToTime = (date) => {
        let ans =
            (date.getHours() === 0 ? "00" : date.getHours().toString()) +
            ":" +
            (date.getMinutes() === 0 ? "00" : date.getMinutes().toString());
        return ans + ":00";
    };

    const handleSubmit = async (values) => {
        if (teacher_id && date) {
            try {
                for (let i = 0; i < values.teacher.length; i++) {
                    const data = values.teacher[i];
                    const res = await weeklyTaskAPI.updateTask({
                        id: data.id,
                        complete: data.complete,
                        title: data.title,
                        description: data.description,
                        start_time: formatToTime(data.start_time),
                        end_time: formatToTime(data.end_time),
                        assigned_to: Number(values.id),
                        date: format(values.day, "yyyy-MM-dd"),
                    });
                    if (res.success) {
                        swal({ text: "Successfully update!", icon: "success" }).then(() => {
                            navigate("/weekly-task");
                        });
                    } else {
                        swal({ title: "Cant Update", icon: "error" });
                    }
                }
            } catch (err) {
                console.log(err);
                swal({ title: "Cant Update", text: String(err), icon: "error" });
            }
        } else {
            console.log("Create", values);
            try {
                for (let task of values.teacher) {
                    const res = await weeklyTaskAPI.createTask({
                        title: task.title,
                        description: task.description,
                        date_start: format(values.day, "yyyy-MM-dd"),
                        date_end: format(values.day, "yyyy-MM-dd"),
                        start_time: formatToTime(task.start_time),
                        end_time: formatToTime(task.end_time),
                        assigned_to: values.id,
                    });
                    if (!res.success) {
                        swal({ title: "Cant create task", icon: "error" });
                        throw new Error(`Cant create task!`);
                    }
                }
                swal({ title: "Success to create task", icon: "success" }).then(() => {
                    navigate("/weekly-task");
                });
            } catch (err) {
                swal({ title: "Cant Update", text: String(err), icon: "error" });
            }
        }
    };

    let navigate = useNavigate();

    return (
        <>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValue}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, handleBlur, handleChange }) => (
                    <Form>
                        <div className="row">
                            <div className="col-6 mb-4">
                                <h6 className="form-label">Teacher's Name</h6>
                                <Select
                                    placeholder="Select teacher"
                                    styles={customSelectStyle()}
                                    options={teacherOption}
                                    value={teacherOption.find((teacher) => teacher.value === Number(values.id))}
                                    onChange={(e) => setFieldValue("id", e.value)}
                                />
                                <ErrorMessage name="id">
                                    {(msg) => (
                                        <div
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {msg}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                            <div className="col-6 mb-4">
                                <h6 className="form-label">Date</h6>
                                <DatePicker
                                    name="day"
                                    dateFormat="dd/MM/yyyy"
                                    wrapperClassName="w-100"
                                    className="form-control"
                                    selected={values.day}
                                    onChange={(d) => setFieldValue("day", d)}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        <FieldArray name="teacher">
                            {({ push, remove }) => (
                                <div className="col-12">
                                    <h6 className="form-label mb-2">Task</h6>
                                    {values.teacher.map((data, index, dataArray) => {
                                        return (
                                            <div key={index} className="col-12">
                                                <div className="card shadow p-4">
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <h6 className="form-label">Title</h6>
                                                            <div className="d-flex mb-2">
                                                                <div
                                                                    style={{
                                                                        width: "50px",
                                                                        padding: "2px",
                                                                        border: "1px solid #D8D8D8",
                                                                        borderRadius: "0.625rem",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    className="d-flex align-items-center justify-content-center"
                                                                    onClick={() => {
                                                                        setFieldValue(
                                                                            `teacher.${index}.complete`,
                                                                            !values.teacher[index].complete
                                                                        );
                                                                    }}
                                                                >
                                                                    {values.teacher[index].complete && (
                                                                        <i
                                                                            className="material-symbols-outlined unselectable"
                                                                            style={{ color: "#2AB514", fontSize: 30 }}
                                                                        >
                                                                            check
                                                                        </i>
                                                                    )}
                                                                </div>
                                                                <input
                                                                    name={`teacher.${index}.title`}
                                                                    className="form-control"
                                                                    value={values.teacher[index].title}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <ErrorMessage name={`teacher.${index}.title`}>
                                                                {(msg) => (
                                                                    <div
                                                                        className="invalid-feedback animated fadeInUp"
                                                                        style={{ display: "block", marginLeft: "50px" }}
                                                                    >
                                                                        {msg}
                                                                    </div>
                                                                )}
                                                            </ErrorMessage>
                                                        </div>
                                                        <div className="col-4">
                                                            <h6 className="form-label">Time</h6>
                                                            <div className="row">
                                                                <div className="col-5">
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={15}
                                                                        dateFormat="h:mm aa"
                                                                        selected={values.teacher[index].start_time}
                                                                        onChange={(d) =>
                                                                            setFieldValue(
                                                                                `teacher.${index}.start_time`,
                                                                                d
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-2 d-flex align-items-center justify-content-center">
                                                                    <i
                                                                        className="material-symbols-outlined"
                                                                        style={{ fontSize: 34 }}
                                                                    >
                                                                        horizontal_rule
                                                                    </i>
                                                                </div>
                                                                <div className="col-5">
                                                                    <DatePicker
                                                                        className="form-control"
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={15}
                                                                        dateFormat="h:mm aa"
                                                                        selected={values.teacher[index].end_time}
                                                                        onChange={(d) =>
                                                                            setFieldValue(
                                                                                `teacher.${index}.end_time`,
                                                                                d
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h6 className="form-label">Description</h6>
                                                    <input
                                                        name={`teacher.${index}.description`}
                                                        className="form-control mb-4"
                                                        value={values.teacher[index].description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"
                                                    />

                                                    {!teacher_id && !date && (
                                                        <div className="d-flex gap-4 col-12 justify-content-end">
                                                            {index === dataArray.length - 1 && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => {
                                                                        push({
                                                                            title: "",
                                                                            description: "",
                                                                            start_time: new Date(),
                                                                            end_time: new Date(),
                                                                        });
                                                                    }}
                                                                >
                                                                    + Add
                                                                </button>
                                                            )}

                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => {
                                                                    if (dataArray.length > 1) {
                                                                        remove(index);
                                                                    }
                                                                }}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </FieldArray>
                        <div className="col-12 d-flex justify-content-center gap-4">
                            <button type="submit" className="btn btn-primary">
                                {teacher_id && date ? "Edit" : "Create"}
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                    navigate("/weekly-task");
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EditTaskPage;
