import { Modal, Tab, Tabs } from 'react-bootstrap';
import classAPI from '../../../../api/classApi';
import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { eachDayOfInterval, endOfDay, startOfDay, format } from 'date-fns';
import swal from 'sweetalert';
import timeTableApi from '../../../../api/timeTableApi';
import { useNavigate } from 'react-router-dom';
import '../../../../css/timetable.css';
import { ErrorMessage, Form, Formik } from 'formik';
import { SketchPicker } from 'react-color';
import * as Yup from 'yup';

const TimeTable = () => {
	const [classes, setClasses] = useState([]);
	const [activeTab, setActiveTab] = useState(0);
	const [activeTime, setActiveTime] = useState({
		startTime: startOfDay(new Date()),
		endTime: endOfDay(new Date()),
	});
	const [timeTable, setTimeTable] = useState([]);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editdata, setEditData] = useState({});
	const [editColor, setEditColor] = useState(false);
	const days = useMemo(() => {
		return eachDayOfInterval({ start: activeTime.startTime, end: activeTime.endTime });
	}, [activeTime]);

	useEffect(() => {
		getClasses();
	}, []);

	// useEffect(() => {
	// 	console.log(editdata);
	// }, [showEditModal]);

	useEffect(() => {
		const getTimeTable = async () => {
			try {
				setTimeTable([]);
				let tableList = [];
				for (let day of days) {
					// console.log("query for class", classes[activeTab].id, " day: ", format(day, "yyyy-MM-dd"));
					if (!classes[activeTab]) return;
					const res = await timeTableApi.getTimeTable(classes[activeTab]?.id, format(day, 'yyyy-MM-dd'));
					if (res && res.success && res.data.length > 0) {
						tableList.push(res.data.sort((a, b) => Number(a.start_time.split(':')[0]) - Number(b.start_time.split(':')[0])));
					}
				}
				// console.log('table list : ', tableList);
				setTimeTable(tableList);
			} catch (err) {
				console.log(err);
			}
		};

		getTimeTable();
	}, [days, activeTab, classes, showEditModal]);

	let navigate = useNavigate();

	const getClasses = async () => {
		try {
			const res = await classAPI.classList();
			if (res && res.success) {
				// console.log(res.data);
				setClasses(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const stringToTime = (time) => {
		const date = new Date();
		const timeArray = time.split(':');
		date.setHours(Number(timeArray[0]));
		date.setMinutes(Number(timeArray[1]));
		return date;
	};

	const formatToTime = (date) => {
		let ans =
			(date.getHours() === 0 ? '00' : date.getHours().toString()) +
			':' +
			(date.getMinutes() === 0 ? '00' : date.getMinutes().toString());
		return ans;
	};

	const validationSchema = Yup.object().shape({
		title: Yup.string().required('Please fill in the field'),
	});

	return (
		<>
			<div className='row'>
				<div className='col-xl-4 col-md-6 col-12'>
					<div className='card card-body'>
						<div>
							<h2 className='heading fw-bold fs-3'>Date Range</h2>
							<div className='d-flex align-items-center'>
								<button
									type='button'
									className='btn btn-linklight'
									style={{
										cursor: 'default',
										color: 'black',
										width: '100px',
										paddingLeft: '0px',
										paddingRight: '0px',
										textAlign: 'left',
									}}>
									Start Date
								</button>
								<DatePicker
									dateFormat='dd/MM/yyyy'
									className='form-control'
									selected={activeTime.startTime}
									onChange={(d) => {
										const _startTime = startOfDay(d);
										if (_startTime < activeTime.endTime) {
											setActiveTime({ ...activeTime, startTime: _startTime });
										} else {
											swal({
												text: 'Start date must be earlier than end date.',
												icon: 'warning',
											});
										}
									}}
								/>
							</div>
							<div className='d-flex'>
								<button
									type='button'
									className='btn btn-linklight'
									style={{
										cursor: 'default',
										color: 'black',
										width: '100px',
										paddingLeft: '0px',
										paddingRight: '0px',
										textAlign: 'left',
									}}>
									End Date
								</button>
								<DatePicker
									dateFormat='dd/MM/yyyy'
									className='form-control'
									selected={activeTime.endTime}
									onChange={(d) => {
										const _endTime = endOfDay(d);
										if (_endTime > activeTime.startTime) {
											setActiveTime({ ...activeTime, endTime: _endTime });
										} else {
											swal({
												text: 'Endt date must be later than start date.',
												icon: 'warning',
											});
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='col-xl-4 col-md-6 col-12'>
					<div className='card card-body'>
						<div>
							<h2 className='heading fw-bold fs-3'>Add Timetable</h2>
							<p className='text-black fs-5'>Add timetable to class</p>
							<button
								type='button'
								className='btn btn-primary'
								onClick={() => {
									navigate('/timetable/class/add');
								}}>
								<div className='d-flex'>
									Add Timetable
									<i className='material-symbols-outlined' style={{ marginRight: '2px' }}>
										arrow_forward
									</i>
								</div>
							</button>
						</div>
					</div>
				</div>
				<div className='col-xl-4 col-md-6 col-12'>
					<div className='card card-body'>
						<div>
							<h2 className='heading fw-bold fs-3'>Edit Timetable</h2>
							<p className='text-black fs-5'>Click the timetable to edit existing timetable entries</p>
							{/* <button type="button" className="btn btn-primary">
                                <div className="d-flex">
                                    Edit Timetable
                                    <i className="material-symbols-outlined" style={{ marginRight: "2px" }}>
                                        arrow_forward
                                    </i>
                                </div>
                            </button> */}
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				<Tabs
					activeKey={activeTab}
					onSelect={(k) => {
						setActiveTab(k);
					}}
					className='mb-3 fs-4 fw-bold'>
					{classes.map((ele, index) => (
						<Tab key={index} eventKey={index} title={ele.name}>
							<div className='d-flex gap-4' style={{ overflow: 'auto' }}>
								{timeTable.map((table, index) => {
									return (
										<div key={index} className='d-flex flex-column align-items-center p-3'>
											<p className='fs-4'>{format(new Date(table[0].date), 'yyyy/MM/dd')}</p>
											{table.map((data, index2) => {
												return (
													<div
														key={index2}
														className='shadow cursor-pointer timetable'
														style={{
															width: '300px',
															textAlign: 'center',
															backgroundColor: data.color ? data.color : 'white',
															color: '#4F42AD',
														}}
														onClick={() => {
															console.log({
																...data,
																date: new Date(data.date.slice(0, 10)),
																start_time: stringToTime(data.start_time),
																end_time: stringToTime(data.end_time),
															});

															setEditData({
																...data,
																date: new Date(data.date.slice(0, 10)),
																start_time: stringToTime(data.start_time),
																end_time: stringToTime(data.end_time),
															});
															setShowEditModal(true);
														}}>
														<div className='row' style={{ width: '100%', margin: '16px 0px' }}>
															<div className='col-5 d-flex align-items-center justify-content-center'>
																{data.start_time} - {data.end_time}
															</div>
															<div className='col-7' style={{ textAlign: 'left' }}>
																<div>{data.title}</div>
																<div>{data.description}</div>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									);
								})}
							</div>
						</Tab>
					))}
				</Tabs>
			</div>
			<Modal
				backdrop='static'
				show={showEditModal}
				onHide={() => {
					setShowEditModal(false);
				}}>
				<Modal.Header closeButton>
					<h4>Edit Timetable</h4>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={editdata}
						enableReinitialize
						validationSchema={validationSchema}
						onSubmit={async (values) => {
							// console.log(values);
							try {
								await timeTableApi.editTimeTable({
									id: values.id,
									title: values.title,
									description: values.description,
									class_id: values.class_id,
									date: format(values.date, 'yyyy-MM-dd'),
									color: values.color,
									start_time: formatToTime(values.start_time),
									end_time: formatToTime(values.end_time),
									date_created: values.date_created,
								});
								swal({ text: 'Saved!', icon: 'success' }).then(() => {
									setShowEditModal(false);
								});
							} catch (err) {
								console.log(err);
								swal({ text: 'Cant Save!', icon: 'error' });
							}
						}}>
						{({ values, handleChange, handleBlur, setFieldValue }) => (
							<Form>
								<div className='col-12 mb-4'>
									<h6 className='form-label'>Title</h6>
									<input
										className='form-control'
										name='title'
										value={values.title}
										type='text'
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder='Class title'
									/>
									<ErrorMessage name='title'>
										{(msg) => (
											<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
												{msg}
											</div>
										)}
									</ErrorMessage>
								</div>
								<div className='row mb-4'>
									<div className='col-6 '>
										<h6 className='form-label'>Date</h6>
										<DatePicker
											name='date'
											dateFormat='dd/MM/yyyy'
											className='form-control'
											selected={values.date}
											onChange={(d) => setFieldValue('day', d)}
											onBlur={handleBlur}
										/>
									</div>
									<div className='col-6'>
										<h6 className='form-label'>Color</h6>
										<button
											type='button'
											className='btn btn-primary form-control'
											style={{ backgroundColor: values.color, color: 'black', textAlign: 'end' }}
											onClick={() => {
												setEditColor(true);
											}}>
											<i className='material-symbols-outlined' style={{ marginRight: '2px' }}>
												left_click
											</i>
										</button>
									</div>
								</div>
								{editColor && (
									<div className='col-12 mb-4 d-flex'>
										<SketchPicker
											color={values.color}
											onChange={(color) => setFieldValue('color', color.hex)}
											width={300}
										/>
										<button
											type='button'
											className='btn btn-primary'
											onClick={() => {
												setEditColor(false);
											}}>
											Done
										</button>
									</div>
								)}
								<div className='row'>
									<div className='col-6 mb-4'>
										<h6 className='form-label'>Start Time</h6>
										<DatePicker
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={15}
											name='start_time'
											dateFormat='h:mm aa'
											className='form-control'
											selected={values.start_time}
											onChange={(d) => setFieldValue('start_time', d)}
											onBlur={handleBlur}
										/>
									</div>
									<div className='col-6 mb-4'>
										<h6 className='form-label'>End time</h6>
										<DatePicker
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={15}
											name='end_time'
											dateFormat='h:mm aa'
											className='form-control'
											selected={values.end_time}
											onChange={(d) => setFieldValue('end_time', d)}
											onBlur={handleBlur}
										/>
									</div>
								</div>
								<div className='col-12 mb-4'>
									<h6 className='form-label'>Description</h6>
									<textarea
										className='form-control'
										name='description'
										value={values.description}
										type='text'
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder='Class title'
										rows={5}
									/>
								</div>
								<div className='col-12 d-flex justify-content-center gap-5'>
									<button
										type='submit'
										className='d-flex justify-content-center btn btn-danger '
										style={{ width: '60px' }}
										onClick={async () => {
											try {
												console.log(editdata.id);
												await timeTableApi.deleteTimeTable(editdata.id);
												swal({ text: 'Succesfully Deleted', icon: 'success' }).then(() => {
													setShowEditModal(false);
												});
											} catch (err) {
												console.log(err);
												swal({ text: 'Error', icon: 'error' });
											}
										}}>
										<i className='material-symbols-outlined'>delete</i>
									</button>
									<button type='submit' className='btn btn-primary' style={{ width: '120px' }}>
										Save
									</button>
									{/* <button
										type='button'
										className='btn btn-danger'
										style={{ width: '120px' }}
										onClick={() => {
											setShowEditModal(false);
										}}>
										Exit
									</button> */}
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default TimeTable;
