import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { customSelectStyle } from '../../../../../services/general';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import enrollmentTypesApi, { EnrollmentTypeOptions } from '../../../../../api/enrollmentTypesApi';
import { is } from 'date-fns/locale';

const convertTime = (time) => {
	const [hours, minutes] = time.split(':');
	const hrs = Number(hours);
	const am = hrs < 12;
	return `${hrs < 13 ? hrs : hrs - 12}:${minutes}${am ? 'am' : 'pm'}`;
};

const convertTimeValue = (timeString) => {
	let isPm = false;
	if (String(timeString).includes('pm')) isPm = true;
	const [time] = timeString.split(isPm ? 'pm' : 'am');
	const [hours, minutes] = time.split(':');
	const numHrs = parseInt(hours);
	if (isPm && numHrs === 12) isPm = false;
	else if (!isPm && numHrs === 12) isPm = true;
	const hrs = Number(isPm ? parseInt(hours) + 12 : hours);
	return `${hrs < 10 ? '0' : ''}${hrs}:${minutes ?? '00'}`;
};

const NewType = ({ editData, setIsOpenModal, isOpenModal, reload }) => {
	const [validation, setValidation] = useState({});

	const [types, setTypes] = useState();

	useEffect(() => {
		enrollmentTypesApi.getEnrollmentTypes().then(({ data }) => setTypes(EnrollmentTypeOptions(data)));
		setValidationScheme();
	}, [editData]);

	const initialValues = {
		type: undefined,
		startTime: undefined,
		endTime: undefined,
	};

	const addItemValidationSchema = {
		type: Yup.string()
			.required('Please enter a name')
			.notOneOf(types?.map(({ label }) => label) ?? [], 'The name is exist please choose another name'),
		startTime: Yup.string().required('Please enter a start time'),
		endTime: Yup.string().required('Please enter an end time'),
	};
	const editItemValidationSchema = {
		type: Yup.string()
			.required('Please enter a name')
			.notOneOf(
				types?.filter((val) => val?.value !== editData?.id)?.map(({ label }) => label) ?? [],
				'The name is exist please choose another name'
			),
		startTime: Yup.string().required('Please enter a start time'),
		endTime: Yup.string().required('Please enter an end time'),
	};

	const setValidationScheme = () => {
		const v = Yup.object().shape(editData ? editItemValidationSchema : addItemValidationSchema);
		setValidation(v);
	};

	const handleSubmit = async (e) => {
		try {
			let res;
			if (e.id) {
				res = await enrollmentTypesApi.editEnrollmentType({
					id: e.id,
					type: e.type,
					startTime: convertTime(e.startTime),
					endTime: convertTime(e.endTime),
				});
			} else {
				res = await enrollmentTypesApi.createEnrollmentType({
					type: e.type,
					startTime: convertTime(e.startTime),
					endTime: convertTime(e.endTime),
				});
			}

			console.log(res);

			if (res.success) {
				setIsOpenModal(false);
				reload();
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Modal onHide={setIsOpenModal} show={isOpenModal} centered backdrop='static'>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title'>{editData ? 'Edit' : 'Add'} Enrollment Type</h5>

					<button title='close' type='button' className='btn-close' onClick={() => setIsOpenModal(false)}></button>
				</div>
				<Formik
					initialValues={
						editData
							? {
									id: editData.id,
									type: editData.type,
									startTime: convertTimeValue(editData.startTime),
									endTime: convertTimeValue(editData.endTime),
							  }
							: initialValues
					}
					enableReinitialize={true}
					validationSchema={validation}
					onSubmit={(e) => handleSubmit(e)}>
					{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
						return (
							<div className='modal-body'>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Enrollment Type
									</label>
									<input
										type='text'
										className='form-control'
										defaultValue={editData?.type}
										name='type'
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{errors?.type && touched.type && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.type}
										</div>
									)}
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Start Time<span className='required'>*</span>
									</label>
									<input
										type='time'
										className='form-control'
										name='startTime'
										defaultValue={values?.startTime}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Start Time'
									/>
									{errors?.startTime && touched.startTime && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.startTime}
										</div>
									)}
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										End Time<span className='required'>*</span>
									</label>
									<input
										type='time'
										className='form-control'
										name='endTime'
										defaultValue={values?.endTime}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='End Time'
									/>
									{errors?.endTime && touched.endTime && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.endTime}
										</div>
									)}
								</div>
								<button type='button' className='btn btn-primary w-100 mt-3' onClick={handleSubmit}>
									Save
								</button>
							</div>
						);
					}}
				</Formik>
			</div>
		</Modal>
	);
};
export default NewType;
