import { useEffect, useState } from "react";
import { customSelectStyle } from "../../../../services/general";
import classAPI from "../../../../api/classApi";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import swal from "sweetalert";
import { SketchPicker } from "react-color";
import { format } from "date-fns";
import timeTableApi from "../../../../api/timeTableApi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const AddTimeTable = () => {
    const [classes, setClasses] = useState([]);

    const initialValue = {
        class: -1,
        day: new Date(),
        data: [
            {
                start_time: new Date(),
                end_time: new Date(),
                color: "#FFFFFF",
                title: "",
                description: "",
                showColor: false,
            },
        ],
    };

    const validationSchema = Yup.object().shape({
        class: Yup.number().not([-1], "Please select a class"),
        data: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().required("Please fill in the title"),
            })
        ),
    });

    useEffect(() => {
        getClasses();
    }, []);

    const getClasses = async () => {
        try {
            const res = await classAPI.classList();
            if (res && res.success) {
                setClasses(
                    res.data.map((data) => {
                        return { label: data.name, value: data.id };
                    })
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    const formatToTime = (date) => {
        let ans =
            (date.getHours() === 0 ? "00" : date.getHours().toString()) +
            ":" +
            (date.getMinutes() === 0 ? "00" : date.getMinutes().toString());
        return ans;
    };

    let navigate = useNavigate();

    return (
        <>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValue}
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                    try {
                        console.log(values);
                        const date_created = format(new Date(), "yyyy-MM-dd");
                        for (let i = 0; i < values.data.length; i++) {
                            const start_time = formatToTime(values.data[i].start_time);
                            const end_time = formatToTime(values.data[i].end_time);
                            const res = await timeTableApi.postTimeTable({
                                title: values.data[i].title,
                                description: values.data[i].description,
                                class_id: values.class,
                                color: values.data[i].color,
                                date: format(values.day, "yyyy-MM-dd"),
                                start_time,
                                end_time,
                                date_created,
                            });
                            if (!res.success) throw new Error("Cant post time table");
                        }
                        swal({ text: "Success to add time table", icon: "success" });
                        resetForm();
                        navigate("/timetable/class");
                    } catch (err) {
                        console.log(err);
                        swal({ text: "Failed to save time table", icon: "error" });
                    }
                }}
            >
                {({ values, handleChange, handleBlur, setFieldValue }) => (
                    <Form>
                        <div className="row">
                            <div className="page-title w-100 shadow-sm justify-content-between">
                                <div className="d-flex w-75 gap-5">
                                    <div className="w-25">
                                        <h6 className="form-label">Class</h6>
                                        <Select
                                            id="class"
                                            name="class"
                                            styles={customSelectStyle()}
                                            options={classes}
                                            onChange={(e) => {
                                                setFieldValue("class", e.value);
                                            }}
                                            inputId="classId"
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage name="class">
                                            {(msg) => (
                                                <div
                                                    className="invalid-feedback animated fadeInUp"
                                                    style={{ display: "block" }}
                                                >
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                    <div className="w-25">
                                        <h6 className="form-label">Date</h6>
                                        <DatePicker
                                            name="day"
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            selected={values.day}
                                            onChange={(d) => {
                                                setFieldValue("day", d);
                                            }}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary">
                                        Save Timetable
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="card shadow-sm" style={{ padding: "30px" }}>
                                <FieldArray name="data">
                                    {({ push, remove }) => (
                                        <>
                                            {values.data.map((data, index) => {
                                                return (
                                                    <div className="row " key={index}>
                                                        <div className="card shadow w-100">
                                                            <div className="card-body">
                                                                <div className="row" style={{ marginBottom: "15px" }}>
                                                                    <div className="col-3">
                                                                        <h6 className="form-label">Time</h6>
                                                                        <div className="d-flex">
                                                                            <DatePicker
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                className="form-control"
                                                                                timeIntervals={15}
                                                                                dateFormat="h:mm aa"
                                                                                selected={values.data[index].start_time}
                                                                                onChange={(d) => {
                                                                                    setFieldValue(
                                                                                        `data.${index}.start_time`,
                                                                                        d
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <div
                                                                                className="h-full d-flex align-items-center"
                                                                                style={{ margin: "0px 10px" }}
                                                                            >
                                                                                -
                                                                            </div>
                                                                            <DatePicker
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                className="form-control"
                                                                                timeIntervals={15}
                                                                                dateFormat="h:mm aa"
                                                                                selected={values.data[index].end_time}
                                                                                onChange={(d) => {
                                                                                    setFieldValue(
                                                                                        `data.${index}.end_time`,
                                                                                        d
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="form-label">Title</h6>
                                                                        <input
                                                                            className="form-control"
                                                                            name={`data.${index}.title`}
                                                                            value={values.data[index].title}
                                                                            type="text"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            placeholder="Class title"
                                                                        />
                                                                        <ErrorMessage name={`data.${index}.title`}>
                                                                            {(msg) => (
                                                                                <div
                                                                                    className="invalid-feedback animated fadeInUp"
                                                                                    style={{ display: "block" }}
                                                                                >
                                                                                    {msg}
                                                                                </div>
                                                                            )}
                                                                        </ErrorMessage>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <h6 className="form-label">Color</h6>

                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary form-control"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    values.data[index].color,
                                                                                color: "black",
                                                                                textAlign: "end",
                                                                            }}
                                                                            onClick={() => {
                                                                                setFieldValue(
                                                                                    `data.${index}.showColor`,
                                                                                    true
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className="material-symbols-outlined"
                                                                                style={{ marginRight: "2px" }}
                                                                            >
                                                                                left_click
                                                                            </i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6 className="form-label">Description</h6>
                                                                        <input
                                                                            className="form-control"
                                                                            name={`data.${index}.description`}
                                                                            value={values.data[index].description}
                                                                            type="text"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            placeholder="Class description"
                                                                        />
                                                                    </div>
                                                                    <div className="col-1">
                                                                        {index !== 0 && (
                                                                            <div className="d-flex w-100 h-100 justify-content-end align-items-end">
                                                                                <div
                                                                                    className="icon-box icon-box-sm bg-light me-2 cursor-pointer"
                                                                                    onClick={() => {
                                                                                        remove(index);
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        className="material-symbols-outlined"
                                                                                        style={{
                                                                                            fontSize: 8,
                                                                                            color: "#4D44B5",
                                                                                        }}
                                                                                    >
                                                                                        delete
                                                                                    </i>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {values.data[index].showColor && (
                                                                    <div className="col-12 mt-4 mb-4 d-flex">
                                                                        <SketchPicker
                                                                            name={`data.${index}.color`}
                                                                            width={320}
                                                                            color={values.data[index].color}
                                                                            onChange={(color) =>
                                                                                setFieldValue(
                                                                                    `data.${index}.color`,
                                                                                    color.hex
                                                                                )
                                                                            }
                                                                            onBlur={handleBlur}
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={() => {
                                                                                setFieldValue(
                                                                                    `data.${index}.showColor`,
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            Done
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div className="row mb-4">
                                                <div className="w-100 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                            push({
                                                                start_time: new Date(),
                                                                end_time: new Date(),
                                                                color: "#FFFFFF",
                                                                title: "",
                                                                description: "",
                                                                showColor: false,
                                                            });
                                                        }}
                                                    >
                                                        + Add Class
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddTimeTable;
