import { Row } from 'react-bootstrap';
import financeApi from '../../../../../api/financeApi';
import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Invoice No',
		Footer: 'Invoice No',
		accessor: 'id',
	},
	{
		Header: 'Action',
		Footer: 'Action',
		accessor: (row) => row,
		Cell: ({ value }) => {
			return (
				<Row>
					<span
						className={`user-select-none badge badge-md light badge-${
							value.status === 'Paid' ? 'success' : 'primary cursor-pointer '
						}`}>
						{value.status === 'Paid' ? 'Paid' : 'Manual Pay'}
					</span>
				</Row>
			);
		},
	},
	{
		Header: 'Invoice',
		Footer: 'Invoice',
		accessor: 'pdf_url',
		Cell: ({ value }) => {
			return (
				<a href={value} target='_blank' rel='noreferrer' download={`test.pdf`}>
					<i className='mdi mdi-24px mdi-download ' />
				</a>
			);
		},
	},
	{
		Header: 'Invoice Date',
		Footer: 'Invoice Date',
		accessor: 'invoice_date',
		Cell: ({ value }) => {
			return moment(new Date(value)).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Paid Date',
		Footer: 'Paid Date',
		accessor: 'date_paid',
		Cell: ({ value }) => {
			return moment(new Date(value)).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
		Cell: ({ value }) => {
			return <span className={`badge badge-sm light badge-${value === 'Paid' ? 'success' : 'danger'}`}>{value}</span>;
		},
	},
	{
		Header: 'Amount',
		Footer: 'Amount',
		accessor: 'total',
		Cell: ({ value }) => {
			if (Number(value) < 0) return <span className='text-red'>{`(RM ${Math.abs(Number(value)).toFixed(2).toLocaleString()})`}</span>;
			else return `RM ${Number(value).toFixed(2).toLocaleString()}`;
		},
	},
	{
		Header: 'Receipt',
		Footer: 'Receipt',
		accessor: 'receipt_url',
		Cell: ({ value }) => {
			return (
				<a href={String(value).startsWith('http') ? value : undefined} target='_blank' rel='noreferrer' download={`test.pdf`}>
					<i className='mdi mdi-24px mdi-download ' />
				</a>
			);
		},
	},
];

export async function getBilling(date) {
	try {
		//new Date(date).toISOString()
		let res = await financeApi.billingList(date);
		if (res.success) {
			return res.data.filter((t) => Number(t.total) > 0);
		} else {
			return [];
		}
	} catch (err) {
		console.log(err.message);
		return [];
	}
}
export async function payBilling(data) {
	try {
		//new Date(date).toISOString()
		let res = await financeApi.billingPay(data);
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err.message);
		return [];
	}
}
export async function AdhocBilling(data) {
	return await financeApi.adhocBilling(data);
}
