import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import TimePickerPicker from 'react-time-picker';
import { IMAGES } from '../Dashboard/Content';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import data from '../../data.json';
import { customSelectStyle } from '../../../services/general';
import { getStaffList } from './utils';
import taskApi from '../../../api/weeklyTaskApi';
import swal from 'sweetalert';
import moment from 'moment';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const AddNewTask = () => {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dt = moment(location?.state?.taskDetails[0]?.date_start).format('YYYY-MM-DD');
	const [teacherList, setTeacherList] = useState([]);
	const [taskList, setTaskList] = useState([]);
	const [taskValue, setTaskValue] = useState({
		title: '',
		description: '',
		date_start: location?.state?.taskDetails[0]?.date ? new Date(location?.state?.taskDetails[0]?.date) : new Date(),
		date_end: location?.state?.taskDetails[0]?.date ? new Date(location?.state?.taskDetails[0]?.date) : new Date(),
		assigned_to: location?.state?.taskDetails[0]?.assigned_to ?? 0,
		start_time: null,
		end_time: null,
	});

	const getParentsDropdownList = async () => {
		const list = await getStaffList();
		setTeacherList(list);
	};
	const taskSubmitHandler = (e) => {
		e.preventDefault();
		setTaskList([...taskList, taskValue]);
		setTaskValue({
			...taskValue,
			title: '',
			description: '',
			start_time: null,
			end_time: null,
		});
	};
	const handleTaskDelete = (index) => {
		const newTaska = [...taskList];
		newTaska.splice(index, 1);
		setTaskList(newTaska);
	};
	useEffect(() => {
		getParentsDropdownList();
		setTaskList(location?.state?.taskDetails ?? []);
	}, []);

	const taskDataSubmit = async () => {
		for (let item of taskList) {
			const inital = {
				title: item?.title,
				description: item?.description,
				date_start: moment(item?.date_start).format('YYYY-MM-DD'),
				date_end: moment(item?.date_end).format('YYYY-MM-DD'),
				assigned_to: item?.assigned_to,
				start_time: item?.start_time,
				end_time: item?.end_time,
			};
			await taskApi.createTask(inital).then((res) => {
				if (res.success) {
					swal('Success', 'You have added a task', 'success').then(() => {
						navigate('..', { relative: 'path', replace: true });
					});
				} else {
					swal('Oops', res?.error || 'Something went wrong!', 'error');
				}
				console.log(res);
			});
		}
	};
	return (
		<form onSubmit={taskSubmitHandler}>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='card'>
						<div className='card-header'>
							<h5 className='mb-0'>Task Details</h5>
						</div>
						<div className='card-body'>
							<div className='row'>
								<div className='col-xl-12 col-lg-12'>
									<div className='row'>
										<div className='col-xl-6 col-sm-6'>
											<div className='mb-3'>
												<label htmlFor='exampleFormControlInput5' className='form-label text-primary'>
													Teacher's Name
													<span className='required'>*</span>
												</label>

												<Select
													inputId='religion'
													styles={customSelectStyle()}
													options={teacherList}
													value={teacherList?.filter(({ value }) => value === taskValue?.assigned_to)}
													onChange={(e) => setTaskValue({ ...taskValue, assigned_to: e.value })}
													placeholder={'Select Teacher Name'}
												/>
											</div>
											<div className='form-group mb-3'>
												<label className='text-black font-w500'>Weekly Task</label>
												<div id='line2' className='input-group mb-3'>
													<div className='input-group-prepend'>
														<div className='input-group-text checklist4'>
															<i className='fa fa-square' style={{ color: '#E4E4E4' }}></i>
														</div>
													</div>
													<input
														type='text'
														className='form-control custext'
														value={taskValue.title}
														onChange={(e) =>
															setTaskValue({
																...taskValue,
																title: e.target.value,
															})
														}
														name='tasklist'
														required='required'
														placeholder='Write your task here'
													/>
												</div>
											</div>
										</div>
										<div className='col-xl-6 col-sm-6'>
											<div className='mb-3'>
												<label className='form-label text-primary'>
													Date
													<span className='required'>*</span>
												</label>
												<div style={{ display: 'grid' }}>
													<DatePicker
														className='form-control'
														selected={taskValue.date_start}
														// onChange={(e) => setFieldValue('dob', e)}
														onChange={(date) =>
															setTaskValue({
																...taskValue,
																date_start: date,
																date_end: date,
															})
														}
														name='date_start'
													/>
												</div>
											</div>
											<div className='mb-3'>
												<label className='form-label text-primary'>
													Time
													<span className='required'>*</span>
												</label>
												<div
													style={{
														justifyContent: 'space-between',
														display: 'flex',
													}}>
													<TimePickerPicker
														onChange={(date) => {
															console.log('onchange', date);
															setTaskValue({ ...taskValue, start_time: date });
														}}
														maxDetail='second'
														value={taskValue.start_time}
														format='HH:mm:ss'
														openClockOnFocus={false}
														clockIcon={false}
														className='w-100'
														locale='my-MY'
													/>
													<span
														style={{
															textAlign: 'center',
															alignContent: 'center',
															padding: '10px',
														}}>
														-
													</span>
													<TimePickerPicker
														onChange={(date) => {
															setTaskValue({ ...taskValue, end_time: date });
															console.log('onchange', date);
														}}
														value={taskValue.end_time}
														format='HH:mm:ss'
														maxDetail='second'
														openClockOnFocus={false}
														clockIcon={false}
														className='w-100'
														locale='my-MY'
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-xl-12 col-lg-12'>
									<div className='form-group mb-3 mt-3' style={{ textAlign: 'center' }}>
										<button
											style={{
												textAlign: 'center',
												cursor: 'pointer',
												background: 'transparent',
												border: 'none',
												color: '#303972',
												fontWeight: '600',
											}}
											type={!location?.state?.isView ? 'submit' : 'button'}>
											+ Add task
										</button>
									</div>
								</div>
								<div className='col-xl-12 col-lg-12'>
									{taskList?.length > 0 &&
										taskList?.map((v, i) => {
											return (
												<div key={i}>
													<div id='line' className='input-group mb-3'>
														<div className='input-group-prepend'>
															<div className='input-group-text checklist'>
																<input
																	type='checkbox'
																	className='form-list-input'
																	aria-label='Checkbox for following text input'
																	checked={v.complete}
																	disabled
																/>
															</div>
														</div>
														<input
															type='text'
															className='form-control custext'
															aria-label='Text input with checkbox'
															value={v.title}
															disabled
														/>
														<div
															className='input-group-append'
															onClick={() => !location?.state?.isView && handleTaskDelete(i)}
															style={{ cursor: 'pointer' }}
															// disabled={location?.state?.isView}
															aria-disabled={location?.state?.isView}>
															<span className='input-group-text checklist3'>
																<i className='fa fa-trash' style={{ color: '#303972' }}></i>
															</span>
														</div>
													</div>
													{(v.start_time !== null || v.start_time !== null) && (
														<div className='input-group mb-3' style={{ width: '55%' }}>
															<div className='input-group-prepend'>
																<span className='input-group-text checklist2' id='basic-addon1'>
																	<i className='fa fa-clock' />
																</span>
															</div>
															<p className='form-control'>
																{v.start_time !== null && v.start_time + '-'}
																{v.end_time !== null && v.end_time}
															</p>
														</div>
													)}
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='d-flex justify-content-end '>
				{location?.state?.isView ? (
					<button
						className='btn btn-secondary w-100'
						type='button'
						onClick={() => {
							navigate(-1);
						}}>
						Back
					</button>
				) : (
					<button
						className='btn btn-primary w-100'
						type='button'
						onClick={() => {
							taskDataSubmit();
						}}>
						Save
					</button>
				)}
			</div>
		</form>
	);
};

export default AddNewTask;
