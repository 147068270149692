import apiHandler from './apiHandler';

export type SchoolInfo = {
	id?: number;
	name: string;
	address_1?: string | null;
	address_2?: string | null;
	city?: string | null;
	state?: string | null;
	postcode?: string | null;
	logoUrl?: string | null;
	email_server_host?: string | null;
	email_server_port?: number | null;
	email_user?: string | null;
	email_password?: string | null;
	email_ssl?: boolean | null;
	email_address?: string | null;
	prorataLeave?: boolean | null;
	hasQualifiedNurse?: boolean | null;
};

export async function get() {
	return await apiHandler.get(`/settings/school`);
}

export async function update(postData: SchoolInfo) {
	return await apiHandler.put('/settings/school', postData);
}

const schoolSetupApi = {
	get,
	update,
};

export default schoolSetupApi;
