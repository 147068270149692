import apiHandler from './apiHandler';

export function createMenu(param: { date: string; type: string; name: string }) {
	return apiHandler.post(`/food/meals`, param);
}

export function getFood(day: string) {
	return apiHandler.get('/food/meals?date=' + day);
}

export function updateFood(param: { id: number; type: string; mealName: string }) {
	return apiHandler.put('/food/meals', param);
}

export function deleteFood(id: number) {
	return apiHandler.delete('/food/meals', { data: { id } });
}

export function getMeal() {
	return apiHandler.get('/food');
}

export function postFood(data: { name: string; description: string }) {
	return apiHandler.post('/food', data);
}

const FoodApi = {
	createMenu,
	getFood,
	postFood,
	getMeal,
	updateFood,
	deleteFood,
};

export default FoodApi;
