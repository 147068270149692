import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { customSelectStyle } from '../../../../../services/general';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import feesApi, { FeeTypeList, FeeType } from '../../../../../api/feesApi';

const NewFee = ({ setIsOpenModal, isOpenModal, isStaff, reload, attendanceList, date }) => {
	const [validation, setValidation] = useState({});

	const [feeTypes] = useState(FeeTypeList());

	useEffect(() => {
		console.log(feeTypes);
	}, []);

	const initialValues = {
		description: undefined,
		amount: undefined,
		type: FeeType.Monthly,
	};

	const validationSchema = {
		description: Yup.string().required('Please input a description'),
		amount: Yup.number().required('Please input an amount'),
	};

	useEffect(() => {
		setValidationScheme();
	}, []);

	const setValidationScheme = () => {
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const handleSubmit = async (e) => {
		console.log(e);
		try {
			const res = await feesApi.createFee({ description: e.description, amount: e.amount, type: e.type });
			if (res.success) {
				setIsOpenModal(false);
				reload();
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Modal onHide={setIsOpenModal} show={isOpenModal} centered backdrop='static'>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title'>Add Device</h5>

					<button title='close' type='button' className='btn-close' onClick={() => setIsOpenModal(false)}></button>
				</div>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validationSchema={validation}
					onSubmit={(e) => handleSubmit(e)}>
					{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
						return (
							<div className='modal-body'>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Description<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='description'
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Description'
									/>
									{errors?.description && touched.description && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.description}
										</div>
									)}
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Fee Type
									</label>
									<Select
										styles={customSelectStyle()}
										options={feeTypes}
										onChange={(e) => {
											setFieldValue('type', e.value);
										}}
										onBlur={handleBlur}
										// defaultValue={values.gender}
										inputId='type'
									/>
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Amount
									</label>

									<input
										type='text'
										className='form-control'
										name='amount'
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Amount'
									/>
									{errors?.amount && touched.amount && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.amount}
										</div>
									)}
								</div>

								<button type='button' className='btn btn-primary w-100 mt-3' onClick={handleSubmit}>
									Save
								</button>
							</div>
						);
					}}
				</Formik>
			</div>
		</Modal>
	);
};
export default NewFee;
