export enum ACL {
	Student = 0x1,
	Teacher = 0x2,
	Attendance = 0x4,
	Class = 0x8,
	Finance = 0x10,
	Calendar = 0x20,
	ProgressReport = 0x40,
	Survey = 0x80,
	LeaveManagement = 0x100,
	News = 0x200,
	ResourceManagement = 0x400,
	Medication = 0x800,
	Holidays = 0x1000,
	Tasks = 0x2000,
	Settings = 0x4000,
	UserAdmin = 0x8000,
	Food = 0x10000,
	Chat = 0x20000,
	TimeTable = 0x40000,
	Health = 0x80000,
	Evaluation = 0x100000,
	// spare_2 = 0x200000,
}

export const MappedACLs: Record<string, number> = {
	student: ACL.Student,
	staff: ACL.Teacher,
	attendance: ACL.Attendance,
	leave: ACL.LeaveManagement,
	medication: ACL.Medication,
	admin: ACL.UserAdmin,
	resourcemanagement: ACL.ResourceManagement,
	survey: ACL.Survey,
	class: ACL.Class,
	finance: ACL.Finance,
	news: ACL.News,
	calendar: ACL.Calendar,
	progressreport: ACL.ProgressReport,
	settings: ACL.Settings,
	holidays: ACL.Holidays,
	tasks: ACL.Tasks,
	food: ACL.Food,
	chat: ACL.Chat,
	timetable: ACL.TimeTable,
	health: ACL.Health,
	evaluation: ACL.Evaluation,
	// spare_2: ACL.spare_2,
};

export default function useACL(acl: number) {
	return (cat: string): boolean => {
		const access: number = MappedACLs[cat.toLowerCase()];
		if (access) return (acl & access) === access;
		return false;
	};
}

export const testAcl =
	ACL.Student |
	ACL.Teacher |
	ACL.Attendance |
	ACL.LeaveManagement |
	ACL.Medication |
	ACL.UserAdmin |
	ACL.ResourceManagement |
	ACL.Survey |
	ACL.Class |
	ACL.Finance |
	ACL.News |
	ACL.Calendar |
	ACL.ProgressReport |
	ACL.Holidays |
	ACL.Tasks |
	ACL.Settings |
	ACL.Food |
	ACL.Chat |
	ACL.TimeTable |
	ACL.Health |
	ACL.Evaluation;

//| ACL.spare_2;
