import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { COLUMNS, getPayroll, preparePost, runPayrollPatch, editPayroll } from './component/tableData';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import swal from 'sweetalert';
import EmptyState from '../../components/EmptyState';
import Pagination from '../../components/Pagination';
import * as dateFn from 'date-fns';
import { Modal, ProgressBar } from 'react-bootstrap';
import MonthPicker from './component/MonthPicker';

/**
 *
 * Row Data
 * comment: null | string;
 * datePrepared : "2024-01-31T10:04:42.190Z"
 * epf : "220"
 * monthYear : "0.0004940711462450593"
 * name : "A Test Teacher"
 * pcb : "0"
 * salary : "2000"
 * salaryBasis : "Monthly"
 * schoolId : 1
 * socso : "0"
 * staffId : 16
 * status : "Pending"
 *
 */

const Payroll = () => {
	const childRef = useRef();

	const [payrollList, setPayrollList] = useState([]);
	const [openModel, setOpenModel] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [payrollCounter, setPayrollCounter] = useState(0);
	const [progress, setProgress] = useState(0);
	const [selectedData, setSelectedData] = useState(undefined);
	const [editedData, setEditedData] = useState(undefined);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => payrollList, [payrollList]);
	const [startDate, setStartDate] = useState(new Date());

	useEffect(() => {
		getList(dateFn.endOfMonth(startDate));
	}, [startDate]);

	useEffect(() => {
		const runProgress = (prog) => {
			if (openLoader && payrollCounter > 0) {
				const tt = 100 / (payrollCounter * 6);
				const pecentage = prog + tt;
				if (pecentage > 100) {
					setProgress(0);
					setOpenLoader(false);
					setPayrollCounter(0);
					getList(dateFn.endOfMonth(startDate));
					swal('Success', 'Payroll is Generated', 'success');
					return;
				}
				setProgress(pecentage);
			}
		};
		if (openLoader) {
			setTimeout(() => {
				runProgress(progress);
			}, 1000);
		}
	}, [openLoader, payrollCounter, progress]);

	const getList = async (date) => {
		const list = await getPayroll(date);
		const pendingRun = list.filter((f) => f.status.toLowerCase() === 'pendingrun');
		setPayrollCounter(pendingRun.length ?? 0);
		setPayrollList(list);
	};

	const prepare = async () => {
		await preparePost();
		window.location.reload();
	};

	const prepareRun = async () => {
		if (payrollCounter > 0) {
			setOpenLoader(true);
			await runPayrollPatch(startDate);
		}
	};

	const openEditModel = (data) => {
		if (String(data.status).toLowerCase() !== 'paid') {
			setOpenModel(true);
			setSelectedData(data);
			setEditedData(data);
		}
	};
	const closeEditModel = () => {
		setOpenModel(false);
		setSelectedData(undefined);
		setEditedData(undefined);
	};

	useEffect(() => {
		if (!openModel) getList(dateFn.endOfMonth(startDate));
	}, [openModel]);

	const tableInstance = useTable({ columns, data }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;

	let navigate = useNavigate();
	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title  shadow-sm flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
								<MonthPicker startDate={startDate} setStartDate={setStartDate} />
							</div>
						</div>

						{/* Balance Sheet */}
						<div className='col-xl-12'>
							<div className='col-xl-12'>
								<div className='card'>
									<div className='card-body pb-xl-4 pb-sm-3 pb-0'>
										<div className='row '>
											{payrollList.length ? (
												<button
													className='btn btn-md btn-danger text-white '
													style={{ width: '200px' }}
													onClick={prepareRun}
													title='Run Payroll'>
													Run Payroll
												</button>
											) : (
												<button
													className='btn btn-md btn-primary text-white '
													style={{ width: '200px' }}
													onClick={prepare}
													title='Prepare'>
													Prepare
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* ------------------------ */}

						{/* Date Picker Sheet */}
						{/* <div className='row'>
							<div className='col-xl-12'>
								<div className='card'>
									<div className='card-body p-4'>
										Choose Month:
										<div className='pt-1 row'>
											<DatePicker
												selected={startDate}
												onChange={(date) => setStartDate(date)}
												dateFormat='MM/yyyy'
												// includeDates={[1661990400000, 1664582400000, 1667260800000, 1672531200000]}
												showMonthYearPicker
												inline
											/>
										</div>
									</div>
								</div>
							</div>
						</div> */}
						{/* --------------------------------------- */}

						<div className='col-xl-12 wow fadeInUp ' data-wow-delay='1.5s'>
							{payrollList.length ? (
								<div className='table-responsive full-data'>
									<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='shadow-sm table-responsive-lg table table-hover display dataTablesCard student-tab dataTable no-footer '>
											<thead>
												{headerGroups.map((headerGroup) => {
													return (
														<tr {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column) => (
																<th
																	style={{
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		whiteSpace: 'nowrap',
																		minWidth: '2rem',
																		maxWidth: '7rem',
																	}}
																	{...column.getHeaderProps()}>
																	{column.render('Header')}
																</th>
															))}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row) => {
													prepareRow(row);
													return (
														<tr
															{...row.getRowProps()}
															role='button'
															onClick={() => openEditModel(row.original)}>
															{row.cells.map((cell) => {
																return (
																	<td
																		className={row.original.status === 'NA' ? '' : 'text-black'}
																		{...cell.getCellProps()}>
																		{cell.value ? cell.render('Cell') : '-'}
																	</td>
																);
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
									</div>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal className='modal fade' show={openModel} onHide={setOpenModel} centered>
				<div className='modal-header'>
					<h4 className='modal-title fs-20'>Edit Payroll Data</h4>
					<button
						title='close'
						type='button'
						className='btn-close'
						onClick={() => setOpenModel(false)}
						data-dismiss='modal'></button>
				</div>
				<div className='modal-body'>
					<div className='add-contact-box'>
						<div className='add-contact-content'>
							<div className='form-group mb-3'>
								<label className='text-black font-w500'>Name</label>
								<br />
								<label className='text-black font-w700 h4 ms-3'>{selectedData?.name}</label>
							</div>
							<div className='row'>
								<div className='col-6'>
									<div className='form-group mb-3'>
										<label className='text-black font-w500'>
											Salary<i className='text-red'>*</i>
										</label>
										<div className='contact-occupation'>
											<input
												type='number'
												min={0}
												defaultValue={selectedData?.salary}
												autoComplete='off'
												onChange={(e) => setEditedData({ ...editedData, salary: Number(e.target.value) })}
												name='classname'
												required='required'
												className='form-control'
											/>
										</div>
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group mb-3'>
										<label className='text-black font-w500'>
											Allowance<i className='text-red'>*</i>
										</label>
										<div className='contact-occupation'>
											<input
												type='number'
												min={0}
												defaultValue={selectedData?.allowance}
												autoComplete='off'
												onChange={(e) => setEditedData({ ...editedData, allowance: Number(e.target.value) })}
												name='classname'
												required='required'
												className='form-control'
											/>
										</div>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-6'>
									<div className='form-group mb-3'>
										<label
											data-toggle='tooltip'
											title='Potongan Cukai Bulanan / Monthly tax deduction'
											className='text-black font-w500'>
											Employer EPF
										</label>
										<div className='contact-occupation'>
											<input
												type='number'
												min={0}
												autoComplete='off'
												defaultValue={selectedData?.epfEmployer}
												onChange={(e) => setEditedData({ ...editedData, epfEmployer: Number(e.target.value) })}
												name='classname'
												required='required'
												className='form-control'
											/>
										</div>
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group mb-3'>
										<label
											data-toggle='tooltip'
											title='Potongan Cukai Bulanan / Monthly tax deduction'
											className='text-black font-w500'>
											Employee EPF
										</label>
										<div className='contact-occupation'>
											<input
												type='number'
												min={0}
												autoComplete='off'
												defaultValue={selectedData?.epf}
												onChange={(e) => setEditedData({ ...editedData, epf: Number(e.target.value) })}
												name='classname'
												required='required'
												className='form-control'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-6'>
									<div className='form-group mb-3'>
										<label
											data-toggle='tooltip'
											title='Potongan Cukai Bulanan / Monthly tax deduction'
											className='text-black font-w500'>
											Employer EIS
										</label>
										<div className='contact-occupation'>
											<input
												type='number'
												min={0}
												autoComplete='off'
												defaultValue={selectedData?.eisEmployer}
												onChange={(e) => setEditedData({ ...editedData, eisEmployer: Number(e.target.value) })}
												name='classname'
												required='required'
												className='form-control'
											/>
										</div>
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group mb-3'>
										<label
											data-toggle='tooltip'
											title='Potongan Cukai Bulanan / Monthly tax deduction'
											className='text-black font-w500'>
											Employee EIS
										</label>
										<div className='contact-occupation'>
											<input
												type='number'
												min={0}
												autoComplete='off'
												defaultValue={selectedData?.eis}
												onChange={(e) => setEditedData({ ...editedData, eis: Number(e.target.value) })}
												name='classname'
												required='required'
												className='form-control'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-6'>
									<div className='form-group mb-3'>
										<label
											data-toggle='tooltip'
											title='Potongan Cukai Bulanan / Monthly tax deduction'
											className='text-black font-w500'>
											Employer SOCSO
										</label>
										<div className='contact-occupation'>
											<input
												type='number'
												min={0}
												autoComplete='off'
												defaultValue={selectedData?.socsoEmployer}
												onChange={(e) => setEditedData({ ...editedData, socsoEmployer: Number(e.target.value) })}
												name='classname'
												required='required'
												className='form-control'
											/>
										</div>
									</div>
								</div>
								<div className='col-6'>
									<div className='form-group mb-3'>
										<label
											data-toggle='tooltip'
											title='Potongan Cukai Bulanan / Monthly tax deduction'
											className='text-black font-w500'>
											Employee SOCSO
										</label>
										<div className='contact-occupation'>
											<input
												type='number'
												min={0}
												autoComplete='off'
												defaultValue={selectedData?.socso}
												onChange={(e) => setEditedData({ ...editedData, socso: Number(e.target.value) })}
												name='classname'
												required='required'
												className='form-control'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='form-group mb-3'>
								<label
									data-toggle='tooltip'
									title='Potongan Cukai Bulanan / Monthly tax deduction'
									className='text-black font-w500'>
									PCB
								</label>
								<div className='contact-occupation'>
									<input
										type='number'
										min={0}
										autoComplete='off'
										defaultValue={selectedData?.pcb}
										onChange={(e) => setEditedData({ ...editedData, pcb: Number(e.target.value) })}
										name='classname'
										required='required'
										className='form-control'
									/>
								</div>
							</div>

							<div className='form-group mb-3'>
								<label className='text-black font-w500'>Status</label>
								<div className='contact-occupation'>
									<select
										className='form-select'
										style={{ backgroundColor: 'white', color: '#303a6f', borderColor: '#dddddd' }}
										defaultValue={selectedData?.status}
										onChange={(e) => setEditedData({ ...editedData, status: e.target.value })}>
										<option value='Pending'>Pending</option>
										<option value='NA'>No Pay</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='modal-footer'>
					<button
						className='btn btn-primary'
						onClick={async () => {
							const res = await editPayroll(selectedData?.monthYear, selectedData?.staffId, editedData);
							// console.log(editedData);
							if (res.success) closeEditModel();
							else {
								console.log(res.error);
								swal('Oops', 'Something went wrong!', 'error');
							}
						}}>
						Update
					</button>
				</div>
			</Modal>

			<Modal className='modal fade' show={openLoader} centered>
				<div className='modal-header'>
					<h4 className='modal-title fs-20 center'>Generating Payslip...</h4>
				</div>
				<div className='modal-body'>
					<ProgressBar animated now={progress} />
				</div>
			</Modal>
		</>
	);
};

export default Payroll;
