import medicationAPI from '../../../../api/medicationApi';

import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Student Name',
		Footer: 'Student Name',
		accessor: 'student.name',
	},
	{
		Header: 'Start Date',
		Footer: 'Start Date',
		accessor: 'startDate',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'End Date',
		Footer: 'End Date',
		accessor: 'endDate',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Medicine Name',
		Footer: 'Medicine Name',
		accessor: 'medicine[0].name',
	},
	{
		Header: 'Mode',
		Footer: 'Mode',
		accessor: 'medicine[0].consumptionMode',
	},
	{
		Header: 'Remarks',
		Footer: 'Remarks',
		accessor: 'medicine[0].remarks',
	},
	{
		Header: 'Given By',
		Footer: 'Given By',
		accessor: 'medicine[0].givenBy',
	},
	{
		Header: 'Acknowledged By',
		Footer: 'Acknowledged By',
		accessor: 'acknowledgedBy',
	},
];

export async function getMedication(date) {
	try {
		let res = await medicationAPI.medicationList(date);
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
	}
}
