import apiHandler from './apiHandler';

export function parentList() {
	// return apiHandler.post('/auth/login', postData);
	return apiHandler.get('/parent');
}
export function studentList() {
	return apiHandler.get('/student');
}

export function createStudent(postData: any) {
	return apiHandler.post('/student', postData);
}
export function updateStudent(postData: any, studentId: number) {
	return apiHandler.put(`/student/${studentId}`, postData);
}

export function createParent(postData: any) {
	return apiHandler.post('/parent', postData);
}

export function getStudentDetails(id: number) {
	return apiHandler.get('/student/' + id);
}
export function deleteStudent(id: number) {
	return apiHandler.delete('/student/' + id);
}

const studentAPI = {
	parentList,
	studentList,
	createStudent,
	getStudentDetails,
	createParent,
	deleteStudent,
	updateStudent,
};

export default studentAPI;
