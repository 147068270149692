import useMediaServer from './useMediaServer';
export function customSelectStyle() {
	const style = {
		control: (style) => ({
			...style,
			background: '#fff',
			color: '#303972',
			lineHeight: 1.7,
			fontSize: '0.9rem',
			height: '3rem',
			borderRadius: '0.375rem',
			borderColor: '#dddddd',
		}),
	};

	return style;
}

export const formatDate = (formatDt) => {
	const date = new Date(formatDt);
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let dt = date.getDate();

	if (dt < 10) {
		dt = '0' + dt;
	}
	if (month < 10) {
		month = '0' + month;
	}
	const formattedDt = dt + '/' + month + '/' + year;
	return formattedDt;
};

export const formatCurrency = (currency) => {
	let curr = Number(currency) < 0 ? parseFloat(Math.abs(currency)).toFixed(2) : parseFloat(currency).toFixed(2);
	curr = curr.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
	const myr = `RM ${curr}`;
	if (Number(currency) < 0) return <span className='text-red'>{`(${myr})`}</span>;
	return myr;
};

export const formatParams = (obj) => {
	if (Object.entries(obj).length === 0) return '';
	if (Object.values(obj).every((val) => val === undefined)) return '';
	return (
		'?' +
		Object.entries(obj)
			.filter(([, value]) => value !== null)
			.map(([key, value]) => `${key}=${value}`)
			.join('&')
	);
};

export const GenerateMediaUrl = async (file, config) => {
	const { uploadMedia } = useMediaServer();
	const res = await uploadMedia(file, config);
	return res[0].url;
};

export const FoodTypeOption = [
	{ label: 'Breakfast', value: 'Breakfast' },
	{ label: 'Lunch', value: 'Lunch' },
	{ label: 'Snack', value: 'Snack' },
	{ label: 'Dinner', value: 'Dinner' },
];

export const isDarkColor = (color) => {
	let r, g, b;
	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
		// If HEX --> store the red, green, blue values in separate variables
		color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

		r = color[1];
		g = color[2];
		b = color[3];
	} else {
		// If RGB --> Convert it to HEX: http://gist.github.com/983661
		color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

		r = color >> 16;
		g = (color >> 8) & 255;
		b = color & 255;
	}

	// HSP equation from http://alienryderflex.com/hsp.html
	const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 127.5) {
		return false;
	} else {
		return true;
	}
};
