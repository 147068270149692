import React, { useRef, useState, useEffect, useMemo } from 'react';
import { AdhocBilling, COLUMNS, getBilling, payBilling } from './component/tableData';
import { Link } from 'react-router-dom';
import BasicModal from '../../Dashboard/BasicModal';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import swal from 'sweetalert';
import EmptyState from '../../components/EmptyState';
import Pagination from '../../components/Pagination';
import MonthPicker from './component/MonthPicker';
import { Modal, Spinner } from 'react-bootstrap';
import { studentList } from '../../../../api/studentApi';

const Billing = () => {
	const childRef = useRef();
	const formRef = useRef();
	const adhocRef = useRef();
	const [openModel, setOpenModel] = useState(false);
	const [openAdhoc, setOpenAdhoc] = useState(false);
	const [showProgress, setShowProgress] = useState(false);
	const [counter, setCounter] = useState([1]);
	const [invoice, setInvoice] = useState(null);

	const [billingList, setBillingList] = useState([]);
	const [students, setStudents] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState(null);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => billingList, [billingList]);
	const [startDate, setStartDate] = useState(new Date());

	useEffect(() => {
		getList(startDate);
		// console.log(testAcl);
		getStudentList();
	}, [startDate]);

	const closeAdhoc = () => {
		setOpenAdhoc(false);
		setCounter([1]);
		setTimeout(() => {
			setSelectedStudent(null);
		}, 600);
	};

	const getList = async (date) => {
		const list = await getBilling(date);
		setBillingList(list);
	};

	const getStudentList = async (date) => {
		const list = await studentList();
		// console.log('sl', list);
		if (list.success) setStudents(list.data);
	};

	const adhocSubmit = async (e) => {
		e.preventDefault();
		if (showProgress) return;
		setShowProgress(true);

		const t = e.target;
		const list = [];
		for (const i of counter) {
			const val = {
				student_id: selectedStudent.student.id,
				description: t['des-' + i].value,
				amount: Number(t['amt-' + i].value),
				feeType: 'Adhoc',
			};
			list.push(val);
		}
		const res = await AdhocBilling({ parent_id: selectedStudent.student.parentId, item: list });
		if (res.success) {
			swal('Success', 'Adhoc Billing is created', 'success').then(() => {
				getList(startDate);
				closeAdhoc();
			});
		} else {
			swal('Error', 'Adhoc Billing is creation failed', 'error').then(() => {
				closeAdhoc();
			});
		}
	};

	const tableInstance = useTable({ columns, data }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;

	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title  shadow-sm flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
								<MonthPicker startDate={startDate} setStartDate={setStartDate} />
							</div>
						</div>

						{/* Balance Sheet */}
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-body pb-xl-4 pb-sm-3 pb-0'>
									<div className='chart-num'>
										<button
											type='button'
											className='btn btn-primary'
											onClick={() => {
												setOpenAdhoc(true);
											}}
										>
											Create AdHoc Billing
										</button>
									</div>
								</div>
							</div>
						</div>
						{/* ------------------------ */}

						<div className='col-xl-12 wow fadeInUp ' data-wow-delay='1.5s'>
							{billingList.length ? (
								<div className='table-responsive full-data'>
									<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer '
										>
											<thead>
												{headerGroups.map((headerGroup) => {
													return (
														<tr {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column) => (
																<th
																	style={{
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		whiteSpace: 'nowrap',
																		minWidth: '2rem',
																		maxWidth: '7rem',
																	}}
																	{...column.getHeaderProps()}
																>
																	{column.render('Header')}
																</th>
															))}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row) => {
													prepareRow(row);
													return (
														<tr {...row.getRowProps()}>
															{row.cells.map((cell) => {
																return (
																	<td
																		{...cell.getCellProps()}
																		onClick={() => {
																			if (
																				cell.column.id === 'Action' &&
																				cell.row.original.status !== 'Paid'
																			) {
																				// console.log(cell.row.original);
																				setOpenModel(true);
																				setInvoice(cell.row.original);
																			}
																		}}
																	>
																		{cell.value ? cell.render('Cell') : '-'}
																	</td>
																);
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
									</div>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<BasicModal ref={childRef} />
			<Modal className='modal fade' show={openModel} onHide={setOpenModel} centered>
				<div className='modal-header'>
					<h4 className='modal-title fs-20 center'>Manual Payment for Billing</h4>
					<button title='close' type='button' className='btn-close' onClick={() => setOpenModel(false)}></button>
				</div>
				{showProgress ? (
					<div className='d-flex justify-content-center p-5'>
						<Spinner style={{ width: 80, height: 80 }} animation='border' role='status' variant='warning'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					</div>
				) : (
					<>
						<div className='modal-body'>
							<form
								ref={formRef}
								onSubmit={async (e) => {
									e.preventDefault();
									const val = e.target;
									const refType = val.refType?.value;
									const paymentType = val.paymentType?.value;
									const ref = val.ref?.value;
									const description = val.description?.value;
									const amount = invoice?.total;
									const res = await payBilling({ refType, paymentType, ref, description, amount, invoiceId: invoice.id });
									if (!Array.isArray(res)) {
										setInvoice(null);
										setOpenModel(false);
										swal('Success', 'You have updated successfully', 'success').then(() => {
											getList(startDate);
											setShowProgress(false);
										});
									} else {
										swal('Error', 'Payment Failed', 'error').then(() => {
											getList(startDate);
											setShowProgress(false);
										});
									}
								}}
							>
								<div className='row'>
									<div className='col-6'>
										<div className='form-group mb-3'>
											<label
												data-toggle='tooltip'
												title='Potongan Cukai Bulanan / Monthly tax deduction'
												className='text-black font-w500'
											>
												Payment Type
											</label>
											<div className='contact-occupation'>
												<select name='paymentType' className='form-control'>
													<option value={'FPX'}> FPX </option>
													<option value={'DUITNOW'}> DUIT NOW</option>
													<option value={'CARD'}> CARD </option>
													<option value={'EWALLET'}> E-WALLET</option>
													<option value={'CASH'}> CASH </option>
													<option value={'CHEQUE'}> CHEQUE </option>
												</select>
											</div>
										</div>
									</div>
									<div className='col-6'>
										<div className='form-group mb-3'>
											<label
												data-toggle='tooltip'
												title='Potongan Cukai Bulanan / Monthly tax deduction'
												className='text-black font-w500'
											>
												Transaction Type
											</label>
											<div className='contact-occupation'>
												<select name='refType' className='form-control'>
													<option value={'FeesInvoice'}>Monthly Fee</option>
													<option value={'DepositInvoice'}>Deposit Fee</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-6'>
										<div className='form-group mb-3'>
											<label
												data-toggle='tooltip'
												title='Potongan Cukai Bulanan / Monthly tax deduction'
												className='text-black font-w500'
											>
												Amount
											</label>
											<div className='contact-occupation'>
												<label
													data-toggle='tooltip'
													title='Potongan Cukai Bulanan / Monthly tax deduction'
													className='text-black font-w800'
												>
													{Number(invoice?.total).toFixed(2)}
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-12'>
										<div className='form-group mb-3'>
											<label
												data-toggle='tooltip'
												title='Potongan Cukai Bulanan / Monthly tax deduction'
												className='text-black font-w500'
											>
												Reference
											</label>
											<div className='contact-occupation'>
												<input
													type='text'
													autoComplete='off'
													// onChange={(e) => setEditedData({ ...editedData, eisEmployer: Number(e.target.value) })}
													name='ref'
													required='required'
													className='form-control'
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-12'>
										<div className='form-group mb-3'>
											<label
												data-toggle='tooltip'
												title='Potongan Cukai Bulanan / Monthly tax deduction'
												className='text-black font-w500'
											>
												Description
											</label>
											<div className='contact-occupation'>
												<input
													type='text'
													autoComplete='off'
													multiple={true}
													// onChange={(e) => setEditedData({ ...editedData, eisEmployer: Number(e.target.value) })}
													name='description'
													required='required'
													className='form-control'
												/>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className='modal-footer'>
							<button className='btn btn-secondary' onClick={() => setOpenModel(false)}>
								Cancel
							</button>
							<button
								className='btn btn-primary'
								onClick={async () => {
									formRef.current.requestSubmit();
								}}
							>
								Manual Payment
							</button>
						</div>
					</>
				)}
			</Modal>
			<Modal className='modal fade' show={openAdhoc} onHide={() => closeAdhoc()} centered size='lg'>
				<div className='modal-header'>
					<h4 className='modal-title fs-20 center'>New Adhoc Invoice</h4>
					<button title='close' type='button' className='btn-close' onClick={() => closeAdhoc()}></button>
				</div>
				{showProgress ? (
					<div className='d-flex justify-content-center p-5'>
						<Spinner style={{ width: 80, height: 80 }} animation='border' role='status' variant='warning'>
							<span className='visually-hidden'>Loading...</span>
						</Spinner>
					</div>
				) : (
					<>
						<div className='modal-body'>
							<form ref={adhocRef} onSubmit={adhocSubmit}>
								<div className='row'>
									<div className='col-6'>
										<div className='form-group mb-3'>
											<label
												data-toggle='tooltip'
												title='Choose the student to bill their parent / guardian'
												className='text-black font-w500'
											>
												Student
											</label>
											<div className='contact-occupation'>
												<select
													className='form-control'
													onChange={(e) => {
														if (Number.isNaN(Number(e.target.value))) return setSelectedStudent(null);
														const stu = students.find((f) => f.id === Number(e.target.value));
														// console.log(stu);
														setSelectedStudent(stu);
													}}
												>
													<option value={null}>Choose a parent</option>
													{students.map((d) => (
														<option value={d.id}>{`${d.name} - (${d.student.parent.name})`}</option>
													))}
												</select>
											</div>
										</div>
									</div>
								</div>
								{/* TODO :  */}
								<div className={selectedStudent ? '' : 'd-none'}>
									<div className='d-flex flex-row-reverse w100' style={{ textAlign: 'end' }}>
										<p
											className='d-flex h3 fa-solid fa-circle-plus cursor-pointer'
											title=''
											onClick={() => {
												const h = counter.toReversed();
												setCounter([...counter, h[0] + 1]);
											}}
										/>
									</div>

									{counter.map((n) => (
										<div key={n} className='row'>
											<div className='col-9'>
												<div className='form-group mb-3'>
													<label
														data-toggle='tooltip'
														title='Description for the adhoc charge'
														className='text-black font-w500'
													>
														Description
													</label>
													<div className='contact-occupation'>
														<input name={`des-${n}`} className='form-control' />
													</div>
												</div>
											</div>
											<div className='col-3'>
												<div className='form-group mb-3'>
													<label data-toggle='tooltip' title='Amount for adhoc' className='text-black font-w500'>
														Amount
													</label>
													<div className='contact-occupation'>
														<input
															name={`amt-${n}`}
															step={0.01}
															type='number'
															min={1}
															className='form-control'
														/>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</form>
						</div>
						<div className='modal-footer'>
							<button className='btn btn-secondary' onClick={() => closeAdhoc()}>
								Cancel
							</button>
							<button
								className='btn btn-primary'
								disabled={!selectedStudent}
								onClick={async () => {
									adhocRef.current.requestSubmit();
								}}
							>
								Create
							</button>
						</div>
					</>
				)}
			</Modal>
		</>
	);
};

export default Billing;
