import React, { useState, useRef } from 'react';
import adminApi from '../../../api/adminApi';
import swal from 'sweetalert';
import { Form } from 'react-bootstrap';

const Notification = () => {
	const formRef = useRef();
	const [title, setTitle] = useState(null);
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(false);

	return (
		<>
			<div className='col-xl-12'>
				<div className='card'>
					<div className='card-body pb-xl-4 pb-sm-3 pb-0'>
						<form
							ref={formRef}
							onSubmit={async (e) => {
								e.preventDefault();
								const t = e.target;
								const destination = Number(t.user.value);
								const title = t.title.value;
								const msg = t.msg.value;
								adminApi
									.broadcast({
										destination,
										title,
										message: msg,
									})
									.then((res) => {
										console.log(res);
									});
								swal('Success', 'Message Broadcasted', 'success').then(() => {
									// window.location.reload();
								});
							}}>
							<div className='chart-num'>
								<div className='d-flex pb-4'>
									<Form.Check type={'radio'} inline label='Teachers' value={1} name='user'></Form.Check>
									<Form.Check required type={'radio'} inline label='Parents' value={2} name='user'></Form.Check>
									<Form.Check type={'radio'} inline label='Both' value={3} name='user'></Form.Check>
								</div>
								<div className='row'>
									<div className='col-6'>
										<div className='form-group mb-3'>
											<label
												data-toggle='tooltip'
												title='Potongan Cukai Bulanan / Monthly tax deduction'
												className='text-black font-w500'>
												<h5>Title</h5>
											</label>
											<div className='contact-occupation'>
												<input name='title' required minLength={2} className='form-control' />
											</div>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-12'>
										<div className='form-group mb-3'>
											<label
												data-toggle='tooltip'
												title='Potongan Cukai Bulanan / Monthly tax deduction'
												className='text-black font-w500'>
												<h5>Message</h5>
											</label>
											<div className=''>
												<textarea name='msg' required minLength={5} className=' w-100 p-2'></textarea>
											</div>
										</div>
									</div>
								</div>
								<p className='text-red'>{error && 'Please fill in all the data'}</p>
								<div className='d-flex flex-row-reverse'>
									<button
										type='submit'
										className='btn btn-primary'
										onClick={() => {
											formRef.current.requestSubmit();
											// if (title?.length && message?.length) {
											// 	setError(false);
											// 	console.log(title, message);
											// } else {
											// 	setError(true);
											// }
										}}>
										Broadcast
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Notification;
