import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { customSelectStyle } from '../../../../../services/general';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import rejectionReasonsApi from '../../../../../api/rejectionReasonsApi';

const AddRejectionReason = ({ setIsOpenModal, isOpenModal, isStaff, reload, attendanceList, date }) => {
	const [validation, setValidation] = useState({});

	const initialValues = {
		name: undefined,
		reason: undefined,
	};

	const validationSchema = {
		name: Yup.string().required('Please input a name'),
		reason: Yup.string().required('Please input a reason'),
	};

	useEffect(() => {
		setValidationScheme();
	}, []);

	const setValidationScheme = () => {
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const handleSubmit = async (e) => {
		console.log(e);
		try {
			const res = await rejectionReasonsApi.createReason({ name: e.name, reason: e.reason });
			if (res.success) {
				setIsOpenModal(false);
				reload();
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Modal onHide={setIsOpenModal} show={isOpenModal} centered backdrop='static'>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title'>Add a Rejection Reason</h5>

					<button title='close' type='button' className='btn-close' onClick={() => setIsOpenModal(false)}></button>
				</div>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validationSchema={validation}
					onSubmit={(e) => handleSubmit(e)}
				>
					{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
						return (
							<div className='modal-body'>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Short Name<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='name'
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Short Name'
									/>
									{errors?.name && touched.name && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.name}
										</div>
									)}
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Reason text<span className='required'>*</span>
									</label>
									<input
										type='text'
										className='form-control'
										name='reason'
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder='Reason text'
									/>
									{errors?.reason && touched.reason && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.reason}
										</div>
									)}
								</div>
								<button type='button' className='btn btn-primary w-100 mt-3' onClick={handleSubmit}>
									Save
								</button>
							</div>
						);
					}}
				</Formik>
			</div>
		</Modal>
	);
};
export default AddRejectionReason;
