import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { customSelectStyle } from '../../../../services/general';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import StaffApi from '../../../../api/staffApi';
import { Form } from 'react-bootstrap';
import studentAPI from '../../../../api/studentApi';
import attendanceAPI from '../../../../api/attendanceApi';
import moment from 'moment';

const NewAttendance = ({ setIsOpenModal, isOpenModal, isStaff, reload, attendanceList, date }) => {
	const [staffList, setStaffList] = useState([]);
	const [studentList, setStudentList] = useState([]);
	const [pickUpPersonList, setPickUpPersonList] = useState([]);
	const [isTimeIn, setIsTimeIn] = useState(true);
	const [validation, setValidation] = useState({});

	useEffect(() => {
		setIsTimeIn(true);
		setPickUpPersonList([]);
		if (isStaff) {
			getStaffList();
		} else {
			getStudentList();
		}
	}, []);

	const getStaffList = async () => {
		const arr = [];

		try {
			const { data } = await StaffApi.getStaffList();
			data.map((item, i) => {
				const obj = { label: item.name, value: item.id };
				arr.push(obj);
			});
			setStaffList(arr);
		} catch (err) {
			console.log(err);
		}
	};
	const getStudentList = async () => {
		const arr = [];
		try {
			const { data } = await studentAPI.studentList();
			data.map((item, i) => {
				const obj = { label: item.name, value: item.id };
				arr.push(obj);
			});
			setStudentList(arr);
		} catch (err) {
			console.log(err);
		}
	};

	const initialValues = {
		personId: undefined,
		time: undefined,
		isTimeIn: false,
		date: moment(date).format('yyyy-MM-DD'),
		temperature: undefined,
		pickupPersonId: undefined,
	};

	const validationSchema = {
		personId: Yup.string().required('Please select the name'),
		time: Yup.string().required('Please set the time'),
		date: Yup.date().required('Please set the date'),
	};

	useEffect(() => {
		setValidationScheme();
	}, [isTimeIn]);

	const setValidationScheme = () => {
		if (isTimeIn) {
			validationSchema.temperature = Yup.number().required('Please enter the temperature');
		}
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const handleSubmit = async (e) => {
		if (e.temperature) {
			e.temperature = Number(e.temperature);
		} else {
			e.temperature = 0;
		}

		try {
			const datetime = new Date(`${e.date} ${e.time}`).toISOString();
			const postData = { ...e, datetime };
			const res = await attendanceAPI.createAttendance(postData);
			if (res.success) {
				setIsOpenModal(false);
				setPickUpPersonList([]);
				reload();
			}
		} catch (err) {
			console.log(err);
		}
	};

	const getPickUpPerson = async (id) => {
		const arr = [];
		try {
			const res = await attendanceAPI.getPickUpPerson(id);
			if (res.success) {
				res.data.map((item, i) => {
					const obj = { label: item.name, value: item.id };
					arr.push(obj);
				});
				setPickUpPersonList(arr);
			}
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<Modal onHide={setIsOpenModal} show={isOpenModal} centered backdrop='static'>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title'>Add {isStaff ? 'Staff' : 'Student'} Attendance</h5>

					<button title='close' type='button' className='btn-close' onClick={() => setIsOpenModal(false)}></button>
				</div>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validationSchema={validation}
					onSubmit={(e) => handleSubmit(e)}>
					{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
						return (
							<div className='modal-body'>
								<div className='mb-3 d-flex justify-content-end'>
									<Form.Check
										type='switch'
										// id='toggle-switch'
										label={`${isTimeIn ? 'Time In' : 'Time Out'}`}
										checked={isTimeIn}
										disabled
										className={`${isTimeIn ? 'text-info' : 'text-danger'}`}
									/>
									{/* <label>{isTimeIn ? 'Time In' : 'Time Out'}</label> */}
								</div>
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Name<span className='required'>*</span>
									</label>
									<Select
										styles={customSelectStyle()}
										options={isStaff ? staffList : studentList}
										onChange={(e) => {
											setFieldValue('personId', e.value);
											setIsTimeIn(true);
											setFieldValue('isTimeIn', true);
											attendanceList.forEach((item) => {
												if (item.personId === e.value) {
													if (!item.timeOut) {
														setIsTimeIn(false);
														setFieldValue('isTimeIn', false);
													} else {
														setIsTimeIn(true);
														setFieldValue('isTimeIn', true);
													}
												}
											});
											if (!isStaff) {
												getPickUpPerson(e.value);
											}
										}}
										onBlur={handleBlur}
										value={(isStaff ? staffList : studentList).filter(({ value }) => value === values.personId)}
										// defaultValue={values.gender}
										inputId='personId'
										placeholder={isStaff ? 'Select Staff' : 'Select Student'}
									/>
									{errors?.personId && touched.personId && typeof errors.personId === 'string' && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.personId}
										</div>
									)}
								</div>
								{isTimeIn && (
									<div className='mb-3'>
										<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
											Temperature<span className='required'>*</span>
										</label>
										<input
											type='text'
											className='form-control'
											name='temperature'
											onChange={handleChange}
											onBlur={handleBlur}
											defaultValue={values.temperature}
										/>
										{errors?.temperature && touched.temperature && typeof errors.temperature === 'string' && (
											<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
												{errors.temperature}
											</div>
										)}
									</div>
								)}
								<div className='mb-3'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Date
									</label>

									<input
										type='date'
										className='form-control'
										name='date'
										onChange={handleChange}
										onBlur={handleBlur}
										defaultValue={values.date}
									/>
									{errors?.date && touched.date && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.date}
										</div>
									)}
								</div>
								<div className='mb-4'>
									<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
										Time
									</label>

									<input type='time' className='form-control' name='time' onChange={handleChange} onBlur={handleBlur} />
									{errors?.time && touched.time && (
										<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
											{errors.time}
										</div>
									)}
								</div>
								{!isStaff && !isTimeIn && (
									<div className='mb-3'>
										<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
											Pick Up Person
										</label>
										<Select
											styles={customSelectStyle()}
											options={pickUpPersonList}
											onChange={(e) => {
												setFieldValue('pickupPersonId', e.value);
											}}
											onBlur={handleBlur}
											value={pickUpPersonList.filter(({ value }) => value === values.pickupPersonId)}
											// defaultValue={values.gender}
											inputId='pickupPersonId'
										/>
									</div>
								)}

								<button type='button' className='btn btn-primary w-100 mt-3' onClick={handleSubmit}>
									Save
								</button>
							</div>
						);
					}}
				</Formik>
			</div>
		</Modal>
	);
};
export default NewAttendance;
