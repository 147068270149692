import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { IMAGES } from '../Dashboard/Content';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { customSelectStyle, GenerateMediaUrl } from '../../../services/general';
import newsAPI from '../../../api/newsApi';
import swal from 'sweetalert';
import { fi } from 'date-fns/locale';

const AddNewNews = () => {
	const location = useLocation();
	const params = useParams();
	const navigate = useNavigate();
	const [image, setImage] = useState(undefined);
	const [file, setFile] = useState(undefined);
	const [avatar, setAvatar] = useState(IMAGES.avat2);
	const [oldfile, setOldFile] = useState(null);
	const [validation, setValidation] = useState({});
	const [newsId, setStudentId] = useState(params.newsId);
	const [newsData, setNewsData] = useState([]);
	const payload = JSON.parse(sessionStorage.getItem('payload'));

	// const [initialValues, setInitialValues] = useState(studentInitialValues);

	// const { uploadMedia, deleteMedia } = useMediaServer();
	const typeOption = [
		{ label: 'News', value: 'News' },
		{ label: 'Event', value: 'Event' },
	];
	useEffect(() => {
		setNewsData(location?.state !== null ? location?.state[0] : []);
		setAvatar(location?.state !== null ? location?.state[0]?.imageUrl : IMAGES.avat2);
		setOldFile(location?.state !== null ? location?.state[0]?.attachmentUrl : undefined);
	}, [location?.state]);

	const fileHandler = (e) => {
		if (e.target.files.length) {
			setFile(e.target.files);
		} else {
			setFile(undefined);
		}
	};
	const imageHandler = (e) => {
		if (e.target.files.length) {
			setImage(e.target.files);
			setAvatar(URL.createObjectURL(e.target.files[0]));
		} else {
			setImage(undefined);
			setAvatar(IMAGES.avat2);
		}
	};
	// const RemoveFile = async () => {
	// 	setFile(null);
	// 	setAvatar(null);
	// 	setIsDeleteAvatar(true);
	// 	// deleteMedia(avatar);
	// };

	const initialValues = {
		id: newsData?.id ?? undefined,
		title: newsData?.title ?? undefined,
		startDate: newsData?.startDate ? new Date(newsData?.startDate) : new Date(),
		endDate: newsData?.endDate ? new Date(newsData?.endDate) : new Date(),
		type: newsData?.type ?? undefined,
		description: newsData?.description ?? undefined,
		imageUrl: newsData?.imageUrl ?? undefined,
		attachmentUrl: newsData?.attachmentUrl ?? undefined,
		isPublic: newsData?.isPublic ?? true,
		postToCalander: newsData?.postToCalander ?? false,
	};
	const validationSchema = {
		title: Yup.string().required('Please fill in the field'),
		startDate: Yup.string().required('Please fill in the field'),
		endDate: Yup.string().required('Please fill in the field'),
		description: Yup.string().required('Please fill in the field'),
		type: Yup.string().required('Please fill in the field'),
	};
	useEffect(() => {
		setValidationScheme();
	}, []);

	const setValidationScheme = () => {
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const handleSubmit = async (e) => {
		if (!newsId) {
			if (file) {
				const config = `school_${payload.schoolId}/news/`;
				e.attachmentUrl = await GenerateMediaUrl(file, config);
			}
			if (image) {
				const config = `school_${payload.schoolId}/news/`;
				e.imageUrl = await GenerateMediaUrl(image, config);
			}
			if (e.startDate) {
				e.startDate = e.startDate.toISOString();
			}
			if (e.endDate) {
				e.endDate = e.endDate.toISOString();
			}
			try {
				const res = await newsAPI.createNews(e);
				if (res.success) {
					swal('Success', 'You have added a Event', 'success').then(() => {
						navigate('..', { relative: 'path', replace: true });
					});
				} else {
					swal('Oops', 'Something went wrong!', 'error');
				}
			} catch (err) {
				console.log(err);
				swal('Oops', 'Something went wrong!', 'error');
			}
		} else {
			try {
				if (image) {
					const config = `school_${payload.schoolId}/news/`;
					e.imageUrl = await GenerateMediaUrl(image, config);
				}
				if (file) {
					const config = `school_${payload.schoolId}/news/`;
					e.attachmentUrl = await GenerateMediaUrl(file, config);
				}

				const res = await newsAPI.updateNews(e);
				if (res.success) {
					swal('Success', 'You have updated successfully', 'success').then(() => {
						navigate('/news');
					});
				} else {
					swal('Oops', 'Something went wrong!', 'error');
				}
			} catch (err) {
				console.log(err);
				swal('Oops', 'Something went wrong!', 'error');
			}
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				enableReinitialize={params.newsId ? true : false}
				validationSchema={validation}
				onSubmit={handleSubmit}>
				{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
					return (
						<>
							<div className='row'>
								<div className='col-xl-12'>
									<div className='card'>
										<div className='card-header'>
											<h5 className='mb-0'>News Details</h5>
										</div>
										<div className='card-body'>
											<div className='row'>
												<div className='col-xl-3 col-lg-4'>
													<label className='form-label text-primary'>
														Photo<span className='required'>*</span>
													</label>
													<div className='avatar-upload'>
														<div className='avatar-preview'>
															<div
																id='imagePreview'
																style={{
																	backgroundImage: 'url(' + avatar + ')',
																}}></div>
														</div>
														<div className='change-btn mt-2 mb-lg-0 mb-3'>
															<input
																type='file'
																className='form-control d-none'
																onChange={imageHandler}
																id='imageUpload'
																accept='.png, .jpg, .jpeg'
															/>
															<label
																htmlFor='imageUpload'
																className='dlab-upload mb-0 btn btn-primary btn-sm'>
																Choose File
															</label>
														</div>
													</div>
												</div>
												<div className='col-xl-9 col-lg-8'>
													<div className='row'>
														<div className='col-xl-6 col-sm-6'>
															<div className='mb-3'>
																<label
																	htmlFor='exampleFormControlInputtitle'
																	className='form-label text-primary'>
																	Title<span className='required'>*</span>
																</label>
																<input
																	type='text'
																	className='form-control'
																	id='exampleFormControlInputtitle'
																	name='title'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	defaultValue={values.title}
																/>
																{errors?.title && touched.title && typeof errors.title === 'string' && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{ display: 'block' }}>
																		{errors.title}
																	</div>
																)}
															</div>
															<div className='mb-3'>
																<label className='form-label text-primary'>
																	Start Date
																	<span className='required'>*</span>
																</label>
																<div style={{ display: 'grid' }}>
																	<DatePicker
																		className='form-control'
																		selected={new Date(values.startDate)}
																		// onChange={(e) => setFieldValue('dob', e)}
																		onSelect={(e) => setFieldValue('startDate', e)}
																		onBlur={handleBlur}
																		name='startDate'
																	/>
																	{errors?.startDate && touched.startDate && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.startDate}
																		</div>
																	)}
																	{/* <input type="text" className="form-control w-50 ms-3" id="exampleFormControlInput7" placeholder="USA" /> */}
																</div>
															</div>
														</div>
														<div className='col-xl-6 col-sm-6'>
															<div className='mb-3'>
																<label htmlFor='type' className='form-label text-primary'>
																	Type<span className='required'>*</span>
																</label>

																<Select
																	styles={customSelectStyle()}
																	options={typeOption}
																	onChange={(e) => setFieldValue('type', e.value)}
																	onBlur={handleBlur}
																	value={typeOption.filter(({ value }) => value === values.type)}
																	// defaultValue={values.gender}
																	inputId='type'
																	placeholder={'Select Type'}
																/>
																{errors?.type && touched.type && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{ display: 'block' }}>
																		{errors.type}
																	</div>
																)}
															</div>
															<div className='mb-3'>
																<label className='form-label text-primary'>
																	End Date
																	<span className='required'>*</span>
																</label>
																<div style={{ display: 'grid' }}>
																	<DatePicker
																		className='form-control'
																		selected={new Date(values.endDate)}
																		// onChange={(e) => setFieldValue('dob', e)}
																		onSelect={(e) => setFieldValue('endDate', e)}
																		onBlur={handleBlur}
																		name='endDate'
																	/>
																	{errors?.endDate && touched.endDate && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.endDate}
																		</div>
																	)}
																	{/* <input type="text" className="form-control w-50 ms-3" id="exampleFormControlInput7" placeholder="USA" /> */}
																</div>
															</div>
														</div>
														<div className='col-xl-12 col-sm-12'>
															<div className='mb-3'>
																<label
																	htmlFor='exampleFormControlTextarea1'
																	className='form-label text-primary'>
																	Description<span className='required'>*</span>
																</label>
																<textarea
																	onChange={handleChange}
																	onBlur={handleBlur}
																	defaultValue={values.description}
																	className='form-control'
																	id='exampleFormControlTextarea1'
																	rows='3'
																	name='description'
																/>
																{errors?.description &&
																	touched.description &&
																	typeof errors.description === 'string' && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.description}
																		</div>
																	)}
															</div>
														</div>
														<div className='col-xl-12 col-sm-12'>
															<div className='mb-3'>
																<label htmlFor='File Attachment' className='form-label text-primary'>
																	File Attachment
																</label>
																<div className='d-flex change-btn mt-2 mb-lg-0 mb-3 align-items-center'>
																	{oldfile ? (
																		<a href={oldfile} target='_black' rel='no-reffrer'>
																			<span className={`badge badge-sm light badge-info`}>
																				Download File
																			</span>
																		</a>
																	) : (
																		<>
																			<input
																				type='file'
																				className='form-control d-none'
																				onChange={fileHandler}
																				id='attachment'
																				accept='application/pdf'
																			/>
																			<label
																				htmlFor='attachment'
																				className='dlab-upload mb-0 btn btn-primary btn-sm'>
																				Choose File
																			</label>
																			<p className='m-2'> {file ? file[0].name : ''}</p>
																		</>
																	)}
																</div>
															</div>
														</div>
														<div className='col-xl-6 col-sm-6'>
															<div className='mb-3'>
																<label
																	className='form-check css-control-primary css-checkbox'
																	htmlFor='isPublic'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		id='isPublic'
																		name='isPublic'
																		value={values?.isPublic}
																		checked={values?.isPublic}
																		onChange={handleChange}
																	/>
																	<span className='css-control-indicator'></span> Public
																</label>
																{errors?.isPublic &&
																	touched.isPublic &&
																	typeof errors.isPublic === 'string' && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.isPublic}
																		</div>
																	)}
															</div>
														</div>
														<div className='col-xl-6 col-sm-6'>
															<div className='mb-3'>
																<label
																	className='form-check css-control-primary css-checkbox'
																	htmlFor='postToCalander'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		id='postToCalander'
																		name='postToCalander'
																		value={values?.postToCalander}
																		checked={values?.postToCalander}
																		onChange={handleChange}
																	/>
																	<span className='css-control-indicator'></span> Add To Calander
																</label>
																{errors?.isPublic &&
																	touched.isPublic &&
																	typeof errors.isPublic === 'string' && (
																		<div
																			className='invalid-feedback animated fadeInUp'
																			style={{ display: 'block' }}>
																			{errors.isPublic}
																		</div>
																	)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='d-flex justify-content-end '>
								<button className='btn btn-primary w-100' type='button' onClick={handleSubmit}>
									Save
								</button>
							</div>
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default AddNewNews;
