import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { customSelectStyle, GenerateMediaUrl } from '../../../../services/general';
import StaffApi from '../../../../api/staffApi';

const UploadModal = ({ show, handleClose, staffId }) => {
	const location = useLocation();
	const [file, setFile] = useState(undefined);
	const [oldfile, setOldFile] = useState(null);
	const [validation, setValidation] = useState({});
	const [newsData, setNewsData] = useState([]);
	const payload = JSON.parse(sessionStorage.getItem('payload'));

	const fileHandler = (e) => {
		if (e.target.files.length) {
			setFile(e.target.files);
		} else {
			setFile(undefined);
		}
	};

	const initialValues = {
		title: newsData?.title ?? undefined,
		description: newsData?.description ?? undefined,
		url: newsData?.attachmentUrl ?? undefined,
	};
	const validationSchema = {
		title: Yup.string().required('Please fill in the field'),
		description: Yup.string().required('Please fill in the field'),
	};
	useEffect(() => {
		setValidationScheme();
	}, []);

	const setValidationScheme = () => {
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const handleSubmit = async (e) => {
		console.log(e);
		console.log(file);
		if (file) {
			const config = `school_${payload.schoolId}/staff/staff_${staffId}`;
			console.log(config);
			e.url = await GenerateMediaUrl(file, config);
			e.people_id = staffId;
		}
		try {
			const res = await StaffApi.addFiling(staffId, e);
			if (res.success) {
				swal('Success', 'You have added a file', 'success').then(() => {
					handleClose();
				});
			} else {
				swal('Oops', 'Something went wrong!', 'error');
			}
		} catch (err) {
			console.log(err);
			swal('Oops', 'Something went wrong!', 'error');
		}
	};
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<h3>Upload Documents</h3>
			</Modal.Header>
			<Modal.Body>
				<Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validation} onSubmit={handleSubmit}>
					{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
						return (
							<>
								<div className='row'>
									<div className='col-xl-12'>
										<div className='card'>
											<div className='row'>
												<div className='col-xl-12 col-sm-12'>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlInputtitle' className='form-label text-primary'>
															Title<span className='required'>*</span>
														</label>
														<input
															type='text'
															className='form-control'
															id='exampleFormControlInputtitle'
															name='title'
															onChange={handleChange}
															onBlur={handleBlur}
															defaultValue={values.title}
														/>
														{errors?.title && touched.title && typeof errors.title === 'string' && (
															<div
																className='invalid-feedback animated fadeInUp'
																style={{ display: 'block' }}>
																{errors.title}
															</div>
														)}
													</div>
												</div>

												<div className='col-xl-12 col-sm-12'>
													<div className='mb-3'>
														<label htmlFor='exampleFormControlTextarea1' className='form-label text-primary'>
															Description<span className='required'>*</span>
														</label>
														<textarea
															onChange={handleChange}
															onBlur={handleBlur}
															defaultValue={values.description}
															className='form-control'
															id='exampleFormControlTextarea1'
															rows='3'
															name='description'
														/>
														{errors?.description &&
															touched.description &&
															typeof errors.description === 'string' && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{ display: 'block' }}>
																	{errors.description}
																</div>
															)}
													</div>
												</div>
												<div className='mb-3'>
													<label htmlFor='File Attachment' className='form-label text-primary'>
														File Attachment
													</label>
													<div className='d-flex change-btn mt-2 mb-lg-0 mb-3 align-items-center'>
														{oldfile ? (
															<a href={oldfile} target='_black' rel='no-reffrer'>
																<span className={`badge badge-sm light badge-info`}>Download File</span>
															</a>
														) : (
															<>
																<input
																	type='file'
																	className='form-control d-none'
																	onChange={fileHandler}
																	id='attachment'
																	accept='application/pdf'
																/>
																<label
																	htmlFor='attachment'
																	className='dlab-upload mb-0 btn btn-primary btn-sm'>
																	Choose File
																</label>
																<p className='m-2'> {file ? file[0].name : ''}</p>
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='d-flex justify-content-end '>
									<button className='btn btn-primary w-100' type='button' onClick={handleSubmit}>
										Save
									</button>
								</div>
							</>
						);
					}}
				</Formik>
			</Modal.Body>
		</Modal>
	);
};

export default UploadModal;
