import emptyTable from '../../../images/emptyTable.svg';

const EmptyState = () => {
	return (
		<div className='card shadow-sm'>
			<div className='card-body text-center'>
				<img alt='empty' src={emptyTable} width={'30%'} />
				<h5 className='mt-2'>There's no data</h5>
			</div>
		</div>
	);
};
export default EmptyState;
