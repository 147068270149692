import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ThemeContext from './context/ThemeContext';
import SubscriptionContextProvider from './context/SubscriptionContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter basename='/'>
				<ThemeContext>
					<SubscriptionContextProvider>
						<App />
					</SubscriptionContextProvider>
				</ThemeContext>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);
reportWebVitals();
