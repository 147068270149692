import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { IMAGES } from '../Dashboard/Content';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { format } from 'date-fns';
import staffApi from '../../../api/staffApi';
import dashboardApi from '../../../api/dashboardApi';
import swal from 'sweetalert';
import data from '../../data.json';
import useMediaServer from '../../../services/useMediaServer';
import { useSubscription } from '../../../context/SubscriptionContextProvider';

const AddNewTeacher = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(null);
	const [isTeacher, setIsTeacher] = useState(false);
	const [eduEdit, setEduEdit] = useState([]);
	const [dob, setDob] = useState(new Date());
	const [staff, setStaff] = useState();
	const [avatar, setAvatar] = useState(null);
	const [oldAvatar, setOldAvatar] = useState(null);
	const { uploadMedia, deleteMedia } = useMediaServer();
	const { subscription } = useSubscription();
	const payload = JSON.parse(sessionStorage.getItem('payload'));

	async function getPeople() {
		const dash = await dashboardApi.dashboardList();
		const total = dash.data.people.registeredStudents + dash.data.people.registeredTeachers;
		if (subscription) {
			if (subscription.headCount <= total && subscription.headCount > 0) {
				alert('Maximum number of students/teachers has been reached. Please contact your administrator.');
				navigate('/staff');
			}
		}
	}

	async function getData() {
		try {
			const res = await staffApi.getSingleStaff(Number(params.staffId));
			if (res && res.success) {
				const staffData = res.data;
				setStaff(staffData);
				setEduEdit(staffData.staff.education);
				setDob(new Date(staffData.staff.dob));
				setIsTeacher(staffData.staff?.isTeacher);
				if (staffData.staff?.avatarUrl) {
					setAvatar(staffData.staff?.avatarUrl);
					setOldAvatar(staffData.staff?.avatarUrl);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (subscription && !params?.staffId) getPeople();
	}, [subscription, params?.staffId]);

	useEffect(() => {
		if (params.staffId) {
			getData();
		}
	}, [params]);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Please enter the name.'),
		idNumber: Yup.string().required('Please enter the IC or Passport number.'),
		dob: Yup.string().required('Please provide the dob.'),
		email: Yup.string().required('Please provide the email.'),
		gender: Yup.string().required('Please select the gender.'),
		address: Yup.string().required('Please enter the address.'),
		mobileNumber: Yup.string().required('Please enter the mobile number.'),
		summary: Yup.string().required('Please enter the summary.'),
		race: Yup.string().required('Please select the race.'),
		religion: Yup.string().required('Please select the religion.'),
		maritalStatus: Yup.string().required('Please select the marital status.'),
		emergencyContactName: Yup.string().required('Please enter the emergency contact name.'),
		emergencyContactNumber: Yup.string().required('Please enter the emergency contact number.'),
		employmentBasis: Yup.string().required('Please select the employment basis.'),
		salaryBasis: Yup.string().required('Please select the salary basis.'),
		numberOfChildren: Yup.string().required('Please enter the number of children.'),
		salary: Yup.string().required('Please enter the salary.'),
		education: Yup.array().of(
			Yup.object().shape({
				establishment: Yup.string().required('Please enter the establishment.'),
				qualification: Yup.string().required('Please enter the qualification.'),
				grade: Yup.string().required('Please enter the grade.'),
				yearGraduated: Yup.string().required('Please enter the yearGraduated.'),
			})
		),
		startDate: Yup.string().required('Please enter the start date.'),
		// education: Yup.array().of(
		//     Yup.object().shape({
		//         establishment: Yup.string().required(
		//             "Please enter the establishment."
		//         ),
		//     })
		// Yup.string().when(["establishment", "related_as"], {
		//     is: (establishment, related_as) => establishment || related_as,
		//     then: Yup.string().required("Enter establishment"),
		//   }),
		// ),
		// establishment: Yup.string().required("Please enter the establishment."),
		// grade: Yup.string().required("Please enter your latest grade."),
		// qualification: Yup.string().required(
		//     "Please enter your qualification."
		// ),
		// yearGraduated: Yup.string().required(
		//     "Please enter the year you graduated."
		// ),
		// education: [
		//     {
		//         establishment: Yup.string().required(
		//             "Please enter the establishment."
		//         ),
		//         qualification: Yup.string().required(
		//             "Please enter your qualification."
		//         ),
		//         grade: Yup.string().required("Please enter your latest grade."),
		//         yearGraduated: Yup.string().required(
		//             "Please enter the year you graduated."
		//         ),
		//     },
		// ],
	});

	useEffect(() => {
		if (location.state) {
			setEdit(location.state.edit);
		}
	}, [location]);

	const fileHandler = (e) => {
		setFile(e.target.files);
		setAvatar(URL.createObjectURL(e.target.files[0]));
	};

	const initialValues = {
		name: staff?.name ?? undefined,
		idNumber: staff?.idNumber ?? undefined,
		email: staff?.staff.email ?? undefined,
		epfNumber: staff?.staff.epfNumber ?? undefined,
		incomeTaxNumber: staff?.staff.incomeTaxNumber ?? undefined,
		dob: staff?.staff.dob ? new Date(staff?.staff.dob) : new Date(),
		startDate: staff?.staff.startDate ? new Date(staff?.staff.startDate) : new Date(),
		address: staff?.staff.address ?? undefined,
		gender: staff?.gender ?? undefined,
		race: staff?.race ?? undefined,
		religion: staff?.religion ?? undefined,
		mobileNumber: staff?.staff.mobileNumber ?? undefined,
		employmentBasis: staff?.staff.employmentBasis ?? undefined,
		salaryBasis: staff?.staff.salaryBasis ?? undefined,
		salary: staff?.staff.salary ?? undefined,
		summary: staff?.staff.summary ?? undefined,
		education: staff?.staff.education ?? [{ establishment: '', qualification: '', grade: '', yearGraduated: '' }],
		expertise: staff?.staff.expertise ?? undefined,
		startDate: staff?.staff.startDate ? new Date(staff?.staff.startDate) : new Date(),
		maritalStatus: staff?.staff.maritalStatus ?? undefined,
		spouseCompany: staff?.staff.spouseCompany ?? undefined,
		numberOfChildren: staff?.staff.numberOfChildren ?? 0,
		emergencyContactName: staff?.staff.emergencyContactName ?? undefined,
		emergencyContactNumber: staff?.staff.emergencyContactNumber ?? undefined,
		isTeacher: staff?.staff.isTeacher ?? false,
		frUserid: staff?.frUserid ?? undefined,
		avatarUrl: staff?.staff.avatarUrl ?? undefined,
	};

	// const addFields = () => {
	// 	let object = {
	// 		establishment: '',
	// 		qualification: '',
	// 		grade: '',
	// 		yearGraduated: '',
	// 	};

	// 	// if (params.staffId) {
	// 	// 	setEduEdit([...eduEdit, object]);
	// 	// } else {
	// 	// 	setEducationForm([...educationForm, object]);
	// 	// }
	// };

	// const removeFields = (index) => {
	// 	if (params.staffId) {
	// 		let remove = [...eduEdit];
	// 		remove.splice(index, 1);
	// 		setEduEdit(remove);
	// 	} else {
	// 		let remove = [...educationForm];
	// 		remove.splice(index, 1);
	// 		setEducationForm(remove);
	// 	}
	// };

	const submit = async (values) => {
		try {
			setLoading(true);
			values.salary = (Math.round(values.salary * 100) / 100).toFixed(2);
			if (values.frUserid) {
				values.frUserid = Number(values.frUserid);
			} else {
				values.frUserid = undefined;
			}
			if (file && avatar && oldAvatar) {
				const res = await deleteMedia(oldAvatar);
				if (res) {
					values.avatarUrl = undefined;
				}
			}

			if (params.staffId && file) {
				values.avatarUrl = await uploadImg(params.staffId);
			}
			if (params.staffId) {
				const res = await staffApi.updateStaff(params.staffId, values);
				if (res && res.success) {
					setLoading(false);
					swal('Success', 'You have updated a staff.', 'success').then(() => {
						navigate('/staff', { relative: 'path', replace: true });
					});
				} else {
					setLoading(false);
					alert('Failed to update staff.');
				}
			} else {
				let convertDate = format(new Date(dob), 'yyyy-LL-dd');
				values.dob = convertDate;
				// values.education = values.education.slice(1);
				const keys = Object.keys(values);
				for (let x = 0; x < keys.length; x++) {
					const findEducation = keys.find((ele) => ele === 'education' + x);
					if (findEducation) {
						values.education.push(values[`${findEducation}`]);
						delete values[`${findEducation}`];
					}
				}
				const res = await staffApi.createStaff(values);
				if (res && res.success) {
					if (res.data.id && file) {
						const uploaded = await uploadImg(res.data.id);
						if (uploaded) {
							const data = { avatarUrl: uploaded };
							const response = await staffApi.updateStaff(res.data.id, data);
							if (response && response.success) {
								setLoading(false);
								swal('Success', 'You have updated a staff.', 'success').then(() => {
									navigate('/staff', { relative: 'path', replace: true });
								});
							}
						}
					} else {
						setLoading(false);
						swal('Success', 'You have added a staff.', 'success').then(() => {
							navigate('/staff', { relative: 'path', replace: true });
						});
					}
				} else {
					setLoading(false);
					swal('Oops', 'Something went wrong!', 'error');
				}
			}
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const customStyles = {
		control: (base) => ({
			...base,
			height: 46,
			minHeight: 35,
		}),
	};

	const uploadImg = async (id) => {
		const config = `school_${payload.schoolId}/staff_${id}/`;

		const res = await uploadMedia(file, config);

		return res[0].url;
		// axios.post('https://media.ocu-NavbarCollapse.com', formData, config);
	};
	return (
		<>
			<Formik
				initialValues={initialValues}
				enableReinitialize={params.staffId ? true : false}
				validationSchema={validationSchema}
				onSubmit={submit}
				validateOnChange>
				{({ values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue }) => (
					<>
						<div className='row'>
							<div className='col-xl-12'>
								<div className='card'>
									<div className='card-header'>
										<h5 className='mb-0'>Personal Details</h5>
									</div>
									<div className='card-body'>
										<div className='row'>
											<div className='col-xl-3 col-lg-4'>
												<div className='mb-3'>
													<label className='form-label text-primary'>
														Photo
														<span className='required'>*</span>
													</label>
													<div className='avatar-upload'>
														<div className='avatar-preview'>
															<div
																id='imagePreview'
																style={{
																	backgroundImage: avatar
																		? 'url(' + avatar + ')'
																		: 'url(' + IMAGES.noimage + ')',
																}}></div>
														</div>
														<div className='change-btn mt-1'>
															<input
																type='file'
																className='form-control d-none'
																onChange={fileHandler}
																id='imageUpload'
																accept='.png, .jpg, .jpeg'
															/>
															<label
																htmlFor='imageUpload'
																className='dlab-upload mb-0 btn btn-primary btn-sm'>
																Choose File
															</label>
															{/* <Link
																to={'#'}
																className='btn btn-danger light remove-img ms-2 btn-sm'
																onClick={RemoveFile}>
																Remove
															</Link> */}
														</div>
													</div>
												</div>
											</div>
											<div className='col-xl-9 col-lg-8'>
												<div className='row'>
													<div className='col-xl-6 col-sm-6'>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Name
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																className='form-control'
																name='name'
																placeholder='James'
																defaultValue={values.name}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															{errors?.name && touched.name && typeof errors.name === 'string' && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.name}
																</div>
															)}
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Email
																<span className='required'>*</span>
															</label>
															<input
																type='email'
																className='form-control'
																name='email'
																placeholder='sean@gmail.com'
																defaultValue={values.email}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															{errors?.email && touched.email && typeof errors.email === 'string' && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.email}
																</div>
															)}
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Date of Birth
																<span className='required'>*</span>
															</label>
															<div style={{ display: 'grid' }}>
																<DatePicker
																	name='dob'
																	className='form-control'
																	selected={new Date(values.dob)}
																	onSelect={(e) => setFieldValue('dob', e)}
																	dateFormat={'dd/MM/yyyy'}
																/>
																{/* <input type="date" name='dob'
																	className='form-control'
																	defaultValue={new Date(values.dob)}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	></input> */}
															</div>
															{errors?.dob && touched.dob && typeof errors.dob === 'string' && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.dob}
																</div>
															)}
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																IC / Passport
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																className='form-control'
																name='idNumber'
																placeholder='990512-12-4276'
																defaultValue={values.idNumber}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															{errors?.idNumber &&
																touched.idNumber &&
																typeof errors.idNumber === 'string' && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{
																			display: 'block',
																		}}>
																		{errors.idNumber}
																	</div>
																)}
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Address<span className='required'>*</span>
															</label>
															<textarea
																className='form-control'
																name='address'
																onChange={handleChange}
																onBlur={handleBlur}
																rows='5'
																defaultValue={values.address}
															/>
															{errors?.address && touched.address && typeof errors.address === 'string' && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.address}
																</div>
															)}
														</div>
													</div>
													<div className='col-xl-6 col-sm-6'>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Gender
																<span className='required'>*</span>
															</label>
															<div>
																<Select
																	options={data.gender}
																	onChange={(e) => {
																		setFieldValue('gender', e['value']);
																	}}
																	onBlur={handleBlur}
																	value={data.gender.filter(({ value }) => value === values.gender)}
																	inputId='gender'
																	styles={customStyles}
																/>
															</div>
															{errors?.gender && touched.gender && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.gender}
																</div>
															)}
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Race
																<span className='required'>*</span>
															</label>
															<div>
																<Select
																	inputId='race'
																	options={data.race}
																	onChange={(e) => {
																		setFieldValue('race', e['value']);
																	}}
																	onBlur={handleBlur}
																	value={data.race.filter(({ value }) => value === values.race)}
																	styles={customStyles}
																/>
															</div>
															{errors?.race && touched.race && typeof errors.race === 'string' && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.race}
																</div>
															)}
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Religion
																<span className='required'>*</span>
															</label>
															<div>
																<Select
																	inputId='religion'
																	options={data.religion}
																	onBlur={handleBlur}
																	onChange={(e) => setFieldValue('religion', e['value'])}
																	value={data.religion.filter(({ value }) => value === values.religion)}
																	styles={customStyles}
																/>
															</div>
															{errors?.religion && touched.religion && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.religion}
																</div>
															)}
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Mobile Number
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																name='mobileNumber'
																className='form-control'
																placeholder='+123456789'
																onChange={handleChange}
																onBlur={handleBlur}
																defaultValue={values.mobileNumber}
															/>
															{errors?.mobileNumber &&
																touched.mobileNumber &&
																typeof errors.mobileNumber === 'string' && (
																	<div
																		className='invalid-feedback animated fadeInUp'
																		style={{
																			display: 'block',
																		}}>
																		{errors.mobileNumber}
																	</div>
																)}
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Summary
																<span className='required'>*</span>
															</label>
															<textarea
																className='form-control'
																name='summary'
																onChange={handleChange}
																onBlur={handleBlur}
																rows='5'
																defaultValue={values.summary}
															/>
															{errors?.summary && touched.summary && typeof errors.summary === 'string' && (
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.summary}
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-header'>
									<h5 className='mb-0'>Family Details</h5>
								</div>
								<div className='card-body'>
									<div className='row'>
										<div className='col-6'>
											<div className='mb-3'>
												<label className='form-label text-primary'>
													Marital Status
													<span className='required'>*</span>
												</label>
												<div>
													<Select
														inputId='maritalStatus'
														options={data.marital}
														onBlur={handleBlur}
														onChange={(e) => setFieldValue('maritalStatus', e['value'])}
														value={data.marital.filter(({ value }) => value === values.maritalStatus)}
														styles={customStyles}
													/>
												</div>
												{errors?.maritalStatus && touched.maritalStatus && (
													<div
														className='invalid-feedback animated fadeInUp'
														style={{
															display: 'block',
														}}>
														{errors.maritalStatus}
													</div>
												)}
											</div>
											<div className='mb-3'>
												<label className='form-label text-primary'>
													Emergency Contact Name
													<span className='required'>*</span>
												</label>
												<input
													type='text'
													name='emergencyContactName'
													className='form-control'
													onChange={(e) => {
														setFieldValue('emergencyContactName', e.target.value);
													}}
													onBlur={handleBlur}
													defaultValue={values.emergencyContactName}
												/>
												{errors?.emergencyContactName &&
													touched.emergencyContactName &&
													typeof errors.emergencyContactName === 'string' && (
														<div
															className='invalid-feedback animated fadeInUp'
															style={{
																display: 'block',
															}}>
															{errors.emergencyContactName}
														</div>
													)}
											</div>
											<div className='mb-3'>
												<label className='form-label text-primary'>Spouse Company Name</label>
												<input
													type='text'
													className='form-control'
													name='spouseCompany'
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.spouseCompany}
												/>
											</div>
										</div>
										<div className='col-6'>
											<div className='mb-3'>
												<label className='form-label text-primary'>
													No. Of Children
													<span className='required'>*</span>
												</label>
												<input
													name='numberOfChildren'
													type='number'
													className='form-control'
													placeholder='0'
													defaultValue={values.numberOfChildren}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors?.numberOfChildren &&
													touched.numberOfChildren &&
													typeof errors.numberOfChildren === 'string' && (
														<div
															className='invalid-feedback animated fadeInUp'
															style={{
																display: 'block',
															}}>
															{errors.numberOfChildren}
														</div>
													)}
											</div>
											<div className='mb-3'>
												<label className='form-label text-primary'>
													Emergency Contact No.
													<span className='required'>*</span>
												</label>
												<input
													type='text'
													name='emergencyContactNumber'
													className='form-control'
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.emergencyContactNumber}
												/>
												{errors?.emergencyContactNumber &&
													touched.emergencyContactNumber &&
													typeof errors.emergencyContactNumber === 'string' && (
														<div
															className='invalid-feedback animated fadeInUp'
															style={{
																display: 'block',
															}}>
															{errors.emergencyContactNumber}
														</div>
													)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-header'>
									<h5 className='mb-0'>Education Details</h5>
								</div>
								<div className='card-body'>
									<FieldArray
										name='education'
										render={(arrayHelpers) => {
											const educationValues = values.education;
											return (
												<div>
													{educationValues && educationValues.length > 0
														? educationValues.map((item, index) => (
																<div className='row mb-4' key={index}>
																	<div className='col-xl-6 col-sm-6'>
																		<div className='mb-3'>
																			<label className='form-label text-primary'>
																				Establishment
																				<span className='required'>*</span>
																			</label>
																			<input
																				type='text'
																				name={`education.${index}.establishment`}
																				className='form-control'
																				placeholder='University of Oxford'
																				value={educationValues?.[index].establishment}
																				onChange={handleChange}
																				onBlur={handleBlur}
																			/>
																			<ErrorMessage name={`education.${index}.establishment`}>
																				{(msg) => (
																					<div
																						className='invalid-feedback animated fadeInUp'
																						style={{ display: 'block' }}>
																						{msg}
																					</div>
																				)}
																			</ErrorMessage>
																		</div>
																	</div>
																	<div className='col-xl-6 col-sm-6'>
																		<div className='mb-3'>
																			<label className='form-label text-primary'>
																				Qualification
																				<span className='required'>*</span>
																			</label>
																			<input
																				type='text'
																				name={`education.${index}.qualification`}
																				className='form-control'
																				placeholder='Bachelor in Information Technology'
																				value={educationValues?.[index].qualification}
																				onChange={handleChange}
																				onBlur={handleBlur}
																			/>
																			<ErrorMessage name={`education.${index}.qualification`}>
																				{(msg) => (
																					<div
																						className='invalid-feedback animated fadeInUp'
																						style={{ display: 'block' }}>
																						{msg}
																					</div>
																				)}
																			</ErrorMessage>
																		</div>
																	</div>
																	<div className='col-xl-6 col-sm-6'>
																		<div className='mb-3'>
																			<label className='form-label text-primary'>
																				Grade
																				<span className='required'>*</span>
																			</label>
																			<input
																				type='text'
																				name={`education.${index}.grade`}
																				// name={`education${[index]}.grade`}
																				className='form-control'
																				placeholder='CGPA 3.98'
																				value={educationValues?.[index].grade}
																				onChange={handleChange}
																				onBlur={handleBlur}
																			/>
																			<ErrorMessage name={`education.${index}.grade`}>
																				{(msg) => (
																					<div
																						className='invalid-feedback animated fadeInUp'
																						style={{ display: 'block' }}>
																						{msg}
																					</div>
																				)}
																			</ErrorMessage>
																		</div>
																	</div>
																	<div className='col-xl-6 col-sm-6'>
																		<div className='mb-3'>
																			<label className='form-label text-primary'>
																				Year Graduated
																				<span className='required'>*</span>
																			</label>
																			<input
																				type='text'
																				name={`education.${index}.yearGraduated`}
																				className='form-control'
																				placeholder='2019'
																				value={educationValues?.[index].yearGraduated}
																				// onChange={(e) => {
																				// 	const update = [...eduEdit];
																				// 	update[index].yearGraduated = e.target.value;
																				// 	setEduEdit(update);
																				// 	setFieldValue(
																				// 		`education${[index]}.yearGraduated`,
																				// 		e.target.value
																				// 	);
																				// }}
																				onChange={handleChange}
																				onBlur={handleBlur}
																			/>
																			<ErrorMessage name={`education.${index}.yearGraduated`}>
																				{(msg) => (
																					<div
																						className='invalid-feedback animated fadeInUp'
																						style={{ display: 'block' }}>
																						{msg}
																					</div>
																				)}
																			</ErrorMessage>
																		</div>
																	</div>

																	{educationValues.length && (
																		<div className='text-end'>
																			<i
																				className='material-symbols-outlined'
																				style={{
																					fontSize: 25,
																					color: 'red',
																					cursor: 'pointer',
																				}}
																				onClick={(id) => {
																					arrayHelpers.remove(index);
																				}}>
																				delete
																			</i>
																		</div>
																	)}
																</div>
														  ))
														: null}

													<button
														title='close'
														className='float-end btn btn-primary'
														onClick={() =>
															arrayHelpers.push({
																establishment: '',
																qualification: '',
																grade: '',
																yearGraduated: '',
															})
														}>
														<i className='fa fa-plus' />
													</button>
												</div>
											);
										}}></FieldArray>

									{/* {params.staffId &&
										eduEdit.map((value, index) => {
											return (
												<div className='row mb-4' key={index}>
													<div className='col-xl-6 col-sm-6'>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Establishment
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																name={`education${[index]}.establishment`}
																className='form-control'
																placeholder='University of Oxford'
																defaultValue={value.establishment}
													
																onChange={
																	(e) => {
																		const update = [...eduEdit];
																		update[index].establishment = e.target.value;
																		setEduEdit(update);
																		setFieldValue(`education${[index]}.establishment`, e.target.value);
																	}
														
																}
																onBlur={
																	handleBlur
														
																}
															/>
															<div
																className='invalid-feedback animated fadeInUp'
																style={{
																	display: 'block',
																}}>
													
															</div>
														</div>
														<div className='mb-3'>
															<div className='mb-3'>
																<label className='form-label text-primary'>
																	Grade
																	<span className='required'>*</span>
																</label>
																<input
																	type='text'
																	name={`education${[index]}.grade`}
																	className='form-control'
																	placeholder='CGPA 3.98'
																	defaultValue={value.grade}
																	onChange={(e) => {
																		const update = [...eduEdit];
																		update[index].grade = e.target.value;
																		setEduEdit(update);
																		setFieldValue(`education${[index]}.grade`, e.target.value);
																	}}
																	onBlur={handleBlur}
																/>
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.grade &&
                                                                    errors.grade}
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-6 col-sm-6'>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Qualification
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																name={`education${[index]}.qualification`}
																className='form-control'
																placeholder='Bachelor in Information Technology'
																defaultValue={value.qualification}
																onChange={(e) => {
																	const update = [...eduEdit];
																	update[index].qualification = e.target.value;
																	setEduEdit(update);
																	setFieldValue(`education${[index]}.qualification`, e.target.value);
																}}
																onBlur={handleBlur}
															/>
															<div
																className='invalid-feedback animated fadeInUp'
																style={{
																	display: 'block',
																}}>
																{errors.qualification &&
                                                                errors.qualification}
															</div>
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Year Graduated
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																name={`education${[index]}.yearGraduated`}
																className='form-control'
																placeholder='2019'
																defaultValue={value.yearGraduated}
																onChange={(e) => {
																	const update = [...eduEdit];
																	update[index].yearGraduated = e.target.value;
																	setEduEdit(update);
																	setFieldValue(`education${[index]}.yearGraduated`, e.target.value);
																}}
																onBlur={handleBlur}
															/>
															<div
																className='invalid-feedback animated fadeInUp'
																style={{
																	display: 'block',
																}}>
																{errors.yearGraduated &&
                                                                errors.yearGraduated}
															</div>
														</div>
														<div
															className='float-end'
															style={{ cursor: 'pointer' }}
															onClick={() => {
																removeFields(index);
															}}>
															<i
																className='material-symbols-outlined'
																style={{
																	fontSize: 25,
																	color: 'red',
																}}>
																delete
															</i>
														</div>
													</div>
													<hr className='mt-4' />
												</div>
											);
										})} */}
									{/* {!params.staffId &&
										educationForm.map((field, index) => {
											return (
												<div className='row mb-4' key={index}>
													<div className='col-xl-6 col-sm-6'>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Establishment
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																name={`education${[index]}.establishment`}
																className='form-control'
																placeholder='University of Oxford'
																defaultValue={field.establishment}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															<div
																className='invalid-feedback animated fadeInUp'
																style={{
																	display: 'block',
																}}>
																{errors?.education &&
                                                                errors
                                                                    ?.education[
                                                                    index
                                                                ]
                                                                    ?.establishment}
															</div>
														</div>
														<div className='mb-3'>
															<div className='mb-3'>
																<label className='form-label text-primary'>
																	Grade
																	<span className='required'>*</span>
																</label>
																<input
																	type='text'
																	name={`education${[index]}.grade`}
																	className='form-control'
																	placeholder='CGPA 3.98'
																	defaultValue={field.grade}
																	onChange={handleChange}
																	onBlur={handleBlur}
																/>
																<div
																	className='invalid-feedback animated fadeInUp'
																	style={{
																		display: 'block',
																	}}>
																	{errors.grade &&
                                                                    errors.grade}
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-6 col-sm-6'>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Qualification
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																name={`education${[index]}.qualification`}
																className='form-control'
																placeholder='Bachelor in Information Technology'
																defaultValue={field.qualification}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															<div
																className='invalid-feedback animated fadeInUp'
																style={{
																	display: 'block',
																}}>
																{errors.qualification &&
                                                                errors.qualification}
															</div>
														</div>
														<div className='mb-3'>
															<label className='form-label text-primary'>
																Year Graduated
																<span className='required'>*</span>
															</label>
															<input
																type='text'
																name={`education${[index]}.yearGraduated`}
																className='form-control'
																placeholder='2019'
																defaultValue={field.yearGraduated}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															<div
																className='invalid-feedback animated fadeInUp'
																style={{
																	display: 'block',
																}}>
																{errors.yearGraduated &&
                                                                errors.yearGraduated}
															</div>
														</div>
														{educationForm.length > 1 && (
															<div
																className='float-end'
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	removeFields(index);
																}}>
																<i
																	className='material-symbols-outlined'
																	style={{
																		fontSize: 25,
																		color: 'red',
																	}}>
																	delete
																</i>
															</div>
														)}
													</div>
													{educationForm.length > 1 && <hr className='mt-4' />}
												</div>
											);
										})}
									<button
										className='float-end btn btn-primary'
										onClick={() => {
											addFields();
										}}>
										<i className='fa fa-plus' />
									</button> */}
								</div>
							</div>
						</div>
						<div className='col-xl-12'>
							<div className='card'>
								<div className='card-header'>
									<h5 className='mb-0'>Employment Details</h5>
								</div>
								<div className='card-body'>
									<div className='row'>
										<div className='col-xl-6 col-sm-6'>
											<div className='mb-3'>
												<label className='form-label text-primary'>
													Employment Basis
													<span className='required'>*</span>
												</label>
												<div>
													<Select
														inputId='employmentBasis'
														options={data.employmentBasis}
														onBlur={handleBlur}
														onChange={(e) => setFieldValue('employmentBasis', e['value'])}
														value={data.employmentBasis.filter(({ value }) => value === values.employmentBasis)}
														styles={customStyles}
													/>
												</div>
												{errors?.employmentBasis && touched.employmentBasis && (
													<div
														className='invalid-feedback animated fadeInUp'
														style={{
															display: 'block',
														}}>
														{errors.employmentBasis}
													</div>
												)}
											</div>
											<div className='mb-3'>
												<div className='mb-3'>
													<label className='form-label text-primary'>
														Salary Basis
														<span className='required'>*</span>
													</label>
													<div>
														<Select
															inputId='salaryBasis'
															options={data.salaryBasis}
															onBlur={handleBlur}
															onChange={(e) => setFieldValue('salaryBasis', e['value'])}
															value={data.salaryBasis.filter(({ value }) => value === values.salaryBasis)}
															styles={customStyles}
														/>
													</div>
													{errors?.salaryBasis && touched.salaryBasis && (
														<div
															className='invalid-feedback animated fadeInUp'
															style={{
																display: 'block',
															}}>
															{errors.salaryBasis}
														</div>
													)}
												</div>
												<div className='mb-3'>
													<label className='form-label text-primary'>
														Salary
														<span className='required'>*</span>
													</label>
													<input
														name='salary'
														type='number'
														className='form-control'
														step='.01'
														placeholder='1800.00'
														defaultValue={values.salary}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													{errors?.salary && touched.salary && (
														<div
															className='invalid-feedback animated fadeInUp'
															style={{
																display: 'block',
															}}>
															{errors.salary}
														</div>
													)}
												</div>
												<div className='mb-3'>
													<label className='form-label text-primary'>EPF Number</label>
													<input
														type='text'
														className='form-control'
														name='epfNumber'
														placeholder='800123456789'
														defaultValue={values.epfNumber}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
												</div>
												<div className='mb-3'>
													<label className='form-label text-primary'>Start Date</label>
													<div style={{ display: 'grid' }}>
														<DatePicker
															name='startDate'
															className='form-control'
															selected={new Date(values.startDate)}
															onSelect={(e) => setFieldValue('startDate', e)}
															dateFormat={'dd/MM/yyyy'}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className='col-xl-6 col-sm-6'>
											<div className='mb-3'>
												<label className='form-label text-primary'>Face Recognition ID</label>
												<input
													type='text'
													className='form-control'
													name='frUserid'
													placeholder='0000'
													defaultValue={values.frUserid}
													onChange={handleChange}
													onBlur={handleBlur}
													maxLength='4'
												/>
											</div>
											<div className='mb-3'>
												<label className='form-label text-primary'>Income Tax Number</label>
												<input
													type='text'
													className='form-control'
													name='incomeTaxNumber'
													placeholder='800123456789'
													defaultValue={values.incomeTaxNumber}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											</div>
											<div className='mb-2'>
												<label className='form-label text-primary'>
													Are you a Teacher?
													<span className='required'>*</span>
												</label>
												<div className='d-flex'>
													<Form.Check
														name='isTeacher'
														type='switch'
														size='xl'
														id='custom-switch'
														defaultChecked={isTeacher ? true : undefined}
														onChange={(e) => {
															setFieldValue('isTeacher', e.target.checked);
														}}
													/>
													&nbsp;
													{/* <p className={isTeacher ? 'text-primary' : ''}>{isTeacher ? 'Yes' : 'No'}</p> */}
												</div>
											</div>
											<div className='mb-3'>
												<label className='form-label text-primary'>Expertise</label>
												<textarea
													name='expertise'
													className='form-control'
													placeholder='Enter any relevant experience or skills (Optional)'
													rows='2'
													onChange={handleChange}
													onBlur={handleBlur}
													defaultValue={values.expertise}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='d-flex justify-content-end pb-2'>
							<button
								className='btn btn-primary w-100'
								type='submit'
								onClick={() => {
									handleSubmit();
								}}>
								Save
							</button>
						</div>
						<Modal size='sm' className='modal fade' show={loading} centered>
							<div className='modal-body' style={{ textAlign: 'center', padding: '50px' }}>
								<Spinner animation='border' variant='primary' />
							</div>
						</Modal>
					</>
				)}
			</Formik>
		</>
	);
};

export default AddNewTeacher;
