import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { customSelectStyle } from '../../../../services/general';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import paymentGatewayApi from '../../../../api/paymentGatewayApi';
import swal from 'sweetalert';

const PaymentGateway = () => {
	const [validation, setValidation] = useState({});
	const [data, setData] = useState();

	const getPaymentGateway = async () => {
		const res = await paymentGatewayApi.get();
		if (res.success) setData(res.data);
	};

	useEffect(() => {
		getPaymentGateway();
	}, []);

	const initialValues = {
		pk: data?.pk,
		sk: data?.sk,
		pmc: data?.pmc,
	};

	const validationSchema = {
		pk: Yup.string().required('Please input a publishable key'),
		sk: Yup.string().required('Please input a secret key'),
		pmc: Yup.string().required('Please input a payment type identifier'),
	};

	useEffect(() => {
		setValidationScheme();
	}, []);

	const setValidationScheme = () => {
		const v = Yup.object().shape(validationSchema);
		setValidation(v);
	};

	const handleSubmit = async (e) => {
		try {
			const res = await paymentGatewayApi.update({ pk: e.pk, sk: e.sk, pmc: e.pmc });
			console.log(res);
			if (res.success) {
				swal('Success', 'Data is updated', 'success').then(() => {
					getPaymentGateway();
				});
			}
		} catch (err) {
			console.log(err);
			swal('Failed', 'Data Update Failed. Please refresh the page and try again', 'error').then(() => {
				getPaymentGateway();
			});
		}
	};

	return (
		<div className='modal-content'>
			<div className='modal-header'>
				<h5 className='modal-title'>Payment Gateway Settings</h5>
			</div>
			<Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validation} onSubmit={(e) => handleSubmit(e)}>
				{({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue }) => {
					return (
						<div className='modal-body'>
							<div className='mb-3'>
								<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
									Publish Key<span className='required'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='pk'
									value={values.pk}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder='Publishable Key'
								/>
								{errors?.pk && touched.pk && (
									<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
										{errors.pk}
									</div>
								)}
							</div>
							<div className='mb-3'>
								<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
									Secret Key<span className='required'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='sk'
									value={values.sk}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder='Secret Key'
								/>
								{errors?.sk && touched.sk && (
									<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
										{errors.sk}
									</div>
								)}
							</div>

							<div className='mb-3'>
								<label htmlFor='exampleFormControlInput1' className='form-label text-primary'>
									Payment Type Identifier<span className='required'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='pmc'
									value={values.pmc}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder='Payment Type Identifier'
								/>
								{errors?.pmc && touched.pmc && (
									<div className='invalid-feedback animated fadeInUp' style={{ display: 'block' }}>
										{errors.pmc}
									</div>
								)}
							</div>

							<button type='button' className='btn btn-primary w-100 mt-3' onClick={handleSubmit}>
								Save
							</button>
						</div>
					);
				}}
			</Formik>
		</div>
	);
};
export default PaymentGateway;
