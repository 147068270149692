import React, { useState, useEffect, useMemo } from 'react';
import { COLUMNS, getReports } from './component/tableData';
import { Link, useNavigate } from 'react-router-dom';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import EmptyState from '../components/EmptyState';
import Pagination from '../components/Pagination';
import Select from 'react-select';
import { customSelectStyle } from '../../../services/general';
import { getClassList } from '../../components/Student/utils';
import { Button, Modal } from 'react-bootstrap';
import schoolSetupApi from '../../../api/schoolSetupApi';
import moment from 'moment';
import useMediaServer from '../../../services/useMediaServer';
import progressReportAPI from '../../../api/progressReportApi';
import swal from 'sweetalert';

const ProgressReport = () => {
	const { uploadMedia, deleteMedia } = useMediaServer();
	const payload = JSON.parse(sessionStorage.getItem('payload'));
	const [progressReport, setProgressReport] = useState([]);
	const [classList, setClassList] = useState([]);
	const [termList, setTermList] = useState([]);
	const [klass, setKlass] = useState(0);
	const [term, setTerm] = useState(0);
	const [student, setStudent] = useState(undefined);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [uploadFile, setUploadFile] = useState(null);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => progressReport, [progressReport]);

	useEffect(() => {
		getList();
	}, [klass, term]);

	const getList = async () => {
		if (klass && term) {
			const list = await getReports(klass, term);
			setProgressReport(list);
		}
	};

	const fileHandler = (e) => {
		if (e.target.value) {
			setUploadFile(e.target.files);
			setIsReady(true);
		} else setIsReady(false);
		// setAvatar(URL.createObjectURL(e.target.files[0]));
	};

	const uploadImg = async () => {
		const folder = `school_${payload.schoolId}/PR_${new Date().getFullYear()}_t${term}/`;
		const res = await uploadMedia(uploadFile, folder);
		return res[0].url;
	};

	useEffect(() => {
		getClassDropdownList();
		getTermDropdownList();
	}, []);

	const getClassDropdownList = async () => {
		const classList = await getClassList();
		const arr = [...classList];
		setClassList(arr);
	};
	const getTermDropdownList = async () => {
		const res = await schoolSetupApi.get();
		let arr = [{ label: 0, value: 0 }];
		if (res && res.success) {
			const max = res.data.exam_terms_count_peryear;
			arr = Array.from({ length: max }, (_, i) => 1 + i);
			arr = arr.map((i) => ({ label: i, value: i }));
		}
		setTermList(arr);
	};
	const uploadProgressReport = async () => {
		let reportUrl;
		if (uploadFile !== null) {
			reportUrl = await uploadImg();
			const res = await progressReportAPI.uploadprogressReport(student.id, reportUrl, String(term));
			if (res && res.success) {
				swal('Success', 'Report Uploaded Successfully', 'success').then(() => {
					setIsOpenModal(false);
					getList();
				});
			}
		}
	};

	const tableInstance = useTable({ columns, data }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;

	const uploadReport = (student) => {
		setStudent(student);
		setIsOpenModal(true);
	};

	let navigate = useNavigate();
	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title  shadow-sm flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
							</div>
						</div>

						<div className='col-xl-12 wow fadeInUp mb-3' data-wow-delay='1.5s'>
							<div className='row'>
								<div className='col-xl-6 w-50'>
									<h6 className='form-label'>Class</h6>
									<Select
										styles={customSelectStyle()}
										options={classList}
										onChange={(e) => setKlass(e.value)}
										value={classList.filter(({ value }) => value === klass)}
										inputId='klassId'
									/>
								</div>
								<div className='col-xl-6 w-50'>
									<h6 className='form-label'>Term</h6>
									<Select
										styles={customSelectStyle()}
										options={termList}
										onChange={(e) => setTerm(e.value)}
										value={termList.filter(({ value }) => value === term)}
										inputId='term'
									/>
								</div>
							</div>
						</div>

						<div className='col-xl-12 wow fadeInUp ' data-wow-delay='1.5s'>
							{progressReport.length ? (
								<div className='table-responsive full-data'>
									<div id='example-student_wrapper' className='dataTables_wrapper no-footer'>
										<table
											{...getTableProps()}
											className='shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer '>
											<thead>
												{headerGroups.map((headerGroup) => {
													return (
														<tr {...headerGroup.getHeaderGroupProps()}>
															{headerGroup.headers.map((column, j) => {
																if (j === 2)
																	return (
																		<th
																			className='text-center'
																			style={{
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																				whiteSpace: 'nowrap',
																				minWidth: '2rem',
																				maxWidth: '7rem',
																				textAlign: 'center',
																			}}
																			{...column.getHeaderProps()}>
																			{column.render('Header')}
																		</th>
																	);
																else
																	return (
																		<th
																			style={{
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																				whiteSpace: 'nowrap',
																				minWidth: '2rem',
																				maxWidth: '7rem',
																			}}
																			{...column.getHeaderProps()}>
																			{column.render('Header')}
																		</th>
																	);
															})}
														</tr>
													);
												})}
											</thead>
											<tbody {...getTableBodyProps()} className=''>
												{page.map((row, r) => {
													prepareRow(row);
													return (
														<tr key={r} {...row.getRowProps()}>
															{row.cells.map((cell, i) => {
																if (i === 2)
																	return (
																		<td
																			key={i}
																			style={{ textAlign: 'center' }}
																			{...cell.getCellProps()}>
																			{cell.value ? cell.render('Cell') : '-'}
																		</td>
																	);
																else if (cell.column.id.toLowerCase() === 'action') {
																	if (!row.original.reportUrl)
																		return (
																			<>
																				<Button
																					onClick={() => uploadReport(row.original.student)}
																					className='m-2'
																					variant='outline-primary'>
																					Upload
																				</Button>
																			</>
																		);
																} else
																	return (
																		<td {...cell.getCellProps()}>
																			{cell.value ? cell.render('Cell') : '-'}
																		</td>
																	);
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
										<Pagination
											nextPage={nextPage}
											previousPage={previousPage}
											canNextPage={canNextPage}
											canPreviousPage={canPreviousPage}
											pageIndex={pageIndex}
											pageOptions={pageOptions}
											pageSize={pageSize}
											setPageSize={setPageSize}
											gotoPage={gotoPage}
											pageCount={pageCount}
										/>
									</div>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal onHide={setIsOpenModal} show={isOpenModal} centered size='lg' backdrop='static'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='exampleModalLabel'>
							Upload Progress Report
						</h5>
						<button
							title='close'
							type='button'
							className='btn-close'
							onClick={() => {
								setIsOpenModal(false);
							}}></button>
					</div>
					<div className='modal-body'>
						<table>
							<tbody>
								<tr>
									<td>Name</td>
									<td>&nbsp;:&nbsp;</td>
									<td>{student?.name}</td>
								</tr>
								<tr>
									<td>ID Number</td>
									<td>&nbsp;:&nbsp;</td>
									<td>{student?.idNumber}</td>
								</tr>
								<tr>
									<td>DOB</td>
									<td>&nbsp;:&nbsp;</td>
									<td>{student?.student?.dob ? moment(student.student?.dob).format('DD MMM YYYY') : '-'}</td>
								</tr>
								<tr>
									<td>Term </td>
									<td>&nbsp;:&nbsp;</td>
									<td>{term}</td>
								</tr>
								<tr>
									<td>Report</td>
									<td>&nbsp;:&nbsp;</td>
									<td>
										<input type='file' accept='application/pdf' onChange={fileHandler} />
									</td>
								</tr>
							</tbody>
						</table>
						{/* <div className='d-flex justify-content-end mb-3' style={{ marginTop: -10 }}> */}

						{/* </div> */}
					</div>
					<div className='modal-footer'>
						<button type='button' disabled={!isReady} className='btn btn-primary' onClick={uploadProgressReport}>
							Upload
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ProgressReport;
