import injuryAPI from '../../../../api/injuryApi';

import moment from 'moment';

export const COLUMNS = [
	{
		Header: 'Student Name',
		Footer: 'Student Name',
		accessor: 'student.name',
	},
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'date',
		Cell: ({ value }) => {
			return moment(value).format('DD/MM/yyyy');
		},
	},
	{
		Header: 'Description',
		Footer: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Acknowledged',
		Footer: 'Acknowledged',
		accessor: 'acknowledged',
		Cell: ({ value }) => {
			return value ? (
				<div className='text-center w-75'>
					<i className='material-symbols-outlined' style={{ fontSize: 22, color: '#367030', fontWeight: 'bolder' }}>
						check
					</i>
				</div>
			) : (
				''
			);
		},
	},
	{
		Header: 'Image',
		Footer: 'Action',
	},
];

export async function getReport() {
	try {
		let res = await injuryAPI.injuryReportList();
		console.log('injury ====== ', res);
		if (res.success) {
			return res?.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
}
