import { formatCurrency } from '../../../../../services/general';
import financeApi from '../../../../../api/financeApi';

export const COLUMNS = [
	{
		Header: 'Name',
		Footer: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Salary',
		Footer: 'Salary',
		accessor: 'salary',
		Cell: ({ value }) => {
			return formatCurrency(value);
		},
	},
	{
		Header: 'Allowance',
		Footer: 'Allowance',
		accessor: 'allowance',
		Cell: ({ value }) => {
			return formatCurrency(value);
		},
	},
	{
		Header: 'Employer Contribution',
		Footer: 'Employer Contribution',
		accessor: (row) => row,
		Cell: ({ value }) => {
			return (
				<div className='d-flex flex-column'>
					<div className='d-flex flex-row '>
						<span style={{ fontWeight: 'bold', flex: 1 }}>EPF:</span>
						<span style={{ fontWeight: 'normal', flex: 1 }}>{formatCurrency(value.epfEmployer)}</span>
					</div>
					<div className='d-flex flex-row '>
						<span style={{ fontWeight: 'bold', flex: 1 }}>EIS:</span>
						<span style={{ fontWeight: 'normal', flex: 1 }}>{formatCurrency(value.eisEmployer)}</span>
					</div>
					<div className='d-flex flex-row '>
						<span style={{ fontWeight: 'bold', flex: 1 }}>SOCSO:</span>
						<span style={{ fontWeight: 'normal', flex: 1 }}>{formatCurrency(value.socsoEmployer)}</span>
					</div>
				</div>
			);
		},
	},
	{
		Header: 'Claims',
		Footer: 'Claims',
		accessor: 'claims',
		Cell: ({ value }) => {
			return formatCurrency(value);
		},
	},
	{
		Header: 'Payout',
		Footer: 'Payout',
		accessor: 'totalPayout',
		Cell: ({ value }) => {
			return formatCurrency(value);
		},
	},
];

export async function getPayouts(date) {
	try {
		let res = await financeApi.payoutList(date);
		if (res.success) {
			return res?.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
}
