import { useEffect, useState } from 'react';

const Basic = (props) => {
	const { basic } = props;
	const [data, setData] = useState([]);

	useEffect(() => {
		if (basic) {
			const cells = [];
			for (let property in basic) {
				cells.push({
					label: property,
					value: basic[property],
				});
			}
			const rows = [];
			for (let i = 0; i < cells.length; i += 5) {
				rows.push(cells.slice(i, i + 5));
			}
			setData(rows);
		}
	}, [basic]);

	return (
		<>
			<label className='text-grey font-w500 mt-2'>Basic evaluation report</label>
			<table className='col-12'>
				{data.map((row, rowIndex) => {
					return (
						<tr key={rowIndex}>
							{row.map((cell, cellIndex) => {
								return (
									<td style={{ border: '1px solid black', borderCollapse: 'collapse' }} key={cellIndex}>
										<div className='w-100' style={{ borderBottom: '1px solid black' }}>
											<h6 className='text-black font-w500 text-center'>{cell.label}</h6>
										</div>
										<div className='w-100 d-flex align-items-center justify-content-center' style={{ height: '40px' }}>
											{cell.value ? (
												<i className='material-symbols-outlined' style={{ color: '#2AB514', userSelect: 'none' }}>
													check
												</i>
											) : (
												<i className='material-symbols-outlined' style={{ color: '#E30303', userSelect: 'none' }}>
													close
												</i>
											)}
										</div>
									</td>
								);
							})}
						</tr>
					);
				})}
			</table>
		</>
	);
};

export default Basic;
