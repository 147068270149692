import React from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { formatCurrency } from '../../../../services/general';

const SchoolPerformance = (props) => {
	const expectedFeesData = props.expectedFees.map((v) => v?.amount);
	const actualFeesData = props.actualFees.map((v) => v?.amount);
	const monthdays = props.expectedFees.map((v) => (moment(v?.date).get('D') % 2 === 0 ? '' : moment(v?.date).format('DD/MM/YY')));

	const series = [
		{
			name: 'Expected Fees',
			data: expectedFeesData,
		},
		{
			name: 'Actual Fees',
			data: actualFeesData,
		},
	];

	const options = {
		chart: {
			type: 'area',
			height: 280,
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},

		colors: ['var(--primary)', 'var(--secondary)'],

		stroke: {
			curve: 'smooth',
			width: 3,
			colors: ['var(--primary)', 'var(--secondary)'],
		},

		legend: {
			fontSize: '13px',
			fontFamily: 'poppins',
			labels: {
				colors: '#888888',
			},
		},

		grid: {
			show: false,
			strokeDashArray: 6,
			borderColor: '#dadada',
		},

		yaxis: {
			labels: {
				style: {
					colors: '#B5B5C3',
					fontSize: '8pt',
					fontFamily: 'Poppins',
					fontWeight: 400,
				},
				formatter: function (value) {
					return formatCurrency(value);
				},
			},
		},

		xaxis: {
			categories: monthdays,
			labels: {
				style: {
					colors: '#B5B5C3',
					fontSize: '12px',
					fontFamily: 'Poppins',
					fontWeight: 400,
				},
			},
		},

		fill: {
			colors: 'white',
			type: 'solid',
			opacity: 0.05,
		},

		tooltip: {
			x: {
				format: 'dd/MM/yy HH:mm',
			},
		},
	};

	return (
		<div id='marketChart'>
			{props && options && series && <ReactApexChart options={options} series={series} type='area' width={'100%'} height={280} />}
		</div>
	);
};
export default SchoolPerformance;
