import { useState } from 'react';
import studentAPI from '../../../../api/studentApi';
import { format } from 'date-fns';

export const COLUMNS = (navigate, deleteStudent) => {
	return [
		{
			Header: 'Name',
			Footer: 'Name',
			accessor: 'name',
		},
		{
			Header: 'DOB',
			Footer: 'DOB',
			accessor: 'student.dob',
			Cell: ({ value }) => {
				return format(new Date(value), 'dd MMM yyyy');
			},
		},
		{
			Header: 'Enrollment Type',
			Footer: 'Enrollment Type',
			accessor: 'student.enrollmentType.type',
		},
		{
			Header: 'Parent Name',
			Footer: 'Parent Name',
			accessor: 'student.parent.name',
		},
		{
			Header: "Parent's Contact",
			Footer: "Parent's Contact",
			accessor: 'student.parent.mobileNumber',
		},
		{
			Header: 'Class',
			Footer: 'Class',
			accessor: 'classes[0].classInfo.name',
		},
		{
			Header: 'Action',
			Footer: 'Action',
			accessor: 'id',
			Cell: ({ value }) => {
				const [click, setClicked] = useState(false);
				return (
					<div
						onClick={(e) => {
							setClicked(!click);
						}}
						className='d-flex'>
						{click ? (
							<>
								<div className='icon-box icon-box-sm bg-light me-2' onClick={() => navigate('/student/details/' + value)}>
									<i className='material-symbols-outlined' style={{ fontSize: 8, color: '#4D44B5' }}>
										visibility
									</i>
								</div>
								<div
									className='icon-box icon-box-sm bg-light me-2'
									onClick={() => navigate('/student/edit-student/' + value)}>
									<i className='material-symbols-outlined' style={{ fontSize: 8, color: '#4D44B5' }}>
										edit
									</i>
								</div>
								<div className='icon-box icon-box-sm bg-light me-2' onClick={() => deleteStudent(value)}>
									<i className='material-symbols-outlined' style={{ fontSize: 8, color: '#4D44B5' }}>
										delete
									</i>
								</div>
								<div className='icon-box icon-box-sm me-2' onClick={(e) => {}}>
									<i className='material-symbols-outlined' style={{ fontSize: 8, color: '#4D44B5' }}>
										keyboard_double_arrow_left
									</i>
								</div>
							</>
						) : (
							<div className='icon-box icon-box-sm bg-light me-2'>
								<i className='material-symbols-outlined' style={{ fontSize: 10, color: '#4D44B5' }}>
									more_vert
								</i>
							</div>
						)}
					</div>
				);
			},
		},
	];
};

export async function getStudentList() {
	try {
		const res = await studentAPI.studentList();
		if (res.success) {
			return res.data;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
	}
}
