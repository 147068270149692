import chatAPI from '../../../../api/chatApi';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

async function getChat(chatId) {
	try {
		let res = await chatAPI.chat(chatId);
		if (res.success) {
			return res.messages;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
}

const ChatDetails = ({ isModalOpen, setIsModalOpen, chatRoom }) => {
	const [messages, setMessages] = useState([]);
	let lastSender = '';
	const teacherStyle = {
		textAlign: 'right',
	};
	const parentStyle = {
		borderRaduis: '10px',
	};

	useEffect(() => {
		// console.log('in the chatdetails component');
		if (chatRoom) getChat(chatRoom.id).then((chat) => setMessages(() => [...chat]));
	}, [chatRoom]);

	return (
		<Modal onHide={setIsModalOpen} show={isModalOpen} centered>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title'>Chat Details</h5>

					<button title='close' type='button' className='btn-close' onClick={() => setIsModalOpen(false)}></button>
				</div>
				<div
					className='modal-body'
					style={{
						maxHeight: 'calc(100vh - 210px)',
						overflowY: 'auto',
					}}>
					{messages.map((message) => {
						const tick = message.isRead ? '✅' : '';
						const isTeacher =
							chatRoom.members.filter((member) => member.userId === message.fromUserId).length > 0 &&
							chatRoom.members.filter((member) => member.userId === message.fromUserId)[0].personId;
						const style = isTeacher ? teacherStyle : parentStyle;
						if (lastSender !== message.fromUserId) {
							lastSender = message.fromUserId;
							return (
								<div style={style}>
									<h6>{message.fromUserId}</h6>
									{isTeacher ? <p>{message.message + tick}</p> : <p>{tick + message.message}</p>}
								</div>
							);
						} else
							return <div style={style}>{isTeacher ? <p>{message.message + tick}</p> : <p>{tick + message.message}</p>}</div>;
					})}
				</div>
			</div>
		</Modal>
	);
};

export default ChatDetails;
