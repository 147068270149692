import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IMAGES } from '../Dashboard/Content';
import { Dropdown, Modal } from 'react-bootstrap';
import BasicModal from '../Dashboard/BasicModal';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import swal from 'sweetalert';
import classAPI from '../../../api/classApi';
import Select from 'react-select';
import Pagination from '../components/Pagination';

const Class = () => {
	const navigate = useNavigate();
	const [classList, setClassList] = useState([]);
	const [addClass, setAddClass] = useState(false);
	const [assignClass, setAssignClass] = useState(false);
	const [className, setClassName] = useState('');
	const COLUMNS = [
		{
			Header: 'Name',
			Footer: 'Name',
			accessor: 'name',
		},
		{
			Header: 'Teachers',
			Footer: 'Teachers',
			accessor: 'teachers',
		},
		{
			Header: 'Action',
			Footer: 'Action',
		},
	];
	const columns = useMemo(() => COLUMNS, []);

	async function getClasses() {
		const res = await classAPI.classList();
		// console.log(res);
		if (res && res.success) {
			setClassList(res.data);
		}
	}

	async function createClass() {
		if (className.length === 0) {
			swal('Warning', 'Please enter the class name.', 'error');
			return;
		}
		let data = {
			name: className,
		};
		const res = await classAPI.createClass(data);
		if (res && res.success) {
			swal('Success', 'You have added a class.', 'success').then(() => {
				window.location.reload();
			});
		} else {
			alert('Failed to add class.');
		}
	}

	async function deleteClass(classId) {
		swal({
			title: 'Are you sure?',
			text: 'Once deleted, you will not be able to recover!',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: 'swal-border-danger',
		}).then(async (yes) => {
			if (yes) {
				const res = await classAPI.deleteClass(classId);
				if (res && res.success) {
					swal('Success', 'You have deleted a class.', 'success').then(() => {
						window.location.reload();
					});
				} else {
					swal('Alert', 'Failed to remove the class. Please try again later.', 'error');
				}
			} else {
				return;
			}
		});
	}

	useEffect(() => {
		getClasses();
	}, []);

	const data = useMemo(() => classList, [classList]);
	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, usePagination);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		setPageSize,
	} = tableInstance;
	const { globalFilter, pageIndex, pageSize } = state;
	return (
		<>
			<div className='row'>
				<div className='col-xl-12'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='page-title flex-wrap'>
								<div className='input-group search-area mb-md-0 mb-3'>
									<input
										type='text'
										className=' form-control'
										value={globalFilter || ''}
										onChange={(e) => setGlobalFilter(e.target.value)}
										placeholder='Search here...'
									/>
									<span className='input-group-text'>
										<Link to={'#'}>
											<svg width='15' height='15' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z'
													fill='#01A3FF'
												/>
											</svg>
										</Link>
									</span>
								</div>
								<div className='d-flex'>
									<button
										type='button'
										className='btn btn-primary'
										onClick={() => {
											setAddClass(true);
										}}>
										+ New Class
									</button>
								</div>
							</div>
						</div>
						<div className='col-xl-12 wow fadeInUp' data-wow-delay='1.5s'>
							<div className='table-responsive full-data'>
								<div id='' className='dataTables_wrapper no-footer'>
									<table
										{...getTableProps()}
										className='table-responsive-lg table display dataTablesCard student-tab dataTable no-footer'>
										<thead>
											{headerGroups.map((headerGroup) => {
												return (
													<tr {...headerGroup.getHeaderGroupProps()}>
														{headerGroup.headers.map((column, idx) => (
															<th
																key={idx}
																className={
																	column.Header.toLowerCase() === 'name' ? 'col-xl-6' : 'col-xl-4'
																}>
																{column.render('Header')}
															</th>
														))}
													</tr>
												);
											})}
										</thead>
										<tbody {...getTableBodyProps()} className=''>
											{page.map((row) => {
												prepareRow(row);
												return (
													<tr {...row.getRowProps()}>
														{row.cells.map((cell, index) => {
															if (cell.column.id.toLowerCase() === 'action') {
																return (
																	<td key={index}>
																		<div className='d-flex justify-content-start'>
																			<div
																				className='icon-box icon-box-sm bg-light me-2'
																				onClick={() => {
																					navigate(`/class/assign/${cell.row.original.id}`, {
																						state: { name: cell.row.original.name },
																					});
																				}}>
																				<i
																					className='material-symbols-outlined'
																					style={{ fontSize: 10, color: '#4D44B5' }}>
																					edit
																				</i>
																			</div>
																			{/* <div
																				className='icon-box icon-box-sm bg-light me-2'
																				onClick={() => {
																					setAssignClass(true);
																				}}>	
																				<i
																					className='material-symbols-outlined'
																					style={{ fontSize: 10, color: '#4D44B5' }}>
																					badge
																				</i>
																			</div> */}
																			<div
																				className='icon-box icon-box-sm bg-light me-2'
																				onClick={() => {
																					deleteClass(cell.row.original.id);
																				}}>
																				<i
																					className='material-symbols-outlined'
																					style={{ fontSize: 8, color: '#4D44B5' }}>
																					delete
																				</i>
																			</div>
																		</div>
																	</td>
																);
															}
															if (
																cell.column.id.toLowerCase() === 'teachers' &&
																cell.row.original.people.length > 0
															) {
																return (
																	<td key={index}>
																		{cell.row.original.people.map((r, i) => {
																			if (r.role.toLowerCase() === 'staff') {
																				return (
																					<span key={i}>
																						{r.person.name}
																						<br />
																					</span>
																				);
																			}
																		})}
																	</td>
																);
															} else {
																return <td key={index}>{cell.value ? cell.render('Cell') : '-'}</td>;
															}
														})}
													</tr>
												);
											})}
										</tbody>
									</table>
									<Pagination
										nextPage={nextPage}
										previousPage={previousPage}
										canNextPage={canNextPage}
										canPreviousPage={canPreviousPage}
										pageIndex={pageIndex}
										pageOptions={pageOptions}
										pageSize={pageSize}
										setPageSize={setPageSize}
										gotoPage={gotoPage}
										pageCount={pageCount}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal className='modal fade' show={addClass} onHide={setAddClass} centered>
				<div className='modal-header'>
					<h4 className='modal-title fs-20'>Add Class</h4>
					<button
						title='close'
						type='button'
						className='btn-close'
						onClick={() => setAddClass(false)}
						data-dismiss='modal'></button>
				</div>
				<div className='modal-body'>
					<div className='add-contact-box'>
						<div className='add-contact-content'>
							<div className='form-group mb-3'>
								<label className='text-black font-w500'>Name</label>
								<div className='contact-occupation'>
									<input
										type='text'
										autoComplete='off'
										onChange={(e) => {
											setClassName(e.target.value);
										}}
										name='classname'
										required='required'
										className='form-control'
										placeholder='name'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='modal-footer'>
					<button
						className='btn btn-primary'
						onClick={() => {
							setAddClass(false);
							createClass();
						}}>
						Add
					</button>
				</div>
			</Modal>
			<Modal className='modal fade' show={assignClass} onHide={setAssignClass} centered>
				<div className='modal-header'>
					<h4 className='modal-title fs-20'>Assign Teacher</h4>
					<button
						title='close'
						type='button'
						className='btn-close'
						onClick={() => setAssignClass(false)}
						data-dismiss='modal'></button>
				</div>
				<div className='modal-body'>
					<div className='add-contact-box'>
						<div className='add-contact-content'>
							<div className='form-group mb-3'>
								<label className='text-black font-w500'>Name</label>
								<div className='contact-occupation'>
									<input
										type='text'
										autoComplete='off'
										onChange={(e) => {
											setClassName(e.target.value);
										}}
										name='classname'
										required='required'
										className='form-control'
										placeholder='name'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='modal-footer'>
					<button
						className='btn btn-primary'
						onClick={() => {
							setAssignClass(false);
						}}>
						Add
					</button>
				</div>
			</Modal>
			{/* <BasicModal ref={childRef} /> */}
		</>
	);
};

export default Class;
